import { Save, Shaders } from "@ortho-next/nextray-core";
import { BufferGeometry, Euler, Mesh, ShaderMaterial, ShapeBufferGeometry, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";

export class CroppedPlane extends Mesh {
  @Save("attributes.position", "attributes.normal", "attributes.uv", "index")
  public geometry: ShapeBufferGeometry; //override
  public material: ShaderMaterial; //override

  public onAfterRestore = () => {
    this.geometry.computeBoundingSphere();
  }

  constructor(planeMaterial: ShaderMaterial) {
    super(new BufferGeometry(), new ShaderMaterial({
      vertexShader: Shaders.planeVertexShader,
      fragmentShader: Shaders.croppedPlaneFragmentShader,
      uniforms: {
        brightness: planeMaterial.uniforms.brightness,
        contrast: planeMaterial.uniforms.contrast,
        hFlip: planeMaterial.uniforms.hFlip,
        vFlip: planeMaterial.uniforms.vFlip,
        map: planeMaterial.uniforms.map
      }
    }));
    this.name = "CroppedPlane";
    this.isDraggable = true;
  }

  public setGeometry(geometry: ShapeBufferGeometry) {
    this.geometry.dispose();
    this.geometry = geometry;
  }

  /**
   * Gets vertices of cropped plane.
   */
  public getVertices(rotation: Euler): Vector3[] {
    const positions = this.geometry.getAttribute("position").array as number[];
    const ret: Vector3[] = [
      this.localToWorld(new Vector3(positions[0], positions[1])).setZ(0).applyEuler(rotation),
      this.localToWorld(new Vector3(positions[3], positions[4])).setZ(0).applyEuler(rotation),
      this.localToWorld(new Vector3(positions[6], positions[7])).setZ(0).applyEuler(rotation),
    ];
    positions.length > 9 && (ret.push(this.localToWorld(new Vector3(positions[9], positions[10])).setZ(0).applyEuler(rotation)));
    positions.length > 12 && (ret.push(this.localToWorld(new Vector3(positions[12], positions[13])).setZ(0).applyEuler(rotation)));
    return ret;
  }
}

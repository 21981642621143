import { LabelTextParams, SelectedApexMech, VectorUtils, ViewType } from "@ortho-next/nextray-core";
import { bindedModel } from "../../Models/BindedModel";
import { FormatUtils } from "../../Utils/FormatUtils";
import { MechanicalAxisAP } from "../DeformityAnalyzer/FullAnalyzerAP";
import { InsertionPoint } from "../InsertionPoint/InsertionPoint";
import { Osteotomy } from "./Osteotomy";

export class OsteotomyAP extends Osteotomy {
  protected _mechanicalAxis: MechanicalAxisAP;

  constructor(mechanicalAxis: MechanicalAxisAP, referencePoint: InsertionPoint, isEOC?: boolean) {
    super(ViewType.AP, referencePoint as any, isEOC);
    this._mechanicalAxis = mechanicalAxis;

    if (!this._isEOC) {
      this.C.bindEvent("onPositionComponentChange", () => {
        this.updateLabelText();
      });
    }
  }

  /**
   * Not used on fitbone app
   */
  public setPositionByApex(selectedApex: SelectedApexMech, distanceToReferenceePoint?: number): void {
    throw new Error("Method not implemented.");
  }

  /**
   * Sets position based on insertion point and selected apex.
   */
  public setPositionByInsertionPoint(insertionPoint: InsertionPoint): void {
    switch (bindedModel.selectedApex) {
      case SelectedApexMech.femurProximal:
      case SelectedApexMech.femurDistal: {
        const femur = this._mechanicalAxis.femur;
        const dir = femur.anatomical.NS_GT_lower_C.position.clone().sub(femur.anatomical.NS_GT_upper_C.position);
        const perpDir = VectorUtils.getPerpendicular(dir);
        const refPoint = this._referencePoint.position.clone().setZ(0);

        const upperPoint = bindedModel.selectedApex === SelectedApexMech.femurProximal ?
          refPoint :
          VectorUtils.lines2DIntersection(refPoint, refPoint.clone().add(dir), femur.mechanical.GT.position, femur.mechanical.GT.position.clone().add(perpDir))

        const lowerPoint = bindedModel.selectedApex === SelectedApexMech.femurProximal ?
          VectorUtils.lines2DIntersection(refPoint, refPoint.clone().add(dir), femur.mechanical.CE.position, femur.mechanical.CE.position.clone().add(perpDir)) :
          refPoint;

        this.setPointsForSync(lowerPoint, upperPoint);
        const projectedInsertionPoint = VectorUtils.projectOnVector(insertionPoint.position, femur.anatomical.NS_GT_lower_C.position, femur.anatomical.NS_GT_upper_C.position);
        this.placeOsteotomyByApex(projectedInsertionPoint.add(dir.setLength(60 * (bindedModel.selectedApex === SelectedApexMech.femurProximal ? 1 : -1))),
          VectorUtils.getPerpendicular(dir).setLength(this._initialLength / 2));
        break;
      }
      case SelectedApexMech.tibiaProximal: {
        const tibia = this._mechanicalAxis.tibia;
        const dir = tibia.proximalDirToCalculateApex;
        //const dir = tibia.CA.position.clone().sub(tibia.CP.position);
        this.setPointsForSync(tibia.CA.position, tibia.CP.position);
        const projectedInsertionPoint = VectorUtils.projectOnVector(insertionPoint.position, tibia.CA.position, tibia.CP.position);
        this.placeOsteotomyByApex(projectedInsertionPoint.add(dir.setLength(-60)), VectorUtils.getPerpendicular(dir).setLength(this._initialLength / 2));
      }
    }

    this.update();
  }

  private updateLabelText(): void {
    const textParams: LabelTextParams[] = [
      { text: 'Osteotomy' },
      { text: `level: ${FormatUtils.distance(this.level)} mm`, fontSize: 60 }
    ];
    this.label.setParam("text", textParams);
  }

  /**
  * Get osteotomy level
  */
  public get level(): number {
    switch (bindedModel.selectedApex) {
      case SelectedApexMech.femurProximal: return this.C.position.distanceTo(this._mechanicalAxis.femur.mechanical.GT.position);
      case SelectedApexMech.femurDistal: return this.C.position.distanceTo(this._mechanicalAxis.femur.mechanical.CE.position);
      case SelectedApexMech.tibiaProximal: return this.C.position.distanceTo(this._mechanicalAxis.tibia.CP.position);
      default: return null;
    }
  }

}

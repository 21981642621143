import { LimitUtils as LimitUtilsBase, SelectedApexMech, VectorUtils } from "@ortho-next/nextray-core";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { bindedModel } from "../Models/BindedModel";
import { Consts } from "./Consts";

/**
 * Contains functions to format data.
 */
export class LimitUtils extends LimitUtilsBase {

 /**
   * Returns true if both osteotomies collide.
   */
  public static applyOsteotomySegmentsTouchingLimit(ostA: Vector3, ostB: Vector3, newClOstA: Vector3, newClOstB: Vector3, axis: Vector3): boolean { //axis could be switch with eoc.cropDir
    const axisFixed = bindedModel.selectedApex !== SelectedApexMech.tibiaProximal ? axis : axis.clone().multiplyScalar(-1);
    const invalidPoint = ostA.clone().lerp(ostB, 0.5).add(axisFixed);
    const ostA2 = ostA.clone().add(axisFixed.clone().setLength(10000));
    const ostB2 = ostB.clone().add(axisFixed.clone().setLength(10000));
    return (VectorUtils.areSegmentTouching(ostA, ostB, newClOstA, newClOstB) ||
      VectorUtils.areSegmentTouching(ostA, ostA2, newClOstA, newClOstB) ||
      VectorUtils.areSegmentTouching(ostB, ostB2, newClOstA, newClOstB)) || (
        VectorUtils.arePointsOnSameSide(ostA, ostB, Consts.planeNormal, invalidPoint, newClOstA, newClOstB) &&
        VectorUtils.arePointsOnSameSide(ostA, ostA2, Consts.planeNormal, invalidPoint, newClOstA, newClOstB) &&
        VectorUtils.arePointsOnSameSide(ostB, ostB2, Consts.planeNormal, invalidPoint, newClOstA, newClOstB)
      );
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { SelectedApexMech, ViewType } from '@ortho-next/nextray-core';
import { bindedModel } from '../../../../nextray/Models/BindedModel';
import { Case, LanguageService } from '../../../core';
import { CanvasService, UserService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
* First page of PDF print.
*/
@Component({
	selector: 'prt-or-plan-page',
	templateUrl: './prt-or-plan-page.component.html'
})
export class PrintOrPlanPageComponent extends BaseComponent implements OnInit {

	private _isDemo: boolean;

	@Input() today: number;
	@Input() viewType: ViewType;
	/**
	 * Doctor full name who generated the report.
	 */
	drFullName: string;
	/**
	 * Mechanical Axis bone(femur/tibia) lengthening by insertion approach.
	 */
	maLength: string;
	/**
	 * Deformity analysis src image.
	 */
	defAnalysSrc: string;
	/**
	 * Fitbone close src image.
	 */
	fitboneCloseScr: string;
	/**
	 * Fitbone open src image.
	 */
	fitboneOpenScr: string;
	/**
	 * Current loaded case.
	 */
	currentCase: Case;
	/**
	 * Fitbone name selected.
	 */
	implant: string;
	/**
	 * Fitbone max length.
	 */
	fitboneLength: number;
	/**
	 * Insertion point is on femur.
	 */
	isInsPointFemur: boolean;
	/**
	 * Insertion point is on tibia.
	 */
	isInsPointTibia: boolean;
	/**
	 * Target bone(tibia/femur) length label. 
	 */
	targetBoneLengthLbl: string;
	/**
	 * mLDFA rpm.
	 */
	mLDFA?: number;
	/**
	 * MPTA: rpm;
	 */
	MPTA?: number;
	/**
	 * Axsis correction.
	 */
	axisCorrection?: string;
	/**
	 * Femur axial rotation correction.
	 */
	femurAxialRotation: string;
	/**
	 * Tibia axial rotation correction.
	 */
	tibiaAxialRotation: string;
	/**
	 * Osteotomy level.
	 */
	osteotomyLevel: number;

	lineOffset: number;

	FHPropAPlineOffset: number;

	CAPropAPlineOffset: number;

	/**
	 * Watermark Demo
	 */
	get isDemo(): boolean {
		return this._isDemo;
	}

	get isViewTypeAP(): boolean {
		return this.viewType === ViewType.AP;
	}

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		private usrSrv: UserService,
	) {
		super(langSrv);
		this.osteotomyLevel = this.canvasSrv.osteotomyLevel;
		this.maLength = this.canvasSrv.maBoneLengthening;
		this.currentCase = this.canvasSrv.currentCase;
		const fitbone = this.canvasSrv.fitbone;
		this.implant = fitbone.description;
		this.fitboneLength = fitbone.maxLength;
		const selectedApex = this.canvasSrv.selectedApex;
		const defAPMeasures = this.canvasSrv.defAPMeasures;
		this.mLDFA = this.canvasSrv.rpmMeasures.rpm_ap_mLDFA;
		this.MPTA = this.canvasSrv.rpmMeasures.rpm_ap_MPTA;

		if (selectedApex === SelectedApexMech.femurDistal || selectedApex === SelectedApexMech.femurProximal) {
			this.targetBoneLengthLbl = this.labels.FEMUR_LENGTHENING;
			this.isInsPointFemur = true;
			this.axisCorrection = defAPMeasures.def_ap_apexFemur || defAPMeasures.def_ap_apexFemur === 0 ? `${defAPMeasures.def_ap_apexFemur} ${this.apexSignLabel(defAPMeasures.def_ap_apexFemurSign)}` : '';
			this.femurAxialRotation = `${this.canvasSrv.def_femur_axialRotation} ${this.axialRotSignLabel(this.canvasSrv.def_femur_axialRotationSign)}`;
		}

		if (selectedApex === SelectedApexMech.tibiaProximal) {
			this.targetBoneLengthLbl = this.labels.TIBIA_LENGTHENING;
			this.isInsPointTibia = true;
			this.axisCorrection = defAPMeasures.def_ap_apexTibia || defAPMeasures.def_ap_apexTibia === 0 ? `${defAPMeasures.def_ap_apexTibia} ${this.apexSignLabel(defAPMeasures.def_ap_apexTibiaSign)}` : '';
			this.tibiaAxialRotation = `${this.canvasSrv.def_tibia_axialRotation} ${this.axialRotSignLabel(this.canvasSrv.def_tibia_axialRotationSign)}`;
		}

		this.usrSrv.isDemoUser().subscribe((isDemoUser) => {
			this._isDemo = isDemoUser;
		});

		this.usrSrv.getCurrentUser().subscribe((user) => {
			this.drFullName = user.firstName + ' ' + user.lastName;
		});
	}

	ngOnInit(): void {
		const topOffsetToFix = -2;
		this.lineOffset = bindedModel.PrintTargetPropAP + topOffsetToFix;
		this.FHPropAPlineOffset = bindedModel.PrintFHPropAP + topOffsetToFix;
		this.CAPropAPlineOffset = bindedModel.PrintCAPropAP + topOffsetToFix;
		this.defAnalysSrc = this.viewType === ViewType.AP ? this.canvasSrv.printResult.deformityAP : this.canvasSrv.printResult.deformityLT;
		this.fitboneCloseScr = this.viewType === ViewType.AP ? this.canvasSrv.printResult.fitboneCloseAP : this.canvasSrv.printResult.fitboneCloseLT;
		this.fitboneOpenScr = this.viewType === ViewType.AP ? this.canvasSrv.printResult.fitboneOpenAP : this.canvasSrv.printResult.fitboneOpenLT;
	}

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CorrectionModule } from '../correction';
import { DeformityModule } from '../deformity';
import { ProductModule } from '../product';
import { SharedModule } from '../shared';
import { ViewerModule } from '../viewer';
import { DrawerComponent } from './drawer';
import { ImageElaborationComponent } from './image-elaboration';
import { SidebarComponent } from './sidebar.component';
import { ToolsMenuComponent } from './tools-menu';

@NgModule({
  declarations: [
    SidebarComponent,
    DrawerComponent,
    ToolsMenuComponent,
    ImageElaborationComponent
  ],
  exports: [
    SidebarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DeformityModule,
    CorrectionModule,
    ProductModule,
    ViewerModule,
    SharedModule
  ]
})
export class SidebarModule { }

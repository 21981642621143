  <!-- Main container -->
  <div class="container-tlhex">

    <span class="wrapper-demo-text demo-text" *ngIf="isDemoUser">NOT FOR CLINICAL USE</span>

    <canvas-tabset></canvas-tabset>
    <!--NOTA: quando stiamo visualizzando la schermata AP o Lateral, bisogna aggiungere al seguente div la classe "wrapper-single-img". Se siamo su Multiple, invece, deve scomparire -->
    <div class="wrapper-ap">
      <div class="content">

        <!-- SINGLE VIEW FOCUS -->
        <div class="divider-single" *ngIf="activeViewState !== activeViewStateEnum.multiple">
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="left"></div>
          <div class="right"></div>
        </div>

        <!-- CENTRAL DIVIDER -->
        <div class="divider-multiple" *ngIf="activeViewState == activeViewStateEnum.multiple"></div>

        <!-- LEFT FOCUS -->
        <div
          [ngClass]="{'selected': activeViewState == activeViewStateEnum.multiple && activeView == activeViewEnum.AP }">
          <div class="line top-left"></div>
          <div class="line left"></div>
          <div class="line bottom-left"></div>
        </div>
        <!-- RIGHT FOCUS -->
        <div
          [ngClass]="{'selected': activeViewState == activeViewStateEnum.multiple && activeView == activeViewEnum.LT }">
          <div class="line top-right"></div>
          <div class="line right"></div>
          <div class="line bottom-right"></div>
        </div>

        <!-- AP VIEW LABELS -->
        <div *ngIf="activeViewState == activeViewStateEnum.AP">
          <div class="btn-xray left">
            {{ isLeft ? labels.CANVAS3D_COMPONENT_MEDIAL_LABEL : labels.CANVAS3D_COMPONENT_LATERAL_LABEL }}</div>
          <div class="btn-xray right">
            {{ isLeft ? labels.CANVAS3D_COMPONENT_LATERAL_LABEL : labels.CANVAS3D_COMPONENT_MEDIAL_LABEL }}</div>
        </div>

        <!-- LT VIEW LABELS -->
        <div *ngIf="activeViewState == activeViewStateEnum.LT">
          <div class="btn-xray left">
            {{ isLeft ? labels.CANVAS3D_COMPONENT_POSTERIOR_LABEL : labels.CANVAS3D_COMPONENT_ANTERIOR_LABEL }}</div>
          <div class="btn-xray right">
            {{ isLeft ? labels.CANVAS3D_COMPONENT_ANTERIOR_LABEL : labels.CANVAS3D_COMPONENT_POSTERIOR_LABEL }}</div>
        </div>

        <!-- MULTIPLE VIEW LABELS -->
        <div *ngIf="activeViewState == activeViewStateEnum.multiple">
          <div class="multiple-left">
            <div class="btn-xray left">
              {{ isLeft ? labels.CANVAS3D_COMPONENT_MEDIAL_LABEL : labels.CANVAS3D_COMPONENT_LATERAL_LABEL }}</div>
            <div class="btn-xray right">
              {{ isLeft ? labels.CANVAS3D_COMPONENT_LATERAL_LABEL : labels.CANVAS3D_COMPONENT_MEDIAL_LABEL }}</div>
          </div>
          <div class="multiple-right">
            <div class="btn-xray left">
              {{ isLeft ? labels.CANVAS3D_COMPONENT_POSTERIOR_LABEL : labels.CANVAS3D_COMPONENT_ANTERIOR_LABEL }}</div>
            <div class="btn-xray right">
              {{ isLeft ? labels.CANVAS3D_COMPONENT_ANTERIOR_LABEL : labels.CANVAS3D_COMPONENT_POSTERIOR_LABEL }}</div>
          </div>
        </div>

        <!-- CALIBRATION FACTOR -->
        <ng-container *ngIf="stateType >= stateTypeEnum.calibrated">
          <div class="wrapper-calibration left" *ngIf="isMultipleAP">{{ labels.CANVAS3D_COMPONENT_CALIBRATION_FACTOR }}
            {{ scaleFactorAP | number:'1.2-2' }}</div>
          <div class="wrapper-calibration right" *ngIf="!isMultipleAP">
            {{ labels.CANVAS3D_COMPONENT_CALIBRATION_FACTOR }}
            {{ (activeViewState == activeViewStateEnum.AP ? scaleFactorAP : scaleFactorLT) | number:'1.2-2' }}</div>
        </ng-container>

        <!--RIGHT WIDGET ZOOM SINGLE VIEW -->
        <div class="widget-zoom" *ngIf="!isMultipleAP">
          <div class="btn-zoom" title="{{ labels.CANVAS3D_COMPONENT_FIT_TO_VIEW }}" (click)="fitToView()">
            <i class="material-icons">fullscreen</i>
          </div>
          <div class="btn-zoom" title="{{ labels.CANVAS3D_COMPONENT_ZOOM_IN }}" (click)="zoomIn()">
            <i class="material-icons">add</i>
          </div>
          <div class="btn-zoom" title="{{ labels.CANVAS3D_COMPONENT_ZOOM_OUT }}" (click)="zoomOut()">
            <i class="material-icons">remove</i>
          </div>
        </div>

        <!-- LEFT WIDGET ZOOM FOR MULTIPLE VIEW -->
        <div class="widget-zoom second" *ngIf="isMultipleAP">
          <div class="btn-zoom" title="{{ labels.CANVAS3D_COMPONENT_FIT_TO_VIEW }}" (click)="fitToView()">
            <i class="material-icons">fullscreen</i>
          </div>
          <div class="btn-zoom" title="{{ labels.CANVAS3D_COMPONENT_ZOOM_IN }}" (click)="zoomIn()">
            <i class="material-icons">add</i>
          </div>
          <div class="btn-zoom" title="{{ labels.CANVAS3D_COMPONENT_ZOOM_OUT }}" (click)="zoomOut()">
            <i class="material-icons">remove</i>
          </div>
        </div>

        <!-- Canvas -->
        <div style="height: 100%" id="canvas-container"></div>


        <layers></layers>

        <messages></messages>

      </div>
    </div>


  </div>

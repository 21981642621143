<div class="spacing-top">
  <a class="wrapper-back" (click)="back()">
    <span class="material-icons md-18 back">arrow_back</span>
    <span class="action">{{ labels.TEMPLATE_SELECTION_COMPONENT_CANCEL_BTN }}</span>
  </a>
</div>

<div class="main-step">
  <div class="box-menu-settings active">

    <!-- TEMPLATE SELECTION -->
    <div class="wrapper-select-accordion">

      <div class="row-box">
        <div class="box-suggestion">
          <p>
            {{ labels.TEMPLATE_SELECTION_COMPONENT_MESSAGE }}
          </p>
        </div>
      </div>

      <div class="row-box pt-2 pb-3" style="min-height: 23rem;">
        <div class="w-100 align-self-start">
          <h4 class="mb-3">{{ labels.TEMPLATE_SELECTION_COMPONENT_RESULTS }} &nbsp; {{ fitboneList?.length || 0 }}</h4>

          <div class="result-filter" *ngIf="fitboneList && fitboneList.length > 0; else loading" [ngClass]="{'loading': isFitboneLoadingAP || isFitboneLoadingLT }">
            <div *ngFor="let fitbone of fitboneList; let i = index" (click)="insertTemplate(i)">{{ fitbone.description }}</div>
          </div>

          <ng-template #loading>
            <div class="d-flex justify-content-center align-items-center" style="height: 18rem;">
              <img [src]="LOADER_URL" alt="loading" />
            </div>
          </ng-template>

        </div>
      </div>

      <div class="row-box">
        <div class="form-group">
          <label class="mb-0" for="InsertionApproach">{{ labels.TEMPLATE_SELECTION_COMPONENT_INSERTION_APPROACH }}</label>
          <div>{{ insertionApproach }}</div>
        </div>
      </div>

      <div class="row-box">
        <div class="form-group">
          <label class="mb-0" for="Diameter">{{ labels.TEMPLATE_SELECTION_COMPONENT_DIAMETER }}</label>
          <div>{{ diameter }}</div>
        </div>
      </div>

      <div class="row-box">
        <div class="form-group">
          <label class="mb-0" for="Stroke">{{ labels.TEMPLATE_SELECTION_COMPONENT_STROKE }}</label>
          <div>{{ stroke }}</div>
        </div>
      </div>

      <div class="row-box">
        <div class="form-group">
          <label class="mb-0" for="MaxLength">{{ labels.TEMPLATE_SELECTION_COMPONENT_MAX_LENGTH }}</label>
          <div>{{ maxLength }}</div>
        </div>
      </div>

      <div class="row-box mb-2">
        <div class="form-group">
          <label class="mb-0" for="MaxPatientWeight">{{ labels.TEMPLATE_SELECTION_COMPONENT_MAX_PAT_WEIGHT }}</label>
          <div>{{ maxPatientWeight }}</div>
        </div>
      </div>

      <div class="mt-auto">
        <div class="divider-box"></div>
        <div class="wrapper-btn-confirm">
          <button type="button" class="btn btn-primary pl-2 pr-2"
                  [disabled]="!isFitboneInserted" (click)="confirmTemplate()">
            {{ labels.TEMPLATE_SELECTION_COMPONENT_BUTTON }}
          </button>
        </div>
      </div>

    </div>



  </div>
</div>

import { LimitUtils, Save, SelectedApexMech, VectorUtils } from "@ortho-next/nextray-core";
import { Mesh, MeshLambertMaterial, SphereBufferGeometry, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { bindedModel } from "../../Models/BindedModel";
import { MechanicalAxisAP } from "../DeformityAnalyzer/FullAnalyzerAP";
import { MechanicalAxisLT } from "../DeformityAnalyzer/FullAnalyzerLT";
import { DiaphysisAnatomicalAxis } from "../DiaphysisAnatomicalAxis";
import { InsertionPoint } from "../InsertionPoint/InsertionPoint";
import { Osteotomy } from "../Osteotomy/Osteotomy";

export class BlockingScrew extends Mesh {
  public static sphereGeometry = new SphereBufferGeometry(2.25, 24, 24);
  public material: MeshLambertMaterial; //override
  @Save() private _axisPoint1: Vector3;
  @Save() private _axisPoint2: Vector3;
  @Save() private _axisPerp: Vector3;
  @Save() private _axisDir: Vector3;
  @Save() private _axisValidPoint: Vector3;
  public moveWithEOC: boolean;

  constructor() {
    super(BlockingScrew.sphereGeometry, new MeshLambertMaterial({ color: 0x3333cc }));  //put in config
    this.name = "Blocking Screw";

    this.isDraggable = true;

    this.bindEvent("onDragMove", (args) => {
      LimitUtils.applyLimitAndProjectVector(this._axisPoint1, this._axisPoint1.clone().add(this._axisPerp), args.position, this._axisValidPoint);
      LimitUtils.applyLimitAndProjectVector(this._axisPoint2, this._axisPoint2.clone().add(this._axisPerp), args.position, this._axisValidPoint);
    });

    // this.bindEvent
  }

  /**
   * Gets position according to selected apex and mechanical axis points.
   */
  private calculatePosition(osteotomy: Osteotomy, eocDir: Vector3): void {
    this.position.copy(osteotomy.A.position).sub(eocDir.clone().setLength(20));
  }

  private setPointsForSync(p1: Vector3, p2: Vector3): void {
    this._axisPoint1 = p1.clone();
    this._axisPoint2 = p2.clone();
    this._axisDir = p1.clone().sub(p2).normalize();
    this._axisPerp = VectorUtils.getPerpendicular(this._axisDir);
    this._axisValidPoint = p1.clone().lerp(p2, 0.5);
  }

  /**
   * Init the blocking screw position and its relative moving limits.
   */
  public init(mechanicalAxis: MechanicalAxisAP | MechanicalAxisLT, referencePoint: InsertionPoint, diaphysisAA: DiaphysisAnatomicalAxis, osteotomy: Osteotomy, eocDir: Vector3): void {
    this.calculatePosition(osteotomy, eocDir);

    switch (bindedModel.selectedApex) {
      case SelectedApexMech.femurProximal:
      case SelectedApexMech.femurDistal: {
        if (mechanicalAxis instanceof MechanicalAxisAP) {
          const femur = mechanicalAxis.femur;
          const dir = femur.anatomical.NS_GT_lower_C.position.clone().sub(femur.anatomical.NS_GT_upper_C.position);
          const perpDir = VectorUtils.getPerpendicular(dir);
          const refPoint = referencePoint.position.clone().setZ(0);

          const upperPoint = bindedModel.selectedApex === SelectedApexMech.femurProximal ?
            refPoint :
            VectorUtils.lines2DIntersection(refPoint, refPoint.clone().add(dir), femur.mechanical.GT.position, femur.mechanical.GT.position.clone().add(perpDir))

          const lowerPoint = bindedModel.selectedApex === SelectedApexMech.femurProximal ?
            VectorUtils.lines2DIntersection(refPoint, refPoint.clone().add(dir), femur.mechanical.CE.position, femur.mechanical.CE.position.clone().add(perpDir)) :
            refPoint;

          this.setPointsForSync(lowerPoint, upperPoint);
        } else {
          const femur = mechanicalAxis.femur;
          const dir = diaphysisAA.B.position.clone().sub(diaphysisAA.A.position);
          const perpDir = VectorUtils.getPerpendicular(dir);
          const refPoint = referencePoint.position.clone().setZ(0);

          const upperPoint = bindedModel.selectedApex === SelectedApexMech.femurProximal ?
            refPoint :
            VectorUtils.lines2DIntersection(refPoint, refPoint.clone().add(dir), femur.FH.position, femur.FH.position.clone().add(perpDir));

          const lowerPoint = bindedModel.selectedApex === SelectedApexMech.femurProximal ?
            VectorUtils.lines2DIntersection(refPoint, refPoint.clone().add(dir), femur.TE.position, femur.TE.position.clone().add(perpDir)) :
            refPoint;

          this.setPointsForSync(lowerPoint, upperPoint);
        }
        break;
      }
      case SelectedApexMech.tibiaProximal: {
        if (mechanicalAxis instanceof MechanicalAxisAP) {
          const tibia = mechanicalAxis.tibia;
          this.setPointsForSync(tibia.CA.position, tibia.CP.position);
        } else {
          const tibia = mechanicalAxis.tibia;
          this.setPointsForSync(tibia.MA.position, tibia.FP.position);
        }
      }
    }
  }

  public dispose(): void {
    super.dispose();
    this.material.dispose();
  }
}

import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Table of wedge dimension in correction panel.
*/
@Component({
  selector: 'wedge-table',
  templateUrl: './wedge-table.component.html',
  styles: []
})
export class WedgeTableComponent extends BaseComponent implements OnInit {

  isCollapsed: boolean = true;

  constructor(
    private langSrv: LanguageService,
    private canvasSrv: CanvasService
  ) {
    super(langSrv, canvasSrv);
  }

  ngOnInit() { }

  /**
  * Get wedge angle in AP view.
  */
  get ap_wedgeAngle(): string {
    return this.rpmMeasures.rpm_ap_angle || this.rpmMeasures.rpm_ap_angle === 0 ? this.rpmMeasures.rpm_ap_angle.toString() : '';
  }

  /**
  * Get wedge angle in LAT view.
  */
  get lt_wedgeAngle(): string {
    return this.rpmMeasures.rpm_lt_angle || this.rpmMeasures.rpm_lt_angle === 0 ? this.rpmMeasures.rpm_lt_angle.toString() : '';
  }

  /**
  * Get cortex length in AP view.
  */
  get ap_cortexLength(): string {
    const value = this.rpmMeasures.rpm_ap_cortexLength;
    return value || value === 0 ? `${Math.abs(value)} ${value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_LENGTHENING'] : this.labels['WEDGE_TABLE_COMPONENT_SHORTENING']}` : '';
  }

  /**
  * Get cortex length in LAT view.
  */
  get lt_cortexLength(): string {
    const value = this.rpmMeasures.rpm_lt_cortexLength;
    return value || value === 0 ? `${Math.abs(value)} ${value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_LENGTHENING'] : this.labels['WEDGE_TABLE_COMPONENT_SHORTENING']}` : '';
  }

  /**
  * Get axial length in AP view.
  */
  get ap_axialLength(): string {
    const value = this.rpmMeasures.rpm_ap_axialLength;
    return value || value === 0 ? `${Math.abs(value)} ${value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_LENGTHENING'] : this.labels['WEDGE_TABLE_COMPONENT_SHORTENING']}` : '';
  }

  /**
  * Get axial length in LAT view.
  */
  get lt_axialLength(): string {
    const value = this.rpmMeasures.rpm_lt_axialLength;
    return value || value === 0 ? `${Math.abs(value)} ${value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_LENGTHENING'] : this.labels['WEDGE_TABLE_COMPONENT_SHORTENING']}` : '';
  }

  /**
  * Get secondary translation in AP view.
  */
  get ap_secondaryTransl(): string {
    const value = this.rpmMeasures.rpm_ap_secondaryTransl;
    return value || value === 0 ? `${Math.abs(value)} ${value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_MEDIAL'] : this.labels['WEDGE_TABLE_COMPONENT_LATERAL']}` : '';
  }

  /**
  * Get secondary translation in LAT view.
  */
  get lt_secondaryTransl(): string {
    const value = this.rpmMeasures.rpm_lt_secondaryTransl;
    return value || value === 0 ? `${Math.abs(value)} ${value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_POSTERIOR'] : this.labels['WEDGE_TABLE_COMPONENT_ANTERIOR']}` : '';
  }

}

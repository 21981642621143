import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared';
import { PrintMeasuresPageComponent, PrintOrPlanComponent, PrintOrPlanPageComponent } from './prt-or-plan';


const routes: Routes = [
  {
    path: 'or-plan',
    outlet: 'print',
    component: PrintOrPlanComponent,
  }
];


@NgModule({
  declarations: [
    PrintOrPlanComponent,
    PrintOrPlanPageComponent,
    PrintMeasuresPageComponent
  ],
  exports: [
    PrintOrPlanComponent,
    PrintOrPlanPageComponent,
    PrintMeasuresPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    SharedModule
  ]
})
export class PrintModule { }

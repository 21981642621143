import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { Binding } from "@ortho-next/three-base/Binding/Binding";
import { bindedModel, BindedModel } from "../../Models/BindedModel";
import { PrintStateTypes, StateTypes } from "../../States/State";
import { Consts } from "../../Utils/Consts";
import { MechanicalAxisAP } from "../DeformityAnalyzer/FullAnalyzerAP";
import { MechanicalAxisLT } from "../DeformityAnalyzer/FullAnalyzerLT";
import { InsertionPoint } from "../InsertionPoint/InsertionPoint";
import { Osteotomy } from "../Osteotomy/Osteotomy";
import { EoCPlaneBase } from "./EoCPlaneBase";
import { Plane } from "@ortho-next/nextray-core/Tools/Plane";
import { DraggablePoint, Save, SelectedApexMech, ToolEvents, VectorUtils, ViewType } from "@ortho-next/nextray-core";
import { LimitUtils } from "src/nextray/Utils/LimitUtils";

export class EoCPlane extends EoCPlaneBase {
  public fitboneDirection: Vector3 = Consts.verDir;
  public cropDirection: Vector3 = Consts.verDir;
  @Save() public diaphysisAADirection: Vector3 = Consts.verDir.clone();
  private _insertionPoint: InsertionPoint;
  private _targetPointDistanceTibiaLT: number;
  @Save() private _oldInsertionPointPosition: Vector3;

  constructor(viewType: ViewType, isLeft: boolean, plane: Plane, osteotomy: Osteotomy, mechanicalAxis: MechanicalAxisAP | MechanicalAxisLT, insertionPoint: InsertionPoint) {
    super(viewType, isLeft, plane, osteotomy, mechanicalAxis);
    this._insertionPoint = insertionPoint;

    Binding.createCustom("insertionPointRestore_" + viewType, (m: BindedModel) => m.EOCCropVisible, (value) => {
      if (!value) {
        this._oldInsertionPointPosition && this._insertionPoint.position.copy(this._oldInsertionPointPosition);
        this._oldInsertionPointPosition = undefined;
      }
    }, ["EOCCropVisible"]);
  }

  protected bindEvents(): void {
    this.croppedPlane.bindEvent("onDragStart", (args) => {
      this.updateCropDirection();
    });
  }

  public restoreInsertionPointPositionToPrintDeformity(): void {
    this._oldInsertionPointPosition && this._insertionPoint.position.copy(this._oldInsertionPointPosition);
  }

  public updateCropDirection(): void { //todo vedere se viene richiamata in maniera corretta
    this.cropDirection = Consts.verDir;
    const mech = this.clonedMechanicalAxis;
    if (mech instanceof MechanicalAxisAP) {
      if (bindedModel.isFitboneInserted) {
        this.cropDirection = this.fitboneDirection;
      } else if (bindedModel.selectedApex === SelectedApexMech.tibiaProximal) {
        this.cropDirection = mech.tibia.CP.position.clone().sub(mech.tibia.CA.position);
      } else {
        this.cropDirection = mech.femur.anatomical.NS_GT_lower_C.position.clone().sub(mech.femur.anatomical.NS_GT_upper_C.position);
      }
    } else {
      if (bindedModel.isFitboneInserted) {
        this.cropDirection = this.fitboneDirection;
      } else {
        if (bindedModel.selectedApex === SelectedApexMech.tibiaProximal) {
          this.cropDirection = mech.tibia.FP.position.clone().sub(mech.tibia.MA.position);
        } else {
          this.cropDirection = this.diaphysisAADirection;
        }
      }
    }

  }

  public getVerticalTranslation(): number {
    this.updateCropDirection();
    const ost1 = this.osteotomy.C.position;
    const ost2 = this.osteotomy.C.position.clone().add(VectorUtils.getPerpendicular(this.cropDirection));
    const proj = VectorUtils.projectOnVector(this.clonedOsteotomy.C.position, ost1, ost2);
    const sign = VectorUtils.arePointsOnSameSide(ost1, ost2, Consts.planeNormal, this.clonedOsteotomy.C.position, this.osteotomy.C.position.clone().add(this.cropDirection)) ? -1 : 1;
    return proj.distanceTo(this.clonedOsteotomy.C.position) * sign;
  }

  public setVerticalTranslation(value: number): Vector3 {
    this.updateCropDirection();
    const verticalDir = this.cropDirection.clone();
    //verticalDir.y > 0 && verticalDir.multiplyScalar(-1); // this can be opt probably
    const pan = verticalDir.setLength(this.getVerticalTranslation() - value);
    this.translationByPoint(pan.clone().add(this.croppedPlane.position));
    this.dispatchEvent({ type: ToolEvents.updated });
    return pan;
  }

  public get horizontalTranslation(): number {
    const verticalDir = this._viewType == ViewType.AP ? this.clonedMechanicalAxis.weightBearing.B.clone().sub(this.clonedMechanicalAxis.weightBearing.A) : this.cropDirection.clone();
    const ost1 = this.osteotomy.C.position;
    const ost2 = this.osteotomy.C.position.clone().add(verticalDir);
    const proj = VectorUtils.projectOnVector(this.clonedOsteotomy.C.position, ost1, ost2);
    let sign = VectorUtils.arePointsOnSameSide(ost1, ost2, Consts.planeNormal, this.clonedOsteotomy.C.position, this.osteotomy.C.position.clone().add(VectorUtils.getPerpendicular(verticalDir))) ? -1 : 1;
    sign *= this._isLeft ? 1 : -1;
    return proj.distanceTo(this.clonedOsteotomy.C.position) * sign;
  }

  protected bindProperties(): void {

    this.line4.bindProperty('visible', (m: BindedModel) => {
      return m.EOCCropVisible
        && m.printState === PrintStateTypes.none;
    }, ['EOCCropVisible', 'printState']);

    this.line5.bindProperty('visible', (m: BindedModel) => {
      return m.EOCCropVisible
        && m.printState === PrintStateTypes.none;
    }, ['EOCCropVisible', 'printState']);

    for (const child of this.children) {
      if (child.name !== 'CtrlBlackPlane' && child.name !== 'ClonedOsteotomy' && child.name !== 'RedShape') {
        if (child.name === 'Line1' || child.name === 'Line2' || child.name === 'Line3' || child.name === 'Ost1' || child.name === 'Ost2') {
          child.bindProperty('visible', (m: BindedModel) => {
            return m.EOCCropVisible
              && m.printState === PrintStateTypes.none;
          }, ['EOCCropVisible', 'printState']);
        } else {
          child.bindProperty('visible', (m: BindedModel) => {
            return m.EOCCropVisible
              && m.printState !== PrintStateTypes.deformityAnalysis;
          }, ['EOCCropVisible', 'printState']);
        }
      }
    }

    this.bindProperty('isEnabled', (m: BindedModel) => {
      return !m.readonly && m.appState === StateTypes.RPM;
    }, ['readonly', 'appState']);

    this.croppedPlane.bindProperty('isEnabled', (m: BindedModel) => {
      return !m.isFitboneInserted || m.isStrokeLocked;
    }, ['isFitboneInserted', 'isStrokeLocked']);

    this._plane.bindProperty('visible', (m: BindedModel) => {
      return m.layer_plane;
    }, ['layer_plane']);

  }

  protected calculateRotationCenter(): void {
    const mech = this.clonedMechanicalAxis;

    if (mech instanceof MechanicalAxisAP) { //check su selected apex
      this._rotationCenter = mech.CH.position;
    } else {
      this._rotationCenter = bindedModel.selectedApex === SelectedApexMech.tibiaProximal ?
        mech.tibia.proximalDirToCalculateApex.clone().setLength(-this._targetPointDistanceTibiaLT).add(mech.tibia.FP.position) :
        mech.femur.FH.position;
    }
  }

  public get _cutPoint(): Vector3 {
    const mech = this._originalMechanicalAxis;
    const sign = bindedModel.selectedApex === SelectedApexMech.tibiaProximal ? 1 : -1;

    if (mech instanceof MechanicalAxisAP) {
      if (mech.tibia) {
        return mech.tibia.CA.position.clone().add(mech.tibia.CA.position.clone().sub(mech.tibia.CP.position).setLength(100000 * sign));
      }
      return mech.femur.mechanical.CE.position.clone().add(mech.femur.mechanical.CE.position.clone().sub(mech.femur.mechanical.FH.position).setLength(100000 * sign));
    }

    if (mech.tibia) {
      return mech.tibia.MA.position.clone().add(mech.tibia.MA.position.clone().sub(mech.tibia.FP.position).setLength(100000 * sign));
    }
    return mech.femur.TE.position.clone().add(mech.femur.TE.position.clone().sub(mech.femur.FH.position).setLength(100000 * sign));
  }

  protected rotateAndTranslateAfterCut(): void {
    const sign = bindedModel.selectedApex === SelectedApexMech.tibiaProximal ? 1 : -1;
    const angle = this._originalMechanicalAxis.getApexWithSign(bindedModel.selectedApex) * sign;
    this.rotationByPoint(this.ost1.position.clone().sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, -angle).add(this._rotationCenter), this.ost1);

    if (this.clonedMechanicalAxis instanceof MechanicalAxisAP) {
      const mech = this.clonedMechanicalAxis;
      const point = bindedModel.selectedApex === SelectedApexMech.tibiaProximal ? mech.tibia.CA.position : mech.femur.mechanical.FH.position;
      this.translationByPoint(this._rotationCenter.clone().sub(point).add(this.croppedPlane.position));
    } else {
      const mech = this.clonedMechanicalAxis;
      const point = bindedModel.selectedApex === SelectedApexMech.tibiaProximal ? mech.tibia.MA.position : mech.femur.FH.position;
      this.translationByPoint(this._rotationCenter.clone().sub(point).add(this.croppedPlane.position));
    }

    this.dispatchEvent({ type: ToolEvents.updated });
  }

  public rotationByPoint(vector: Vector3, point: DraggablePoint, triggerUpdate = false): void { // apex funge solo se ruoti
    let variationAngle = VectorUtils.linesAngle(point.position, vector, this._rotationCenter);

    const newClonedOstA = this.clonedOsteotomy.A.position.clone().sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, variationAngle).add(this._rotationCenter);
    const newClonedOstB = this.clonedOsteotomy.B.position.clone().sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, variationAngle).add(this._rotationCenter);

    if (!LimitUtils.applyOsteotomySegmentsTouchingLimit(this.osteotomy.A.position, this.osteotomy.B.position, newClonedOstA, newClonedOstB, this.osteotomy.axisDir)) {
      const shapeOrigin = this.croppedPlane.position.clone().sub(this._rotationCenter);

      this.croppedPlane.rotateZ(variationAngle);
      this.croppedPlane.position.sub(shapeOrigin).add(shapeOrigin.applyAxisAngle(Consts.planeNormal, variationAngle))

      this.ost1.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, variationAngle).add(this._rotationCenter);
      this.ost2.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, variationAngle).add(this._rotationCenter);
      this.A.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, variationAngle).add(this._rotationCenter);
      this.B.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, variationAngle).add(this._rotationCenter);
      this.C.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, variationAngle).add(this._rotationCenter);
      this.D.parent && this.D.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, variationAngle).add(this._rotationCenter);
      this.E.parent && this.E.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, variationAngle).add(this._rotationCenter);
      if (bindedModel.selectedApex === SelectedApexMech.femurProximal) {
        this._oldInsertionPointPosition && this._insertionPoint.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, variationAngle).add(this._rotationCenter);
      }
      this._clonedMechanicalAxisManager.makeRotation(variationAngle, this._rotationCenter);
      this._clonedOsteotomyManager.makeRotation(variationAngle, this._rotationCenter);
      this.dispatchEvent({ type: "rotated", angle: variationAngle, rotationCenter: this._rotationCenter });
    }

    triggerUpdate && this.dispatchEvent({ type: ToolEvents.updated });
  }

  public translationByPoint(vector: Vector3): void {
    const pan = vector.clone().sub(this.croppedPlane.position);
    super.translationByPoint(vector);
    if (bindedModel.selectedApex === SelectedApexMech.femurProximal) {
      this._oldInsertionPointPosition && this._insertionPoint.position.add(pan);
    }
  }

  public cutPlane(verticalTransl: number, targetPointDistanceTibia?: number): boolean {
    this._oldInsertionPointPosition = this._insertionPoint.position.clone();
    this._targetPointDistanceTibiaLT = targetPointDistanceTibia;
    if (super.cutPlane(verticalTransl)) {
      return true;
    } else {
      this._oldInsertionPointPosition = undefined;
      return false;
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared';
import { InsertionPointWorkflowComponent } from './insertion-point-workflow';
import { LldRecapComponent } from './lld-recap';
import { MaApRecapComponent } from './ma-ap-recap';
import { MaLtRecapComponent } from './ma-lt-recap';
import { OsteotomyWorkflowComponent } from './osteotomy-workflow';
import { WedgeTableComponent } from './wedge-table';

@NgModule({
	declarations: [
		OsteotomyWorkflowComponent,
		WedgeTableComponent,
		MaApRecapComponent,
		MaLtRecapComponent,
		LldRecapComponent,
		InsertionPointWorkflowComponent
	],
	exports: [
		OsteotomyWorkflowComponent,
		WedgeTableComponent,
		MaApRecapComponent,
		MaLtRecapComponent,
		LldRecapComponent,
		InsertionPointWorkflowComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgbCollapseModule,
		SharedModule
	]
})
export class CorrectionModule { }

import { Save, SelectedApexMech } from "@ortho-next/nextray-core";
import { BufferGeometry, MathUtils, Mesh, MeshPhongMaterial, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { FitboneMesh } from "src/nextray/Models/AppModel";
import { bindedModel } from "../../Models/BindedModel";
import { Consts } from "../../Utils/Consts";
import { FitBoneExternal } from "./FitBoneExternal";
import { ScrewManager } from "./ScrewManager";

export class FitBoneInternal extends Mesh {
  public geometry: BufferGeometry; // override
  public material: MeshPhongMaterial; // override
  public parent: FitBoneExternal; // override
  public fullLength: number;
  public minLength: number;
  public maxLength: number;
  public screws: ScrewManager[] = [];
  @Save() public lengthsToRestore: number[] = [];
  @Save() public anchorsToRestore: Vector3[] = [];

  public get lengthening(): number {
    if (bindedModel.selectedApex == SelectedApexMech.tibiaProximal) {
      return this.fullLength - this.position.z - this.minLength;
    }
    return this.fullLength + this.position.z - this.parent.fullLength - this.minLength;
  }
  public set lengthening(value: number) {
    if (bindedModel.selectedApex == SelectedApexMech.tibiaProximal) { // questo perchè è rovesciato il modello
      this.position.z = this.fullLength - MathUtils.clamp(value, 0, this.maxLength) - this.minLength;
    } else {
      this.position.z = this.parent.fullLength - this.fullLength + MathUtils.clamp(value, 0, this.maxLength) + this.minLength;
    }
  }

  constructor() {
    super(Consts.emptyGeometry, Consts.toolMaterial);
    this.name = "FitBoneInternal";
    this.isDraggable = false;

    this.bindEvent("onDragMove", (e) => {
      if (e.mesh == this) {
        e.preventDefault = true;
        if (bindedModel.selectedApex === SelectedApexMech.tibiaProximal) {
          this.lengthening = this.fullLength - e.position.z - this.minLength;
        } else {
          this.lengthening = e.position.z + this.fullLength - this.parent.fullLength - this.minLength;
        }
      }
    });
  }

  public setGeometry(geometry: BufferGeometry, fitboneMesh: FitboneMesh): void {
    this.geometry = geometry;
    this.minLength = fitboneMesh.minL;
    this.maxLength = fitboneMesh.maxL - fitboneMesh.minL;
    this.fullLength = fitboneMesh.internalL;
  }

  /**
  * Creates and add screws to fitbone.
  */
  public addScrews(screws: ScrewManager[]): void {
    this.add(...(this.screws = screws));
  }

  public deleteGeometry(): void {
    this.geometry.dispose();
    this.geometry = Consts.emptyGeometry;
    for (const screw of this.screws) {
      screw.dispose();
    }
  }

}

import { FormatUtilsBase } from "@ortho-next/nextray-core";

/**
 * Contains functions to format data.
 */
export class FormatUtils extends FormatUtilsBase {

  /**	
   * Returns rounded value with sign and adds mm at the ends.	
   */
  public static distanceWithSign(value: number): string {
    return this.distance(value) < 0 ? `${this.distance(value)} mm` : `+${this.distance(value)} mm`;
  }

}

import { MechanicalAxisFemurAP, Save, SelectedApexMech, ToolToSave, ToolType } from "@ortho-next/nextray-core";
import { MechanicalAxisFemurLT } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/FemurAnalyzerLT";
import { MechanicalAxisTibiaAP } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/TibiaAnalyzerAP";
import { MechanicalAxisTibiaLT } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/TibiaAnalyzerLT";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { MechanicalAxisAP } from "./DeformityAnalyzer/FullAnalyzerAP";
import { MechanicalAxisLT } from "./DeformityAnalyzer/FullAnalyzerLT";

/**
 * Reference point used to sync osteotomies. TODO remove it from fitbone
 */
export class ReferencePoint implements ToolToSave {
  public toolType = ToolType.referencePoint;
  @Save() public readonly position = new Vector3();

  /**
   * Gets position according to selected apex and mechanical axis points.
   */
  public calculate(selectedApex: SelectedApexMech, mechanicalAxis: MechanicalAxisAP | MechanicalAxisLT): void {
    switch (selectedApex) {
      case SelectedApexMech.femurProximal:
      case SelectedApexMech.femurDistal:
        return this.handleFemurApex(mechanicalAxis.femur);
      case SelectedApexMech.tibiaProximal:
        return this.handleTibiaApex(mechanicalAxis.tibia);
      case SelectedApexMech.none:
        return this.handleNoSelectedApex();
    }
  }

  private handleNoSelectedApex(): void { //todo
    this.position.set(0, 0, 0);
  }

  private handleFemurApex(femur: MechanicalAxisFemurAP | MechanicalAxisFemurLT): void {
    this.position.copy(femur.FH.position);
  }

  private handleTibiaApex(tibia: MechanicalAxisTibiaAP | MechanicalAxisTibiaLT): void {
    if (tibia instanceof MechanicalAxisTibiaAP) {
      this.position.copy(tibia.CP.position).lerp(tibia.CA.position, 0.5);
    } else {
      //const halFP = tibia.AP.position.clone().lerp(tibia.PP.position, 0.5);
      //this.position.copy(halFP.lerp(tibia.MA.position, 0.5));
      this.position.copy(tibia.FP.position).lerp(tibia.MA.position, 0.5);
    }
  }

}

import { ContextBase, StateTransitionData } from "@ortho-next/nextray-core";
import { Loading } from "./Loading";
import { RPM } from "./RPM";
import { Actions, State, StateTypes } from "./State";

/**
 * Events dispatcher to execute events logic inside contextInit.
 */
export class Context extends ContextBase {
	protected _state: State;

	public get state(): State { return super.state as State } //override

	protected changeState(state: StateTransitionData): State {
		switch (state.type) {
			case StateTypes.loading:
				return new Loading(this);
			case StateTypes.RPM:
				return new RPM(this, state.data.caseModel, state.data.restore);
		}
		return this._state;
	}

	constructor() {
		super();
		this._state = new Loading(this);
	}

	//override

	public handle<K extends keyof Actions>(event: K, args?: Actions[K]): void {
		super.handle(event, args);
	}
	public addEventListener<K extends keyof Actions>(type: K, listener: (event: Event & { args: Actions[K] }) => void): void {
		super.addEventListener(type, listener);
	}
	public hasEventListener<K extends keyof Actions>(type: K, listener: (event: Event & { args: Actions[K] }) => void): boolean {
		return super.hasEventListener(type, listener);
	}
	public removeEventListener<K extends keyof Actions>(type: K, listener: (event: Event & { args: Actions[K] }) => void): void {
		super.removeEventListener(type, listener);
	}
	public dispatchEvent<K extends keyof Actions>(event: { type: K } & { args: Actions[K] }): void {
		super.dispatchEvent(event);
	}

}

import { DraggablePoint, Save, SelectableTool, ToolToSave, ToolType, ViewType } from "@ortho-next/nextray-core";
import { GridHelper, Group, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { GridConfig as Config } from "../Config/GridConfig";
import { BindedModel } from "../Models/BindedModel";
import { Default } from "@ortho-next/nextray-core/Utils/Default";

/**
 * Grid.
 */
export class Grid extends Group implements ToolToSave, SelectableTool {
  public isSelectable = true;
  public isDeletable = false;
  public isSelected: boolean;
  public toolType = ToolType.grid;
  @Save("position", "rotation") public center: DraggablePoint;
  @Save("position", "rotation") public grid: GridHelper;

  public onSelect(): void {
    Default.colorAllDraggablePoints(this);
  }

  public onDeselect(): void {
    Default.restoreColorAllDraggablePoints(this);
  }

  constructor(viewType: ViewType) {
    super();
    this.name = "Grid";

    this.add(
      this.grid = new GridHelper(Config.grid_size, Config.grid_divisions),
      this.center = new DraggablePoint("center", viewType, Config.center_color, new Vector3(0, 0, 10))
    );

    this.grid.interceptedByRaycaster = false;

    this.grid.rotateX(Math.PI / 2);
    this.position.setZ(200);

    this.bindEvents();

    this.bindProperty("visible", (m: BindedModel) => {
      return m.isGridInserted && m.layer_grid;
    }, ["isGridInserted", "layer_grid"]);


    this.center.bindProperty("visible", (m: BindedModel) => {
      return m.layer_grid_center;
    }, ["layer_grid_center"]);
  }

  private bindEvents(): void {

    this.center.bindEvent("onDragMove", (args) => {
      this.grid.position.copy(args.position).setZ(0);
    });

  }

  public rotate(angle: number): void {
    this.grid.rotation.y = angle;
    //TODO: refactor hitbox rotation
    this.center.rotation.z = (angle);
  }

}

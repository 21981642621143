import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../../language.service';


/**
* Errors page component to handle generic errors 
*/
@Component({
	selector: 'app-errors',
	templateUrl: './errors.component.html'
})
export class ErrorsComponent implements OnInit {

	errMsg: string;
	backToHomeMsg: string;

	err: any;

	constructor(
		private langSrv: LanguageService,
		private route: ActivatedRoute,
	) {
		const labels = this.langSrv.labels;
		this.errMsg = labels['ERROR_MESSAGE_PAGE'];
		this.backToHomeMsg = labels['BACK_TO_HOME'];
	}

	ngOnInit() {
		if (environment.name === 'development') {
			this.err = this.route.snapshot.queryParamMap.get('error').toString();
		}
	}

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { Canvas3dModule } from '../canvas3D';
import { LoaderComponent, ToastComponent, ToastService } from '../core';
import { GuideService } from '../services';
import { SharedModule } from '../shared';
import { SidebarModule } from '../sidebar';
import { ContainerTemplateComponent } from './container-template';
import { NavbarComponent } from './navbar';


@NgModule({
  declarations: [
    ContainerTemplateComponent,
    NavbarComponent,
    ToastComponent,
    LoaderComponent
  ],
  entryComponents: [
    LoaderComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SidebarModule,
    Canvas3dModule,
    NgbToastModule,
    NgbDropdownModule
  ],
  exports: [
    ContainerTemplateComponent
  ],
  providers: [
    ToastService,
    GuideService
  ]
})
export class TemplateModule { }

import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiResponse, ModelRepository, RayModel, TemplateModelViewModel } from '../core';
import { BaseService } from './base.service';
import { CaseService } from './case.service';


/**
* This service handles the ray analysis model.
*/
@Injectable()
export class ModelService extends BaseService {

	private _isReadOnly: boolean;

	constructor(
		private modelRepo: ModelRepository,
		private caseSrv: CaseService
	) {
		super();
		this.caseSrv.isReadOnly().subscribe(res => this._isReadOnly = res);
	}

	/**
	* Save current model.
	*/
	persistModel(caseId: string, userGuid: string): Observable<void> {
		if (!this._isReadOnly) {
			const model = (window as any).main._saveHandler.getUpdatedData(); // TODO: refactor
			const templateModel: TemplateModelViewModel = {
				version: environment.fitboneModelVersion,
				caseId: caseId,
				userGuid: userGuid,
				model
			};
			return this.modelRepo.saveModel(templateModel).pipe(map((res: ApiResponse<void>) => this.handleApiResponse(res)));
		}
		return of();
	}

	/**
	* Restore current Ray model.
	*/
	restoreRayModel(caseId: string, userGuid: string): Observable<RayModel> {
		return this.modelRepo.getRayModel(caseId, userGuid).pipe(
			map((res: ApiResponse<RayModel>) => this.handleApiResponse(res))
		);
	}

	/**
	 * Restore current Template model.
	 * @param {string} caseId
	 * @param {string} userGuid
	 */
	restoreTemplateModel(caseId: string, userGuid: string): Observable<TemplateModelViewModel> {
		return this.modelRepo.getTemplateModel(caseId, userGuid).pipe(
			map((res: ApiResponse<TemplateModelViewModel>) => this.handleApiResponse(res))
		);
	}

}

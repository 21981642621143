// @ts-nocheck
import { BoneTypeEnum, DraggablePoint, LabelText, LabelTextConfig, MechanicalAxisAP as MechanicalAxisAPBase, Save, SaveChild, SelectedApexMech, ToolEvents, VectorUtils, ViewType } from "@ortho-next/nextray-core";
import { Vector3, Object3D } from "@ortho-next/three-base/three.js/build/three.module";
import { Binding } from "@ortho-next/three-base/Binding/Binding";
import { CalculationPoint } from "@ortho-next/nextray-core/Tools/Primitive/CalculationPoint";
import { bindedModel, BindedModel } from "../../Models/BindedModel";
import { PrintStateTypes, StateTypes } from "../../States/State";
import { Consts } from "src/nextray/Utils/Consts";
import { LimitUtils } from "src/nextray/Utils/LimitUtils";

export enum FullAnalyzerAPEvent {
	updateCHPosition = 'updateCHPosition',
	onDragMoveCHPoint = 'onDragMoveCHPoint',
}

export class MechanicalAxisAP extends MechanicalAxisAPBase {
	@Save('position') public CH: DraggablePoint;
	@SaveChild() public CHLabel: LabelText;
	@Save() public CHLimitPoint: Vector3;
	public weightBearingV1: CalculationPoint; // Weight Bearing vertex 1 calculation point on Apex selected
	private _isEOC: boolean;

	public get isEnabled(): boolean { // override
		return this.isEnabledCH || (this.tibia && this.tibia.isEnabled);
	}
	public set isEnabled(value: boolean) { // override
		this.femur && (this.femur.isEnabled = value);
		this.tibia && (this.tibia.isEnabled = value);
	}

	public get isEnabledCH(): boolean {
		return this.CH && this.CH.isEnabled;
	}
	public set isEnabledCH(value: boolean) {
		this.CHLabel.isEnabled = this.CH.isEnabled = value;
	}

	public get targetPointDistanceTibia(): number {
		return bindedModel.selectedApex === SelectedApexMech.tibiaProximal ? this.CH.position.distanceTo(this.tibia.CP.position) : 0;
	}

	constructor(isLeft: boolean, boneType: BoneTypeEnum, isContralateral: boolean, isEOC: boolean) {
		super(isLeft, boneType, isContralateral, isEOC);
		const viewType = ViewType.AP;
		this._isEOC = isEOC;

		if (boneType === BoneTypeEnum.LongLeg && this.weightBearing && !isContralateral) {
			this.add(
				this.weightBearingV1 = new CalculationPoint('WeightBearingV1', this.weightBearing.v1.position),
				this.CH = new DraggablePoint('CH', viewType, 0xFF0000, undefined, false),
				this.CHLabel = new LabelText({
					name: 'CH',
					color: LabelTextConfig.color,
					objToAnchor: this.CH,
					viewType,
					anchor: new Vector3(isLeft ? 7 : -7, 5),
					labelParams: {
						backgroundColor: 'rgba(0, 0, 0, 0.6)',
						fontColor: '#ffffff',
						text: [{ text: 'TARGET' }]
					}
				})
			);

			this.CH.bindEvent('onDragMove', (args) => {
				const v1 = this.weightBearing.v1.position;
				const v2 = this.weightBearing.v2.position;
				const perpDir = VectorUtils.getPerpendicular(v1.clone().sub(v2));
				const axisPoint1_B = v1.clone().sub(perpDir);
				const axisPoint2_B = v2.clone().sub(perpDir);
				const validPoint = v1.clone().lerp(v2, 0.5);
				args.position.copy(VectorUtils.projectOnVector(args.position, v1, v2));
				// TODO: refactor with new limit function
				LimitUtils.applyLimitAndSetVector(v1, axisPoint1_B, args.position, this.CH.position, validPoint);
				LimitUtils.applyLimitAndSetVector(v2, axisPoint2_B, args.position, this.CH.position, validPoint);

				// limit to prevent shortening
				const CHLimitPoint_B = this.CHLimitPoint.clone().sub(perpDir);
				LimitUtils.applyLimitAndSetVector(this.CHLimitPoint, CHLimitPoint_B, args.position, this.CH.position, v1);

				this.dispatchEvent({ type: FullAnalyzerAPEvent.onDragMoveCHPoint, position: args.position.clone() });
			});

			this.CHLabel.bindProperty('visible', (m: BindedModel) => {
				return m.layer_mechAxis_weightBearing && m.selectedApex !== SelectedApexMech.none && m.isOsteotomyValid && m.printState === PrintStateTypes.none;
			}, ['layer_mechAxis_weightBearing', 'selectedApex', 'isOsteotomyValid', 'printState']);

			this.CH.bindProperty('visible', (m: BindedModel) => {
				return m.layer_mechAxis_weightBearing && m.selectedApex !== SelectedApexMech.none && m.isOsteotomyValid && m.printState === PrintStateTypes.none;
			}, ['layer_mechAxis_weightBearing', 'selectedApex', 'isOsteotomyValid', 'printState']);

			this.bindProperty('isEnabledCH', (m: BindedModel) => {
				return !m.readonly && !m.EOCCropVisible;
			}, ['readonly', 'EOCCropVisible']);

			Binding.createCustom(
				`${this.id}.WeightBearingVertices`,
				(m: BindedModel) => m.selectedApex,
				(v: SelectedApexMech) => this.updatedWeightBearingVertices(v),
				['selectedApex']);

			this.addEventListener(ToolEvents.updated, () => this.updatedWeightBearingVertices(bindedModel.selectedApex));

		}

		this.bindProperties(isContralateral, isEOC);
	}

	private bindProperties(isContralateral: boolean, isEOC: boolean): void {
		if (!isEOC) {
			if (isContralateral) {
				this.bindProperty('visible', (m: BindedModel) => m.layer_contralateral_all && m.isControlateralMechanicalAxisValid,
					['layer_contralateral_all', 'isControlateralMechanicalAxisValid']);
			} else {
				this.bindProperty('visible', (m: BindedModel) => {
					return (!m.EOCCropVisible && m.printState === PrintStateTypes.none) || m.printState === PrintStateTypes.deformityAnalysis;
				}, ['EOCCropVisible', 'printState']);
			}

			this.bindProperty('isEnabled', (m: BindedModel) => {
				return !m.readonly && !m.isRegisteringClickAP && m.appState === StateTypes.deformityAnalysis;
			}, ['readonly', 'isRegisteringClickAP', 'appState']);

		}

		this.weightBearing?.bindProperty('visible', (m: BindedModel) => m.layer_mechAxis_weightBearing || m.printState !== PrintStateTypes.none,
			["layer_mechAxis_weightBearing", "printState"]);

		if (!isContralateral) {
			this.tibia?.bindProperty('visible', (m: BindedModel) => {
				return m.layer_mechAxis_mechanical;
			}, ['layer_mechAxis_mechanical']);

			this.femur?.mechanical?.bindProperty('visible', (m: BindedModel) => {
				return m.layer_mechAxis_mechanical;
			}, ['layer_mechAxis_mechanical']);

			this.femur?.anatomical?.bindProperty('visible', (m: BindedModel) => {
				return m.layer_mechAxis_anatomical;
			}, ['layer_mechAxis_anatomical']);
		}
	}

	public getAngleForApexConfirmed(selectedApex: SelectedApexMech): number {
		if (selectedApex === SelectedApexMech.femurDistal || selectedApex === SelectedApexMech.femurProximal) {
			return VectorUtils.linesAngleFromOrigin(this.weightBearing.A.clone().sub(this.weightBearing.B), Consts.verDir);
		}
		return VectorUtils.linesAngleFromOrigin(this.weightBearing.B.clone().sub(this.weightBearing.A), Consts.verDir);
	}

	public getJointCenter(selectedApex: SelectedApexMech): CalculationPoint {
		return selectedApex === SelectedApexMech.femurDistal || selectedApex === SelectedApexMech.femurProximal ? this.tibia.CP : this.femur.mechanical.CE;
	}

	public getPointToProjectWeightBearing(selectedApex: SelectedApexMech): Vector3 {
		return selectedApex === SelectedApexMech.femurDistal || selectedApex === SelectedApexMech.femurProximal ? this.femur.mechanical.FH.position : this.tibia.CA.position;
	}

	public updateWeightBearingAndCH(selectedApex: SelectedApexMech): void {
		this.updatedWeightBearingVertices(selectedApex);
		this.dispatchEvent({ type: FullAnalyzerAPEvent.updateCHPosition });
		this.CHLabel.label.resetPosition();
	}

	public updatedWeightBearingVertices(selectedApex: SelectedApexMech): void {
		if (selectedApex !== SelectedApexMech.none) {
			this.weightBearing.v1 = this.weightBearingV1;
			!this._isEOC && (this.weightBearing.v2 = this.getWeightBearingVertex2(selectedApex));
			const projectedPoint = this.getWeightBearingProjectedPoint(selectedApex, 80);
			this.weightBearingV1.position.copy(projectedPoint);
		} else {
			this.weightBearing.v1 = this.femur.mechanical.FH;
			this.weightBearing.v2 = this.tibia.CA;
		}
	}

	private getWeightBearingProjectedPoint(selectedApex: SelectedApexMech, length: number): Vector3 {
		const v2 = this.weightBearing.v2.position;
		const jointCenter = this.getJointCenter(selectedApex).position;
		const pointToProject = this.getPointToProjectWeightBearing(selectedApex);
		const dir = jointCenter.clone().sub(v2);
		return VectorUtils.projectOnVector(pointToProject, v2, jointCenter).add(dir.setLength(length));
	}

	private getWeightBearingVertex2(selectedApex: SelectedApexMech): Object3D {
		let vertex: Object3D;
		let apexAngle: number;
		let oppositeApex: Object3D;
		if (selectedApex === SelectedApexMech.femurDistal || selectedApex === SelectedApexMech.femurProximal) {
			oppositeApex = this.tibia.apex;
			vertex = this.tibia.CA;
			if (this.tibia.apex.visible) {
				apexAngle = this.tibia.getApexAngle();
			}
		} else {
			oppositeApex = this.femur.mechanical.apex;
			vertex = this.femur.mechanical.FH;
			if (this.femur.isApexValid) {
				apexAngle = this.femur.getApexAngle();
			}
		}
		if (!apexAngle || apexAngle > Math.PI / 2) {
			return vertex;
		}
		return oppositeApex;
	}
}  

import { SceneBase, ToolsProto } from "@ortho-next/nextray-core/Core/Scene";
import { ViewType } from "@ortho-next/nextray-core/Models/AppModel";
import { CustomCameraControls } from "../Controls/CustomCameraControls";
import { Tools } from "./Tools";
import { WebGLRenderer } from "@ortho-next/three-base/three.js/build/three.module";

/**
 * Scene to render. Contains all tools, lights, camera and camera controls.
 */
export class Scene extends SceneBase {
	public meshes: Tools; //override
	public cameraControls: CustomCameraControls;

	constructor(renderer: WebGLRenderer, viewType: ViewType, tools: ToolsProto) {
		super(renderer, viewType, tools);
		this.cameraControls = new CustomCameraControls(this.camera, viewType);
	}
}

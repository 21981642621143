import { SelectedApexMech, VectorUtils, ViewType } from "@ortho-next/nextray-core";
import { bindedModel } from "../../Models/BindedModel";
import { MechanicalAxisLT } from "../DeformityAnalyzer/FullAnalyzerLT";
import { DiaphysisAnatomicalAxis } from "../DiaphysisAnatomicalAxis";
import { InsertionPoint } from "../InsertionPoint/InsertionPoint";
import { Osteotomy } from "./Osteotomy";

export class OsteotomyLT extends Osteotomy {
  protected _mechanicalAxis: MechanicalAxisLT;
  private _diaphysisAA: DiaphysisAnatomicalAxis;

  constructor(mechanicalAxis: MechanicalAxisLT, referencePoint: InsertionPoint, diaphysisAA?: DiaphysisAnatomicalAxis, isEOC?: boolean) {
    super(ViewType.LT, referencePoint as any, isEOC);
    this._mechanicalAxis = mechanicalAxis;
    this._diaphysisAA = diaphysisAA;
  }

  public set diaphysisAA(tool: DiaphysisAnatomicalAxis) {
    this._diaphysisAA = tool;
  }

  /**
  * Not used on fitbone app
  */
  public setPositionByApex(selectedApex: SelectedApexMech, distanceToReferenceePoint?: number): void {
    throw new Error("Method not implemented.");
  }

  /**
  * Sets position based on insertion point and selected apex.
  */
  public setPositionByInsertionPoint(insertionPoint: InsertionPoint): void {
    switch (bindedModel.selectedApex) {
      case SelectedApexMech.femurProximal:
      case SelectedApexMech.femurDistal: {
        const femur = this._mechanicalAxis.femur;
        const dir = this._diaphysisAA.B.position.clone().sub(this._diaphysisAA.A.position);
        const perpDir = VectorUtils.getPerpendicular(dir);
        const refPoint = this._referencePoint.position.clone().setZ(0);

        const upperPoint = bindedModel.selectedApex === SelectedApexMech.femurProximal ?
          refPoint :
          VectorUtils.lines2DIntersection(refPoint, refPoint.clone().add(dir), femur.FH.position, femur.FH.position.clone().add(perpDir));

        const lowerPoint = bindedModel.selectedApex === SelectedApexMech.femurProximal ?
          VectorUtils.lines2DIntersection(refPoint, refPoint.clone().add(dir), femur.TE.position, femur.TE.position.clone().add(perpDir)) :
          refPoint;

        this.setPointsForSync(lowerPoint, upperPoint);
        const projectedInsertionPoint = VectorUtils.projectOnVector(insertionPoint.position, this._diaphysisAA.A.position, this._diaphysisAA.B.position);
        this.placeOsteotomyByApex(projectedInsertionPoint.add(dir.setLength(60 * (bindedModel.selectedApex === SelectedApexMech.femurProximal ? 1 : -1))),
          VectorUtils.getPerpendicular(dir).setLength(this._initialLength / 2));
        break;
      }
      case SelectedApexMech.tibiaProximal: {
        const tibia = this._mechanicalAxis.tibia;
        const dir = tibia.proximalDirToCalculateApex;
        //const dir = tibia.MA.position.clone().sub(tibia.FP.position);
        this.setPointsForSync(tibia.MA.position, tibia.FP.position);
        const projectedInsertionPoint = VectorUtils.projectOnVector(insertionPoint.position, tibia.FP.position, tibia.MA.position);
        this.placeOsteotomyByApex(projectedInsertionPoint.add(dir.setLength(-60)), VectorUtils.getPerpendicular(dir).setLength(this._initialLength / 2));
      }
    }

    this.update();
  }

}

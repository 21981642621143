import { NextrayModel, SaveHandlerBase, ToolState, ToolToSave, ToolType, ToolUtils, ViewType } from "@ortho-next/nextray-core";
import { AppModel } from "../Models/AppModel";
import { BindedModel, bindedModel } from "../Models/BindedModel";
import { Context } from "../States/Context";
import { Main } from "./Main";
import { Tools } from "./Tools";

/**
 * Contains data to restore fitbone app.
 */
export interface TemplateModel {
  state: string;
  bindedModel: string;
  appStateModel: string;
  ap: TemplateToolState;
  lt: TemplateToolState;
}

/**
 * Contains data to restore a single scene.
 */
export interface TemplateToolState {
  tools: ToolState[];
}

/**
 * Manages the save and restore.
 */
export class SaveHandler extends SaveHandlerBase {
  protected _main: Main; //override
  protected _context: Context; //override
  // private _model: TemplateModel;
  protected override readonly cacheTools: ToolType[] = [
    ToolType.plane,
    ToolType.line,
    ToolType.goniometer,
    ToolType.mechanicalAxis,
    ToolType.contralateralMechanicalAxis
  ];

  constructor(main: Main, context: Context) {
    super(main, context);
  }

  protected onBeforeAppModelStringfy = (appModel: AppModel) => {
    appModel.printResult = undefined;
    appModel.printMeasures = undefined;
    appModel.isBlockingScrewSelectedAP = undefined;
    appModel.isBlockingScrewSelectedLT = undefined;
  }

  protected onBeforeBindedModelStringfy = (bindedModelCloned: BindedModel) => {
    bindedModelCloned.isCropPolygonToolVisible = false;
  }

  /**
   * Inits and restore tools.
   */
  public restoreData(restoreData: TemplateModel, cacheData: NextrayModel): void {
    this.mergeData(cacheData);
    super.restoreData(restoreData, cacheData);
  }

  protected initMesh(tools: Tools, meshType: ToolType, viewType: ViewType, meshData: { [x: string]: any }): ToolToSave {
    switch (meshType) {
      case ToolType.referencePoint:
        return viewType === ViewType.AP ? this._main.toolsInit.initReferencePointAP() : this._main.toolsInit.initReferencePointLT();
      case ToolType.osteotomy:
        return viewType === ViewType.AP ? this._main.toolsInit.initOsteotomyAP() : this._main.toolsInit.initOsteotomyLT();
      case ToolType.EOCCrop:
        return this._main.toolsInit.initEoCPlaneTool(viewType);
      case ToolType.grid:
        return tools.grid;
      case ToolType.insertionPoint:
        return this._main.toolsInit.initInsertionPoint(viewType);
      case ToolType.diaphysisAnatomicalAxis:
        return viewType === ViewType.LT && this._main.toolsInit.initDiaphysisAA();
      case ToolType.fitBone:
        return this._main.toolsInit.initFitBone(viewType);
      case ToolType.blockingScrew:
        return this._main.toolsInit.initBlockingScrew(tools);
    }
    return super.initMesh(tools, meshType, viewType, meshData);
  }

  protected updateTools(viewType: ViewType): void {
    const toolsToSave = viewType === ViewType.AP ? this._model.ap.tools : this._model.lt.tools;
    const tools = this._main.getTools(viewType);

    for (const tool of [ // order is the priority. Puo' essere automatizzato
      tools.plane,
      ...tools.lines,
      ...tools.goniometers,
      tools.mechanicalAxis,
      tools.contralateralMechanicalAxis,
      tools.referencePoint,
      tools.grid,
      tools.insertionPointManager,
      tools.diaphysisAA,
      tools.osteotomy,
      tools.EoCPlane,
      tools.fitBoneManager,
      ...tools.blockingScrews
    ]) {
      const toolToSave = ToolUtils.getStateToRestore(tool);
      toolToSave && toolsToSave.push(toolToSave);
    }
  }

  private mergeData(cacheData: NextrayModel): void {
    const cachedAppModel = JSON.parse(cacheData.appStateModel);

    for (const key in cachedAppModel) {
      this._main.deformityModel[key] = cachedAppModel[key];
      if (this._main.model.hasOwnProperty(key)) {
        this._main.model[key] = cachedAppModel[key];
      }
    }

    const cachedBindedModel = JSON.parse(cacheData.bindedModel);
    for (const key in cachedBindedModel) {
      if (bindedModel.hasOwnProperty(key)) {
        bindedModel[key] = cachedBindedModel[key];
      }
    }
  }
}

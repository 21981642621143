import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { filter, first, map, tap } from "rxjs/operators";
import { Case, CaseRepository } from "../core/repositories";
import { BaseService } from "./base.service";
import { UserService } from "./user.service";


/**
* This service handles the case data.
*/
@Injectable()
export class CaseService extends BaseService {

	private _currentCase: BehaviorSubject<Case> = new BehaviorSubject<Case>(null);
	private _isReadOnly: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

	constructor(
		private caseRepo: CaseRepository,
		private userSrv: UserService
	) {
		super();
	}

	/**
	* Get current case.
	*/
	getCurrentCase(): Observable<Case> {
		return this._currentCase.asObservable().pipe(first(_case => !!_case));
	}

	/**
	* Get case by id
	* @param {string} id Case GUID
	*/
	getCase(id: string): Observable<Case> {
		return this.caseRepo.getCase(id).pipe(
			map(response => this.handleApiResponse(response)),
			tap(c => this._currentCase.next(c))
		);
	}

	/**
	* Check if is read-only case.
	*/
	isReadOnly(): Observable<boolean> {
		if (this._isReadOnly.value === null) {
			return combineLatest([this.getCurrentCase(), this.userSrv.getCurrentUser()]).pipe(
				filter(([_case, _user]) => !!_case && !!_user),
				first(),
				map(([_case, _user]) => _case.userGuid !== _user.id),
				tap(res => this._isReadOnly.next(res))
			);
		}
		return this._isReadOnly.asObservable();
	}

}

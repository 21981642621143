import { AuthCoreConfig } from '@ortho-next/auth-core';
import { environment } from '../environments/environment';


/**
* Authentication configuration for application
*/
export const AUTH_CONFIG: AuthCoreConfig = {
  allowedUrls: [environment.domainUserAPI, environment.domainPatientAPI, environment.domainImageAPI, environment.domainRayAPI, environment.domainTemplatesAPI],
  appSSOutUriApi: environment.appSSOutUriApi,
  applicationScope: environment.b2cApplicationScope,
  clientId: environment.b2cClientId,
  idleTimeToEndSession: environment.idleTimeToEndSession,
  tenantName: environment.b2cTenantName,
  resetPasswordPolicyId: environment.b2cResetPasswordPolicyId,
  signInPolicyId: environment.b2cSignInPolicyId,
  showDebugInformation: environment.name !== 'production'
};

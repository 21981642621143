import { bindedModelBase, BindedModelBase, BoneTypeEnum, SelectedApexMech } from '@ortho-next/nextray-core';
import { PrintStateTypes, StateTypes } from '../States/State';

/**
 * Model used by three.js part to create 3d objects binding.
 */
export class BindedModel extends BindedModelBase { // don't create obj or array properties //always initialize property
  /**
   * Gets current state type.
   */
  public appState: StateTypes = 0;
  /**
   * Indicates if last contralateral mechanical axis inserted is valid.
   */
  public isControlateralMechanicalAxisValid = false;
  /**
   * Indicates if EOC crop is vibile (switch is on).
   */
  public EOCCropVisible = false;
  /**
   * Binded variable used to change mesh visibility to generate images for print report.
   */
  public printState: PrintStateTypes = 0;
  /**
   * Indicates if osteotomy must be visible in the current state.
   */
  public isOsteotomyValid = false;
  /**
   * Indicates if the grid is inserted.
   */
  public isGridInserted = false;
  /**
   * Get selected apex on osteotomy site.
   */
  public selectedApex: SelectedApexMech = 0;
  /**
   * Indicates if stroke is locked.
   */
  public isStrokeLocked = false;
  /**
  * Gets bone type of Lateral view.
  */
  public boneTypeLT: BoneTypeEnum = null;
  /**
   * Target point line offset on print report.
   */
  public PrintTargetPropAP = 0;
  /**
   * FH point line offset on print report.
   */
  public PrintFHPropAP = 0;
  /**
   * CA point line offset on print report.
   */
  public PrintCAPropAP = 0;
  /**
   * Indicates if the fitbone must be visible in the current state.
   */
  public isFitboneInserted = false;
  /**
   * Indicates if the cut polygon is cropped.
   */
  public isPolygonCropped = false;
  /**
   * Indicates if the insertion point must be visible in the current state.
   */
  public isInsertionPointInserted = false;
  /**
   * Indicates if the insertion point must be enable in the current state.
   */
  public isInsertionPointEnabled = false;
  /**
   * Indicates if the diaphysis anatomical axis must be visible in the current state.
   */
  public isDiaphysisAAInserted = false;
  /**
   * Indicates if the diaphysis anatomical axis must be enable in the current state.
   */
  public isDiaphysisAAEnabled = false;
  /**
   * Indicates if the crop polygon tool must be visible in the current state.
   */
  public isCropPolygonToolVisible = false;
  /**
   * Indicates if viewer is visible.
   */
  public isViewerVisible = false;

  /**
   * Indicates if mechAxis_anatomical layer is enabled.
   */
  public layer_mechAxis_anatomical = true;
  /**
   * Indicates if mechAxis_mechanical layer is enabled.
   */
  public layer_mechAxis_mechanical = true;
  /**
   * Indicates if mechAxis_weightBearing layer is enabled.
   */
  public layer_mechAxis_weightBearing = true;
  /**
   * Indicates if contralateral_all layer is enabled.
   */
  public layer_contralateral_all = true;
  /**
   * Indicates if grid layer is enabled.
   */
  public layer_grid = false;
  /**
   * Indicates if grid center is enabled.
   */
  public layer_grid_center = false;
  /**
   * Indicates if fitbone layer is enabled.
   */
  public layer_fitbone = true;
  /**
   * Indicates if plane layer is enabled.
   */
  public layer_plane = true;
}

export const bindedModel: BindedModel = bindedModelBase as BindedModel;
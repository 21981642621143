import { CaseModel, StateTransitionData } from "@ortho-next/nextray-core";
import { Context } from "./Context";
import { Actions, State, StateTypes, WorkflowRunning } from "./State";

/**
 * State indicating that the application is loading and restoring data. At the end it will the state loaded.
 */
export class Loading extends State {
  public stateDescription = {
    isWorkflowRunning: WorkflowRunning.none,
    isPointsPositioningRunning: false
  };

  constructor(context: Context) {
    super(context, undefined, StateTypes.loading);
  }

  public initState(): void {
  }

  protected handleChangeStateEvent<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData {
    switch (event) {
      case "setModel":
        this.dispatchToMain(event, args)
        if ((args as unknown as CaseModel).model && (args as unknown as CaseModel).model.state) {
          const contextState = JSON.parse((args as unknown as CaseModel).model.state);

          switch (contextState.type) {
            case StateTypes.RPM:
              return { type: StateTypes.RPM, data: { caseModel: args, restore: contextState } };
          }

          console.error("Error. Cannot restore state.");
        }

        return { type: StateTypes.RPM, data: { caseModel: args } };
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
 * Second left vertical bar 
 */
@Component({
  selector: 'drawer',
  templateUrl: './drawer.component.html',
  styles: []
})
export class DrawerComponent extends BaseComponent implements OnInit {

  isDeformityDrawerOpen: boolean;
  isCorrectionDrawerOpen: boolean;

  constructor(
    private langSrv: LanguageService,
    public canvasSrv: CanvasService
  ) {
    super(langSrv, canvasSrv);
  }

  ngOnInit() { }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IProductRepository } from './Iproduct.repository';
import { ApiResponse, Fitbone, FitboneSearchFilters } from './models';


@Injectable()
export class ProductRepository implements IProductRepository {

  constructor(private httpClient: HttpClient) { }

  getFitboneList(filters: FitboneSearchFilters): Observable<ApiResponse<Fitbone[]>> {
    return this.httpClient.post<ApiResponse<Fitbone[]>>(environment.domainTemplatesAPI + '/api/Fitbone/GetFitboneList', filters);
  }

}

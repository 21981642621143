import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../core';
import { CanvasService } from '../services';
import { BaseComponent } from '../shared';


/**
 * First left vertical bar 
 */
@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent extends BaseComponent implements OnInit {

  constructor(
    private langSrv: LanguageService,
    public canvasSrv: CanvasService
  ) {
    super(langSrv, canvasSrv);
  }

  ngOnInit() { }

}

import { Pipe, PipeTransform } from '@angular/core';
import { BoneTypeEnum, LanguageService } from '../../core';


/**
 * Pipe to convert Bone Type enum to display value
 */
@Pipe({
	name: 'boneTypeLabel'
})
export class BoneTypeLabelPipe implements PipeTransform {

	constructor(private langSrv: LanguageService) { }

	transform(boneType: BoneTypeEnum): string {
		switch (boneType) {
			case BoneTypeEnum.LongLeg: return this.langSrv.labels.BONE_TYPE_LONG_LEG_LABEL;
			case BoneTypeEnum.Femur: return this.langSrv.labels.BONE_TYPE_FEMUR_LABEL;
			case BoneTypeEnum.Tibia: return this.langSrv.labels.BONE_TYPE_TIBIA_LABEL;
			default: return boneType;
		}
	}

}

import { DraggableLabel, ViewType } from '@ortho-next/nextray-core';
import { Screw } from './Screw';

/**
 * Fitbone screw label.
 */
export class LabelScrew extends DraggableLabel {
  public screw: Screw;
  public updatePosition: () => void; // override
  private _originalScrewColor: number;

  constructor(name: string, viewType: ViewType, screw: Screw) {
    super(name, viewType, screw);

    this.name = name;
    this.screw = screw;
    this._originalScrewColor = screw.material.emissive.getHex();
    this.setParam("text", this.screw.length);
    this.defaultAnchor = this._anchor.clone();
    this.resetPosition();

    this.bindEvent('onHover', () => {
      this.screw.material.emissive.setHex(0xff0000);
      this.needsRender = true;
    });

    this.bindEvent('onLeave', () => {
      this.screw.material.emissive.setHex(this._originalScrewColor);
      this.needsRender = true;
    });
  }

  public update(pan: number): void {
    this._anchor.add(this.screw.direction.clone().setLength(pan / 2));
    this.updatePosition();
  }

  public resetPosition(): void {
    this._anchor.copy(this.defaultAnchor.clone().add(this.screw.direction.clone().setLength((this.screw.length + 10) / 2)));
    this.updatePosition();
  }
}

<!-- Modal -->
<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">{{ labels.CLOSURE_MODAL_COMPONENT_TITLE }}</h5>
    <!--Serve per mettere il focus sul modale ed evitare l'autofocus sul primo bottone-->
    <button type="button"
      style="border-color: transparent; background-color: transparent; outline: none; cursor: inherit;"></button>
  </div>
  <div class="modal-body" [innerHtml]="labels.CLOSURE_MODAL_COMPONENT_TEXT"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary"
      (click)="activeModal.dismiss()">{{ labels.CLOSURE_MODAL_COMPONENT_CANCEL_BUTTON }}</button>
    <button type="button" class="btn btn-primary"
      (click)="closeWithoutSave()">{{ labels.CLOSURE_MODAL_COMPONENT_NOT_SAVE_BUTTON }}</button>
    <button type="button" class="btn btn-primary" (click)="closeWithSave()"
      [disabled]="isReadOnly">{{ labels.CLOSURE_MODAL_COMPONENT_SAVE_BUTTON }}</button>
  </div>
</div>

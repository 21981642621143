import { Component, OnInit } from '@angular/core';
import { Utils } from '@ortho-next/nextray-core';
import { Consts } from '../../../nextray/Utils/Consts';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Table of correction measures for Lateral image.
*/
@Component({
  selector: 'ma-lt-recap',
  templateUrl: './ma-lt-recap.component.html',
  styles: []
})
export class MaLtRecapComponent extends BaseComponent implements OnInit {

  isCollapsed: boolean = true;

  ltAngles = { ...Consts.paleyAngles.LT };


  constructor(
    private langSrv: LanguageService,
    private canvasSrv: CanvasService
  ) {
    super(langSrv, canvasSrv);
  }

  ngOnInit() { }


  // VISIBILITY

  /**
  * Check if PDFA is visible.
  */
  get isPDFA(): boolean {
    return this.isLongLeg || this.isFemur;
  }

  /**
  * Check if PPTA is visible.
  */
  get isPPTA(): boolean {
    return this.isLongLeg || this.isTibia;
  }

  /**
  * Check if ADTA is visible.
  */
  get isADTA(): boolean {
    return this.isLongLeg || this.isTibia;
  }

  /**
  * Check if Knee Translation is visible.
  */
  get isKneeTransl(): boolean {
    return this.isLongLeg;
  }


  // VALIDATION

  /**
  * Check if PDFA value is valid.
  */
  get isValidPDFA(): boolean {
    return !this.rpmMeasures.rpm_lt_PDFA || Utils.isPaleyAngleValid(this.rpmMeasures.rpm_lt_PDFA, this.ltAngles.PDFA);
  }

  /**
  * Check if Knee Translation value is valid.
  */
  get isValidKneeTransl(): boolean {
    return !this.rpmMeasures.rpm_lt_kneeTranslation || Utils.isPaleyAngleValid(this.rpmMeasures.rpm_lt_kneeTranslation, this.ltAngles.KneeTransl);
  }

  /**
  * Check if PPTA value is valid.
  */
  get isValidPPTA(): boolean {
    return !this.rpmMeasures.rpm_lt_PPTA || Utils.isPaleyAngleValid(this.rpmMeasures.rpm_lt_PPTA, this.ltAngles.PPTA);
  }

  /**
  * Check if ADTA value is valid.
  */
  get isValidADTA(): boolean {
    return !this.rpmMeasures.rpm_lt_ADTA || Utils.isPaleyAngleValid(this.rpmMeasures.rpm_lt_ADTA, this.ltAngles.ADTA);
  }

  /**
  * Check if a measure is out of range.
  */
  get isMeasureOutOfRange(): boolean {
    return !this.isValidPDFA || !this.isValidKneeTransl || !this.isValidPPTA || !this.isValidADTA;
  }


  // ANGLES RANGE

  /**
  * Get PDFA range.
  */
  get rangePDFA(): string {
    return `(${this.ltAngles.PDFA.min}° - ${this.ltAngles.PDFA.max}°)`;
  }


  /**
  * Get Knee Translation range.
  */
  get rangeKneeTransl(): string {
    return `(${this.ltAngles.KneeTransl.min}mm - ${this.ltAngles.KneeTransl.max}mm)`;
  }

  /**
  * Get PPTA range.
  */
  get rangePPTA(): string {
    return `(${this.ltAngles.PPTA.min}° - ${this.ltAngles.PPTA.max}°)`;
  }

  /**
  * Get ADTA range.
  */
  get rangeADTA(): string {
    return `(${this.ltAngles.ADTA.min}° - ${this.ltAngles.ADTA.max}°)`;
  }

}

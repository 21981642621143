<navbar></navbar>

<!--BUG FIX: Add user select none to prevent text highlight during mechanical axis registering -->
<div class="wrapper-container-tlhex" style="-ms-user-select: none">

  <sidebar></sidebar>

  <canvas3d class="w-100"></canvas3d>

</div>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>

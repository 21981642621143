import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../../core';
import { CanvasService, CaseService, ModelService } from '../../services';
import { BaseComponent } from '../base-component';


/**
* Modal component to confirm before exit from ray site
*/
@Component({
  selector: 'app-closure-modal',
  templateUrl: './closure-modal.component.html',
  styles: []
})
export class ClosureModalComponent extends BaseComponent implements OnInit {

  isReadOnly: boolean;

  constructor(
    private langSrv: LanguageService,
    private canvasSrv: CanvasService,
    private modelSrv: ModelService,
    private caseSrv: CaseService,
    public activeModal: NgbActiveModal,
  ) {
    super(langSrv);
  }

  ngOnInit() {
    this.caseSrv.isReadOnly().subscribe(res => this.isReadOnly = res);
  }

  /**
  * Close site without save current state
  */
  closeWithoutSave(): void {
    window.location.href = environment.patplanSite;
  }

  /**
  * Close site and save current state
  */
  closeWithSave() {
    if (!this.isReadOnly) {
      const currentCase = this.canvasSrv.currentCase;
      this.modelSrv.persistModel(currentCase.id, currentCase.userGuid)
        .subscribe(() => window.location.href = environment.patplanSite);
    }
  }

}

import { BoneTypeEnum, MechanicalAxisLT as MechanicalAxisLTBase, SelectedApexMech, VectorUtils } from "@ortho-next/nextray-core";
import { Consts } from "src/nextray/Utils/Consts";
import { bindedModel, BindedModel } from "../../Models/BindedModel";
import { PrintStateTypes, StateTypes } from "../../States/State";

export class MechanicalAxisLT extends MechanicalAxisLTBase {

	public onAfterRestore(): void {
		this.boneType = bindedModel.boneTypeLT;
		super.onAfterRestore();
	}

	constructor(isLeft: boolean, boneType: BoneTypeEnum, isEOC: boolean) {
		super(isLeft, boneType, isEOC);
		this.bindProperties(isEOC);
	}

	private bindProperties(isEOC: boolean): void {
		if (!isEOC) {
			this.bindProperty('visible', (m: BindedModel) => {
				return (!m.EOCCropVisible && m.printState === PrintStateTypes.none) || m.printState === PrintStateTypes.deformityAnalysis;
			}, ['EOCCropVisible', 'printState']);

			this.bindProperty('isEnabled', (m: BindedModel) => {
				return !m.readonly && !m.isRegisteringClickLT && m.appState === StateTypes.deformityAnalysis;
			}, ['readonly', 'isRegisteringClickLT', 'appState']);
		}

		this.weightBearing?.bindProperty('visible', (m: BindedModel) => {
			return m.layer_mechAxis_weightBearing && m.boneTypeLT === BoneTypeEnum.LongLeg;
		}, ["layer_mechAxis_weightBearing", "boneTypeLT"]);

		this.femur?.bindProperty('visible', (m: BindedModel) => {
			return m.layer_mechAxis_mechanical && (m.boneTypeLT === BoneTypeEnum.LongLeg || m.boneTypeLT === BoneTypeEnum.Femur);
		}, ["layer_mechAxis_mechanical", "boneTypeLT"]);

		this.tibia?.bindProperty('visible', (m: BindedModel) => {
			return m.layer_mechAxis_mechanical && (m.boneTypeLT === BoneTypeEnum.LongLeg || m.boneTypeLT === BoneTypeEnum.Tibia);
		}, ["layer_mechAxis_mechanical", "boneTypeLT"]);
	}

	public getAngleForApexConfirmed(selectedApex: SelectedApexMech): number {
		if (selectedApex === SelectedApexMech.tibiaProximal) {
			return VectorUtils.linesAngleFromOrigin(this.tibia.FP.position.clone().sub(this.tibia.MA.position), Consts.verDir);
		}
		return 0;
	}
}  

<!-- Modal -->
<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">{{ config?.title || labels.CONFIRMATION_COMPONENT_TITLE }}</h5>
    <!--Serve per mettere il focus sul modale ed evitare l'autofocus sul primo bottone-->
    <button type="button"
      style="border-color: transparent; background-color: transparent; outline: none; cursor: inherit;"></button>
    <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="material-icons">clear</i>
    </button>-->
  </div>
  <div class="modal-body" [innerHtml]="config?.message || labels.CONFIRMATION_COMPONENT_TEXT"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" *ngIf="config?.isCancelBtn"
      (click)="activeModal.dismiss()">{{ config?.cancelBtn || labels.CONFIRMATION_COMPONENT_CANCEL_BUTTON }}</button>
    <button type="button" class="btn btn-primary"
      (click)="activeModal.close()">{{ config?.confirmBtn || labels.CONFIRMATION_COMPONENT_CONFIRM_BUTTON }}</button>
  </div>
</div>

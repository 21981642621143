import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { CanvasTabsetComponent } from './canvas-tabset';
import { Canvas3DComponent } from './canvas3d.component';
import { LayersComponent } from './layers';
import { MessageComponent, MessagesComponent } from './messages';


@NgModule({
  declarations: [
    Canvas3DComponent,
    CanvasTabsetComponent,
    LayersComponent,
    MessagesComponent,
    MessageComponent
  ],
  exports: [
    Canvas3DComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    NgbToastModule
  ],
  providers: []
})
export class Canvas3dModule { }

import { CaseModel, StateTransitionData, ViewType } from "@ortho-next/nextray-core";
import { Context } from "./Context";
import { Actions, State, StateTypes, WorkflowRunning } from "./State";

/**
 * State indicating that user can execute Reverse Planning Method.
 */
export class RPM extends State {
  public stateDescription = {
    isWorkflowRunning: WorkflowRunning.none,
    isPointsPositioningRunning: false
  };

  constructor(context: Context, caseModel: CaseModel, restore?: object) {
    super(context, caseModel, StateTypes.RPM, restore);
  }

  public initState(): void {
    if (this._restore) { //TODO check if needed
      if (this._caseModel.imgAP) {
        this.dispatchToMain("setView", ViewType.AP);
      } else {
        this.dispatchToMain("setView", ViewType.LT);
      }
    }
  }

  protected handleChangeStateEvent<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData {
    switch (event) {
      case "selectApex":
        return this.dispatchToMain(event, args);
      case "startSelectingApex":
        this.dispatchToMain(event, args);
        this.stateDescription.isWorkflowRunning = WorkflowRunning.insertOsteotomy;
        return { type: this.type };
      case "cancelSelectingApex":
        this.dispatchToMain(event, args);
        this.stateDescription.isWorkflowRunning = WorkflowRunning.none;
        return { type: this.type };
      case "confirmApex":
        this.dispatchToMain(event, args);
        this.dispatchToMain("startInsertionPoint");
        this.stateDescription.isWorkflowRunning = WorkflowRunning.insertInsertionPoint;
        return { type: this.type };
      case "cancelInsertionPoint":
      case "confirmInsertionPoint":
        this.stateDescription.isWorkflowRunning = WorkflowRunning.none;
        return this.dispatchToMain(event, args);
      case "startCropPolygon":
      case "confirmCropPolygon":
      case "cutEoC":
      case "insertFitbone":
      case 'updateMeausuresOnlyPrint':
      case "generateImagesToPrint":
      case "lockStroke":
      case "openViewer":
      case "closeViewer":
      case "setViewer":
      case "setRealisticZoom":
      case "addBlockingScrew":
      case "deleteBlockingScrew":
        return this.dispatchToMain(event, args);
      case "startInsertingFitbone":
        this.stateDescription.isWorkflowRunning = WorkflowRunning.insertFitbone;
        return this.dispatchToMain(event, args);
      case "cancelFitbone":
      case "confirmFitbone":
        this.stateDescription.isWorkflowRunning = WorkflowRunning.none;
        return this.dispatchToMain(event, args);
    }
  }
}

import { PrintUtilsBase } from "@ortho-next/nextray-core/Utils/PrintUtils";
import { Main } from "../Core/Main";
import { MeasuresRPM } from "../Models/AppModel";
import { Context } from "../States/Context";
import { PrintStateTypes, StateTypes } from "../States/State";
import { Vector2, Vector3, Color, Object3D } from "@ortho-next/three-base/three.js/build/three.module";
import { Label, MultiViewsElementCustom, ToolEvents, VectorUtils, ViewType } from "@ortho-next/nextray-core";
import { Globals } from "@ortho-next/three-base/Utils/Globals";
import { bindedModel } from "../Models/BindedModel";
import { Tools } from "../Core/Tools";
import { CustomCameraControls } from "../Controls/CustomCameraControls";

type PRINT_MEASURES_LIST = 'rpm_ap_angle' | 'rpm_lt_angle' | 'rpm_ap_cortexLength' | 'rpm_lt_cortexLength' | 'rpm_ap_axialLength' | 'rpm_lt_axialLength' | 'rpm_ap_secondaryTransl' | 'rpm_lt_secondaryTransl';
export type PrintMeasures = Pick<MeasuresRPM, PRINT_MEASURES_LIST>

/**
 * Print phase used to generate custom image.
 */
export interface PrintResult {
  deformityAP?: string;
  fitboneOpenAP?: string;
  fitboneCloseAP?: string;
  deformityLT?: string;
  fitboneCloseLT?: string;
  fitboneOpenLT?: string;
}

/**	
* Temporary data used to restore the old app state after generating the images.	
*/
export interface TempDataToRestore {
  size?: Vector2;
  camerasZoom?: number[];
  camerasPosition?: Vector3[];
  multiViewsActive?: boolean;
  activeViewType?: ViewType;
  eocPlanePosition?: Vector3;
  insertionPointPosition?: Vector3;
  clearColor?: Color;
  clearColorAlpha?: number;
  devicePixelRatio?: number;
  canvasContainerWidth?: string;
  canvasContainerHeight?: string;
}

/**
 * Generates images for use in the print report.
 */
export class PrintUtils extends PrintUtilsBase {
  protected _main: Main;
  protected _context: Context;
  protected _tempData: TempDataToRestore;

  constructor(main: Main, context: Context) {
    super(main, context);
  }

  /**	
   * Generates three images (deformity analysis, opening fitbone locked, closing fitbone locked).	
   */
  public generateImages(w: number, h: number): PrintResult {
    const printResult: PrintResult = {};
    this.updateRendererData(w, h);
    this._main.updateLine2Materials();
    this._main.model.printMeasures = {};
    if (this._main.hasAP) {
      this.updateViewData(ViewType.AP);
      this._main.scenes[ViewType.AP].cameraControls.fitToPlane();
      bindedModel.PrintTargetPropAP = this.calculateObjProportion(ViewType.AP, this._main.toolsAP.mechanicalAxis.CH);
      bindedModel.PrintFHPropAP = this.calculateObjProportion(ViewType.AP, this._main.toolsAP.mechanicalAxis.femur.mechanical.FH);
      bindedModel.PrintCAPropAP = this.calculateObjProportion(ViewType.AP, this._main.toolsAP.mechanicalAxis.tibia.CA);
      printResult.deformityAP = this.generateImage(PrintStateTypes.deformityAnalysis, ViewType.AP);
      printResult.fitboneCloseAP = this.generateImage(PrintStateTypes.closeFitboneLock, ViewType.AP);
      printResult.fitboneOpenAP = this.generateImage(PrintStateTypes.openFitboneLock, ViewType.AP);
    }
    if (this._main.hasLT) {
      this.updateViewData(ViewType.LT);
      this._main.scenes[ViewType.LT].cameraControls.fitToPlane();
      printResult.deformityLT = this.generateImage(PrintStateTypes.deformityAnalysis, ViewType.LT);
      printResult.fitboneCloseLT = this.generateImage(PrintStateTypes.closeFitboneLock, ViewType.LT);
      printResult.fitboneOpenLT = this.generateImage(PrintStateTypes.openFitboneLock, ViewType.LT);
    }
    this.restoreRendererData();
    this._main.updateLine2Materials();
    return printResult;
  }

  private updateRendererData(w: number, h: number): void {
    this._tempData = {};
    this._tempData.activeViewType = this._main.model.activeView;
    this._tempData.multiViewsActive = this._main.multiViewsActive;
    this._tempData.size = new Vector2();
    this._main.renderer.getSize(this._tempData.size);
    this._tempData.canvasContainerWidth = Globals.canvasContainer.style.width;
    this._tempData.canvasContainerHeight = Globals.canvasContainer.style.height;
    this._tempData.clearColor = this._main.renderer.getClearColor(new Color()); //TODO refactoring	
    this._tempData.clearColorAlpha = this._main.renderer.getClearAlpha();
    this._tempData.devicePixelRatio = this._main.renderer.getPixelRatio();
    this._tempData.camerasZoom = [];
    this._tempData.camerasPosition = [];
    const scenes = [...this._main.scenes, ...this._main.multiviewRenderer.viewsports as MultiViewsElementCustom[]];
    for (let i = 0; i < scenes.length; i++) {
      this._tempData.camerasZoom.push(scenes[i].camera.zoom);
      this._tempData.camerasPosition.push(scenes[i].camera.position.clone());
    }
    this.applyResize(w / 3.1, h * 0.92);
    this._main.renderer.setPixelRatio(1);
  }

  private updateViewData(viewType: ViewType): void {
    const tools = this._main.getTools(viewType);
    this.setViewDataToRestore(tools);
    this._context.handle('setView', viewType);
    this._main.renderer.setClearColor(0xffffff, 1);
  }

  private restoreRendererData(): void {
    const scenes = [...this._main.scenes, ...this._main.multiviewRenderer.viewsports as MultiViewsElementCustom[]];
    for (let i = 0; i < scenes.length; i++) {
      scenes[i].camera.zoom = this._tempData.camerasZoom[i];
      (scenes[i].cameraControls as CustomCameraControls).setCameraPosition(this._tempData.camerasPosition[i]);
    }
    this.applyResize(this._tempData.size.x, this._tempData.size.y);
    Globals.canvasContainer.style.width = this._tempData.canvasContainerWidth;
    Globals.canvasContainer.style.height = this._tempData.canvasContainerHeight;
    this._main.renderer.setClearColor(this._tempData.clearColor, this._tempData.clearColorAlpha);
    this._main.renderer.setPixelRatio(this._tempData.devicePixelRatio);
    if (this._tempData.multiViewsActive) {
      this._main.multiViewsActive = this._tempData.multiViewsActive;
      this._main.multiviewRenderer.activeViewport = (this._main.multiviewRenderer.viewsports as MultiViewsElementCustom[])[this._tempData.activeViewType]
    } else {
      this._context.handle('setView', this._tempData.activeViewType);
    }
  }

  protected generateImage(printState: PrintStateTypes, viewType?: ViewType): string {
    const tools = this._main.getTools(viewType);
    bindedModel.printState = printState;
    const offsetLabel = new Vector3(0, 0, 200);
    this.updateLabelZ(offsetLabel);
    switch (printState) {
      case PrintStateTypes.deformityAnalysis:
        tools.EoCPlane.restoreInsertionPointPositionToPrintDeformity();
        break;
      case PrintStateTypes.closeFitboneLock:
        this._main.toolsInit.closeFitbone(tools, viewType);
        tools.EoCPlane.dispatchEvent({ type: ToolEvents.updated });
        this.updatePrintMeasures(viewType);
        break;
      case PrintStateTypes.openFitboneLock:
        break;
    }
    this._main.multiviewRenderer.render();
    const image = this._main.renderer.domElement.toDataURL("image/png", 1.0);
    this.restoreViewData(printState, tools, viewType);
    this.updateLabelZ(offsetLabel.multiplyScalar(-1));
    return image;
  }

  private setViewDataToRestore(tools: Tools): void {
    this._tempData.eocPlanePosition = tools.EoCPlane.croppedPlane.position.clone();
    this._tempData.insertionPointPosition = tools.insertionPoint.position.clone();
  }

  private restoreViewData(printState: PrintStateTypes, tools: Tools, viewType: ViewType): void {
    const tempData = this._tempData;
    bindedModel.printState = PrintStateTypes.none;
    if (tempData.eocPlanePosition && printState === PrintStateTypes.closeFitboneLock) {
      this._main.toolsInit.moveEOCAndFitboneLenght(tempData.eocPlanePosition, tools, viewType);
      tools.EoCPlane.dispatchEvent({ type: ToolEvents.updated });
    }
    if (tempData.insertionPointPosition && printState === PrintStateTypes.deformityAnalysis) {
      tools.insertionPoint.position.copy(tempData.insertionPointPosition);
    }
  }

  /**	
   * Calculate the image offsetY to create insertion point red line on report	
   */
  private calculateObjProportion(viewType: ViewType, obj: Object3D): number {
    const camera = this._main.scenes[viewType].camera;
    const zoomedHeight = camera.height / camera.zoom;

    const cameraRotation = camera.rotation.clone();
    cameraRotation.z *= -1;
    const point = obj.getWorldPosition(new Vector3()).sub(camera.position).setZ(0).applyEuler(cameraRotation); //get the world pos could be better	
    return (zoomedHeight / 2 - point.y) / zoomedHeight * this._main.renderer.getSize(new Vector2()).y;
  }

  /**	
   * Set labels Z axis to render them over all tools.	
   */
  private updateLabelZ(offsetZ: Vector3): void {
    for (const scene of this._main.scenes) {
      this.recursiveUpdateLabel(scene, offsetZ);
    }
  }

  private recursiveUpdateLabel(obj: Object3D, offset: Vector3): void {
    if (obj === this._main.toolsAP.fitBoneManager || obj === this._main.toolsLT.fitBoneManager) return; //fitbone label goes out of camera with offset	
    if (obj instanceof Label) {
      obj.position.add(VectorUtils.getWorldToLocalOrientation(obj.parent, offset).setLength(offset.length()));
      return;
    }
    for (const child of obj.children) {
      this.recursiveUpdateLabel(child, offset);
    }
  }

  /**	
   * Updates measures for print.	
   */
  private updatePrintMeasures(viewType: ViewType): void {
    if (viewType === ViewType.AP) {
      this._main.model.printMeasures.rpm_ap_angle = this._main.model.rpmMeasures.rpm_ap_angle;
      this._main.model.printMeasures.rpm_ap_cortexLength = this._main.model.rpmMeasures.rpm_ap_cortexLength;
      this._main.model.printMeasures.rpm_ap_axialLength = this._main.model.rpmMeasures.rpm_ap_axialLength;
      this._main.model.printMeasures.rpm_ap_secondaryTransl = this._main.model.rpmMeasures.rpm_ap_secondaryTransl;
    } else {
      this._main.model.printMeasures.rpm_lt_angle = this._main.model.rpmMeasures.rpm_lt_angle;
      this._main.model.printMeasures.rpm_lt_cortexLength = this._main.model.rpmMeasures.rpm_lt_cortexLength;
      this._main.model.printMeasures.rpm_lt_axialLength = this._main.model.rpmMeasures.rpm_lt_axialLength;
      this._main.model.printMeasures.rpm_lt_secondaryTransl = this._main.model.rpmMeasures.rpm_lt_secondaryTransl;
    }
  }

}

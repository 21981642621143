import { DraggablePoint, SaveChild, ToolEvents } from "@ortho-next/nextray-core";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { Consts } from "../../Utils/Consts";
import { Osteotomy } from "../Osteotomy/Osteotomy";
import { OsteotomyAP } from "../Osteotomy/OsteotomyAP";
import { OsteotomyLT } from "../Osteotomy/OsteotomyLT";
import { EoCPlaneBase } from "./EoCPlaneBase";

export class ClonedOsteotomyManager {
  @SaveChild() public obj: Osteotomy;
  private _originalObj: Osteotomy;
  private _pointsToUpdate: DraggablePoint[];

  constructor(osteotomy: Osteotomy, EoCPlane: EoCPlaneBase) {
    this._originalObj = osteotomy;

    osteotomy instanceof OsteotomyAP ? this.cloneOsteotomyAP() : this.cloneOsteotomyLT();

    this.obj.position.setZ(4);
    this.obj.name = "ClonedOsteotomy";
    this.obj.interceptedByRaycaster = false;
    this.obj.label.visible = false;

    this._pointsToUpdate = [this.obj.A, this.obj.B, this.obj.C];

    this.obj.addEventListener(ToolEvents.updated, () => {
      EoCPlane.dispatchEvent({ type: ToolEvents.updated });
    });

    EoCPlane.dispatchEvent({ type: ToolEvents.updated });
  }

  public reset(): void {
    const oldOsteotomy = this._originalObj;
    this.obj.A.position.copy(oldOsteotomy.A.position);
    this.obj.B.position.copy(oldOsteotomy.B.position);
    this.obj.C.position.copy(oldOsteotomy.C.position);
  }

  private cloneOsteotomyAP(): void {
    this.obj = new OsteotomyAP(undefined, undefined, true);
    const oldOsteotomy = this._originalObj;
    this.obj.A.position.copy(oldOsteotomy.A.position);
    this.obj.B.position.copy(oldOsteotomy.B.position);
    this.obj.C.position.copy(oldOsteotomy.C.position);
  }

  private cloneOsteotomyLT(): void {
    this.obj = new OsteotomyLT(undefined, undefined, undefined, true);
    const oldOsteotomy = this._originalObj;
    this.obj.A.position.copy(oldOsteotomy.A.position); //func
    this.obj.B.position.copy(oldOsteotomy.B.position);
    this.obj.C.position.copy(oldOsteotomy.C.position);
  }

  public makeRotation(angle: number, rotationCenter: Vector3): void {
    for (const obj of this._pointsToUpdate) {
      obj.position.sub(rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(rotationCenter);
    }
  }

  public makeTranslation(pan: Vector3): void {
    for (const obj of this._pointsToUpdate) {
      obj.position.add(pan);
    }
  }

}

import { ApexSign, MechanicalAxisFemurAP, SelectedApexMech, VectorUtils } from "@ortho-next/nextray-core";
import { MathUtils, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { ToolsAP, ToolsLT } from '../Core/Tools';
import { bindedModel } from '../Models/BindedModel';
import { OsteotomyCut } from "../States/State";
import { MechanicalAxisAP } from '../Tools/DeformityAnalyzer/FullAnalyzerAP';
import { MechanicalAxisLT } from '../Tools/DeformityAnalyzer/FullAnalyzerLT';
import { EoCPlane } from '../Tools/EoCPlane/EoCPlane';
import { OsteotomyAP } from '../Tools/Osteotomy/OsteotomyAP';
import { OsteotomyBase } from '../Tools/Osteotomy/OsteotomyBase';
import { Consts } from './Consts';

/**
 * Contains formulas for calculating measurements using multiple tools.
 */
export class MeasuresUtils {

  /**
   * Calculates the horizontal translation that will be applied after correction.
   */
  public static getHorizontalTranslation(ost: OsteotomyBase, mechAxis: MechanicalAxisAP | MechanicalAxisLT, rotationCenter: Vector3, isLeft: boolean): number {
    const isTibia = bindedModel.selectedApex === SelectedApexMech.tibiaProximal;
    if (mechAxis instanceof MechanicalAxisLT && !isTibia) {
      return 0;
    }
    const mechSide = isTibia ? mechAxis.tibia : mechAxis.femur as MechanicalAxisFemurAP;
    const angle = mechSide.apexWithSign;
    const apex = mechSide instanceof MechanicalAxisFemurAP ? mechSide.mechanical.apex.position : mechSide.apex.position;
    const bisector = mechSide.apexBisector;
    const apexAngle = mechSide instanceof MechanicalAxisFemurAP ? mechSide.apexAngleToFix.value : mechSide.apexAngleToFix.value;
    let distanceRotCenterFromBisector;

    if (apexAngle <= Math.PI / 2) {
      const projectedRotationCenter = VectorUtils.projectOnVector(rotationCenter, apex, apex.clone().add(bisector));
      distanceRotCenterFromBisector = projectedRotationCenter.distanceTo(rotationCenter);
    } else { // apex out of joints
      const projectedRotationCenter = VectorUtils.projectOnVector(rotationCenter, apex, apex.clone().add(bisector));
      distanceRotCenterFromBisector = projectedRotationCenter.distanceTo(apex);
    }

    const verticalDir = mechAxis.getProximalDirToCalculateApex(bindedModel.selectedApex);
    const ost1 = ost.C.position;
    const ost2 = ost.C.position.clone().add(verticalDir);
    let sign = VectorUtils.arePointsOnSameSide(ost1, ost2, Consts.planeNormal, apex, ost.C.position.clone().add(VectorUtils.getPerpendicular(verticalDir))) ? -1 : 1;
    sign *= isLeft ? -1 : 1;
    return distanceRotCenterFromBisector * Math.tan((angle) / -2) * 2 * sign;
  }

  /**
   * Calculates the EOC vertical translation tha will be applied in both views.
   */
  public static getEoCVerticalTranslationBeforeCut(toolsAP: ToolsAP, toolsLT: ToolsLT, cutType: OsteotomyCut): number {
    const osteotomyLength = Math.max(toolsAP.osteotomy ? toolsAP.osteotomy.length : 0, toolsLT.osteotomy ? toolsLT.osteotomy.length : 0);
    const angleAP = (toolsAP.mechanicalAxis && toolsAP.osteotomy) ? toolsAP.mechanicalAxis.getApexWithSign(bindedModel.selectedApex) : 0;
    const angleLT = (toolsLT.mechanicalAxis && toolsLT.osteotomy) ? toolsLT.mechanicalAxis.getApexWithSign(bindedModel.selectedApex) : 0;
    const sign = cutType === OsteotomyCut.closing ? -1 : 1;
    return Math.sin(Math.atan(Math.sqrt(Math.tan(angleAP) ** 2 + Math.tan(angleLT) ** 2))) * osteotomyLength / 2 * sign;
  }

  /**
   * Calculates the cortex wire used in report to print only.
   */
  public static getCortexWire(mechanicalAxis: MechanicalAxisAP, osteotomy: OsteotomyAP, selectedApex: SelectedApexMech, wireOrigin: Vector3): number {
    if (selectedApex === SelectedApexMech.femurProximal || selectedApex === SelectedApexMech.femurDistal) {
      const refPoint = this.getReferencePointToProximalPrintMeasure(mechanicalAxis, osteotomy);
      return refPoint.distanceTo(wireOrigin.setZ(0));
    }
  }

  /**
   * Calculate target point leg length.
   */
  public static getTargetLegLength(mechanicalAxis: MechanicalAxisAP): number {
    return mechanicalAxis.CH.position.distanceTo(mechanicalAxis.CHLimitPoint);
  }

  /**
   * Calculate target point bone(femur/tibia) length.
   */
  public static getTargetBoneLength(mechanicalAxis: MechanicalAxisAP, chPosition?: Vector3): number {
    chPosition = chPosition || mechanicalAxis.CH.position.clone();
    const approach = bindedModel.selectedApex;
    const oppositeBonePoint = approach === SelectedApexMech.tibiaProximal ? mechanicalAxis.tibia.CP : mechanicalAxis.femur.mechanical.CE;
    return chPosition.distanceTo(oppositeBonePoint.position);
  }

  /**
   * Calculates the wire insertion angle used in report to print only.
   */
  public static getWireInsertionAngle(mechanicalAxis: MechanicalAxisAP, EOCplane: EoCPlane, wireAngleDegrees: number, selectedApex: SelectedApexMech): number {
    if (selectedApex === SelectedApexMech.femurProximal || selectedApex === SelectedApexMech.femurDistal) {
      const wedge = EOCplane.angle;
      const sign = mechanicalAxis.femur.apexAngleSign.value === ApexSign.valgus ? 1 : -1;
      return MathUtils.degToRad(wireAngleDegrees) + (wedge * sign);
    }
  }

  /**
   * Calculates default CH position.
   */
  public static getCHposition(mechanicalAxis: MechanicalAxisAP, ctrlateral: MechanicalAxisAP, isCtrlAxis = bindedModel.isControlateralMechanicalAxisValid): Vector3 {
    const selectedApex = bindedModel.selectedApex;
    const v1 = mechanicalAxis.weightBearing.v1.position;
    const v2 = mechanicalAxis.weightBearing.v2.position;
    let length: number;
    let intersection: Vector3;
    let dir: Vector3;
    const maAP = isCtrlAxis ? ctrlateral : mechanicalAxis;
    if (selectedApex === SelectedApexMech.femurDistal || selectedApex === SelectedApexMech.femurProximal) {
      length = maAP.femur.length.value;
      const mechFemur = mechanicalAxis.femur.mechanical;
      intersection = VectorUtils.lines2DIntersection(mechFemur.ME.position, mechFemur.LE.position, v1, v2);
      dir = intersection.clone().sub(v2);
    } else {
      length = maAP.tibia.length.value;
      const mechTibia = mechanicalAxis.tibia;
      intersection = VectorUtils.lines2DIntersection(mechTibia.LP.position, mechTibia.MP.position, v1, v2);
      dir = v1.clone().sub(intersection);
    }

    return intersection.add(dir.setLength(length));
  }

  /**
   * Gets reference point to proximal print measure.
   */
  private static getReferencePointToProximalPrintMeasure(mechanicalAxis: MechanicalAxisAP, osteotomy: OsteotomyAP): Vector3 {
    const sideAxis = Consts.horDir.clone().multiplyScalar(mechanicalAxis.isLeft ? 1 : -1).applyAxisAngle(Consts.planeNormal, mechanicalAxis.femur.angleForTemplating);
    const Asign = VectorUtils.computeSign(osteotomy.A.position, osteotomy.C.position, sideAxis);
    return Asign === 1 ? osteotomy.A.position : osteotomy.B.position;
  }

}

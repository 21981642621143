import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IModelRepository } from './Imodel.repository';
import { ApiResponse, RayModel, TemplateModelViewModel } from './models';


@Injectable()
export class ModelRepository implements IModelRepository {

  constructor(private httpClient: HttpClient) { }

  getTemplateModel(caseId: string, userId: string): Observable<ApiResponse<TemplateModelViewModel>> {
    const options = {
      params: { caseId, userId }
    };
    return this.httpClient.get<ApiResponse<TemplateModelViewModel>>(environment.domainTemplatesAPI + '/api/model/get', options);
  }

  getRayModel(caseId: string, userId: string): Observable<ApiResponse<RayModel>> {
    const options = {
      params: { caseId, userId }
    };
    return this.httpClient.get<ApiResponse<RayModel>>(environment.domainRayAPI + '/api/model/get', options);
  }

  saveModel(model: TemplateModelViewModel): Observable<ApiResponse<void>> {
    return this.httpClient.post<ApiResponse<void>>(environment.domainTemplatesAPI + '/api/model/save', model);
  }

}

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CaseModel, InitialFlipType, NextrayModel } from '@ortho-next/nextray-core';
import { combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Bridge } from '../../nextray/Core/Bridge';
import { Main } from '../../nextray/Core/Main';
import { TemplateModel } from '../../nextray/Core/Save';
import { AppModel } from '../../nextray/Models/AppModel';
import { DeformityAppModel } from '../../nextray/Models/DeformityAppModel';
import { State, StateTypes } from '../../nextray/States/State';
import { Case, Image, ImageOrientationEnum, isGuid, LanguageService, RayModel } from '../core';
import { CaseService } from './case.service';
import { ImageService } from './image.service';
import { ModelService } from './model.service';


export interface Canvas3DReadyArgs {
	bridge: Bridge;
	model: AppModel;
	deformityModel: DeformityAppModel;
}


/**
* This service initialized the canvas component.
*/
@Injectable()
export class MainCanvasLoaderService {

	private _initialized = false;
	private _canvas3DArgs: Canvas3DReadyArgs;

	public canvas3D: Subject<Canvas3DReadyArgs> = new Subject<Canvas3DReadyArgs>();

	constructor(
		private modelSrv: ModelService,
		private caseSrv: CaseService,
		private imgSrv: ImageService,
		private route: ActivatedRoute,
		private titleSrv: Title,
		private langSrv: LanguageService
	) { }


	/**
	 * Prepare canvas component.
	 */
	public init(): Observable<void> {
		if (!this._initialized) {
			this._initialized = true;

			try {
				const main = new Main(document.getElementById('canvas-container') as HTMLDivElement);
				this.canvas3D.next(this._canvas3DArgs = {
					bridge: main.bridge,
					model: main.model,
					deformityModel: main.deformityModel
				});
				this.canvas3D.complete();
			} catch (error) {
				if (error instanceof Error && error.message === 'Error creating WebGL context.') {
					return throwError(() => new Error('WebglDisabled'));
				}
			}

			if (this.isScreenResolutionInvalid) {
				return throwError(() => new Error('ScreenResolutionInvalid'));
			}

			const caseGuid = this.route.snapshot.queryParamMap.get('caseGuid');
			return this.loadPlan(caseGuid).pipe(
				switchMap(plan => this.loadModel(plan))
			);
		}
	}

	private get isScreenResolutionInvalid(): boolean {
		return (window.screen.width * window.devicePixelRatio < 1280) || (window.screen.height * window.devicePixelRatio < 768);
	}

	private loadPlan(planGuid: string): Observable<Case> {
		if (!isGuid(planGuid)) return throwError(() => new Error('PlanNotValid'));
		return this.caseSrv.getCase(planGuid).pipe(
			catchError((err: Error) => {
				return err.message === 'ItemNotFound' ? throwError(() => new Error('PlanNotFound')) : throwError(() => err)
			}),
			switchMap(plan => {
				return plan.isReceived ? throwError(() => new Error('PlanNotValid')) : of(plan);
			}),
			tap(plan => this.setTitle(plan))
		);
	}

	private setTitle(plan: Case): void {
		const titleLbl: string = this.langSrv.labels.APP_TITLE_PATIENT_PLAN;
		this.titleSrv.setTitle(titleLbl.replace('{PAT}', plan.patient.number).replace('{PLAN}', plan.number));
	}

	private loadModel(plan: Case): Observable<void> {
		let nextrayModel: RayModel;
		return this.modelSrv.restoreRayModel(plan.id, plan.userGuid).pipe(
			switchMap(rayModel => {
				return !this.isFitboneModel(rayModel) ? throwError(() => new Error('PlanNotValid')) : of(rayModel);
			}),
			tap(rayModel => nextrayModel = rayModel),
			switchMap(() => this.modelSrv.restoreTemplateModel(plan.id, plan.userGuid)),
			switchMap(fitboneModel => this.setMainModel(plan, fitboneModel?.model, nextrayModel?.model))
		);
	}

	private isFitboneModel(model: RayModel): boolean {
		return model?.model?.state && (JSON.parse(model?.model?.state) as unknown as State)?.type === StateTypes.RPM;
	}

	private observAP = (plan: Case) => plan.apImageGuid ? this.imgSrv.getImage(plan.apImageGuid) : of(null);
	private observLT = (plan: Case) => plan.ltImageGuid ? this.imgSrv.getImage(plan.ltImageGuid) : of(null);

	private setMainModel(plan: Case, templateModel: TemplateModel, rayModel: NextrayModel): Observable<void> {
		return combineLatest([
			this.observAP(plan),
			this.observLT(plan)
		]).pipe(
			map(([imgAP, imgLT]) => this.caseModelMapper(plan, imgAP, imgLT, templateModel, rayModel)),
			map(caseModel => this._canvas3DArgs.bridge.mapEvent('setModel', caseModel))
		);
	}

	private caseModelMapper(plan: Case, imgAP: Image, imgLT: Image, templateModel: TemplateModel, rayModel: NextrayModel): CaseModel {
		if (!plan) return null;
		return {
			// plan data
			boneType: plan.boneType,
			version: plan.version,
			type: plan.type,
			referenceType: plan.referenceType,
			side: plan.side,
			isPostOperative: plan.isPostOperative,
			// image AP data
			imgAP: imgAP?.url,
			scaleFactorAP: imgAP?.scaleFactor,
			flipTypeAP: this.flipTypeMapper(imgAP?.orientation),
			// image LT data
			imgLT: imgLT?.url,
			scaleFactorLT: imgLT?.scaleFactor,
			flipTypeLT: this.flipTypeMapper(imgLT?.orientation),
			// ray model
			model: templateModel,
			cacheModel: rayModel
		}
	}

	private flipTypeMapper(orientationType: ImageOrientationEnum): InitialFlipType {
		switch (orientationType) {
			case ImageOrientationEnum.Nothing: return null;
			case ImageOrientationEnum.Horizontal: return InitialFlipType.horizontal;
			case ImageOrientationEnum.Vertical: return InitialFlipType.vertical;
			case ImageOrientationEnum.Rotate180: return InitialFlipType.rotate180;
			default: return null;
		}
	}

}

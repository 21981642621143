import { DOCUMENT } from "@angular/common";
import { ApplicationRef, ChangeDetectorRef, ComponentFactoryResolver, Directive, ElementRef, Inject, Injector, Input, NgZone, OnInit, Renderer2, ViewContainerRef } from "@angular/core";
import { NgbTooltip, NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";
import { of, Subscription } from "rxjs";
import { delay } from "rxjs/operators";


/**
* Custom tooltip component to highlight flow steps
 * @param {string} valueTooltip text of tooltip
 * @param {boolean} optionalStyle if true set optional style version (i.e. change style)
 * @param {boolean} activeTooltip when true tooltip is opened for some time
*/
@Directive({
  selector: "[customTooltip]",
  exportAs: "customTooltip"
})
export class CustomTooltipDirective extends NgbTooltip implements OnInit {

  @Input() valueTooltip: string;
  @Input() optionalStyle: boolean;
  @Input() set activeTooltip(val: boolean) {
    if (val !== this._active) {
      this._active = val;
      this._active ? this.open() : this.close();
    }
  }

  private delaySubscription: Subscription;
  private _active: boolean = false;
  private _done: boolean = false;
  private readonly OPEN_DELAY: number = 5000;

  constructor(
    private _elRef: ElementRef,
    private _render: Renderer2,
    injector: Injector,
    private viewContainerRef: ViewContainerRef,
    config: NgbTooltipConfig,
    ngZone: NgZone,
    private changeRef: ChangeDetectorRef,
    @Inject(DOCUMENT) _document: any,
    applicationRef: ApplicationRef
  ) {
    super(
      _elRef,
      _render,
      injector,
      viewContainerRef,
      config,
      ngZone,
      _document,
      changeRef,
      applicationRef
    );

    this.triggers = "manual";
    this.autoClose = false;
    this.placement = "right";
    this.tooltipClass = "custom-tooltip";
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.optionalStyle && (this.tooltipClass = "custom-tooltip optional");
    this.ngbTooltip = this.valueTooltip;
    this.shown.subscribe(res => {
      this.addStyle();
      this._done = false;
    });
    this.hidden.subscribe(res => this.removeStyle());
    this.open();
  }

  /**
  * Open tooltip
  */
  open(): void {
    if (!this._done && this._active) {
      super.open();
      this.delaySubscription = of(true)
        .pipe(delay(this.OPEN_DELAY))
        .subscribe(res => this.close());
    }
  }

  /**
  * Close tooltip
  */
  close(): void {
    super.close();
    this.delaySubscription && this.delaySubscription.unsubscribe();
  }

  /**
  * Get style for reference element
  */
  get style(): string[] {
    return this.optionalStyle ? ["highlighted", "optional"] : ["highlighted"];
  }

  /**
  * Add style in reference element
  */
  addStyle(): void {
    (this._elRef.nativeElement as HTMLDivElement).classList.add(...this.style);
  }

  /**
  * Remove style in reference element 
  */
  removeStyle(): void {
    (this._elRef.nativeElement as HTMLDivElement).classList.remove(...this.style);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

import { Component, OnInit } from '@angular/core';
import { SelectedApexMech } from '@ortho-next/nextray-core';
import { LanguageService } from '../../core';
import { ApproachImageEnum, CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component of first vertical sidebar to handle insertion point workflow.
*/
@Component({
  selector: 'insertion-point-workflow',
  templateUrl: './insertion-point-workflow.component.html',
  styles: []
})
export class InsertionPointWorkflowComponent extends BaseComponent implements OnInit {

  helpImgAP: string;
  helpImgLT: string;
  isImgAPVisible: boolean;
  isImgLTVisible: boolean;

  constructor(
    private langSrv: LanguageService,
    private canvasSrv: CanvasService
  ) {
    super(langSrv, canvasSrv);
  }

  ngOnInit() {
    this.helpImgAP = this._helpImgAP;
    this.hasLT && (this.helpImgLT = this._helpImgLT);
  }


  /**
  * Back to insertion approach selection.
  */
  back(): void {
    this.canvasSrv.dispatch('cancelInsertionPoint');
    this.canvasSrv.dispatch('startSelectingApex');
  }

  /**
  * Close insertion point workflow.
  */
  cancel(): void {
    this.canvasSrv.dispatch('cancelInsertionPoint');
  }

  /**
  * Confirm osteotomy site selection and place osteotomy line.
  */
  confirm(): void {
    this.canvasSrv.dispatch('confirmInsertionPoint');
    this.canvasSrv.saveState();
  }


  private get _helpImgAP(): string {
    let fileName: ApproachImageEnum = null;
    if (this.isRight) {
      this.selectedApex === SelectedApexMech.femurProximal ? fileName = ApproachImageEnum.Right_AntegradeFemur_AP : fileName;
      this.selectedApex === SelectedApexMech.femurDistal ? fileName = ApproachImageEnum.Right_RetrogradeFemur_AP : fileName;
      this.selectedApex === SelectedApexMech.tibiaProximal ? fileName = ApproachImageEnum.Right_AntegradeTibia_AP : fileName;
    } else {
      this.selectedApex === SelectedApexMech.femurProximal ? fileName = ApproachImageEnum.Left_AntegradeFemur_AP : fileName;
      this.selectedApex === SelectedApexMech.femurDistal ? fileName = ApproachImageEnum.Left_RetrogradeFemur_AP : fileName;
      this.selectedApex === SelectedApexMech.tibiaProximal ? fileName = ApproachImageEnum.Left_AntegradeTibia_AP : fileName;
    }
    return fileName;
  }

  private get _helpImgLT(): string {
    let fileName: ApproachImageEnum = null;
    if (this.isRight) {
      this.selectedApex === SelectedApexMech.femurProximal ? fileName = ApproachImageEnum.Right_AntegradeFemur_LT : fileName;
      this.selectedApex === SelectedApexMech.femurDistal ? fileName = ApproachImageEnum.Right_RetrogradeFemur_LT : fileName;
      this.selectedApex === SelectedApexMech.tibiaProximal ? fileName = ApproachImageEnum.Right_AntegradeTibia_LT : fileName;
    } else {
      this.selectedApex === SelectedApexMech.femurProximal ? fileName = ApproachImageEnum.Left_AntegradeFemur_LT : fileName;
      this.selectedApex === SelectedApexMech.femurDistal ? fileName = ApproachImageEnum.Left_RetrogradeFemur_LT : fileName;
      this.selectedApex === SelectedApexMech.tibiaProximal ? fileName = ApproachImageEnum.Left_AntegradeTibia_LT : fileName;
    }
    return fileName;
  }

}

<!-- Menu Settings -->
<div class="menu-settings-tlhex">


  <!--LEFT MENU COMPONENTS-->
  <tools-menu *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.none && !isViewerVisible"></tools-menu>

  <viewer *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.none && isViewerVisible"></viewer>

  <osteotomy-workflow *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.insertOsteotomy"></osteotomy-workflow>

  <insertion-point-workflow *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.insertInsertionPoint"></insertion-point-workflow>

  <template-selection *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.insertFitbone"></template-selection>

  <!--RIGHT MENU - DRAWER-->
  <drawer></drawer>

</div>

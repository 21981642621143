import { LabelText, LabelTextConfig, Save, SaveChild, SelectableTool, ToolToSave, ToolType, ViewType } from "@ortho-next/nextray-core";
import { Group, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { InsertionPointConfig as Config } from "../../Config/InsertionPointConfig";
import { BindedModel } from "../../Models/BindedModel";
import { InsertionPoint } from "./InsertionPoint";
import { Default } from "@ortho-next/nextray-core/Utils/Default";

export class InsertionPointManager extends Group implements ToolToSave, SelectableTool {
  public isSelectable = true;
  public isDeletable = false;
  public isSelected: boolean;
  public toolType = ToolType.insertionPoint;
  @Save("position") public insertionPoint: InsertionPoint;
  @SaveChild() public label: LabelText;

  public onSelect(): void {
    Default.colorAllDraggablePoints(this);
  }

  public onDeselect(): void {
    Default.restoreColorAllDraggablePoints(this);
  }

  constructor(viewType: ViewType) {
    super();
    this.position.z = 100;

    this.add(
      this.insertionPoint = new InsertionPoint(viewType),
      this.label = new LabelText({
        name: 'InsertionPointLabel',
        color: LabelTextConfig.color,
        viewType: viewType,
        objToAnchor: this.insertionPoint,
        anchor: new Vector3(7, 5),
        labelParams: { backgroundColor: Config.label_backgroundColor, fontColor: Config.label_fontColor, text: 'Insertion Point' }
      })
    );

		Default.bindOnEnabledChange_NoRaycastAndHiddenLabel(this);

    this.bindProperty("visible", (m: BindedModel) => {
      return m.isInsertionPointInserted && m.layer_plane;
    }, ["isInsertionPointInserted", "layer_plane"]);

    this.bindProperty("isEnabled", (m: BindedModel) => {
      return m.isInsertionPointEnabled;
    }, ["isInsertionPointEnabled"]);

  }

}

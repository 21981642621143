// @ts-nocheck
import { DraggablePoint, MechanicalAxisFemurAP, Save, SaveChild, SelectableTool, SelectedApexMech, ToolEvents, ToolToSave, ToolType, VectorUtils, ViewType } from "@ortho-next/nextray-core";
import { Cursors } from "@ortho-next/three-base/RaycasterHandler/CursorHandler";
import { Plane } from "@ortho-next/nextray-core/Tools/Plane";
import { CalculationPoint } from "@ortho-next/nextray-core/Tools/Primitive/CalculationPoint";
import { LinePointToPoint } from "@ortho-next/nextray-core/Tools/Primitive/LinePointToPoint";
import { Group, Shape, ShapeBufferGeometry, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { EoCPlaneConfig as Config } from "../../Config/EoCPlaneConfig";
import { BindedModel, bindedModel } from "../../Models/BindedModel";
import { Consts } from "../../Utils/Consts";
import { MechanicalAxisAP } from "../DeformityAnalyzer/FullAnalyzerAP";
import { MechanicalAxisLT } from "../DeformityAnalyzer/FullAnalyzerLT";
import { Osteotomy } from "../Osteotomy/Osteotomy";
import { BlackPlane } from "./BlackPlane";
import { ClonedMechanicalAxisManager } from "./ClonedMechanicalAxisManager";
import { ClonedOsteotomyManager } from "./ClonedOsteotomyManager";
import { CroppedPlane } from "./CroppedPlane";
import { Default } from "@ortho-next/nextray-core/Utils/Default";

export class EOCPlaneVertices {
  @Save() A: Vector3;
  @Save() B: Vector3;
  @Save() C: Vector3;
  @Save() D: Vector3;
  @Save() E: Vector3;
}

export class CropVertices extends EOCPlaneVertices {
  @Save() numOfVertices: number;
  blockedVertices: string;
}

export abstract class EoCPlaneBase extends Group implements ToolToSave, SelectableTool {
  public isSelectable = true;
  public isDeletable = false;
  public isSelected: boolean;
  public toolType = ToolType.EOCCrop;
  public osteotomy: Osteotomy;
  @SaveChild("position", "scale", "rotation") public croppedPlane: CroppedPlane;
  @SaveChild("position", "scale", "rotation") public blackPlane: BlackPlane;
  @SaveChild("position", "scale", "rotation") public ctrlBlackPlane: BlackPlane;
  @Save("position") public A: CalculationPoint;
  @Save("position") public B: CalculationPoint;
  @Save("position") public C: CalculationPoint;
  @Save("position") public D: CalculationPoint;
  @Save("position") public E: CalculationPoint;
  @Save("position") public ost1: DraggablePoint;
  @Save("position") public ost2: DraggablePoint;
  public line1: LinePointToPoint;
  public line2: LinePointToPoint;
  public line3: LinePointToPoint;
  public line4: LinePointToPoint;
  public line5: LinePointToPoint;
  @SaveChild() protected _clonedMechanicalAxisManager: ClonedMechanicalAxisManager;
  @SaveChild() protected _clonedOsteotomyManager: ClonedOsteotomyManager;
  protected _plane: Plane;
  protected _originalMechanicalAxis: MechanicalAxisAP | MechanicalAxisLT;
  @Save() protected _rotationCenter: Vector3;
  @Save() protected _oppositeToRotationCenter: Vector3;
  @Save() protected _oppositeToRotationCenterCloned: Vector3;
  protected _viewType: ViewType;
  protected _isLeft: boolean;
  protected _isEnabled: boolean;

  @Save() public numOfVertices: number;
  @SaveChild() protected _croppedPoints: CropVertices = new CropVertices();
  protected _startPoints: CropVertices;

  protected abstract get _cutPoint(): Vector3;
  protected abstract bindEvents(): void;
  protected abstract bindProperties(): void;
  protected abstract calculateRotationCenter(): void;
  protected abstract rotateAndTranslateAfterCut(): void;
  protected abstract setVerticalTranslation(value: number): Vector3;
  protected abstract getVerticalTranslation(): number;

  public onSelect(): void {
    Default.colorAllDraggablePoints(this);
  }

  public onDeselect(): void {
    Default.restoreColorAllDraggablePoints(this);
  }

  public onAfterRestore = () => { //todo automatism
    this._viewType === ViewType.AP && (this.clonedMechanicalAxis.femur as MechanicalAxisFemurAP).updateNSATool();
    if (this.E.position.x !== 0 || this.E.position.y !== 0) {
      this.line3.v1 = this.C;
      this.line3.v2 = this.D;
      this.line4.v1 = this.D;
      this.line4.v2 = this.E;
      this.line5.v1 = this.E;
      this.line5.v2 = this.A;
      this.add(this.D, this.E, this.line4, this.line5);
    } else if (this.D.position.x !== 0 || this.D.position.y !== 0) {
      this.line3.v1 = this.C;
      this.line3.v2 = this.D;
      this.line4.v1 = this.D;
      this.line4.v2 = this.A;
      this.add(this.D, this.line4);
    } else {
      this.line3.v1 = this.C;
      this.line3.v2 = this.A;
    }
    this.ctrlBlackPlane && this.add(this.ctrlBlackPlane);
    this.dispatchEvent({ type: ToolEvents.updated });
  }

  constructor(viewType: ViewType, isLeft: boolean, plane: Plane, osteotomy: Osteotomy, mechanicalAxis: MechanicalAxisAP | MechanicalAxisLT) {
    super();
    this.name = "EoCPlane";
    this._plane = plane;
    this._viewType = viewType;
    this.osteotomy = osteotomy;
    this._originalMechanicalAxis = mechanicalAxis;
    this._clonedMechanicalAxisManager = new ClonedMechanicalAxisManager(this, this._originalMechanicalAxis);
    this._clonedOsteotomyManager = new ClonedOsteotomyManager(this.osteotomy, this);
    this._isLeft = isLeft;

    this.add(
      this.blackPlane = new BlackPlane(),
      this.croppedPlane = new CroppedPlane(plane.material),
      this.ost1 = new DraggablePoint("Ost1", viewType, Config.ost1_color),
      this.ost2 = new DraggablePoint("Ost2", viewType, Config.ost2_color),
      this.A = new CalculationPoint("A"),
      this.B = new CalculationPoint("B"),
      this.C = new CalculationPoint("C"),
      this.line1 = new LinePointToPoint("Line1", Config.line1_color, this.A, this.B).translateZ(1),
      this.line2 = new LinePointToPoint("Line2", Config.line2_color, this.B, this.C).translateZ(1),
      this.line3 = new LinePointToPoint("Line3", Config.line3_color).translateZ(1),
      this._clonedMechanicalAxisManager.obj,
      this._clonedOsteotomyManager.obj
    );

    this.ctrlBlackPlane = new BlackPlane();

    this.ost1.cursorOnDrag = Config.CURSOR_ROTATE;
    this.ost2.cursorOnDrag = Config.CURSOR_ROTATE;

    this.D = new CalculationPoint("D");
    this.E = new CalculationPoint("E");

    this.line4 = new LinePointToPoint("Line4", Config.line4_color).translateZ(1);
    this.line5 = new LinePointToPoint("Line5", Config.line5_color).translateZ(1);

    this.bindEventsBase();

    this.bindPropertiesBase();
  }

  public get isEnabled(): boolean {
    return this._isEnabled;
  }
  public set isEnabled(value: boolean) {
    this._isEnabled = value;
    this.ost1.cursorOnHover = value ? Config.CURSOR_ROTATE : Cursors["not-allowed"];
    this.ost2.cursorOnHover = value ? Config.CURSOR_ROTATE : Cursors["not-allowed"];
  }

  public get clonedMechanicalAxis(): MechanicalAxisAP | MechanicalAxisLT {
    return this._clonedMechanicalAxisManager.obj;
  }

  public get clonedOsteotomy(): Osteotomy {
    return this._clonedOsteotomyManager.obj;
  }

  public get clonedMechanicalAxisManager(): ClonedMechanicalAxisManager {
    return this._clonedMechanicalAxisManager;
  }

  public get clonedOsteotomyManager(): ClonedOsteotomyManager {
    return this._clonedOsteotomyManager;
  }

  public get angle(): number {
    const ostDir = this.osteotomy.A.position.clone().sub(this.osteotomy.B.position);
    const clonedOstDir = this.clonedOsteotomy.A.position.clone().sub(this.clonedOsteotomy.B.position);
    return ostDir.angleTo(clonedOstDir);
  }
  public set angle(value: number) {
    console.error("set angle not implemented yet.");
  }

  public get cortexLength(): number {
    const cortex1 = this.calculateCortexLength(this.clonedOsteotomy.A.position);
    const cortex2 = this.calculateCortexLength(this.clonedOsteotomy.B.position);
    const maxCortex = Math.max(cortex1, cortex2);
    return maxCortex;
  }

  private calculateCortexLength(clonedOst: Vector3): number {
    const verticalDir = this._originalMechanicalAxis.getProximalDirToCalculateApex(bindedModel.selectedApex);
    const ost1 = this.osteotomy.A.position;
    const ost2 = this.osteotomy.B.position;
    const proj = VectorUtils.projectOnVector(clonedOst, ost1, ost2);
    let sign = VectorUtils.arePointsOnSameSide(ost1, ost2, Consts.planeNormal, clonedOst, ost1.clone().add(verticalDir)) ? -1 : 1;
    sign *= bindedModel.selectedApex === SelectedApexMech.tibiaProximal ? 1 : -1;
    return proj.distanceTo(clonedOst) * sign;
  }

  //public get verticalTranslation(): number {
  //  let verticalDir;
  //  if (this.osteotomy.selectedApex === SelectedApex.tibiaProximal) {
  //    verticalDir = this._originalMechanicalAxis.getProximalDirToCalculateApex(this.osteotomy.selectedApex);
  //  } else {
  //    verticalDir = this._originalMechanicalAxis.getDistalDirToCalculateApex(this.osteotomy.selectedApex);
  //  }
  //  const ost1 = this.osteotomy.C.position;
  //  const ost2 = this.osteotomy.C.position.clone().add(VectorUtils.getPerpendicular(verticalDir));
  //  const proj = VectorUtils.projectOnVector(this.clonedOsteotomy.C.position, ost1, ost2);
  //  let sign = VectorUtils.arePointsOnSameSide(ost1, ost2, Consts.planeNormal, this.clonedOsteotomy.C.position, this.osteotomy.C.position.clone().add(verticalDir)) ? -1 : 1;
  //  sign *= this.osteotomy.selectedApex === SelectedApex.tibiaProximal ? 1 : -1;
  //  return proj.distanceTo(this.clonedOsteotomy.C.position) * sign;
  //}

  //public set verticalTranslation(value: number) {
  //  let verticalDir;
  //  if (this.osteotomy.selectedApex === SelectedApex.tibiaProximal) {
  //    verticalDir = this._originalMechanicalAxis.getProximalDirToCalculateApex(this.osteotomy.selectedApex);
  //  } else {
  //    verticalDir = this._originalMechanicalAxis.getDistalDirToCalculateApex(this.osteotomy.selectedApex);
  //  }
  //  verticalDir.y > 0 && verticalDir.multiplyScalar(this.osteotomy.selectedApex === SelectedApex.tibiaProximal ? -1 : 1);
  //  const pan = verticalDir.setLength(value - this.verticalTranslation);
  //  this.translationByPoint(pan.add(this.croppedPlane.position));
  //  this.dispatchEvent({ type: ToolEvents.updated });
  //}

  //public get horizontalTranslation(): number {
  //  const verticalDir = this._originalMechanicalAxis.getProximalDirToCalculateApex(bindedModel.selectedApex);
  //  const ost1 = this.osteotomy.C.position;
  //  const ost2 = this.osteotomy.C.position.clone().add(verticalDir);
  //  const proj = VectorUtils.projectOnVector(this.clonedOsteotomy.C.position, ost1, ost2);
  //  let sign = VectorUtils.arePointsOnSameSide(ost1, ost2, Consts.planeNormal, this.clonedOsteotomy.C.position, this.osteotomy.C.position.clone().add(VectorUtils.getPerpendicular(verticalDir))) ? -1 : 1;
  //  sign *= this._isLeft ? 1 : -1;
  //  return proj.distanceTo(this.clonedOsteotomy.C.position) * sign;
  //}

  protected bindEventsBase(): void {
    this.bindEvent("onDragMove", (args) => {
      args.preventDefault = true;
      this.dispatchEvent({ type: ToolEvents.updated });
    });

    this.ost1.bindEvent("onDragMove", (args) => {
      this.rotationByPoint(args.position, this.ost1);
    });

    this.ost2.bindEvent("onDragMove", (args) => {
      this.rotationByPoint(args.position, this.ost2);
    });

    this.bindEvents();
  }

  protected bindPropertiesBase(): void {

    this.ctrlBlackPlane.bindProperty('visible', (m: BindedModel) => {
      return !m.layer_contralateral_all && m.EOCCropVisible && m.isPolygonCropped;
    }, ['layer_contralateral_all', 'EOCCropVisible', 'isPolygonCropped']);

    this.bindProperties();
  }

  public translationByPoint(vector: Vector3): void {
    const pan = vector.clone().sub(this.croppedPlane.position);
    this.croppedPlane.position.add(pan);
    this.ost1.position.add(pan);
    this.ost2.position.add(pan);
    this.A.position.add(pan);
    this.B.position.add(pan);
    this.C.position.add(pan);
    this.D.parent && this.D.position.add(pan);
    this.E.parent && this.E.position.add(pan);
    this._clonedMechanicalAxisManager.makeTranslation(pan);
    this._clonedOsteotomyManager.makeTranslation(pan);
  }

  protected rotationByPoint(vector: Vector3, point: DraggablePoint): void { // apex funge solo se ruoti
    const angle = VectorUtils.linesAngle(point.position, vector, this._rotationCenter);
    const shapeOrigin = this.croppedPlane.position.clone().sub(this._rotationCenter);

    this.croppedPlane.rotateZ(angle);
    this.croppedPlane.position.sub(shapeOrigin).add(shapeOrigin.applyAxisAngle(Consts.planeNormal, angle))

    this.ost1.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(this._rotationCenter);
    this.ost2.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(this._rotationCenter);
    this.A.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(this._rotationCenter);
    this.B.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(this._rotationCenter);
    this.C.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(this._rotationCenter);
    this.D.parent && this.D.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(this._rotationCenter);
    this.E.parent && this.E.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(this._rotationCenter);
    this._clonedMechanicalAxisManager.makeRotation(angle, this._rotationCenter);
    this._clonedOsteotomyManager.makeRotation(angle, this._rotationCenter);
    this.dispatchEvent({ type: "rotated", angle: angle, rotationCenter: this._rotationCenter });
  }

  protected reset(): void {
    this.croppedPlane.position.set(0, 0, 2);
    this.croppedPlane.rotation.set(0, 0, 0);
    this.blackPlane.position.set(0, 0, 1);
    this.blackPlane.rotation.set(0, 0, 0);
    this.ost1.position.set(0, 0, 0);
    this.ost2.position.set(0, 0, 0);
    this.A.position.set(0, 0, 0);
    this.B.position.set(0, 0, 0);
    this.C.position.set(0, 0, 0);
    this.D.position.set(0, 0, 0);
    this.E.position.set(0, 0, 0);
    this.remove(this.D, this.E, this.line4, this.line5);

    this.ctrlBlackPlane.position.set(0, 0, 1);
    this.ctrlBlackPlane.rotation.set(0, 0, 0);
    this.remove(this.ctrlBlackPlane);
  }

  public cutPlane(verticalTransl: number): boolean {
    const vertices = this.getCurrentVertices();
    if (!vertices || !vertices.A) {
      return false;  // No polygon
    }

    this.updateVertices(vertices);

    const croppedVertices = new EOCPlaneVertices();
    croppedVertices.A = this.A.position.clone().setZ(0);
    croppedVertices.B = this.B.position.clone().setZ(0);
    croppedVertices.C = this.C.position.clone().setZ(0);
    croppedVertices.D = this.numOfVertices >= 4 ? this.D.position.clone().setZ(0) : null;
    croppedVertices.E = this.numOfVertices === 5 ? this.E.position.clone().setZ(0) : null;

    const startVertices = this.getStartVertices();
    const blackPlaneVertices = new EOCPlaneVertices();
    blackPlaneVertices.A = startVertices.A.clone().setZ(0);
    blackPlaneVertices.B = startVertices.B.clone().setZ(0);
    blackPlaneVertices.C = startVertices.C.clone().setZ(0);
    blackPlaneVertices.D = this.numOfVertices >= 4 ? startVertices.D.clone().setZ(0) : null;
    blackPlaneVertices.E = this.numOfVertices === 5 ? startVertices.E.clone().setZ(0) : null;

    this.setCroppedAndBlackPlane(croppedVertices, this._plane.rotation.z, blackPlaneVertices);

    this.ost1.position.copy(this.A.position).setZ(5);
    this.ost2.position.copy(this.B.position).setZ(5);

    this._clonedOsteotomyManager.reset();
    this._clonedMechanicalAxisManager.reset();

    this._viewType === ViewType.AP && this.setCtrlBlackPlane();

    this._clonedMechanicalAxisManager.updatePointsToUpdate();
    this._clonedMechanicalAxisManager.updatedWeightBearingVertices();
    this.calculateRotationCenter();
    this.rotateAndTranslateAfterCut();
    //this.verticalTranslation = verticalTransl;

    return true;
  }

  public getCurrentVertices(): CropVertices {
    const vertices = bindedModel.isPolygonCropped ? this._croppedPoints : this.getStartVertices();
    return vertices;
  }

  public getStartVertices(): CropVertices {
    if (!this._startPoints) {
      const vertices = this.calculateStartingPoints();
      if (!vertices) {
        this._startPoints = null;
      } else {
        this._startPoints = new CropVertices();
        this._startPoints.blockedVertices = vertices.blockedVertices;
        this._startPoints.numOfVertices = vertices.numOfVertices;
        this._startPoints.A = vertices.A.clone();
        this._startPoints.B = vertices.B.clone();
        this._startPoints.C = vertices.C.clone();
        this._startPoints.D = vertices.D ? vertices.D.clone() : null;
        this._startPoints.E = vertices.E ? vertices.E.clone() : null;
      }
    }
    return this._startPoints;
  }

  public resetPoints(): void {
    this._croppedPoints = new CropVertices();
    this._startPoints = null;
  }

  public updateCroppedVertices(vertices: CropVertices): void {
    this._croppedPoints = new CropVertices();
    this._croppedPoints.numOfVertices = vertices.numOfVertices;
    this._croppedPoints.A = vertices.A.clone();
    this._croppedPoints.B = vertices.B.clone();
    this._croppedPoints.C = vertices.C.clone();
    this._croppedPoints.D = vertices.D ? vertices.D.clone() : null;
    this._croppedPoints.E = vertices.E ? vertices.E.clone() : null;
  }

  private updateVertices(vertices: CropVertices): void {
    this.reset();
    this.numOfVertices = vertices.numOfVertices;
    this.A.position.copy(vertices.A);
    this.B.position.copy(vertices.B);
    this.C.position.copy(vertices.C);
    switch (vertices.numOfVertices) {
      case 3: {
        this.line3.v1 = this.C;
        this.line3.v2 = this.A;
        break;
      }
      case 4: {
        this.D.position.copy(vertices.D);
        this.add(this.D, this.line4);
        this.line3.v1 = this.C;
        this.line3.v2 = this.D;
        this.line4.v1 = this.D;
        this.line4.v2 = this.A;
        break;
      }
      case 5: {
        this.D.position.copy(vertices.D);
        this.E.position.copy(vertices.E);
        this.add(this.D, this.E, this.line4, this.line5);
        this.line3.v1 = this.C;
        this.line3.v2 = this.D;
        this.line4.v1 = this.D;
        this.line4.v2 = this.E;
        this.line5.v1 = this.E;
        this.line5.v2 = this.A;
        break;
      }
    }
  }

  /**
  * Calculate initial vertices of polygon.
  */
  private calculateStartingPoints(): CropVertices {
    let startingPoints: CropVertices;
    const vertices = this._plane.getVertices(0) as EOCPlaneVertices;
    const ost1 = this.osteotomy.A.position.clone().setZ(0); // TODO: remove set z and insert line intersection with no z considering
    const ost2 = this.osteotomy.B.position.clone().setZ(0);
    const intersectionAB = VectorUtils.lines2DIntersection(ost1, ost2, vertices.A, vertices.B);
    const intersectionBC = VectorUtils.lines2DIntersection(ost1, ost2, vertices.B, vertices.C);
    const intersectionCD = VectorUtils.lines2DIntersection(ost1, ost2, vertices.C, vertices.D);
    const intersectionDA = VectorUtils.lines2DIntersection(ost1, ost2, vertices.D, vertices.A);

    // takes the part of plane from given point
    const normOsteotomyPlane = Consts.planeNormal.clone().cross(ost1.clone().sub(ost2)); // TODO: perp
    const signA = Math.sign(normOsteotomyPlane.clone().dot(vertices.A.clone().sub(ost1))); // TODO: use computesign
    const signB = Math.sign(normOsteotomyPlane.clone().dot(vertices.B.clone().sub(ost1)));
    const signC = Math.sign(normOsteotomyPlane.clone().dot(vertices.C.clone().sub(ost1)));
    const signD = Math.sign(normOsteotomyPlane.clone().dot(vertices.D.clone().sub(ost1)));
    const signPoint = Math.sign(normOsteotomyPlane.clone().dot(this._cutPoint.clone().sub(ost1)));

    let unlockedVertices = "";
    let intersection1: Vector3, intersection2: Vector3;

    if (signA === signPoint) { unlockedVertices += "A"; }
    if (signB === signPoint) { unlockedVertices += "B"; }
    if (signC === signPoint) { unlockedVertices += "C"; }
    if (signD === signPoint) { unlockedVertices += "D"; }

    if (unlockedVertices.length === 0 || unlockedVertices.length === 4) return null;

    if (unlockedVertices.length === 2) {
      //CROP 4 VERTICES

      if (intersectionAB === undefined) {
        intersection1 = intersectionDA;
        intersection2 = intersectionBC;
      } else if (intersectionBC === undefined) {
        intersection1 = intersectionAB;
        intersection2 = intersectionCD;
      } else {
        if (intersectionAB.distanceTo(intersectionCD) > intersectionDA.distanceTo(intersectionBC)) {
          intersection1 = intersectionDA;
          intersection2 = intersectionBC;
        } else {
          intersection1 = intersectionAB;
          intersection2 = intersectionCD;
        }
      }

      let blockedVertex = "";
      if (signA !== signPoint && signA !== 0) { blockedVertex += "A"; }
      if (signB !== signPoint && signB !== 0) { blockedVertex += "B"; }
      if (signC !== signPoint && signC !== 0) { blockedVertex += "C"; }
      if (signD !== signPoint && signD !== 0) {
        blockedVertex += "D";
        if (blockedVertex === "AD") { blockedVertex = "DA" }
      }

      //update vertex
      const otherindex: number = this.setIntersectionFromVector(vertices[blockedVertex[0]], vertices[blockedVertex[1]], intersection1);
      vertices[blockedVertex[otherindex]].copy(intersection2);

      startingPoints = new CropVertices();
      startingPoints.A = vertices.A.clone().add(Consts.planeNormal);
      startingPoints.B = vertices.B.clone().add(Consts.planeNormal);
      startingPoints.C = vertices.C.clone().add(Consts.planeNormal);
      startingPoints.D = vertices.D.clone().add(Consts.planeNormal);
      startingPoints.blockedVertices = blockedVertex;
      startingPoints.numOfVertices = 4;
      return startingPoints;
    }
    else if (unlockedVertices.length == 1) {
      //CROP 3 VERTICES
      if (unlockedVertices == "A") {
        startingPoints = new CropVertices();
        startingPoints.A = vertices.A.clone().add(Consts.planeNormal);
        startingPoints.B = intersectionAB.clone().add(Consts.planeNormal);
        startingPoints.C = intersectionDA.clone().add(Consts.planeNormal);
        startingPoints.blockedVertices = unlockedVertices;
        startingPoints.numOfVertices = 3;
        return startingPoints;
      }
      if (unlockedVertices == "B") {
        startingPoints = new CropVertices();
        startingPoints.A = intersectionAB.clone().add(Consts.planeNormal);
        startingPoints.B = vertices.B.clone().add(Consts.planeNormal);
        startingPoints.C = intersectionBC.clone().add(Consts.planeNormal);
        startingPoints.blockedVertices = unlockedVertices;
        startingPoints.numOfVertices = 3;
        return startingPoints;
      }
      if (unlockedVertices == "C") {
        startingPoints = new CropVertices();
        startingPoints.A = intersectionCD.clone().add(Consts.planeNormal);
        startingPoints.B = intersectionBC.clone().add(Consts.planeNormal);
        startingPoints.C = vertices.C.clone().add(Consts.planeNormal);
        startingPoints.blockedVertices = unlockedVertices;
        startingPoints.numOfVertices = 3;
        return startingPoints;
      }
      if (unlockedVertices == "D") {
        startingPoints = new CropVertices();
        startingPoints.A = intersectionDA.clone().add(Consts.planeNormal);
        startingPoints.B = intersectionCD.clone().add(Consts.planeNormal);
        startingPoints.C = vertices.D.clone().add(Consts.planeNormal);
        startingPoints.blockedVertices = unlockedVertices;
        startingPoints.numOfVertices = 3;
        return startingPoints;
      }
    }
    else if (unlockedVertices.length == 3) {
      //CROP 5 VERTICES
      if (unlockedVertices == "ABC") {
        startingPoints = new CropVertices();
        startingPoints.A = vertices.A.clone().add(Consts.planeNormal);
        startingPoints.B = vertices.B.clone().add(Consts.planeNormal);
        startingPoints.C = vertices.C.clone().add(Consts.planeNormal);
        startingPoints.D = intersectionCD.clone().add(Consts.planeNormal);
        startingPoints.E = intersectionDA.clone().add(Consts.planeNormal);
        startingPoints.blockedVertices = unlockedVertices;
        startingPoints.numOfVertices = 5;
        return startingPoints;
      }
      if (unlockedVertices == "BCD") {
        startingPoints = new CropVertices();
        startingPoints.A = intersectionAB.clone().add(Consts.planeNormal);
        startingPoints.B = vertices.B.clone().add(Consts.planeNormal);
        startingPoints.C = vertices.C.clone().add(Consts.planeNormal);
        startingPoints.D = vertices.D.clone().add(Consts.planeNormal);
        startingPoints.E = intersectionDA.clone().add(Consts.planeNormal);
        startingPoints.blockedVertices = unlockedVertices;
        startingPoints.numOfVertices = 5;
        return startingPoints;
      }
      if (unlockedVertices == "ACD") {
        startingPoints = new CropVertices();
        startingPoints.A = vertices.A.clone().add(Consts.planeNormal);
        startingPoints.B = intersectionAB.clone().add(Consts.planeNormal);
        startingPoints.C = intersectionBC.clone().add(Consts.planeNormal);
        startingPoints.D = vertices.C.clone().add(Consts.planeNormal);
        startingPoints.E = vertices.D.clone().add(Consts.planeNormal);
        startingPoints.blockedVertices = unlockedVertices;
        startingPoints.numOfVertices = 5;
        return startingPoints;
      }
      if (unlockedVertices == "ABD") {
        startingPoints = new CropVertices();
        startingPoints.A = vertices.A.clone().add(Consts.planeNormal);
        startingPoints.B = vertices.B.clone().add(Consts.planeNormal);
        startingPoints.C = intersectionBC.clone().add(Consts.planeNormal);
        startingPoints.D = intersectionCD.clone().add(Consts.planeNormal);
        startingPoints.E = vertices.D.clone().add(Consts.planeNormal);
        startingPoints.blockedVertices = unlockedVertices;
        startingPoints.numOfVertices = 5;
        return startingPoints;
      }
    }
  }

  protected setIntersectionFromVector(vertex1: Vector3, vertex2: Vector3, intersection: Vector3): number {
    if (vertex1.distanceTo(intersection) > vertex2.distanceTo(intersection)) {
      vertex2.copy(intersection);
      return 0;
    }
    vertex1.copy(intersection);
    return 1;
  }

  protected setCroppedAndBlackPlane(vertices: EOCPlaneVertices, angle: number, blackPlaneVertices?: EOCPlaneVertices): void {
    const geo = this.generateGeometry(vertices, angle);
    const blackGeo = blackPlaneVertices ? this.generateGeometry(blackPlaneVertices, angle) : geo;
    this.blackPlane.setGeometry(blackGeo);
    this.blackPlane.scale.copy(this._plane.scale).multiplyScalar(100);
    this.blackPlane.scale.y *= this._plane.heightGeometry / this._plane.widthGeometry;
    this.blackPlane.scale.z = 1; // TODO: refactor
    this.blackPlane.rotation.z = angle;
    this.croppedPlane.setGeometry(geo);
    this.croppedPlane.scale.copy(this.blackPlane.scale);
    this.croppedPlane.rotation.z = angle;
    const { D } = this._plane.getNoClippedVertices(0);
    this.blackPlane.position.add(D);
    this.croppedPlane.position.add(D);
  }

  protected generateGeometry(vertices: EOCPlaneVertices, angle: number): ShapeBufferGeometry {
    const { A, C, D } = this._plane.getNoClippedVertices(0);

    A.applyAxisAngle(Consts.planeNormal, -angle);
    C.applyAxisAngle(Consts.planeNormal, -angle);
    D.applyAxisAngle(Consts.planeNormal, -angle);

    const sub = new Vector3(D.x, D.y, 0);
    const div = new Vector3(C.x - D.x, A.y - D.y, 1);
    vertices.A.applyAxisAngle(Consts.planeNormal, -angle).sub(sub).divide(div);
    vertices.B.applyAxisAngle(Consts.planeNormal, -angle).sub(sub).divide(div);
    vertices.C.applyAxisAngle(Consts.planeNormal, -angle).sub(sub).divide(div);
    vertices.D && vertices.D.applyAxisAngle(Consts.planeNormal, -angle).sub(sub).divide(div);
    vertices.E && vertices.E.applyAxisAngle(Consts.planeNormal, -angle).sub(sub).divide(div);

    const planeShape = new Shape().
      moveTo(vertices.A.x, vertices.A.y).
      lineTo(vertices.B.x, vertices.B.y).
      lineTo(vertices.C.x, vertices.C.y);
    vertices.D && planeShape.lineTo(vertices.D.x, vertices.D.y);
    vertices.E && planeShape.lineTo(vertices.E.x, vertices.E.y);
    return new ShapeBufferGeometry(planeShape);
  }

  // Set Contralateral black plane
  protected setCtrlBlackPlane(): void {
    if (bindedModel.isPolygonCropped) {
      const vertices = new EOCPlaneVertices();
      vertices.A = this._croppedPoints.A.clone().setZ(0);
      vertices.B = this._croppedPoints.B.clone().setZ(0);
      vertices.C = this._croppedPoints.C.clone().setZ(0);
      vertices.D = this.numOfVertices > 3 ? this._croppedPoints.D.clone().setZ(0) : null;
      vertices.E = this.numOfVertices === 5 ? this._croppedPoints.E.clone().setZ(0) : null;

      const angle = this._plane.rotation.z;
      const geo = this.generateCtrlGeometry(vertices, angle);
      this.add(this.ctrlBlackPlane);
      this.ctrlBlackPlane.setGeometry(geo);
      this.ctrlBlackPlane.scale.copy(this._plane.scale).multiplyScalar(100);
      this.ctrlBlackPlane.scale.y *= this._plane.heightGeometry / this._plane.widthGeometry;
      this.ctrlBlackPlane.scale.z = 1;
      this.ctrlBlackPlane.rotation.z = angle;
      const { D } = this._plane.getNoClippedVertices(0);
      this.ctrlBlackPlane.position.add(D);
    }
  }

  // Generate geometry for Contralateral black plane
  protected generateCtrlGeometry(vertices: EOCPlaneVertices, angle: number): ShapeBufferGeometry {
    const { A, B, C, D } = this._plane.getVertices(0);
    const realVertices = this._plane.getNoClippedVertices(0);
    const rA = realVertices.A;
    const rC = realVertices.C;
    const rD = realVertices.D;

    // calculate external vertices
    const vertex1 = this._isLeft ? A : B;
    const vertex2 = this._isLeft ? D : C;

    // calculate cut point
    let nearestMechPoint = VectorUtils.getNearestPointFromSegment(vertex1, vertex2, ...this.clonedMechanicalAxisManager.allPoints.map(x => x.position));
    const offset = Consts.horDir.clone().multiplyScalar(this._isLeft ? -10 : 10);
    nearestMechPoint = nearestMechPoint.clone().add(offset); // add offset for mechanical axis points
    const points = [vertices.A, vertices.B, vertices.C, nearestMechPoint];
    vertices.D && points.push(vertices.D);
    vertices.E && points.push(vertices.E);
    const limitPoint = VectorUtils.getNearestPointFromSegment(vertex1, vertex2, ...points);

    // calculate internal vertices
    const intersect1 = VectorUtils.lines2DIntersection(A, B, limitPoint, limitPoint.clone().add(Consts.verDir));
    const intersect2 = VectorUtils.lines2DIntersection(C, D, limitPoint, limitPoint.clone().add(Consts.verDir));

    rA.applyAxisAngle(Consts.planeNormal, -angle);
    rC.applyAxisAngle(Consts.planeNormal, -angle);
    rD.applyAxisAngle(Consts.planeNormal, -angle);
    const sub = new Vector3(rD.x, rD.y, 0);
    const div = new Vector3(rC.x - rD.x, rA.y - rD.y, 1);
    vertex1.applyAxisAngle(Consts.planeNormal, -angle).sub(sub).divide(div);
    vertex2.applyAxisAngle(Consts.planeNormal, -angle).sub(sub).divide(div);
    intersect1.applyAxisAngle(Consts.planeNormal, -angle).sub(sub).divide(div);
    intersect2.applyAxisAngle(Consts.planeNormal, -angle).sub(sub).divide(div);

    const planeShape = new Shape().
      moveTo(vertex1.x, vertex1.y).
      lineTo(vertex2.x, vertex2.y).
      lineTo(intersect2.x, intersect2.y).
      lineTo(intersect1.x, intersect1.y);
    return new ShapeBufferGeometry(planeShape);
  }

}

import { Save } from "@ortho-next/nextray-core";
import { BufferGeometry, Euler, Mesh, MeshBasicMaterial, ShapeBufferGeometry, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { BindedModel } from "../../Models/BindedModel";
import { PrintStateTypes } from "../../States/State";
import { Binding } from "@ortho-next/three-base/Binding/Binding";

export class BlackPlane extends Mesh {
  @Save("attributes.position", "attributes.normal", "attributes.uv", "index")
  public geometry: ShapeBufferGeometry; //override
  public material: MeshBasicMaterial; //override

  public onAfterRestore = () => {
    this.geometry.computeBoundingSphere();
  }

  constructor() {
    super(new BufferGeometry(), new MeshBasicMaterial());
    this.interceptedByRaycaster = false;
    this.name = "BlackPlane";

    Binding.createCustom(`blackPlane_${this.id}`, (m: BindedModel) => m.printState === PrintStateTypes.none, (value: boolean) => {
      this.material.color.setHex(value ? 0x000000 : 0xffffff);
    }, ['printState']);
  }

  public setGeometry(geometry: ShapeBufferGeometry) {
    this.geometry.dispose();
    this.geometry = geometry;
  }

  /**
   * Gets vertices of cropped plane applying a rotation (usually camera).
   */
  public getVerticesWithEuler(rotation: Euler): Vector3[] {
    if (this.geometry.getAttribute("position")) {
      const positions = this.geometry.getAttribute("position").array as number[];
      const ret: Vector3[] = [
        this.localToWorld(new Vector3(positions[0], positions[1])).setZ(0).applyEuler(rotation),
        this.localToWorld(new Vector3(positions[3], positions[4])).setZ(0).applyEuler(rotation),
        this.localToWorld(new Vector3(positions[6], positions[7])).setZ(0).applyEuler(rotation),
      ];
      positions.length > 9 && (ret.push(this.localToWorld(new Vector3(positions[9], positions[10])).setZ(0).applyEuler(rotation)));
      positions.length > 12 && (ret.push(this.localToWorld(new Vector3(positions[12], positions[13])).setZ(0).applyEuler(rotation)));
      return ret;
    }
    return [];
  }

  /**
   * Gets vertices of cropped plane.
   */
  public getVertices(): Vector3[] {
    if (this.geometry.getAttribute("position")) {
      const positions = this.geometry.getAttribute("position").array as number[];
      const ret: Vector3[] = [
        this.localToWorld(new Vector3(positions[0], positions[1])).setZ(0),
        this.localToWorld(new Vector3(positions[3], positions[4])).setZ(0),
        this.localToWorld(new Vector3(positions[6], positions[7])).setZ(0),
      ];
      positions.length > 9 && (ret.push(this.localToWorld(new Vector3(positions[9], positions[10])).setZ(0)));
      positions.length > 12 && (ret.push(this.localToWorld(new Vector3(positions[12], positions[13])).setZ(0)));
      return ret;
    }
    return [];
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorMessageBindingStrategy, pattern, range, ReactiveFormConfig, required, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { ViewerStateTypes } from '../../nextray/States/State';
import { LanguageService } from '../core';
import { CanvasService } from '../services';
import { BaseComponent } from '../shared';


/**
* Component for viewer tool.
*/
@Component({
	selector: 'viewer',
	templateUrl: './viewer.component.html'
})
export class ViewerComponent extends BaseComponent implements OnInit {

	diagonalForm: FormGroup;

	private _validationMsg: any = {};

	constructor(
		private langSrv: LanguageService,
		private canvaSrv: CanvasService,
		private formBuilder: RxFormBuilder
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.initForm();
	}

	private initForm(): void {
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.labels,
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched
		});
		this.diagonalForm = <RxFormGroup>this.formBuilder.formGroup(ViewerForm, { diagonal: null } as ViewerForm);
	}

	/**
	* Get diagonal form control.
	*/
	get diagonalCtrl() { return this.diagonalForm.get('diagonal'); }

	/**
	* Set proximal setting.
	*/
	setProximal(): void {
		this.canvaSrv.dispatch('setViewer', ViewerStateTypes.proximal);
		this.setRealisticZoom();
	}

	/**
	* Set osteotomy setting.
	*/
	setOsteotomy(): void {
		this.canvaSrv.dispatch('setViewer', ViewerStateTypes.osteotomy);
		this.setRealisticZoom();
	}

	/**
	* Set distal setting.
	*/
	setDistal(): void {
		this.canvaSrv.dispatch('setViewer', ViewerStateTypes.distal);
		this.setRealisticZoom();
	}

	/**
	* Fit to view.
	*/
	fitToView(): void {
		this.canvaSrv.dispatch('setViewer', ViewerStateTypes.fitToView);
	}

	/**
	* Set realistic zoom.
	*/
	setRealisticZoom(): void {
		if (!this.diagonalCtrl.invalid) {
			const diagonal: number = this.diagonalCtrl.value;
			this.canvaSrv.dispatch('setRealisticZoom', diagonal);
		}
	}

	/**
	* Close the viewer.
	*/
	closeViewer(): void {
		this.canvaSrv.dispatch('closeViewer');
		this.canvaSrv.saveState();
	}

}

/**
 * Viewer form model.
 */
class ViewerForm {
	@required({ messageKey: 'VALIDATION_MESSAGE_REQUIRED_NUMBER' })
	@pattern({ expression: { 'onlyOneDecimal': /^[0-9]+(\.[0-9]?)?$/ }, messageKey: 'VALIDATION_MESSAGE_PATTERN_NUMBER' })
	@range({ minimumNumber: 7, maximumNumber: 100, messageKey: 'VALIDATION_MESSAGE_RANGE_NUMBER' })
	diagonal: number;
}

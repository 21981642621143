import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@ortho-next/auth-core';
import { SseService } from '@ortho-next/sse';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { LanguageService, MonitoringService } from './core';
import { UserService } from './services';
import { BaseComponent, ConfirmationComponent } from './shared';


/**
* Root application component
*/
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent extends BaseComponent implements OnInit {
	constructor(
		private sse: SseService,
		private authService: AuthService,
		private langSrv: LanguageService,
		private usrSrv: UserService,
		private monitoringService: MonitoringService,
		private titleService: Title,
		private modalSrv: NgbModal
	) {
		super(langSrv);

		authService.isDoneLoading$.subscribe(() => {
			if (authService.hasValidToken()) {
				sse.connection(environment.sseAPI, () => firstValueFrom(authService.getAccessToken()));

				const userName = authService.userName;
				if (userName) {
					monitoringService.setAuthenticatedUserId(userName);
				}
			}
		});

		authService.onLogout$.subscribe(() => monitoringService.clearAuthenticatedUserId());
	}

	ngOnInit() {
		this.titleService.setTitle(this.labels.APP_MAIN_TITLE);

		this.usrSrv.hasFitboneGrant().subscribe(value => {
			if (!value) {
				const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, { centered: true, backdrop: 'static' });
				(modalRef.componentInstance as ConfirmationComponent).config = {
					title: this.labels.USER_UNAUTHORIZED_MODAL_TITLE,
					message: this.labels.USER_UNAUTHORIZED_MODAL_MESSAGE,
					confirmBtn: this.labels.USER_UNAUTHORIZED_MODAL_CONFIRM_BTN,
					isCancelBtn: false
				};
				modalRef.result.then(() => window.location.href = environment.patplanSite);
			}
		});
	}

}

import { BoneTypeEnum } from "@ortho-next/nextray-core";
import { LayersBase } from "@ortho-next/nextray-core/Core/Layers";
import { LayerElements } from "../Models/AppModel";
import { BindedModel, bindedModel } from "../Models/BindedModel";
import { Main } from "./Main";

/**
 * Binds layers logic and visibility
 */
export class Layers extends LayersBase {
  protected _main: Main;

  //override
  protected createBinding(name: string, getCallback: (model: any) => boolean, setCallback: (value: boolean) => void, propertiesBound: (keyof BindedModel)[]): void {
    super.createBinding(name, getCallback, setCallback, propertiesBound as any);
  }
  protected createBindingValue(name: string, bindedModelKey: keyof BindedModel, appModelKey: keyof LayerElements): void {
    super.createBindingValue(name, bindedModelKey as any, appModelKey as any);
  }

  constructor(main: Main) {
    super(main);
  }

  /**
   * Creates all bindings.
   */
  public createAllBinding(): void {
    super.createAllBinding();
    this.createContralateralBinding();
    this.createMechanicalAxisBinding();
    this._main.caseModel.boneType !== BoneTypeEnum.Tibia && this.createMechanicalAnatomicalAxisBinding();
    this._main.caseModel.boneType === BoneTypeEnum.LongLeg && this.createWeightbearingBinding();
    this.createFitboneBinding();
    this.createGridBinding();
    this.createPlaneBinding();
  }

  /**
   * Set on/off value to a specific layer.
   */
  public setLayersVisibility(key: keyof LayerElements, value: boolean): boolean {
    super.setLayersVisibility(key as any, value);
    switch (key) {
      case "mechanicalAxis_anatomical":
        return bindedModel.layer_mechAxis_anatomical = value;
      case "mechanicalAxis_anatomical":
        return bindedModel.layer_mechAxis_anatomical = value;
      case "mechanicalAxis_mechanical":
        return bindedModel.layer_mechAxis_mechanical = value;
      case "mechanicalAxis_weightBearing":
        return bindedModel.layer_mechAxis_weightBearing = value;
      case "contralateral_all":
        return bindedModel.layer_contralateral_all = value;
      case "fitbone":
        return bindedModel.layer_fitbone = value;
      case "grid":
        return bindedModel.layer_grid = value;
      case "grid_center":
        return bindedModel.layer_grid_center = value;
      case "plane":
        return bindedModel.layer_plane = value;
    }
  }

  private createContralateralBinding(): void {
    this.createBinding("layerContralateralAxis", (m: BindedModel) => m.isControlateralMechanicalAxisValid, (value) => {
      this._main.model.layers.contralateral_all = value;
    }, ['isControlateralMechanicalAxisValid']);

    this.createBindingValue("layerContralateralAxis", "layer_contralateral_all", "contralateral_all");
  }

  private createMechanicalAxisBinding(): void {
    this.createBinding("layerMechanicalAxis", (m: BindedModel) => true, (value) => { //todo fix
      this._main.model.layers.mechanicalAxis_mechanical = value;
    }, []);

    this.createBindingValue("layerMechanicalAxis", "layer_mechAxis_mechanical", "mechanicalAxis_mechanical");
  }

  private createMechanicalAnatomicalAxisBinding(): void {
    this.createBinding("layerMechanicalAnatomicalAxis", (m: BindedModel) => true, (value) => {
      this._main.model.layers.mechanicalAxis_anatomical = value;
    }, []);

    this.createBindingValue("layerMechanicalAnatomicalAxis", "layer_mechAxis_anatomical", "mechanicalAxis_anatomical");
  }

  private createWeightbearingBinding(): void {
    this.createBinding("layerWeightbearing", (m: BindedModel) => true, (value) => {
      this._main.model.layers.mechanicalAxis_weightBearing = value;
    }, []);

    this.createBindingValue("layerWeightbearing", "layer_mechAxis_weightBearing", "mechanicalAxis_weightBearing");
  }

  private createFitboneBinding(): void {
    this.createBinding("layerFitbone", (m: BindedModel) => m.isFitboneInserted, (value) => {
      this._main.model.layers.fitbone = value;
    }, ["isFitboneInserted"]);

    this.createBindingValue("layerFitbone", "layer_fitbone", "fitbone");
  }

  private createGridBinding(): void {
    this.createBinding("layerGrid", (m: BindedModel) => m.isGridInserted, (value) => {
      this._main.model.layers.grid = value;
    }, ["isGridInserted"]);
    this.createBindingValue("layerGrid", "layer_grid", "grid");

    this.createBinding("layerGridCenter", (m: BindedModel) => m.isGridInserted, (value) => {
      this._main.model.layers.grid_center = value;
    }, ["isGridInserted"]);
    this.createBindingValue("layerGridCenter", "layer_grid_center", "grid_center");
  }

  private createPlaneBinding(): void {
    this.createBinding("layerPlane", (m: BindedModel) => m.EOCCropVisible, (value) => {
      this._main.model.layers.plane = value;
      !value && (bindedModel.layer_plane = true);
    }, ["EOCCropVisible"]);

    this.createBindingValue("layerPlane", "layer_plane", "plane");
  }

}

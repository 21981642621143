<!-- EMPTY DRAWER -->
<div class="sidebar" *ngIf="false">

	<div class="spacing-top"></div>

	<span class="text-vertical"></span>

</div>
<div class="sidebar second" *ngIf="!isEocCut">

	<span class="text-vertical"></span>

</div>


<!-- DEFORMITY DRAWER -->
<div class="sidebar first-color" [ngClass]="{'open': isDeformityDrawerOpen }">

	<div class="spacing-top">
		<div class="open-sidebar" (click)="isDeformityDrawerOpen = !isDeformityDrawerOpen">
			<i class="material-icons">chevron_right</i>
		</div>
	</div>

	<span class="text-vertical clickable" (click)="isDeformityDrawerOpen = !isDeformityDrawerOpen">
		{{ labels.DRAWER_COMPONENT_TEXT_VERTICAL_DEFORMITY }}
	</span>

	<div class="wrapper-table-accordion">

		<h3>{{ labels.DRAWER_COMPONENT_TITLE_DEFORMITY }}</h3>

		<ma-ap-table *ngIf="hasAP"></ma-ap-table>

		<lld-table *ngIf="hasAP"></lld-table>

		<ma-lt-table *ngIf="hasLT"></ma-lt-table>

		<apex-table></apex-table>

	</div>
</div>



<!-- CORRECTION DRAWER -->
<div class="sidebar second second-color" [ngClass]="{'open': isCorrectionDrawerOpen }" *ngIf="isEocCut">

	<div class="spacing-top">
		<div class="open-sidebar" (click)="isCorrectionDrawerOpen = !isCorrectionDrawerOpen">
			<i class="material-icons">chevron_right</i>
		</div>
	</div>

	<span class="text-vertical clickable" (click)="isCorrectionDrawerOpen = !isCorrectionDrawerOpen">
		{{ labels.DRAWER_COMPONENT_TEXT_VERTICAL_CORRECTION }}
	</span>


	<div class="wrapper-table-accordion">

		<h3>{{ labels.DRAWER_COMPONENT_TITLE_CORRECTION }}</h3>

		<wedge-table></wedge-table>

		<ma-ap-recap></ma-ap-recap>

		<lld-recap></lld-recap>

		<ma-lt-recap></ma-lt-recap>

	</div>
</div>
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Fitbone, FitboneSearchFilters, ProductRepository } from '../core';


/**
* This service handles product data.
*/
@Injectable()
export class ProductService {

  constructor(private prodRepo: ProductRepository) { }

  /**
  * Get fitbone list according filters options in input.
   * @param {FitboneSearchFilters} filter Filters options
  */
  getFitboneList(filter: FitboneSearchFilters): Observable<Fitbone[]> {
    return this.prodRepo.getFitboneList(filter).pipe(
      map(res => res.result),
      map(res => res.sort((a, b) => a.description.localeCompare(b.description)))
    );
  }

}

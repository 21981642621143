import { Injectable } from '@angular/core';
import { BridgeResult, SelectedApexMech, ViewStateType, ViewType } from '@ortho-next/nextray-core';
import { Bridge, BridgeActions } from '../../nextray/Core/Bridge';
import { AppModel, LayerElements, MeasuresRPM, Messages } from '../../nextray/Models/AppModel';
import { AxialRotationEnum, DeformityAppModel, NextRayMeasuresDeformityAP, NextRayMeasuresDeformityLT } from '../../nextray/Models/DeformityAppModel';
import { StateDescription, StateTypes } from '../../nextray/States/State';
import { PrintMeasures, PrintResult } from '../../nextray/Utils/PrintUtils';
import { AnatomicalSideEnum, BoneTypeEnum, Case, Fitbone } from '../core';
import { CaseService } from './case.service';
import { MainCanvasLoaderService } from './main-canvas-loader.service';
import { ModelService } from './model.service';


/**
* This service handles the canvas data.
*/
@Injectable()
export class CanvasService {

  currentCase: Case;

  private _model: AppModel;
  private _bridge: Bridge;
  private _deformityModel: DeformityAppModel;

  private _hasAP: boolean;
  private _hasLT: boolean;

  private _isLongLeg: boolean;
  private _isFemur: boolean;
  private _isTibia: boolean;

  private _isLeft: boolean;
  private _isRight: boolean;

  private _isMobile: boolean;

  constructor(
    private loaderSrv: MainCanvasLoaderService,
    private caseSrv: CaseService,
    private modelSrv: ModelService
  ) {
    this.loaderSrv.canvas3D.subscribe(c => {
      this._model = c.model;
      this._bridge = c.bridge;
      this._deformityModel = c.deformityModel
    });
    this.caseSrv.getCurrentCase()
      .subscribe(c => {
        this.updateImgStatus(c.apImageGuid, c.ltImageGuid);
        this.updateBoneStatus(c.boneType);
        this.updateSideStatus(c.side);
        this.currentCase = c;
      });
    this.checkMobileDevice();
  }

  /**
  * Get mobile device flag.
  */
  get isMobile(): boolean {
    return this._isMobile;
  }

  /**
  * Check if current case has AP image.
  */
  get hasAP(): boolean {
    return this._hasAP;
  }

  /**
  * Check if current case has Lateral image.
  */
  get hasLT(): boolean {
    return this._hasLT;
  }

  /**
  * Check if current case has Long Leg bone type.
  */
  get isLongLeg(): boolean {
    return this._isLongLeg;
  }

  /**
  * Check if current case has Femur bone type.
  */
  get isFemur(): boolean {
    return this._isFemur;
  }

  /**
  * Check if current case has Tibia bone type.
  */
  get isTibia(): boolean {
    return this._isTibia;
  }

  /**
  * Check if current case has Left bone side.
  */
  get isLeft(): boolean {
    return this._isLeft;
  }

  /**
  * Check if current case has Right bone side.
  */
  get isRight(): boolean {
    return this._isRight;
  }

  /**
  * Get current state description.
  */
  get stateDescription(): StateDescription {
    return this._model?.stateDescription;
  }

  /**
  * Get current state type.
  */
  get stateType(): StateTypes {
    return this._model?.stateType;
  }

  /**
  * Get current view state, i.e AP, Lateral or Multiple.
  */
  get activeViewState(): ViewStateType {
    return this._model?.activeViewState;
  }

  /**
  * Get current active view, i.e AP or Lateral.
  */
  get activeView(): ViewType {
    return this._model?.activeView;
  }

  /**
  * Get images to create print report
  */
  get printResult(): PrintResult {
    return this._model?.printResult;
  }

  /**
  * Get current deformity measures of mechanical axis in AP View.
  */
  get defAPMeasures(): NextRayMeasuresDeformityAP {
    return this._deformityModel ? this._deformityModel?.measures : {};
  }

  /**
  * Get current deformity measures of mechanical axis in Lateral View.
  */
  get defLTMeasures(): NextRayMeasuresDeformityLT {
    return this._deformityModel ? this._deformityModel?.measures : {};
  }

  /**
  * Get current deformity measures of contralateral axis.
  */
  get defCLMeasures(): NextRayMeasuresDeformityAP {
    return this._deformityModel && this.isContralateralAxisInserted ? this._deformityModel?.measures : {};
  }

  /**
  * Check if contralateral axis is inserted.
  */
  get isContralateralAxisInserted(): boolean {
    return this._deformityModel?.isContralateralAxisInserted;
  }

  /**
  * Get scale factor of AP image.
  */
  get scaleFactorAP(): number {
    return this._deformityModel ? this._deformityModel?.scaleFactorAP : null;
  }
  /**
  * Get scale factor of Lateral image.
  */
  get scaleFactorLT(): number {
    return this._deformityModel ? this._deformityModel?.scaleFactorLT : null;
  }
  /**
  * Get current layers state.
  */
  get layers(): LayerElements {
    return this._model?.layers;
  }
  /**
  * Get current layers values.
  */
  get layersValue(): LayerElements {
    return this._model?.layersValue;
  }
  /**
  * Check if layers component is visible.
  */
  get layersVisible(): boolean {
    return this._model?.layersVisible;
  }
  /**
  * Get current RPM correction measures.
  */
  get rpmMeasures(): MeasuresRPM {
    return (this._model && this.isEocCut) ? this._model?.rpmMeasures : {};
  }
  /**
  * Get correction measures for print report.
  */
  get printMeasures(): PrintMeasures {
    return this._model ? this._model?.printMeasures : {};
  }
  /**
  * Get messages visibility.
  */
  get messages(): Messages {
    return this._model?.messages;
  }
  /**
  * Check if eoc cut is active.
  */
  get isEocCut(): boolean {
    return this._model?.eocCut;
  }
  /**
  * Get selected apex.
  */
  get selectedApex(): SelectedApexMech {
    return this._model?.selectedApex;
  }
  /**
   * Indicates if the fitbone is inserted.
   */
  public get isFitboneInserted(): boolean {
    return this._model?.isFitboneInserted;
  }
  /**
   * Get fitbone data.
   */
  public get fitbone(): Fitbone {
    return this._model?.fitbone;
  }
  /**
   * Indicates if stroke is locked.
   */
  public get isStrokeLocked(): boolean {
    return this._model?.isStrokeLocked;
  }
  /**
   * Indicates if the insertion point is inserted.
   */
  public get isInsPointInserted(): boolean {
    return this._model?.isInsPointInserted;
  }
  /**
  * Get axial roatation value of femur.
  */
  get def_femur_axialRotation(): number {
    return this._deformityModel ? this._deformityModel?.def_femur_axialRotation : null;
  }
  /**
  * Get axial roatation sign of femur.
  */
  get def_femur_axialRotationSign(): AxialRotationEnum {
    return this._deformityModel ? this._deformityModel?.def_femur_axialRotationSign : null;
  }
  /**
  * Get axial roatation value of tibia.
  */
  get def_tibia_axialRotation(): number {
    return this._deformityModel ? this._deformityModel?.def_tibia_axialRotation : null;
  }
  /**
  * Get axial roatation sign of tibia.
  */
  get def_tibia_axialRotationSign(): AxialRotationEnum {
    return this._deformityModel ? this._deformityModel?.def_tibia_axialRotationSign : null;
  }
  /**
  * Get standard angle of LPFA.
  */
  get def_standardAngles_LPFA(): number {
    return this._deformityModel ? this._deformityModel?.def_standardAngles_LPFA : null;
  }
  /**
  * Get standard angle of mLDFA.
  */
  get def_standardAngles_mLDFA(): number {
    return this._deformityModel ? this._deformityModel?.def_standardAngles_mLDFA : null;
  }
  /**
  * Get standard angle of MPTA.
  */
  get def_standardAngles_MPTA(): number {
    return this._deformityModel ? this._deformityModel?.def_standardAngles_MPTA : null;
  }
  /**
  * Get standard angle of LDTA.
  */
  get def_standardAngles_LDTA(): number {
    return this._deformityModel ? this._deformityModel?.def_standardAngles_LDTA : null;
  }
  /**
  * Get standard angle of PPTA.
  */
  get def_standardAngles_PPTA(): number {
    return this._deformityModel ? this._deformityModel?.def_standardAngles_PPTA : null;
  }
  /**
  * Get standard angle of ADTA.
  */
  get def_standardAngles_ADTA(): number {
    return this._deformityModel ? this._deformityModel?.def_standardAngles_ADTA : null;
  }

  /**
   * Get brightness of AP image.
   */
  public get brightnessAP(): number {
    return this._model?.brightnessAP;
  }

  /**
   * Get brightness of Lateral image.
   */
  public get brightnessLT(): number {
    return this._model?.brightnessLT;
  }

  /**
   * Get contrast of AP image.
   */
  public get contrastAP(): number {
    return this._model?.contrastAP;
  }

  /**
   * Get contrast of Lateral image.
   */
  public get contrastLT(): number {
    return this._model?.contrastLT;
  }

  /**
   * Gets bone type of Lateral view.
   */
  public get boneTypeLT(): BoneTypeEnum {
    return this._deformityModel?.boneTypeLT;
  }

  /**
   * Indicates if viewer is visible.
   */
  public get isViewerVisible(): boolean {
    return this._model?.isViewerVisible;
  }
  /**
   * Osteotomy level.
   */
  get osteotomyLevel(): number {
    return this._model?.osteotomyLevel;
  }
  /**
   * Target Leg length.
   */
  get targetLegLength(): number {
    return this._model?.targetLegLength;
  }
  /**
   * Mechanical Axis bone(femur/tibia) lengthening by insertion approach.
   */
  get maBoneLengthening(): string {
    return this._model?.maBoneLengthening;
  }
  /**
   * Indicates if blocking screw is selected in AP view.
   */
  public get isBlockingScrewSelectedAP(): boolean {
    return this._model?.isBlockingScrewSelectedAP;
  }

  /**
   * Indicates if blocking screw is selected in LT view.
   */
  public get isBlockingScrewSelectedLT(): boolean {
    return this._model?.isBlockingScrewSelectedLT;
  }

  /**
   * Indicates if number of blocking screws is maximum in AP view.
   */
  public get isBlockingScrewFullAP(): boolean {
    return this._model?.isBlockingScrewFullAP;
  }

  /**
   * Indicates if number of blocking screws is maximum in LT view.
   */
  public get isBlockingScrewFullLT(): boolean {
    return this._model?.isBlockingScrewFullLT;
  }

  /**
   * Indicates if measurement tool is selected in AP view.
   */
  public get isMeasurementToolSelectedAP(): boolean {
    return this._model?.isMeasurementToolSelectedAP;
  }

  /**
   * Indicates if measurement tool is selected in LT view.
   */
  public get isMeasurementToolSelectedLT(): boolean {
    return this._model?.isMeasurementToolSelectedLT;
  }

  /**
   * Indicates if app is downloading a fitbone in AP view.
   */
  public get isFitboneLoadingAP(): boolean {
    return this._model?.isFitboneLoadingAP;
  }

  /**
  * Indicates if app is downloading a fitbone in lateral view.
  */
  public get isFitboneLoadingLT(): boolean {
    return this._model?.isFitboneLoadingLT;
  }

  /**
  * Dispatch an event or action.
  */
  dispatch<K extends keyof BridgeActions>(type: K, args?: BridgeActions[K]): void {
    this._bridge?.mapEvent(type, args);
  }

  /**
  * Add event listener on bridge.
  */
  addEventListener<K extends keyof BridgeResult>(type: K, listener: (event: Event & { args: BridgeResult[K] }) => void): void {
    this._bridge?.addEventListener(type, listener);
  }

  /**
  * Check if has an event listener on bridge.
  */
  hasEventListener<K extends keyof BridgeResult>(type: K, listener: (event: Event & { args: BridgeResult[K] }) => void): boolean {
    return this._bridge?.hasEventListener(type, listener);
  }

  /**
  * Remove an event listener on bridge.
  */
  removeEventListener<K extends keyof BridgeResult>(type: K, listener: (event: Event & { args: BridgeResult[K] }) => void): void {
    this._bridge?.removeEventListener(type, listener);
  }

  /**
  * Dispatch an event listener on bridge.
  */
  dispatchEvent<K extends keyof BridgeResult>(event: { type: K } & { args: BridgeResult[K] }): void {
    this._bridge?.dispatchEvent(event);
  }

  /**
  * Save current state.
  */
  saveState(): void {
    this.modelSrv.persistModel(this.currentCase.id, this.currentCase.userGuid).subscribe({
      error: e => console.log(e)
    });
  }

  private updateBoneStatus(boneType: BoneTypeEnum): void {
    this._isLongLeg = boneType === BoneTypeEnum.LongLeg;
    this._isFemur = boneType === BoneTypeEnum.Femur;
    this._isTibia = boneType === BoneTypeEnum.Tibia;
  }

  private updateImgStatus(apImg: string, ltImg: string) {
    this._hasAP = !!apImg && apImg !== '';
    this._hasLT = !!ltImg && ltImg !== '';
  }

  private updateSideStatus(side: AnatomicalSideEnum) {
    this._isLeft = side === AnatomicalSideEnum.Left;
    this._isRight = side === AnatomicalSideEnum.Right;
  }

  private checkMobileDevice(): void {
    this._isMobile = navigator.userAgent && navigator.userAgent.includes('Mobile');
  }

}

export enum ApproachImageEnum {
  Left_AntegradeFemur_AP = 'assets/images/approach/fitbone_femore_antegrade_AP_left.png',
  Right_AntegradeFemur_AP = 'assets/images/approach/fitbone_femore_antegrade_AP_right.png',
  Left_AntegradeFemur_LT = 'assets/images/approach/fitbone_femore_antegrade_LAT_left.png',
  Right_AntegradeFemur_LT = 'assets/images/approach/fitbone_femore_antegrade_LAT_right.png',

  Left_RetrogradeFemur_AP = 'assets/images/approach/fitbone_femore_retrograde_AP_left.png',
  Right_RetrogradeFemur_AP = 'assets/images/approach/fitbone_femore_retrograde_AP_right.png',
  Left_RetrogradeFemur_LT = 'assets/images/approach/fitbone_femore_retrograde_LAT_left.png',
  Right_RetrogradeFemur_LT = 'assets/images/approach/fitbone_femore_retrograde_LAT_right.png',

  Left_AntegradeTibia_AP = 'assets/images/approach/fitbone_tibia_antegrade_AP_left.png',
  Right_AntegradeTibia_AP = 'assets/images/approach/fitbone_tibia_antegrade_AP_right.png',
  Left_AntegradeTibia_LT = 'assets/images/approach/fitbone_tibia_antegrade_LAT_left.png',
  Right_AntegradeTibia_LT = 'assets/images/approach/fitbone_tibia_antegrade_LAT_right.png'
}

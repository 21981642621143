import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from './loader.component';


/**
* This service handles the component that indicates the loading state of another component or page.
*/
@Injectable()
export class LoaderService {

  private _modalRef: NgbModalRef;

  constructor(private modalSrv: NgbModal) { }

  /**
  * Show the loading component.
  */
  show(): void {
    if (!this.modalSrv.hasOpenModals()) {
      this._modalRef = this.modalSrv.open(LoaderComponent, {
        centered: true, backdrop: 'static'
      });
    }
  }

  /**
  * Hide the loading component.
  */
  hide(): void {
    if (this._modalRef) {
      this._modalRef.close();
    }
  }

}

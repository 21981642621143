import { VectorUtils } from "@ortho-next/nextray-core";
import { BufferGeometry, CylinderBufferGeometry, Material, Mesh, MeshLambertMaterial, Object3D, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";

/**
 * Fitbone screw
 */
export class Screw extends Mesh {
  public material: MeshLambertMaterial;
  public direction: Vector3;
  public origin: Vector3;
  private _radius: number;
  private _minSize: number;
  private _maxSize: number;
  private _startLength: number;
  private _startLengthDrag: number;
  private _EPSStretch = 1;
  private _startDragPoint: Vector3;
  private _leftLength = 0;
  private _rightLength = 0;
  private _isLeftScaling = false;
  private _lengths: number[];

  constructor(child: Object3D, screwsLengths: number[]) {
    super(undefined, new MeshLambertMaterial({ color: 0x3f565a }));
    this.isDraggable = true;
    this.name = child.name;

    // array already sorted
    this._minSize = screwsLengths[0];
    this._maxSize = screwsLengths[screwsLengths.length - 1];
    this._startLength = this._minSize;
    this._lengths = screwsLengths;

    this.assignScrewData(child);
    this.geometry = new CylinderBufferGeometry(this._radius, this._radius, 1);
    //this.rotateY(Math.PI / 2);
    this.leftLength = this._startLength / 2;
    this.rightLength = this._startLength / 2;

    this.bindEvent("onDragMove", (args) => {
      args.preventDefault = true;
      args.position.copy(VectorUtils.projectOnVector(args.position, this.origin, this.origin.clone().sub(this.direction)));

      const translSign = VectorUtils.computeSign(args.position, this._startDragPoint, this.direction);
      const sideSign = this._isLeftScaling ? -1 : 1;
      const distance = VectorUtils.fixApproximalError(args.position.distanceTo(this._startDragPoint)) * translSign * sideSign;
      if (this._isLeftScaling) {
        this.leftLength = this.getLengthInRange(this._startLengthDrag + distance, this.rightLength)
      } else {
        this.rightLength = this.getLengthInRange(this._startLengthDrag + distance, this.leftLength);
      }
    });

    this.bindEvent("onMouseDown", (event) => {
      this._startDragPoint = VectorUtils.projectOnVector(this.position, this.origin, this.origin.clone().sub(this.direction));
      const point = this.parent.worldToLocal(event.point.setZ(0).clone());

      if (VectorUtils.computeSign(point, this.origin, this.direction) == 1) {
        this._isLeftScaling = false;
        this._startLengthDrag = this._rightLength;
      } else {
        this._isLeftScaling = true;
        this._startLengthDrag = this._leftLength;
      }

    });

    this.bindEvent("onMouseUp", () => {
      this._startDragPoint = undefined;
    });
  }

  public get length(): number {
    return this.scale.y;
  }

  public get leftLength(): number {
    return this._leftLength;
  }
  public set leftLength(value: number) {
    this._leftLength = value;
    this.position.copy(this.origin).add(this.direction.clone().setLength((this._rightLength - value) / 2));
    this.scale.y = value + this._rightLength;
  }

  public get rightLength(): number {
    return this._rightLength;
  }
  public set rightLength(value: number) {
    this._rightLength = value;
    this.position.copy(this.origin).add(this.direction.clone().setLength((value - this._leftLength) / 2));
    this.scale.y = value + this._leftLength;
  }

  private assignScrewData(child: Object3D): void {
    const geometry = (child.children[0] as Mesh).geometry as BufferGeometry;
    const position = geometry.attributes.position;
    const positionArray = position.array;
    const indexes = geometry.index;
    const indexesArray = indexes.array;
    const occ: { count: number; vertexIndex: number; arrayIndex: number }[] = [];

    for (let i = 0; i < position.count; i++) {
      occ[i] = { count: 0, vertexIndex: i, arrayIndex: -1 };
    }

    for (let i = 0; i < indexes.count; i += 3) {
      occ[indexesArray[i]].count++;
      occ[indexesArray[i]].arrayIndex = i;
    }

    occ.sort((a, b) => b.count - a.count);

    if (occ[0].vertexIndex > occ[1].vertexIndex) { //important to avoid problem with direction calculation
      const temp = occ[0];
      occ[0] = occ[1];
      occ[1] = temp;
    }

    const center1PosIndex = occ[0].vertexIndex * 3;
    const center1 = new Vector3(positionArray[center1PosIndex], positionArray[center1PosIndex + 1], positionArray[center1PosIndex + 2]);
    const center2PosIndex = occ[1].vertexIndex * 3;
    const center2 = new Vector3(positionArray[center2PosIndex], positionArray[center2PosIndex + 1], positionArray[center2PosIndex + 2]);

    const center1Index = occ[0].arrayIndex;
    const center1IndexModulo = center1Index % 3;
    const secondVertexPosIndex = indexesArray[center1IndexModulo === 0 ? center1Index + 1 : center1Index - center1IndexModulo] * 3;
    const secondVertex = new Vector3(positionArray[secondVertexPosIndex], positionArray[secondVertexPosIndex + 1], positionArray[secondVertexPosIndex + 2]);

    geometry.dispose();
    ((child.children[0] as Mesh).material as Material).dispose();

    this.origin = center1.clone().lerp(center2, 0.5).setX(0);
    this.direction = center2.clone().sub(center1);
    this._radius = center1.distanceTo(secondVertex);
  }

  private getLengthInRange(length: number, oppositeLength: number): number {
    if (length >= this._maxSize - oppositeLength) {
      return this._maxSize - oppositeLength;
    }
    if (length <= this._minSize / 2) {
      return this._minSize / 2;
    }
    for (let i = 1; i < this._lengths.length; i++) {
      if (length <= this._lengths[i] - oppositeLength) {
        return this._lengths[i] - oppositeLength;
      }
    }
  }

  public dispose(): void {
    super.dispose();
    this.material.dispose();
    this.geometry.dispose();
  }

}

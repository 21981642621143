<div class="spacing-top"></div>

<div class="main-step">
  <div class="box-menu-settings active">
    <div class="row-box">
      <h4>{{ labels.VIEWER_COMPONENT_TITLE }}</h4>
    </div>
    <div class="row-box">
      <div class="box-suggestion">
        <p>
          {{ labels.VIEWER_COMPONENT_MESSAGE }}
        </p>
      </div>
    </div>

    <div class="wrapper-btn-confirm mt-5">
      <button type="button" class="btn btn-secondary pl-2 pr-2" (click)="setProximal()">
        {{ labels.VIEWER_COMPONENT_OPTION_1 }}
      </button>
    </div>
    <div class="wrapper-btn-confirm">
      <button type="button" class="btn btn-secondary pl-2 pr-2" (click)="setOsteotomy()">
        {{ labels.VIEWER_COMPONENT_OPTION_2 }}
      </button>
    </div>
    <div class="wrapper-btn-confirm">
      <button type="button" class="btn btn-secondary pl-2 pr-2" (click)="setDistal()">
        {{ labels.VIEWER_COMPONENT_OPTION_3 }}
      </button>
    </div>
    <div class="wrapper-btn-confirm">
      <button type="button" class="btn btn-secondary pl-2 pr-2" (click)="fitToView()">
        {{ labels.VIEWER_COMPONENT_OPTION_4 }}
      </button>
    </div>
    <form [formGroup]="diagonalForm">
      <div class="row-box mt-5">
        <div class="form-group pb-0">
          <label for="">{{ labels.VIEWER_COMPONENT_OPTION_DIAGONAL }}</label>
          <div class="highlighted">
            <input type="number" class="form-control" placeholder="{{ labels.VIEWER_COMPONENT_OPTION_DIAGONAL }}" formControlName="diagonal"
                   [ngClass]="{'is-valid': diagonalCtrl.valid && diagonalCtrl.touched,  'is-invalid': diagonalCtrl.invalid && diagonalCtrl.touched }">
            <div class="invalid-feedback" *ngIf="diagonalCtrl.invalid && diagonalCtrl.touched">{{ diagonalCtrl['errorMessage'] }}</div>
          </div>
        </div>
      </div>
      <div class="wrapper-btn-confirm">
        <button type="button" class="btn btn-secondary pl-2 pr-2" [disabled]="diagonalCtrl.invalid" (click)="setRealisticZoom()">
          {{ labels.VIEWER_COMPONENT_OPTION_5 }}
        </button>
      </div>
    </form>

    <div class="mt-auto">
      <div class="divider-box"></div>
      <div class="wrapper-btn-confirm">
        <button type="button" class="btn btn-primary pl-2 pr-2" (click)="closeViewer()">
          {{ labels.VIEWER_COMPONENT_EXIT_BTN }}
        </button>
      </div>
    </div>

  </div>
</div>

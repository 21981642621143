import { DraggablePoint, Save, SelectableTool, SelectedApexMech, ToolToSave, ToolType, VectorUtils, ViewType } from "@ortho-next/nextray-core";
import { MechanicalAxisFemurLT } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/FemurAnalyzerLT";
import { LinePointToPoint2 } from "@ortho-next/nextray-core/Tools/Primitive/LinePointToPoint2";
import { Default } from "@ortho-next/nextray-core/Utils/Default";
import { Group, Matrix4, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { BindedModel } from '../Models/BindedModel';
import { PrintStateTypes } from '../States/State';
import { Consts } from '../Utils/Consts';
import { LimitUtils } from '../Utils/LimitUtils';

export class DiaphysisAnatomicalAxis extends Group implements SelectableTool, ToolToSave {
  public isSelectable = true;
  public isDeletable = false;
  public isSelected: boolean;
  @Save("position") public A: DraggablePoint;
  @Save("position") public A1: DraggablePoint;
  @Save("position") public A2: DraggablePoint;
  @Save("position") public Ac: DraggablePoint;
  @Save("position") public B: DraggablePoint;
  @Save("position") public B1: DraggablePoint;
  @Save("position") public B2: DraggablePoint;
  @Save("position") public Bc: DraggablePoint;
  @Save("position") public center: DraggablePoint;
  public toolType = ToolType.diaphysisAnatomicalAxis;
  public A_B_Line: LinePointToPoint2;
  public A1_A2_Line: LinePointToPoint2;
  public B1_B2_Line: LinePointToPoint2;
  @Save() public AB_dirOrigin: Vector3;
  private _mechanicalAxisFemur: MechanicalAxisFemurLT;
  private distance_A_B = 80;
  private distance_A_Ac = 10;
  private distance_A1_A2 = 30;
  private minDistance_A_Ac = 10;

  public onSelect(): void {
    Default.colorAllDraggablePoints(this);
  }

  public onDeselect(): void {
    Default.restoreColorAllDraggablePoints(this);
  }

  constructor(mechanicalAxisFemurLT: MechanicalAxisFemurLT) {
    super();
    this.name = 'DiaphysisAnatomicalAxis';
    this._mechanicalAxisFemur = mechanicalAxisFemurLT;

    this.add(
      this.A = new DraggablePoint('A', ViewType.LT, 0xED7D31),
      this.Ac = new DraggablePoint('Ac', ViewType.LT, 0xED7D31),
      this.A1 = new DraggablePoint('A1', ViewType.LT, 0xED7D31),
      this.A2 = new DraggablePoint('A2', ViewType.LT, 0xED7D31),
      this.B = new DraggablePoint('B', ViewType.LT, 0xED7D31),
      this.Bc = new DraggablePoint('Bc', ViewType.LT, 0xED7D31),
      this.B1 = new DraggablePoint('B1', ViewType.LT, 0xED7D31),
      this.B2 = new DraggablePoint('B2', ViewType.LT, 0xED7D31),
      this.center = new DraggablePoint('center', ViewType.LT, 0xED7D31),
      this.A_B_Line = new LinePointToPoint2('A_B_Line', ViewType.LT, 0xED7D31, this.A, this.B),
      this.A1_A2_Line = new LinePointToPoint2('A1_A2_Line', ViewType.LT, 0xED7D31, this.A1, this.A2),
      this.B1_B2_Line = new LinePointToPoint2('B1_B2_Line', ViewType.LT, 0xED7D31, this.B1, this.B2),
    );

    this.bindEvents();
    this.initPosition();

    this.bindProperty("visible", (m: BindedModel) => {
      return m.isDiaphysisAAInserted && m.layer_plane && m.printState !== PrintStateTypes.deformityAnalysis;
    }, ["isDiaphysisAAInserted", "layer_plane", 'printState']);

    this.bindProperty("isEnabled", (m: BindedModel) => {
      return m.isDiaphysisAAEnabled;
    }, ["isDiaphysisAAEnabled"]);

  }

  /**
   * Sets default position.
   */
  public initPosition(): void {
    const startPosition = this._mechanicalAxisFemur.FH.position.clone().lerp(this._mechanicalAxisFemur.TE.position, 0.5);
    const dir = this._mechanicalAxisFemur.FH.position.clone().sub(this._mechanicalAxisFemur.TE.position).setLength(this.distance_A_B / 2);
    const perpDir = VectorUtils.getPerpendicular(dir).setLength(this.distance_A1_A2 / 2);
    this.center.position.copy(startPosition);
    this.A.position.copy(startPosition).add(dir);
    this.B.position.copy(startPosition).sub(dir);
    this.Ac.position.copy(startPosition).add(dir.setLength(this.distance_A_B / 2 - this.distance_A_Ac));
    this.Bc.position.copy(startPosition).sub(dir);
    this.A1.position.copy(this.Ac.position).add(perpDir);
    this.A2.position.copy(this.Ac.position).sub(perpDir);
    this.B1.position.copy(this.Bc.position).add(perpDir);
    this.B2.position.copy(this.Bc.position).sub(perpDir);
    this.AB_dirOrigin = this.A.position.clone().sub(this.B.position);
  }

  private bindEvents(): void {

    this.bindEvent("onEnabledChange", (value) => {
      this.interceptedByRaycaster = value;
    });

    this.center.bindEvent('onDragMove', (args) => {
      args.preventDefault = true;
      const translation = args.position.clone().sub(this.center.position);
      this.translatePoints(translation);
    });

    this.A1.bindEvent('onDragMove', (args) => {
      // does not allow to A1 to go under B1 B2 line
      LimitUtils.applyLimitAndProjectVector(this.B1.position, this.B2.position, args.position, this.A.position);
      LimitUtils.applyLimitAndProjectVector(this.A2.position, this.B2.position, args.position, this.B1.position);
      LimitUtils.applyLimitAndProjectVector(this.A2.position, this.B1.position, args.position, this.A.position);

      // does not allow to Ac to swith with Bc (does not allow to A B line to exceed 90 degrees)
      const newAc = this.A2.position.clone().lerp(args.position, 0.5);
      this.preventReverse(args.position, newAc, this.Bc.position, this.Ac.position.clone().add(this.AB_dirOrigin));

      // update other points
      this.moveExternalPointA(args.position, this.A2);
    });

    this.A2.bindEvent('onDragMove', (args) => {
      // does not allow to A2 to go under B1 B2 line
      LimitUtils.applyLimitAndProjectVector(this.B1.position, this.B2.position, args.position, this.A.position);
      LimitUtils.applyLimitAndProjectVector(this.A1.position, this.B1.position, args.position, this.B2.position);
      LimitUtils.applyLimitAndProjectVector(this.A1.position, this.B2.position, args.position, this.A.position);

      // does not allow to Ac to swith with Bc (does not allow to A B line to exceed 90 degrees)
      const newAc = this.A1.position.clone().lerp(args.position, 0.5);
      this.preventReverse(args.position, newAc, this.Bc.position, this.Ac.position.clone().add(this.AB_dirOrigin));

      // update other points
      this.moveExternalPointA(args.position, this.A1);
    });

    this.B1.bindEvent('onDragMove', (args) => {
      // does not allow to B1 to go under A1 A2 line
      LimitUtils.applyLimitAndProjectVector(this.A1.position, this.A2.position, args.position, this.B.position);
      LimitUtils.applyLimitAndProjectVector(this.B2.position, this.A2.position, args.position, this.A1.position);
      LimitUtils.applyLimitAndProjectVector(this.B2.position, this.A1.position, args.position, this.B.position);

      // does not allow to Bc to swith with Ac (does not allow to A B line to exceed 90 degrees)
      let newBc = this.B2.position.clone().lerp(args.position, 0.5);
      this.preventReverse(args.position, newBc, this.Ac.position, this.Bc.position.clone().sub(this.AB_dirOrigin));

      // update other points
      this.moveExternalPointB(args.position, this.B2);
    });

    this.B2.bindEvent('onDragMove', (args) => {
      // does not allow to B2 to go under A1 A2 line
      LimitUtils.applyLimitAndProjectVector(this.A1.position, this.A2.position, args.position, this.B.position);
      LimitUtils.applyLimitAndProjectVector(this.B1.position, this.A1.position, args.position, this.A2.position);
      LimitUtils.applyLimitAndProjectVector(this.B1.position, this.A2.position, args.position, this.B.position);

      // does not allow to Bc to swith with Ac (does not allow to A B line to exceed 90 degrees)
      const newBc = this.B1.position.clone().lerp(args.position, 0.5);
      this.preventReverse(args.position, newBc, this.Ac.position, this.Bc.position.clone().sub(this.AB_dirOrigin));

      // update other points
      this.moveExternalPointB(args.position, this.B1);
    });

    this.Ac.bindEvent('onDragMove', (args) => {
      // project mouse on Ac Bc line
      args.position.copy(VectorUtils.projectOnVector(args.position, this.Ac.position, this.Bc.position));

      // does not allow to A1 A2 line to go under B1 B2 line
      LimitUtils.applyLimitAndSetVectorCenterLinePoint(this.B1.position, this.B2.position, this.Ac.position, args.position, this.A1.position, this.A2.position, this.A.position);

      // does not allow to A1 A2 line to go under B1 B2 rotated line
      const dir = this.A1.position.clone().sub(this.A2.position);
      LimitUtils.applyLimitAndSetVectorCenterLinePoint(this.B1.position, this.B1.position.clone().add(dir), this.Ac.position, args.position, this.A1.position, this.A2.position, this.A.position);
      LimitUtils.applyLimitAndSetVectorCenterLinePoint(this.B2.position, this.B2.position.clone().add(dir), this.Ac.position, args.position, this.A1.position, this.A2.position, this.A.position);

      // update A when Ac move over A to take minimum distance between A and Ac
      const translation = args.position.clone().sub(this.Ac.position);
      this.A1.position.add(translation);
      this.A2.position.add(translation);
      const sign = VectorUtils.computeSign(args.position, this.A.position, this.Ac.position.clone().sub(this.A.position));
      const distance_A_Ac = args.position.distanceTo(this.A.position) * sign;
      if (distance_A_Ac < this.minDistance_A_Ac) {
        const dir = args.position.clone().sub(this.Bc.position);
        this.A.position.copy(args.position.clone().add(dir.setLength(this.minDistance_A_Ac)));
      }

      // update center point
      this.center.position.lerpVectors(args.position, this.Bc.position, 0.5);
    });

    this.Bc.bindEvent('onDragMove', (args) => {
      // project mouse on Ac Bc line
      args.position.copy(VectorUtils.projectOnVector(args.position, this.Ac.position, this.Bc.position));

      // does not allow to B1 B2 line to go under A1 A2 line
      LimitUtils.applyLimitAndSetVectorCenterLinePoint(this.A1.position, this.A2.position, this.Bc.position, args.position, this.B1.position, this.B2.position, this.B.position);

      // does not allow to B1 B2 line to go under A1 A2 rotated line
      const dir = this.B1.position.clone().sub(this.B2.position);
      LimitUtils.applyLimitAndSetVectorCenterLinePoint(this.A1.position, this.A1.position.clone().add(dir), this.Bc.position, args.position, this.B1.position, this.B2.position, this.B.position);
      LimitUtils.applyLimitAndSetVectorCenterLinePoint(this.A2.position, this.A2.position.clone().add(dir), this.Bc.position, args.position, this.B1.position, this.B2.position, this.B.position);
      //LimitUtils.applyLimitAndSetVector(this.oppositeBc, this.oppositeBc.clone().add(Consts.horDir), args.position, this.Bc.position, this.Ac.position);

      // update B when Bc move over B to take minimum distance between B and Bc
      const translation = args.position.clone().sub(this.Bc.position);
      this.B1.position.add(translation);
      this.B2.position.add(translation);
      const sign = VectorUtils.computeSign(args.position, this.B.position, this.Bc.position.clone().sub(this.B.position));
      const distance_B_Bc = args.position.distanceTo(this.B.position) * sign;
      if (distance_B_Bc < this.minDistance_A_Ac) {
        const dir = args.position.clone().sub(this.Ac.position);
        this.B.position.copy(args.position.clone().add(dir.setLength(this.minDistance_A_Ac)));
      }

      // update center point
      this.center.position.lerpVectors(args.position, this.Ac.position, 0.5);
    });

    this.A.bindEvent('onDragMove', (args) => {
      // does not allow to A B line to exceed 90 degrees
      this.rotationPointsByA(args.position);

      // prevent A to go under Ac plus a minimum distance
      const sign = VectorUtils.computeSign(args.position, this.Ac.position, this.Bc.position.clone().sub(this.B.position));
      const distance_A_Ac = args.position.distanceTo(this.Ac.position) * sign;
      if (distance_A_Ac < this.minDistance_A_Ac) {
        const dir = this.Ac.position.clone().sub(this.Bc.position);
        args.position.copy(this.Ac.position.clone().add(dir.setLength(this.minDistance_A_Ac)));
      }
    });

    this.B.bindEvent('onDragMove', (args) => {
      // does not allow to A B line to exceed 90 degrees
      const maxAngle = Math.PI / 2;
      const newAngle = VectorUtils.linesAngle(this.A.position.clone().sub(this.AB_dirOrigin), args.position, this.A.position);
      const newAngleLimited = Math.min(Math.abs(newAngle), maxAngle) * Math.sign(newAngle);
      const actualAngle = VectorUtils.linesAngle(this.A.position.clone().sub(this.AB_dirOrigin), this.B.position, this.A.position);
      this.rotationPoints(newAngleLimited - actualAngle, newAngle - actualAngle, this.A.position, args.position, this.B.position);

      // prevent B to go under Bc plus a minimum distance
      const sign = VectorUtils.computeSign(args.position, this.Bc.position, this.Ac.position.clone().sub(this.A.position));
      const distance_A_Ac = args.position.distanceTo(this.Bc.position) * sign;
      if (distance_A_Ac < this.minDistance_A_Ac) {
        const dir = this.Bc.position.clone().sub(this.Ac.position);
        args.position.copy(this.Bc.position.clone().add(dir.setLength(this.minDistance_A_Ac)));
      }
    });
  }

  private preventReverse(position: Vector3, newC1: Vector3, C2: Vector3, validPoint: Vector3): void {
    const limitedC1 = LimitUtils.applyLimitAndProjectVector(C2, C2.clone().add(Consts.horDir), newC1.clone(), validPoint);
    position.add(limitedC1.sub(newC1).multiplyScalar(2));
  }

  private moveExternalPointA(position: Vector3, oppositePoint: DraggablePoint): void {
    const distance_A_Ac = this.Ac.position.distanceTo(this.A.position);
    const distance_B_Bc = this.Bc.position.distanceTo(this.B.position);
    this.Ac.position.lerpVectors(position, oppositePoint.position, 0.5);
    const dir = this.Ac.position.clone().sub(this.Bc.position);
    this.A.position.copy(this.Ac.position.clone().add(dir.setLength(distance_A_Ac)));
    this.B.position.copy(this.Bc.position.clone().sub(dir.setLength(distance_B_Bc)));
    this.center.position.lerpVectors(this.Ac.position, this.Bc.position, 0.5);
  }

  private moveExternalPointB(position: Vector3, oppositePoint: DraggablePoint): void {
    const distance_A_Ac = this.Ac.position.distanceTo(this.A.position);
    const distance_B_Bc = this.Bc.position.distanceTo(this.B.position);
    this.Bc.position.lerpVectors(position, oppositePoint.position, 0.5);
    const dir = this.Bc.position.clone().sub(this.Ac.position);
    this.B.position.copy(this.Bc.position.clone().add(dir.setLength(distance_B_Bc)));
    this.A.position.copy(this.Ac.position.clone().sub(dir.setLength(distance_A_Ac)));
    this.center.position.lerpVectors(this.Ac.position, this.Bc.position, 0.5);
  }

  private rotationPoints(angle: number, oldAngleForFix: number, rotationCenter: Vector3, position: Vector3, rotatingPoint: Vector3): void {
    const matrix = new Matrix4();
    matrix.makeRotationZ(angle);

    this.A1.position.sub(rotationCenter).applyMatrix4(matrix).add(rotationCenter);
    this.A2.position.sub(rotationCenter).applyMatrix4(matrix).add(rotationCenter);
    this.B1.position.sub(rotationCenter).applyMatrix4(matrix).add(rotationCenter);
    this.B2.position.sub(rotationCenter).applyMatrix4(matrix).add(rotationCenter);

    this.Ac.position.lerpVectors(this.A1.position, this.A2.position, 0.5);
    this.Bc.position.lerpVectors(this.B1.position, this.B2.position, 0.5);
    this.center.position.lerpVectors(this.Ac.position, this.Bc.position, 0.5);

    matrix.makeRotationZ(angle - oldAngleForFix);
    position.sub(rotationCenter).applyMatrix4(matrix).add(rotationCenter);
  }

  private rotationPointsByA(position: Vector3): void {
    const maxAngle = Math.PI / 2;
    const newAngle = VectorUtils.linesAngle(this.B.position.clone().add(this.AB_dirOrigin), position, this.B.position);
    const newAngleLimited = Math.min(Math.abs(newAngle), maxAngle) * Math.sign(newAngle);
    const actualAngle = VectorUtils.linesAngle(this.B.position.clone().add(this.AB_dirOrigin), this.A.position, this.B.position);
    this.rotationPoints(newAngleLimited - actualAngle, newAngle - actualAngle, this.B.position, position, this.A.position);

  }

  /**
   * Translates all points.
   */
  public translatePoints(translation: Vector3): void {
    this.A.position.add(translation);
    this.B.position.add(translation);
    this.Ac.position.add(translation);
    this.Bc.position.add(translation);
    this.A1.position.add(translation);
    this.A2.position.add(translation);
    this.B1.position.add(translation);
    this.B2.position.add(translation);
    this.center.position.add(translation);
  }

  /**
   * Gets the angle to rotate the camera.
   */
  public getAngleForApexConfirmed(selectedApex: SelectedApexMech): number {
    if (selectedApex === SelectedApexMech.femurDistal || selectedApex === SelectedApexMech.femurProximal) {
      return VectorUtils.linesAngleFromOrigin(this.A.position.clone().sub(this.B.position), Consts.verDir);
    }
    return 0;
  }

  public get direction(): Vector3 {
    return this.B.position.clone().sub(this.A.position);
  }

}

import { Component } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Table of apex measures.
*/
@Component({
	selector: 'apex-table',
	templateUrl: './apex-table.component.html',
	styles: []
})
export class ApexTableComponent extends BaseComponent {

	isCollapsed: boolean = true;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	// APEX TABLE

	/**
	* Get apex measure of femur in AP image.
	*/
	get apFemurMeasure(): string {
		return this.defAPMeasures.def_ap_apexFemur || this.defAPMeasures.def_ap_apexFemur === 0 ? `${this.defAPMeasures.def_ap_apexFemur} ${this.apexSignLabel(this.defAPMeasures.def_ap_apexFemurSign)}` : '';
	}

	/**
	* Get apex measure of tibia in AP image.
	*/
	get apTibiaMeasure(): string {
		return this.defAPMeasures.def_ap_apexTibia || this.defAPMeasures.def_ap_apexTibia === 0 ? `${this.defAPMeasures.def_ap_apexTibia} ${this.apexSignLabel(this.defAPMeasures.def_ap_apexTibiaSign)}` : '';
	}

	/**
	* Get apex measure of tibia in Lateral image.
	*/
	get ltTibiaMeasure(): string {
		return this.defLTMeasures.def_lt_apexTibia || this.defLTMeasures.def_lt_apexTibia === 0 ? `${this.defLTMeasures.def_lt_apexTibia} ${this.apexSignLabel(this.defLTMeasures.def_lt_apexTibiaSign)}` : '';
	}

}

import { DraggableLabel, Save, SaveChild, ViewType } from "@ortho-next/nextray-core";
import { BufferGeometry, Matrix4, Mesh, MeshPhongMaterial, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { FitboneMesh } from "src/nextray/Models/AppModel";
import { BindedModel } from "../../Models/BindedModel";
import { Consts } from "../../Utils/Consts";
import { FormatUtils } from "../../Utils/FormatUtils";
import { FitBoneInternal } from "./FitBoneInternal";
import { ScrewManager } from "./ScrewManager";

export class FitBoneExternal extends Mesh {
  public geometry: BufferGeometry; // override
  public material: MeshPhongMaterial; // override
  @SaveChild("position") public internal: FitBoneInternal;
  public fullLength = 245 - 14.5;
  public screws: ScrewManager[] = [];
  @SaveChild() public stretchLabel: DraggableLabel;
  public objMatrix = new Matrix4().makeRotationY(Math.PI / -2).multiply(new Matrix4().makeRotationX(Math.PI / 2));
  private _defaultRotation: Matrix4;
  @Save() public lengthsToRestore: number[] = [];
  @Save() public anchorsToRestore: Vector3[] = [];

  constructor(viewType: ViewType, sightMatrix: Matrix4, sideMatrix: Matrix4) {
    super(Consts.emptyGeometry, Consts.toolMaterial);
    this.name = "FitBoneExternal";

    this.updateDefaultRotation(sightMatrix, sideMatrix);

    this.add(
      this.internal = new FitBoneInternal(),
      this.stretchLabel = new DraggableLabel("stretchLabel", viewType),
    );

    this.bindProperty("isDraggable", (m: BindedModel) => !m.isStrokeLocked, ["isStrokeLocked"]);

    this.internal.bindEvent("onPositionComponentChange", (args) => {
      this.stretchLabel.setParam("text", FormatUtils.roundedNumber(this.internal.lengthening).toString());
    });
  }

  /**
   * Creates and add screws to fitbone.
   */
  public addScrews(screws: ScrewManager[]): void {
    this.add(...(this.screws = screws));
  }

  public deleteGeometry(): void {
    this.geometry.dispose();
    this.geometry = Consts.emptyGeometry;
    for (const screw of this.screws) {
      screw.dispose();
    }
  }

  /**
   * Sets internal data to create geometry and screws.
   */
  public setGeometryAndScrews(geometry: BufferGeometry, fitboneMesh: FitboneMesh) {
    this.geometry = geometry;
    this.fullLength = fitboneMesh.initialLength - fitboneMesh.minL;
  }

  private updateDefaultRotation(sightMatrix: Matrix4, sideMatrix: Matrix4): void {
    this.applyMatrix4(this.objMatrix);
    sightMatrix && this.applyMatrix4(sightMatrix);
    sideMatrix && this.applyMatrix4(sideMatrix);
    this._defaultRotation = this.matrix.clone();
  }

  /**
   * Sets a specific rotation.
   */
  public updateRotation(siteMatrix: Matrix4): void {
    this.position.set(0, 0, 0);
    this.setRotationFromMatrix(this._defaultRotation);
    siteMatrix && this.applyMatrix4(siteMatrix);
    this.updateMatrixWorld(true);
  }

}

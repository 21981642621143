import { Routes } from '@angular/router';
import { AuthGuardWithForcedLogin } from '@ortho-next/auth-core';
import { ErrorsComponent, FallbackComponent } from './core';
import { ContainerTemplateComponent } from './template';

export const APP_ROUTES: Routes = [
  { path: 'error', component: ErrorsComponent },
  {
    path: '', component: ContainerTemplateComponent
    , canActivate: [AuthGuardWithForcedLogin]
  },
  { path: '**', component: FallbackComponent, canActivate: [AuthGuardWithForcedLogin] },
];

import { Component, Input, OnInit } from '@angular/core';
import { CanvasService, UserService } from '../../../../app/services';
import { Consts } from '../../../../nextray/Utils/Consts';
import { Case, LanguageService } from '../../../core';
import { BaseComponent } from '../../../shared';


/**
* Second page of PDF print.
*/
@Component({
	selector: 'prt-measures-page',
	templateUrl: './prt-measures-page.component.html'
})
export class PrintMeasuresPageComponent extends BaseComponent implements OnInit {

	private _isDemo: boolean;
	private apAngles = { ...Consts.paleyAngles.AP };
	private ltAngles = { ...Consts.paleyAngles.LT };

	@Input() today: number;
	/**
	 * Current loaded case.
	 */
	currentCase: Case;
	/**
	 * Doctor full name who generated the report.
	 */
	drFullName: string;

	/**
	 * Watermark Demo
	 */
	get isDemo(): boolean {
		return this._isDemo;
	}
	/**
	* Get Limb Lenght Discrepancy measure of long leg.
	*/
	get longlegLLD(): string {
		return this.calculateLLD(this.defAPMeasures.def_ap_fullLength, this.defCLMeasures.def_ap_fullLength_ctrlateral);
	}
	/**
	* Get Limb Lenght Discrepancy measure of femur.
	*/
	get femurLLD(): string {
		return this.calculateLLD(this.defAPMeasures.def_ap_femurLength, this.defCLMeasures.def_ap_femurLength_ctrlateral);
	}
	/**
	* Get Limb Lenght Discrepancy measure of tibia.
	*/
	get tibiaLLD(): string {
		return this.calculateLLD(this.defAPMeasures.def_ap_tibiaLength, this.defCLMeasures.def_ap_tibiaLength_ctrlateral);
	}
	/**
	* Get True Lenght measure.
	*/
	get trueLength(): number {
		return this.defAPMeasures.def_ap_femurLength && this.defAPMeasures.def_ap_tibiaLength ? this.defAPMeasures.def_ap_femurLength + this.defAPMeasures.def_ap_tibiaLength : null;
	}
	/**
	* Get True Lenght measure of Contralateral.
	*/
	get trueLengthCL(): number {
		return this.defCLMeasures.def_ap_femurLength_ctrlateral && this.defCLMeasures.def_ap_tibiaLength_ctrlateral ? this.defCLMeasures.def_ap_femurLength_ctrlateral + this.defCLMeasures.def_ap_tibiaLength_ctrlateral : null;
	}
	/**
	* Get True Lenght measure of Limb Lenght Discrepancy.
	*/
	get trueLengthLLD(): string {
		return this.calculateLLD(this.trueLength, this.trueLengthCL);
	}
	/**
	* Get Limb Lenght Discrepancy measure of long leg.
	*/
	get rpmLonglegLLD(): string {
		return this.calculateLLD(this.rpmMeasures.rpm_ap_fullLength, this.defCLMeasures.def_ap_fullLength_ctrlateral);
	}
	/**
	* Get Limb Lenght Discrepancy measure of femur.
	*/
	get rpmFemurLLD(): string {
		return this.calculateLLD(this.rpmMeasures.rpm_ap_femurLength, this.defCLMeasures.def_ap_femurLength_ctrlateral);
	}
	/**
	* Get Limb Lenght Discrepancy measure of tibia.
	*/
	get rpmTibiaLLD(): string {
		return this.calculateLLD(this.rpmMeasures.rpm_ap_tibiaLength, this.defCLMeasures.def_ap_tibiaLength_ctrlateral);
	}
	/**
	* Get True Lenght measure.
	*/
	get rpmTrueLength(): number {
		return this.rpmMeasures.rpm_ap_femurLength && this.rpmMeasures.rpm_ap_tibiaLength ? this.rpmMeasures.rpm_ap_femurLength + this.rpmMeasures.rpm_ap_tibiaLength : null;
	}
	/**
	* Get True Lenght measure of Limb Lenght Discrepancy.
	*/
	get rpmTrueLengthLLD(): string {
		return this.calculateLLD(this.rpmTrueLength, this.rpmTrueLengthCL);
	}
	/**
	* Get True Lenght measure of Contralateral.
	*/
	get rpmTrueLengthCL(): number {
		return this.defCLMeasures.def_ap_femurLength_ctrlateral && this.defCLMeasures.def_ap_tibiaLength_ctrlateral ? this.defCLMeasures.def_ap_femurLength_ctrlateral + this.defCLMeasures.def_ap_tibiaLength_ctrlateral : null;
	}

	/**
	* Get wedge angle in AP view.
	*/
	get ap_wedgeAngle(): string {
		return this.printMeasures.rpm_ap_angle || this.printMeasures.rpm_ap_angle === 0 ? this.printMeasures.rpm_ap_angle.toString() : '';
	}

	/**
	* Get wedge angle in LAT view.
	*/
	get lt_wedgeAngle(): string {
		return this.printMeasures.rpm_lt_angle || this.printMeasures.rpm_lt_angle === 0 ? this.printMeasures.rpm_lt_angle.toString() : '';
	}

	/**
	* Get cortex length in AP view.
	*/
	get ap_cortexLength(): string {
		const value = this.printMeasures.rpm_ap_cortexLength;
		return value || value === 0 ? `${Math.abs(value)} ${value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_LENGTHENING'] : this.labels['WEDGE_TABLE_COMPONENT_SHORTENING']}` : '';
	}

	/**
	* Get cortex length in LAT view.
	*/
	get lt_cortexLength(): string {
		const value = this.printMeasures.rpm_lt_cortexLength;
		return value || value === 0 ? `${Math.abs(value)} ${value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_LENGTHENING'] : this.labels['WEDGE_TABLE_COMPONENT_SHORTENING']}` : '';
	}

	/**
	* Get axial length in AP view.
	*/
	get ap_axialLength(): string {
		const value = this.printMeasures.rpm_ap_axialLength;
		return value || value === 0 ? `${Math.abs(value)} ${value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_LENGTHENING'] : this.labels['WEDGE_TABLE_COMPONENT_SHORTENING']}` : '';
	}

	/**
	* Get axial length in LAT view.
	*/
	get lt_axialLength(): string {
		const value = this.printMeasures.rpm_lt_axialLength;
		return value || value === 0 ? `${Math.abs(value)} ${value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_LENGTHENING'] : this.labels['WEDGE_TABLE_COMPONENT_SHORTENING']}` : '';
	}

	/**
	* Get secondary translation in AP view.
	*/
	get ap_secondaryTransl(): string {
		const value = this.printMeasures.rpm_ap_secondaryTransl;
		return value || value === 0 ? `${Math.abs(value)} ${value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_MEDIAL'] : this.labels['WEDGE_TABLE_COMPONENT_LATERAL']}` : '';
	}

	/**
	* Get secondary translation in LAT view.
	*/
	get lt_secondaryTransl(): string {
		const value = this.printMeasures.rpm_lt_secondaryTransl;
		return value || value === 0 ? `${Math.abs(value)} ${value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_POSTERIOR'] : this.labels['WEDGE_TABLE_COMPONENT_ANTERIOR']}` : '';
	}

	// ANGLES RANGE

	/**
	* Get MAD range.
	*/
	get rangeMAD(): string {
		return `(${this.apAngles.MAD.min}mm - ${this.apAngles.MAD.max}mm)`;
	}

	/**
	* Get MNSA range.
	*/
	get rangeMNSA(): string {
		return `(${this.apAngles.MNSA.min}° - ${this.apAngles.MNSA.max}°)`;
	}

	/**
	* Get LPFA range.
	*/
	get rangeLPFA(): string {
		return `(${this.apAngles.mLPFA.min}° - ${this.apAngles.mLPFA.max}°)`;
	}

	/**
	* Get MPFA range.
	*/
	get rangeMPFA(): string {
		return `(${this.apAngles.MPFA.min}° - ${this.apAngles.MPFA.max}°)`;
	}

	/**
	* Get MLDFA range.
	*/
	get rangeMLDFA(): string {
		return `(${this.apAngles.mLDFA.min}° - ${this.apAngles.mLDFA.max}°)`;
	}

	/**
	* Get JLCA range.
	*/
	get rangeJLCA(): string {
		return `(${this.apAngles.JLCA.min}° - ${this.apAngles.JLCA.max}°)`;
	}

	/**
	* Get Knee Translation range.
	*/
	get rangeKneeTransl(): string {
		return `(${this.apAngles.KneeTransl.min}mm - ${this.apAngles.KneeTransl.max}mm)`;
	}

	/**
	* Get MPTA range.
	*/
	get rangeMPTA(): string {
		return `(${this.apAngles.MPTA.min}° - ${this.apAngles.MPTA.max}°)`;
	}

	/**
	* Get LDTA range.
	*/
	get rangeLDTA(): string {
		return `(${this.apAngles.LDTA.min}° - ${this.apAngles.LDTA.max}°)`;
	}

	/**
	* Get PDFA range.
	*/
	get rangePDFA(): string {
		return `(${this.ltAngles.PDFA.min}° - ${this.ltAngles.PDFA.max}°)`;
	}

	/**
	* Get PPTA range.
	*/
	get rangePPTA(): string {
		return `(${this.ltAngles.PPTA.min}° - ${this.ltAngles.PPTA.max}°)`;
	}

	/**
	* Get ADTA range.
	*/
	get rangeADTA(): string {
		return `(${this.ltAngles.ADTA.min}° - ${this.ltAngles.ADTA.max}°)`;
	}

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		private usrSrv: UserService) {
		super(langSrv, canvasSrv);

		this.currentCase = this.canvasSrv.currentCase;

		this.usrSrv.isDemoUser().subscribe((isDemoUser) => {
			this._isDemo = isDemoUser;
		});

		this.usrSrv.getCurrentUser().subscribe((user) => {
			this.drFullName = user.firstName + ' ' + user.lastName;
		});
	}

	ngOnInit() { }

	private calculateLLD(length: number, cl_length: number): string {
		if (length && cl_length) {
			const lld = length - cl_length;
			const sign = lld > 0 ? this.labels['LLD_TABLE_LONG'] : lld < 0 ? this.labels['LLD_TABLE_SHORT'] : null;
			return !!sign ? `${Math.abs(lld)} ${sign}` : `${lld}`;
		}
		return null;
	}

}

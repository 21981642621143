import { ViewType } from "@ortho-next/nextray-core";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { BindedModel } from "../../Models/BindedModel";
import { PrintStateTypes, StateTypes } from "../../States/State";
import { ReferencePoint } from "../ReferencePoint";
import { OsteotomyBase } from "./OsteotomyBase";

export abstract class Osteotomy extends OsteotomyBase {
  protected _referencePoint: any/*InsertionPoint*/;

  public get axisDir(): Vector3 {
    return this._axisDir;
  }

  public get axisValidPoint(): Vector3 {
    return this._axisValidPoint;
  }

  public constructor(viewType: ViewType, referencePoint: ReferencePoint, isEOC?: boolean) {
    super(viewType, referencePoint, isEOC);
    this.C.hideWhenDisabled = false;
  }

  public bindProperties(): void {
    this.bindProperty('isEnabled', (m: BindedModel) => {
      return !m.readonly && m.appState === StateTypes.RPM && !m.EOCCropVisible && !m.isCropPolygonToolVisible;
    }, ['readonly', 'appState', 'EOCCropVisible', 'isCropPolygonToolVisible']);

    if (this._isEOC) {
      this.bindProperty('visible', (m: BindedModel) => {
        return m.isOsteotomyValid && m.EOCCropVisible
          && m.printState !== PrintStateTypes.deformityAnalysis;
      }, ['isOsteotomyValid', 'EOCCropVisible', 'printState']);
    } else {

      this.bindProperty('visible', (m: BindedModel) => {
        return m.isOsteotomyValid && m.layer_plane;
      }, ['isOsteotomyValid', 'layer_plane']);

      this.label.bindProperty('visible', (m: BindedModel) => {
        return m.printState === PrintStateTypes.none || m.printState === PrintStateTypes.deformityAnalysis;
      }, ['printState']);
    }
  }
}

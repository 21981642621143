import { environment } from "../../environments/environment";

export class EoCPlaneConfig {
  public static ost1_color = 0xF7A300;
  public static ost2_color = 0xF7A300;
  public static line1_color = 0xF7A300;
  public static line2_color = 0xF7A300;
  public static line3_color = 0xF7A300;
  public static line4_color = 0xF7A300;
  public static line5_color = 0xF7A300;
  public static CURSOR_ROTATE = `${environment.cdnUrl}/img/rotate_icon.cur`;
}

import { SaveChild, SelectableTool, ToolToSave, ToolType } from "@ortho-next/nextray-core";
import { Group } from "@ortho-next/three-base/three.js/build/three.module";
import { BindedModel, bindedModel } from "../../Models/BindedModel";
import { PrintStateTypes } from "../../States/State";
import { Consts } from "../../Utils/Consts";
import { MechanicalAxisAP } from "../DeformityAnalyzer/FullAnalyzerAP";
import { MechanicalAxisLT } from "../DeformityAnalyzer/FullAnalyzerLT";
import { DiaphysisAnatomicalAxis } from "../DiaphysisAnatomicalAxis";
import { EoCPlane } from "../EoCPlane/EoCPlane";
import { InsertionPoint } from "../InsertionPoint/InsertionPoint";
import { Osteotomy } from "../Osteotomy/Osteotomy";
import { BlockingScrew } from "./BlockingScrew";

/**
 * Blocking screw container.
 */
export class BlockingScrewManager extends Group implements SelectableTool, ToolToSave {
  public isSelectable = true;
  public isDeletable = true;
  public isSelected: boolean;
  public toolType = ToolType.blockingScrew;
  @SaveChild("position") public obj: BlockingScrew;
  private _eocPlane: EoCPlane;

  public onSelect(): void {
    this.obj.material.emissive.setHex(0x222222);
    this.needsRender = true;
  }

  public onDeselect(): void {
    this.obj.material.emissive.setHex(0x000000);
    this.needsRender = true;
  }

  constructor(eocPlane: EoCPlane, mechanicalAxis: MechanicalAxisAP | MechanicalAxisLT, referencePoint: InsertionPoint, diaphysisAA: DiaphysisAnatomicalAxis, osteotomy: Osteotomy) {
    super();
    this.position.z = 450;
    this._eocPlane = eocPlane;

    this.add(this.obj = new BlockingScrew());
    this.obj.init(mechanicalAxis, referencePoint, diaphysisAA, osteotomy, eocPlane.cropDirection);

    this.bindProperty("visible", (m: BindedModel) => {
      return m.isStrokeLocked && m.printState !== PrintStateTypes.deformityAnalysis;
    }, ["isStrokeLocked", "printState"]);

    //this.bindProperty("isEnabled", (m: BindedModel) => {
    //  return m.isInsertionPointEnabled;
    //}, ["isInsertionPointEnabled"]);

    eocPlane.addEventListener("rotated", this._syncFunc);
  }

  private _syncFunc = (args: any) => {
    if (bindedModel.isFitboneInserted && bindedModel.isStrokeLocked && this.obj.moveWithEOC) {
      this.obj.position.sub(args.rotationCenter).applyAxisAngle(Consts.planeNormal, args.angle).add(args.rotationCenter);
    }
  }

  public dispose(): void {
    super.dispose();
    this._eocPlane.removeEventListener("rotated", this._syncFunc);
    this.obj.dispose();
  }

}

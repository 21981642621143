import { Box2, Euler, Vector2, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { Tools } from "../Core/Tools";
import { bindedModel } from "../Models/BindedModel";
import { Consts } from "../Utils/Consts";
import { CustomCameraControls as CustomCameraControlsBase, VectorUtils } from "@ortho-next/nextray-core";

/**
 * Camera controls created converting trackballcontrols.
 * It works only with orthographic camera and it can only zoom and pan and it supports custom viewport size.
 */
export class CustomCameraControls extends CustomCameraControlsBase {
  protected _tools: Tools;

  protected getUpdatedScaleFactor(): { defaultZoom: number, box: Box2 } {
    const cameraRotation = this.camera.rotation.clone();
    cameraRotation.z *= -1;
    const box = this.getEdgesBBox(cameraRotation);
    const width = box.max.x - box.min.x;
    const height = box.max.y - box.min.y;
    const defaultZoom = Math.min(this.camera.height / height, this.camera.width / width);
    this._minZoom = defaultZoom / 10;
    this._maxZoom = defaultZoom * 10;
    return { defaultZoom: defaultZoom, box };
  }

  private getEdgesBBox(cameraRotation: Euler): Box2 {
    let edges: Vector3[] = [];

    const planeVertices = this._tools.plane.getVertices(0);
    edges.push(
      planeVertices.A.applyEuler(cameraRotation),
      planeVertices.B.applyEuler(cameraRotation),
      planeVertices.C.applyEuler(cameraRotation),
      planeVertices.D.applyEuler(cameraRotation)
    );

    if (bindedModel.EOCCropVisible) {
      edges = this.getVerticesCroppedWithBlackPlanes(edges, this._tools.EoCPlane.blackPlane.getVerticesWithEuler(cameraRotation));
      edges = this.getVerticesCroppedExcludingControlateral(edges, this._tools.EoCPlane.ctrlBlackPlane.getVerticesWithEuler(cameraRotation));
      edges.push(...this._tools.EoCPlane.croppedPlane.getVertices(cameraRotation));
    }

    return new Box2(
      new Vector2(Math.min(...edges.map(x => x.x)), Math.min(...edges.map(x => x.y))),
      new Vector2(Math.max(...edges.map(x => x.x)), Math.max(...edges.map(x => x.y)))
    );
  }

  private getVerticesCroppedWithBlackPlanes(edges: Vector3[], blackVertices: Vector3[]): Vector3[] {
    for (let i = edges.length - 1; i >= 0; i--) {
      for (let j = blackVertices.length - 1; j >= 0; j--) {
        if (VectorUtils.areVec3EqualsWithTolerance(edges[i], blackVertices[j])) {
          blackVertices.splice(j, 1);
          edges.splice(i, 1);
          break;
        }
      }
    }
    return VectorUtils.sortConvexPolygonVertices([...edges, ...blackVertices]);
  }

  private getVerticesCroppedExcludingControlateral(edges: Vector3[], ctrlVertices: Vector3[]): Vector3[] {
    if (ctrlVertices.length > 0) {

      const intersections: Vector3[] = [];
      for (let i = 0; i < edges.length; i++) {
        for (let j = 0; j < ctrlVertices.length; j++) {
          const intersection = VectorUtils.lines2DIntersection(edges[i], edges[(i + 1) % edges.length], ctrlVertices[j], ctrlVertices[(j + 1) % ctrlVertices.length]);
          if (intersection && VectorUtils.isPointOnSegmentWithTolerance(edges[i], edges[(i + 1) % edges.length], intersection) &&
            VectorUtils.isPointOnSegmentWithTolerance(ctrlVertices[j], ctrlVertices[(j + 1) % ctrlVertices.length], intersection)) {
            intersections.push(intersection);
          }
        }
      }

      this.applyDistinct(intersections);
      return this.getVerticesCroppedWithBlackPlanes(edges, intersections);
    }
    return edges;
  }

  private applyDistinct(intersections: Vector3[]): Vector3[] {
    for (let i = 0; i < intersections.length; i++) {
      for (let j = intersections.length - 1; j >= i + 1; j--) {
        if (VectorUtils.areVec3EqualsWithTolerance(intersections[i], intersections[j])) {
          intersections.splice(j, 1);
        }
      }
    }
    return intersections;
  }

  /**
   * Sets the camera postion to absolute system origin.
   */
  public centerCamera(box: Box2): void {
    const position = new Vector3();

    if (bindedModel.EOCCropVisible) {
      position.set(box.getCenter(Consts.emptyVector2).x, box.getCenter(Consts.emptyVector2).y, 0).applyEuler(this.camera.rotation);
    } else {
      position.set(0, 0, 0);
    }
    this.setCameraPosition(position);
  }

  /**
   * Set camera position. This is the only way to move camera.
   */
  public setCameraPosition(position: Vector3): void {
    this._target.copy(position).setZ(0);
    this.camera.position.set(this._target.x, this._target.y, 5000);
    this.camera.updateProjectionMatrix();
    this._eye.subVectors(this.camera.position, this._target);
    this.camera.lookAt(this._target);
  }
}

import { Component, OnInit } from '@angular/core';
import { SelectedApexMech } from '@ortho-next/nextray-core';
import { BoneTypeEnum, LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component of first vertical sidebar to select osteotomy site.
*/
@Component({
	selector: 'osteotomy-workflow',
	templateUrl: './osteotomy-workflow.component.html',
	styles: []
})
export class OsteotomyWorkflowComponent extends BaseComponent implements OnInit {

	site: ApexSiteEnum;
	apexSiteEnum = ApexSiteEnum;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.site = this.apexSite();
	}

	get isLongLegVisible(): boolean {
		return this.hasLT ? this.boneTypeLT === BoneTypeEnum.LongLeg : this.isLongLeg;
	}
	get isFemurVisible(): boolean {
		return this.hasLT ? this.boneTypeLT === BoneTypeEnum.Femur : this.isFemur;
	}
	get isTibiaVisible(): boolean {
		return this.hasLT ? this.boneTypeLT === BoneTypeEnum.Tibia : this.isTibia;
	}


	/**
	* Close osteotomy site selection workflow.
	*/
	back(): void {
		this.canvasSrv.dispatch('cancelSelectingApex');
	}

	/**
	* Confirm insertion approach.
	*/
	confirm(): void {
		this.canvasSrv.dispatch(this.selectedApexEvent());
		this.canvasSrv.dispatch('confirmApex');
	}

	private apexSite(): ApexSiteEnum {
		switch (this.selectedApex) {
			case SelectedApexMech.femurProximal: return ApexSiteEnum.ProximalFemur;
			case SelectedApexMech.femurDistal: return ApexSiteEnum.DistalFemur;
			case SelectedApexMech.tibiaProximal: return ApexSiteEnum.ProximalTibia;
			default: return null;
		}
	}

	private selectedApexEvent(): 'apexFemurProximal' | 'apexFemurDistal' | 'apexTibiaProximal' {
		switch (this.site) {
			case ApexSiteEnum.ProximalFemur: return 'apexFemurProximal';
			case ApexSiteEnum.DistalFemur: return 'apexFemurDistal';
			case ApexSiteEnum.ProximalTibia: return 'apexTibiaProximal';
			default: return null;
		}
	}

	/**
	 * Map label for apex site.
	 */
	apexSiteLabel(site: ApexSiteEnum): string {
		switch (site) {
			case ApexSiteEnum.ProximalFemur: return this.labels.INSERTION_APPROACH_ANTEGRADE_FEMUR;
			case ApexSiteEnum.DistalFemur: return this.labels.INSERTION_APPROACH_RETROGRADE_FEMUR;
			case ApexSiteEnum.ProximalTibia: return this.labels.INSERTION_APPROACH_ANTEGRADE_TIBIA;
			default: return null;
		}
	}

}


export enum ApexSiteEnum {
	ProximalFemur = 'Antegrade Femur',
	DistalFemur = 'Retrograde Femur',
	ProximalTibia = 'Antegrade Tibia'
}

<div class="spacing-top">
	<a class="wrapper-back" href="#" (click)="openBackToDefModal()" onclick="return false;">
		<span class="material-icons md-18 back">arrow_back</span>
		<span class="action">{{ labels.TOOLS_MENU_COMPONENT_BACK_DEFORMITY }}</span>
	</a>
</div>

<div class="main-step">

	<image-elaboration [view]="activeView"></image-elaboration>

	<!-- --------------------------------------------------------------------------------------------------------------------------- -->
	<!-- MENU REVERSE PLANNING METHOD -->
	<div class="box-menu-settings active">

		<div class="row-box" style="padding-left: 1rem; padding-right: 0.5rem;">
			<h4>{{ labels.TOOLS_MENU_COMPONENT_RPM }}</h4>
			<div class="check-success ml-auto" *ngIf="isStrokeLocked"></div>
		</div>

		<div class="divider-box"></div>


		<div class="row-box" [ngClass]="{'clickable': !isEocCut, 'disabled': !!isEocCut }" customTooltip
			[activeTooltip]="step === stepEnum.InsApproach" valueTooltip="{{ labels.TOOLS_MENU_COMPONENT_TOOLTIP_NEXT_STEP }}"
			(click)="openOsteotomyWorkflow()">
			<div class="fake-material-icon osteotomy"></div>
			<span>{{ labels.TOOLS_MENU_COMPONENT_INSERTION_APPROACH }}</span>
		</div>
		<div class="row-box" [ngClass]="{'clickable': !!selectedApex, 'disabled': !selectedApex }" customTooltip
			[activeTooltip]="step === stepEnum.Cut" valueTooltip="{{ labels.TOOLS_MENU_COMPONENT_TOOLTIP_OPTIONAL_STEP }}"
			[optionalStyle]="true" (click)="setResize()">
			<i class="material-icons">transform</i>
			<span>{{ labels.TOOLS_MENU_COMPONENT_CROP_SEGMENT }}</span>
		</div>
		<form [formGroup]="cutForm">
			<div class="row-box flex-wrap pr-0" [ngClass]="{'disabled': !selectedApex }" customTooltip
				[activeTooltip]="step === stepEnum.Cut" valueTooltip="{{ labels.TOOLS_MENU_COMPONENT_TOOLTIP_NEXT_STEP }}">
				<i class="material-icons">crop_rotate</i>
				<span>{{ labels.TOOLS_MENU_COMPONENT_CUT }}</span>
				<div class="form-group switch-custom-container align-items-center pb-0 ml-4 mb-0" style="width: fit-content;">
					<label class="switch" (click)="setCut()">
						<input type="checkbox" formControlName="cut" />
						<span></span>
					</label>
					<p class="mb-0">{{ labels.TOOLS_MENU_COMPONENT_ON_OFF }}</p>
					<i class="material-icons img-action p-2 ml-3"
						[ngClass]="{'clickable': isEocCut, 'not-allowed': !isEocCut, 'disabled': !isEocCut && selectedApex }"
						(click)="resetCut()">
						sync
					</i>
				</div>
			</div>
		</form>
		<div class="row-box" [ngClass]="{'clickable': !!isEocCut, 'disabled': !isEocCut }" customTooltip
			[activeTooltip]="step === stepEnum.FitBone" valueTooltip="{{ labels.TOOLS_MENU_COMPONENT_TOOLTIP_NEXT_STEP }}"
			(click)="openFitboneWorkflow()">
			<div class="fake-material-icon template"></div>
			<span>{{ labels.TOOLS_MENU_COMPONENT_FITBONE_SELECTION }}</span>
		</div>
		<form [formGroup]="fitboneForm">
			<div class="row-box flex-wrap pr-0" [ngClass]="{'disabled': !isFitboneInserted }" customTooltip
				[activeTooltip]="step === stepEnum.Stroke" valueTooltip="{{ labels.TOOLS_MENU_COMPONENT_TOOLTIP_NEXT_STEP }}">
				<i class="material-icons">lock</i>
				<span>{{ labels.TOOLS_MENU_COMPONENT_STROKE }}</span>
				<div class="form-group switch-custom-container align-items-center pb-0 ml-4 mb-0" style="width: fit-content;">
					<label class="switch" (click)="setLock()">
						<input type="checkbox" formControlName="lock" />
						<span></span>
					</label>
					<p class="mb-0">
						{{ isStrokeLocked ? labels.TOOLS_MENU_COMPONENT_LOCKED : labels.TOOLS_MENU_COMPONENT_UNLOCKED }}</p>
				</div>
			</div>
		</form>
		<div class="row-box flex-wrap pr-0" [ngClass]="{ 'disabled': !isStrokeLocked }" customTooltip
			[activeTooltip]="step === stepEnum.View" valueTooltip="{{ labels.TOOLS_MENU_COMPONENT_TOOLTIP_OPTIONAL_STEP }}"
			[optionalStyle]="true">
			<i class="material-icons">push_pin</i>
			<span>{{ labels.TOOLS_MENU_COMPONENT_BLOCKING_SCREW }}</span>
			<i class="material-icons img-action p-2 ml-4" style="max-width: fit-content;"
				title="{{ labels.TOOLS_MENU_COMPONENT_BLOCKING_SCREW_ADD }}" (click)="addBlockingScrew()"
				[ngClass]="{'clickable': !isAddDisabled, 'not-allowed': isAddDisabled, 'disabled': isAddDisabled && isStrokeLocked }">
				add_circle
			</i>
			<i class="material-icons img-action p-2 ml-1" style="max-width: fit-content;"
				title="{{ labels.TOOLS_MENU_COMPONENT_BLOCKING_SCREW_DELETE }}" (click)="deleteBlockingScrew()"
				[ngClass]="{'clickable': !isDeleteDisabled, 'not-allowed': isDeleteDisabled, 'disabled': isDeleteDisabled && isStrokeLocked }">
				delete_outline
			</i>
		</div>

	</div>

	<!-- --------------------------------------------------------------------------------------------------------------------------- -->
	<!-- FOOTER MENU -->
	<div class="box-menu-settings active">

		<div class="row-box" style="padding-left: 1rem;">
			<h4>{{ labels.TOOLS_MENU_COMPONENT_VISUALIZE }}</h4>
		</div>

		<div class="divider-box"></div>

		<div class="row-box" (click)="printOR()" [ngClass]="{'clickable': isStrokeLocked, 'disabled': !isStrokeLocked }"
			customTooltip [activeTooltip]="step === stepEnum.View"
			valueTooltip="{{ labels.TOOLS_MENU_COMPONENT_TOOLTIP_VIEW_PLAN }}" [optionalStyle]="true">
			<i class="material-icons">local_printshop</i>
			<span>{{ labels.TOOLS_MENU_COMPONENT_OR_PLAN }}</span>
		</div>

		<div class="row-box" (click)="showViewer()" [ngClass]="{'clickable': isStrokeLocked, 'disabled': !isStrokeLocked }"
			customTooltip [activeTooltip]="step === stepEnum.View"
			valueTooltip="{{ labels.TOOLS_MENU_COMPONENT_TOOLTIP_VIEW_PLAN }}" [optionalStyle]="true">
			<i class="material-icons">search</i>
			<span>{{ labels.TOOLS_MENU_COMPONENT_VIEWER }}</span>
		</div>

	</div>
	<!-- --------------------------------------------------------------------------------------------------------------------------- -->

</div>
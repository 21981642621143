import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { ViewType } from '@ortho-next/nextray-core';
import { LanguageService } from '../../core';
import { CanvasService, PrinterService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Main component of OR Plan PDF print.
*/
@Component({
  selector: 'app-or-plan-print',
  templateUrl: './prt-or-plan.component.html',
  styles: []
})
export class PrintOrPlanComponent extends BaseComponent implements AfterViewInit {
  /**
   * Today's date time.
   */
  today: number = Date.now();
  /**
   * Flag indicating whether that both AP and LT views are available for printing.
   */
  isMultiView: boolean;
  /**
   * Main view type scene to print.
   */
  viewType: ViewType;
  /**
   * Default view type for second pages.
   */
  viewTypeLT: ViewType.LT;

  constructor(
    private langSrv: LanguageService,
    private canvasSrv: CanvasService,
    private prtSrv: PrinterService,
    private element: ElementRef<Element>
  ) {
    super(langSrv);
    this.viewType = canvasSrv.hasAP ? ViewType.AP : ViewType.LT;
    this.isMultiView = canvasSrv.hasAP && canvasSrv.hasLT;
  }

  async ngAfterViewInit() {
    const pages = this.element.nativeElement.querySelectorAll<HTMLElement>(':scope > *');
    await this.prtSrv.onORPlanDataReady(pages);
  }

}

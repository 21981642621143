<div class="box-menu-settings settings-image active">
  <div class="wrapper-image-setup">
    <div class="img-action"
         title="{{ labels.IMAGE_ELABORATION_COMPONENT_LINE }}"
         [ngClass]="{'clickable': true }"
         (click)="addLine()">
      <i class="material-icons">straighten</i>
    </div>
    <div class="img-action"
         title="{{ labels.IMAGE_ELABORATION_COMPONENT_GONIOMETER }}"
         [ngClass]="{'clickable': true }"
         (click)="addGoniometer()">
      <div class="fake-material-icon protractor"></div>
    </div>
    <div class="img-action"
         title="{{ labels.IMAGE_ELABORATION_COMPONENT_DELETE }}"
         [ngClass]="{'clickable': isDeleteEnabled, 'disabled': !isDeleteEnabled }"
         style="margin-left: 5rem;"
         (click)="deleteTool()">
      <div class="material-icons" style="cursor: inherit;">delete_outline</div>
    </div>
  </div>
  <div class="row-box">
    <i class="material-icons">brightness_5</i>
    <span>{{ labels.IMAGE_ELABORATION_COMPONENT_BRIGHTNESS }}</span>
  </div>
  <div class="row-box">
    <input id="brightnessSlider" type="text" />
  </div>
  <div class="row-box">
    <i class="material-icons">tonality</i>
    <span>{{ labels.IMAGE_ELABORATION_COMPONENT_CONTRAST }}</span>
  </div>
  <div class="row-box">
    <input id="contrastSlider" type="text" />
  </div>
</div>

import { MultiViewsElementCustom, RotateCameraType, SelectedApexMech, ToolEvents, VectorUtils, ViewType } from "@ortho-next/nextray-core";
import { FitboneMesh } from "../Models/AppModel";
import { bindedModel } from "../Models/BindedModel";
import { Context } from "../States/Context";
import { OsteotomyCut } from "../States/State";
import { BlockingScrewManager } from "../Tools/BlockingScrew/BlockingScrewManager";
import { Consts } from "../Utils/Consts";
import { FormatUtils } from "../Utils/FormatUtils";
import { MeasuresUtils } from "../Utils/MeasuresUtils";
import { BridgeResultMessages } from "./Bridge";
import { Main } from "./Main";

/**
 * Handle events logic.
 */
export class ContextInit {
  private static _previousFitboneState = false;
  private static _previousFitboneLocked = false;
  private static _previousFitboneModel: FitboneMesh;

  /**
   * Init all events logic.
   */
  public static create(main: Main, context: Context): Context {

    context.addEventListener("setModel", (event) => {
      main.loadScenes(event.args);
    });

    context.addEventListener("rotateCamera", () => {
      if (main.model.selectedApex !== SelectedApexMech.none) {
        main.rotateCamera(RotateCameraType.templating);

        // update grid rotation
        main.toolsAP.grid && main.toolsAP.grid.rotate(-main.toolsAP.mechanicalAxis.getAngleForApexConfirmed(main.model.selectedApex));
        if (main.model.selectedApex === SelectedApexMech.tibiaProximal) {
          main.toolsLT.grid && main.toolsLT.grid.rotate(-main.toolsLT.mechanicalAxis.getAngleForApexConfirmed(main.model.selectedApex));
        } else {
          main.toolsLT.grid && main.toolsLT.grid.rotate(-main.toolsLT.diaphysisAA.getAngleForApexConfirmed(main.model.selectedApex));
        }
      }
    });

    context.addEventListener("startSelectingApex", (event) => {
      main.hasLT && context.handle("setViewMultiple");
      this.resetInsertionApproach(main);
    });

    context.addEventListener("selectApex", (event) => {
      bindedModel.selectedApex =
        main.model.selectedApex = event.args;
    });

    context.addEventListener("startInsertionPoint", (event) => {
      main.toolsInit.initInsertionPoints();
      bindedModel.isInsertionPointInserted = true;
      bindedModel.isInsertionPointEnabled = true;
      main.model.isInsPointInserted = true;
      if (main.hasLT && (bindedModel.selectedApex === SelectedApexMech.femurProximal || bindedModel.selectedApex === SelectedApexMech.femurDistal)) {
        main.toolsInit.initDiaphysisAA();
        bindedModel.isDiaphysisAAInserted = true;
        bindedModel.isDiaphysisAAEnabled = true;
      }
    });

    context.addEventListener("cancelInsertionPoint", (event) => {
      bindedModel.isInsertionPointInserted = false;
      main.model.isInsPointInserted = false;
      bindedModel.isDiaphysisAAInserted = false;
    });

    context.addEventListener("confirmInsertionPoint", (event) => {
      const selectedApex = main.model.selectedApex;
      main.toolsAP.mechanicalAxis.updateWeightBearingAndCH(selectedApex);
      main.toolsInit.initOsteotomies();
      main.toolsAP.osteotomy && main.toolsAP.osteotomy.setPositionByInsertionPoint(main.toolsAP.insertionPoint);
      const osteotomyDistanceToReferencePointAP = main.toolsAP.mechanicalAxis && main.toolsAP.mechanicalAxis.getApexAngle(selectedApex) ? main.toolsAP.osteotomy.distanceToReferencePoint : undefined;
      main.toolsLT.osteotomy && main.toolsLT.osteotomy.setPositionByInsertionPoint(main.toolsLT.insertionPoint);
      if (osteotomyDistanceToReferencePointAP === undefined && main.toolsAP.osteotomy && main.toolsLT.osteotomy) {
        main.toolsAP.osteotomy.distanceToReferencePoint = main.toolsLT.osteotomy.distanceToReferencePoint;
      }
      bindedModel.isOsteotomyValid = true;
      bindedModel.isInsertionPointEnabled = false;
      bindedModel.isDiaphysisAAEnabled = false;
      context.handle("rotateCamera");
    });

    context.addEventListener("startCropPolygon", (event) => {
      let APValid = true, LTValid = true;
      if (main.hasAP) {
        main.scenes[ViewType.AP].cameraControls.fitToPlane();

        main.toolsInit.initEoCPlaneTool(ViewType.AP);
        main.toolsInit.initCropPolygonTool(ViewType.AP);
        APValid = main.getTools(ViewType.AP).cropPolygonTool.check();
      }
      if (main.hasLT) {
        main.scenes[ViewType.LT].cameraControls.fitToPlane();
        (main.multiviewRenderer.viewsports[ViewType.AP] as MultiViewsElementCustom).cameraControls.fitToPlane();
        (main.multiviewRenderer.viewsports[ViewType.LT] as MultiViewsElementCustom).cameraControls.fitToPlane();

        main.toolsInit.initEoCPlaneTool(ViewType.LT);
        main.toolsInit.initCropPolygonTool(ViewType.LT);
        LTValid = main.getTools(ViewType.LT).cropPolygonTool.check();
      }
      if (APValid && LTValid) {
        main.hasAP && main.getTools(ViewType.AP).cropPolygonTool.start();
        main.hasLT && main.getTools(ViewType.LT).cropPolygonTool.start();
        bindedModel.isCropPolygonToolVisible = true;
      } else {
        main.bridge.dispatchEvent({ type: "onResult", args: BridgeResultMessages.cannotCut });
        bindedModel.isPolygonCropped = false;
      }
    });

    context.addEventListener("confirmCropPolygon", (event) => {
      main.hasAP && main.getTools(ViewType.AP).cropPolygonTool.confirm();
      main.hasLT && main.getTools(ViewType.LT).cropPolygonTool.confirm();
      bindedModel.isCropPolygonToolVisible = false;
      bindedModel.isPolygonCropped = true;
    });

    context.addEventListener("cutEoC", (event) => {
      if (event.args) {
        let APValid = true, LTValid = true;
        const verticalTransl = MeasuresUtils.getEoCVerticalTranslationBeforeCut(main.toolsAP, main.toolsLT, OsteotomyCut.opening);
        if (main.hasAP) {
          main.toolsInit.initEoCPlaneTool(ViewType.AP);
          APValid = main.toolsAP.EoCPlane.cutPlane(verticalTransl);
        }
        if (main.hasLT && APValid) {
          main.toolsInit.initEoCPlaneTool(ViewType.LT);
          LTValid = main.toolsLT.EoCPlane.cutPlane(verticalTransl, main.toolsAP.mechanicalAxis.targetPointDistanceTibia);
        }
        if (APValid && LTValid) {
          if (bindedModel.selectedApex === SelectedApexMech.femurProximal || bindedModel.selectedApex === SelectedApexMech.femurDistal) {
            main.toolsLT.EoCPlane && (main.toolsLT.EoCPlane.diaphysisAADirection = main.toolsLT.diaphysisAA.direction);
          }
          main.toolsLT.EoCPlane && (main.toolsLT.EoCPlane.setVerticalTranslation(main.toolsAP.EoCPlane.getVerticalTranslation()));
          main.toolsInit.bindEoCSync();
          bindedModel.EOCCropVisible = true;
          main.model.eocCut = true;
          bindedModel.layer_contralateral_all = false;
        } else {
          main.bridge.dispatchEvent({ type: "onResult", args: BridgeResultMessages.cannotCut });
          bindedModel.isPolygonCropped = false;
        }
      } else {
        main.model.eocCut = false;
        bindedModel.EOCCropVisible = false;
        bindedModel.isFitboneInserted && this.resetTemplating(main);
      }
    });

    context.addEventListener("updateMeausuresOnlyPrint", () => {
      const mecAxis = main.toolsAP.mechanicalAxis;
      const osteotomy = main.toolsAP.osteotomy;
      main.model.targetLegLength = FormatUtils.distance(MeasuresUtils.getTargetLegLength(mecAxis));
      const initialBoneLength = bindedModel.selectedApex === SelectedApexMech.tibiaProximal ? main.deformityModel.measures.def_ap_tibiaLength : main.deformityModel.measures.def_ap_femurLength;
      const boneLength = MeasuresUtils.getTargetBoneLength(mecAxis);
      const boneMALength = boneLength - initialBoneLength;
      main.model.maBoneLengthening = FormatUtils.distanceWithSign(boneMALength);
      main.model.osteotomyLevel = FormatUtils.distance(osteotomy.level);
    });

    context.addEventListener("generateImagesToPrint", (event) => {
      main.model.printResult = main.printUtils.generateImages(event.args.w, event.args.h);
    });

    context.addEventListener("insertFitbone", (event) => {
      main.model.fitbone = event.args;
      main.toolsInit.initFitBones();
      bindedModel.isFitboneInserted = true;
      main.model.isFitboneInserted = true;
      bindedModel.layer_fitbone = true;
    });

    context.addEventListener("startInsertingFitbone", () => {
      this._previousFitboneState = main.model.isFitboneInserted;
      this._previousFitboneLocked = main.model.isStrokeLocked;
      this._previousFitboneModel = main.model.fitbone;
      main.toolsAP.fitBoneManager && main.toolsAP.fitBoneManager.createStateToRestore();
      main.toolsLT.fitBoneManager && main.toolsLT.fitBoneManager.createStateToRestore();
      bindedModel.isStrokeLocked = false;
      main.model.isStrokeLocked = false;
    });

    context.addEventListener("cancelFitbone", () => {
      main.toolsAP.fitBoneManager && (main.toolsAP.fitBoneManager.isSyncEnabled = false);
      main.toolsLT.fitBoneManager && (main.toolsLT.fitBoneManager.isSyncEnabled = false);
      main.toolsAP.fitBoneManager && main.toolsAP.fitBoneManager.confirmStateRestore();
      main.toolsLT.fitBoneManager && main.toolsLT.fitBoneManager.confirmStateRestore();
      bindedModel.isFitboneInserted = this._previousFitboneState;
      main.model.isFitboneInserted = this._previousFitboneState;
      main.model.fitbone = this._previousFitboneModel;
      main.toolsAP.fitBoneManager && (main.toolsAP.fitBoneManager.isSyncEnabled = true);
      main.toolsLT.fitBoneManager && (main.toolsLT.fitBoneManager.isSyncEnabled = true);
      main.bridge.mapEvent("lockStroke", this._previousFitboneLocked);
    });

    context.addEventListener("confirmFitbone", () => {
      main.toolsAP.fitBoneManager && main.toolsAP.fitBoneManager.cancelStateRestore();
      main.toolsLT.fitBoneManager && main.toolsLT.fitBoneManager.cancelStateRestore();
      this.deleteAllBlockingScrews(main);
    });

    context.addEventListener("lockStroke", (event) => {
      bindedModel.isStrokeLocked = event.args;
      main.model.isStrokeLocked = event.args;
      if (event.args) {
        this.rotateFitbone(main);
      }
    });

    context.addEventListener("openViewer", (event) => {
      main.viewer.start();
      bindedModel.isViewerVisible = true;
      main.model.isViewerVisible = true;
      main.hasAP && main.toolsAP.EoCPlane.dispatchEvent({ type: ToolEvents.updated });
      main.hasLT && main.toolsLT.EoCPlane.dispatchEvent({ type: ToolEvents.updated });
    });

    context.addEventListener("closeViewer", (event) => {
      main.viewer.stop();
      bindedModel.isViewerVisible = false;
      main.model.isViewerVisible = false;
    });

    context.addEventListener("setViewer", (event) => {
      main.viewer.set(event.args);
    });

    context.addEventListener("setRealisticZoom", (event) => {
      main.setRealScale(event.args);
    });

    context.addEventListener("addBlockingScrew", (event) => {
      main.toolsInit.initBlockingScrew();
    });

    context.addEventListener("deleteBlockingScrew", (event) => {
      const tool = main.activeScene.activeTool;
      if (tool && tool instanceof BlockingScrewManager) {
        main.activeScene.deleteActiveTool();
      }
    });

    return context;
  }

  private static resetInsertionApproach(main: Main): void {
    main.model.selectedApex = SelectedApexMech.none;
    bindedModel.selectedApex = SelectedApexMech.none;
    bindedModel.isInsertionPointInserted = false;
    bindedModel.isDiaphysisAAInserted = false;
    main.model.isInsPointInserted = false;
    bindedModel.isOsteotomyValid = false;
    bindedModel.isCropPolygonToolVisible = false;
    bindedModel.isPolygonCropped = false;
    main.rotateCamera(RotateCameraType.none);

    // hide tools
    bindedModel.layer_grid = false;
    bindedModel.layer_lines = false;
    bindedModel.layer_goniometers = false;
    bindedModel.layer_mechAxis_mechanical = false;
    bindedModel.layer_mechAxis_anatomical = false;
    bindedModel.layer_contralateral_all = false;
  }

  private static resetTemplating(main: Main): void {
    main.hasAP && main.toolsAP.fitBoneManager && main.toolsAP.fitBoneManager.reset();
    main.hasLT && main.toolsLT.fitBoneManager && main.toolsLT.fitBoneManager.reset();
    main.model.isFitboneInserted = false;
    main.model.fitbone = undefined;
    bindedModel.isFitboneInserted = false;
    main.model.isStrokeLocked = false;
    bindedModel.isStrokeLocked = false;
    main.model.messages.isScrewNearOstAP = false;
  }

  private static rotateFitbone(main: Main): void {
    if (bindedModel.selectedApex !== SelectedApexMech.femurProximal) {
      if (main.hasAP) {
        const tools = main.toolsAP;
        const EOCPlaneAP = tools.EoCPlane;
        const angle = VectorUtils.linesAngle(tools.fitBoneManager.rotationCenterExternal, tools.insertionPoint.position, tools.clonedMechanicalAxis.CH.position);
        const pan = EOCPlaneAP.ost1.position.clone().sub(tools.clonedMechanicalAxis.CH.position);
        const rotatedPan = pan.applyAxisAngle(Consts.planeNormal, angle);
        const pointForRotation = tools.clonedMechanicalAxis.CH.position.clone().add(rotatedPan);
        EOCPlaneAP.rotationByPoint(pointForRotation, EOCPlaneAP.ost1, true);
        if (main.hasLT) {
          const EOCPlaneLT = main.toolsLT.EoCPlane;
          EOCPlaneLT.setVerticalTranslation(EOCPlaneAP.getVerticalTranslation());
        }
      }
    }
  }

  private static deleteAllBlockingScrews(main: Main): void {
    main.hasAP && this.deleteBlockingScrews(main, ViewType.AP);
    main.hasLT && this.deleteBlockingScrews(main, ViewType.LT);
  }

  private static deleteBlockingScrews(main: Main, viewType: ViewType) {
    const tools = main.getTools(viewType);
    for (const bScrew of tools.blockingScrews) {
      bScrew.dispose();
    }
    tools.blockingScrews = [];
    main.model[viewType === ViewType.AP ? "isBlockingScrewSelectedAP" : "isBlockingScrewSelectedLT"] = null;
    main.model[viewType === ViewType.AP ? "isBlockingScrewFullAP" : "isBlockingScrewFullLT"] = null;
  }

}

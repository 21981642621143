import { Component, OnInit } from '@angular/core';
import { SelectedApexMech } from '@ortho-next/nextray-core';
import { environment } from '../../../environments/environment';
import { BoneTypeEnum, Fitbone, FitboneSearchFilters, LanguageService } from '../../core';
import { CanvasService, ProductService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component of first vertical sidebar to select the template.
*/
@Component({
	selector: 'template-selection',
	templateUrl: './template-selection.component.html',
	styles: []
})
export class TemplateSelectionComponent extends BaseComponent implements OnInit {

	fitboneList: Fitbone[];
	insertionApproach: string;

	readonly LOADER_URL: string = environment.cdnUrl + '/style/images/loading.gif';

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		public prodSrv: ProductService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.insertionApproach = this._insertionApproach;
		this.initFitboneList();
	}

	private initFitboneList(): void {
		const filters: FitboneSearchFilters = { bone: this.bone };
		this.prodSrv.getFitboneList(filters).subscribe(list => this.fitboneList = list);
	}

	private get bone(): BoneTypeEnum.Femur | BoneTypeEnum.Tibia {
		return this.selectedApex === SelectedApexMech.tibiaProximal ? BoneTypeEnum.Tibia : BoneTypeEnum.Femur;
	}

	private get _insertionApproach(): string {
		switch (this.selectedApex) {
			case SelectedApexMech.femurProximal: return this.labels.INSERTION_APPROACH_ANTEGRADE_FEMUR;
			case SelectedApexMech.femurDistal: return this.labels.INSERTION_APPROACH_RETROGRADE_FEMUR;
			case SelectedApexMech.tibiaProximal: return this.labels.INSERTION_APPROACH_ANTEGRADE_TIBIA;
			default: return null;
		}
	}

	get diameter(): string {
		return this.fitbone ? `${this.fitbone.diameter} mm` : '-';
	}

	get stroke(): string {
		return this.fitbone ? `${this.fitbone.stroke} mm` : '-';
	}

	get maxLength(): string {
		return this.fitbone ? `${this.fitbone.maxLength} mm` : '-';
	}

	get maxPatientWeight(): string {
		return this.fitbone && this.fitbone.maxPatientWeight ? `${this.fitbone.maxPatientWeight} kg` : '-';
	}

	/**
	* Close the fitbone selection workflow.
	*/
	back(): void {
		this.canvasSrv.dispatch('cancelFitbone');
	}

	/**
	* Insert template.
	*/
	insertTemplate(index: number): void {
		const fitbone = this.fitboneList[index];
		this.canvasSrv.dispatch('insertFitbone', fitbone);
	}

	/**
	* Confirm the selected template and close the workflow.
	*/
	confirmTemplate(): void {
		this.canvasSrv.dispatch('confirmFitbone');
		this.canvasSrv.saveState();
	}

}

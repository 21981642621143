import { BoneTypeEnum } from "../../app/core/repositories/models/case";
import { Context } from "../States/Context";
import { Consts } from "../Utils/Consts";


export interface BOM {
  screwNoLockingList?: number[];
  screwLockingList?: number[];
}

export interface NextRayMeasures extends
  NextRayMeasuresDeformityAP, NextRayMeasuresDeformityLT, NexrayMeasuresEOC { }

export interface NexrayMeasuresDeformityAxial {
  def_femur_axialRotation?: number;
  def_femur_axialRotationSign?: AxialRotationEnum;
  def_tibia_axialRotation?: number;
  def_tibia_axialRotationSign?: AxialRotationEnum;
}

export interface NexrayMeasuresStandardAngles {
  def_standardAngles_LPFA?: number;
  def_standardAngles_mLDFA?: number;
  def_standardAngles_MPTA?: number;
  def_standardAngles_LDTA?: number;
  def_standardAngles_PPTA?: number;
  def_standardAngles_ADTA?: number;
}

export interface NextRayMeasuresDeformityAP {
  def_ap_LPFA?: number;
  def_ap_mLDFA?: number;
  def_ap_MPTA?: number;
  def_ap_LDTA?: number;
  def_ap_JLCA?: number;
  def_ap_MAD?: number;
  def_ap_MNSA?: number;
  def_ap_MPFA?: number;
  def_ap_aLDFA?: number;
  def_ap_kneeTranslation?: number;
  def_ap_femurLength?: number;
  def_ap_tibiaLength?: number;
  def_ap_fullLength?: number;

  def_ap_LPFA_ctrlateral?: number;
  def_ap_mLDFA_ctrlateral?: number;
  def_ap_MPTA_ctrlateral?: number;
  def_ap_LDTA_ctrlateral?: number;
  def_ap_JLCA_ctrlateral?: number;
  def_ap_MAD_ctrlateral?: number;
  def_ap_MNSA_ctrlateral?: number;
  def_ap_MPFA_ctrlateral?: number;
  def_ap_aLDFA_ctrlateral?: number;
  def_ap_kneeTranslation_ctrlateral?: number;
  def_ap_femurLength_ctrlateral?: number;
  def_ap_tibiaLength_ctrlateral?: number;
  def_ap_fullLength_ctrlateral?: number;

  def_ap_apexFemur?: number;
  def_ap_apexFemurSign?: string;
  def_ap_apexTibia?: number;
  def_ap_apexTibiaSign?: string;
}

export interface NextRayMeasuresDeformityLT {
  def_lt_PDFA?: number;
  def_lt_PPTA?: number;
  def_lt_ADTA?: number;
  def_lt_kneeTranslation?: number;

  def_lt_apexTibia?: number;
  def_lt_apexTibiaSign?: string;
}

export interface NexrayMeasuresEOC {
  eoc_ap_LPFA?: number;
  eoc_ap_mLDFA?: number;
  eoc_ap_MPTA?: number;
  eoc_ap_LDTA?: number;
  eoc_ap_JLCA?: number;
  eoc_ap_MAD?: number;
  eoc_ap_MNSA?: number;
  eoc_ap_MPFA?: number;
  eoc_ap_aLDFA?: number;
  eoc_ap_kneeTranslation?: number;
  eoc_ap_femurLength?: number;
  eoc_ap_tibiaLength?: number;
  eoc_ap_fullLength?: number;

  eoc_lt_PDFA?: number;
  eoc_lt_PPTA?: number;
  eoc_lt_ADTA?: number;
  eoc_lt_kneeTranslation?: number;

  eoc_ap_angle?: number;
  eoc_lt_angle?: number;
  eoc_ap_cortexLength?: number;
  eoc_lt_cortexLength?: number;
  eoc_ap_axialLength?: number;
  eoc_lt_axialLength?: number;
  eoc_ap_secondaryTransl?: number;
  eoc_lt_secondaryTransl?: number;
}

export enum AxialRotationEnum {
  external = "external",
  internal = "internal"
}

export enum PlateOrientationType {
  lateral,
  medial
}

/**
 * Model used by angular to create bindings. Only three.js part can set this values.
 */
export class DeformityAppModel implements NexrayMeasuresStandardAngles, NexrayMeasuresDeformityAxial {
  protected _context: Context;
  public measures: NextRayMeasures = {};
  public scaleFactorAP: number;
  public scaleFactorLT: number;
  public isContralateralAxisInserted: boolean;
  public def_femur_axialRotation = 0;
  public def_femur_axialRotationSign = AxialRotationEnum.external;
  public def_tibia_axialRotation = 0;
  public def_tibia_axialRotationSign = AxialRotationEnum.external;
  public def_standardAngles_LPFA = Consts.paleyAngles.AP.mLPFA.standard;
  public def_standardAngles_mLDFA = Consts.paleyAngles.AP.mLDFA.standard;
  public def_standardAngles_MPTA = Consts.paleyAngles.AP.MPTA.standard;
  public def_standardAngles_LDTA = Consts.paleyAngles.AP.LDTA.standard;
  public def_standardAngles_PPTA = Consts.paleyAngles.LT.PPTA.standard;
  public def_standardAngles_ADTA = Consts.paleyAngles.LT.ADTA.standard;
  public boneTypeLT: BoneTypeEnum;
}

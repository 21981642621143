import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { Image, ImageRepository } from '../core';
import { BaseService } from './base.service';
import { CaseService } from './case.service';
import { UserService } from './user.service';


/**
* This service handles the ray images data.
*/
@Injectable()
export class ImageService extends BaseService {

	constructor(
		private imgRepo: ImageRepository,
		private userSrv: UserService,
		private caseSrv: CaseService
	) {
		super();
	}

	/**
	* Get image
	* @param {string} image Image GUID
	*/
	getImage(image: string): Observable<Image> {
		return combineLatest([
			this.userSrv.isCustomerCare(),
			this.caseSrv.getCurrentCase()
		]).pipe(
			first(),
			filter(([isCC, _case]) => !!_case),
			map(([isCC, _case]) => !!isCC ? _case.userGuid : null),
			switchMap(user => this.imgRepo.getImageDetail(image, user)),
			map(res => this.handleApiResponse(res))
		);
	}

}

import { ConstsBase } from "@ortho-next/nextray-core";
import { MeshPhongMaterial } from "@ortho-next/three-base/three.js/build/three.module";
/**
 * Defines constant data.
 */
export class Consts extends ConstsBase {
  /**	
  * Default Material used to mesh not loaded yet, to avoid null material exception.	
  */
  public static readonly toolMaterial = new MeshPhongMaterial({ color: 0x3f565a, flatShading: true });
  
}

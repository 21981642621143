import { ViewType } from "@ortho-next/nextray-core";
import { Group, Object3D } from "@ortho-next/three-base/three.js/build/three.module";
import { LabelScrew } from './LabelScrew';
import { Screw } from './Screw';

export enum ScrewEvents {
  updated = 'ScrewUpdated'
}

export class ScrewManager extends Group {
  public screw: Screw;
  public labelRight: LabelScrew;
  private _viewType: ViewType;
  private _oldLeftValue: number;
  private _oldRightValue: number;

  constructor(viewType: ViewType, child: Object3D, screwsLengths: number[]) {
    super();
    this.name = 'ScrewManager';
    this._viewType = viewType;

    this.screw = new Screw(child, screwsLengths);
    this.add(
      this.screw,
      this.labelRight = new LabelScrew('label', viewType, this.screw)
    );

    this.screw.bindEvent('onBeforeDragMove', () => {
      this._oldLeftValue = this.screw.leftLength;
      this._oldRightValue = this.screw.rightLength;
    });

    this.screw.bindEvent('onAfterDragMove', () => {
      this.update();
    });

    this._oldLeftValue = this.screw.leftLength; // maybe useless now check
    this._oldRightValue = this.screw.rightLength; // maybe useless now check
  }

  /**
   * Update labels
   */
  public update(): void {
    const leftDiff = this.screw.leftLength - this._oldLeftValue;
    if (leftDiff === 0) {
      const rightDiff = this.screw.rightLength - this._oldRightValue;
      this.labelRight.update(rightDiff);
    } else {
      this.labelRight.update(leftDiff);
    }
    this.labelRight.setParam('text', this.screw.length);

    this.dispatchEvent({ type: ScrewEvents.updated });
  }

  public dispose(): void {
    super.dispose();
    this.screw.dispose();
    this.labelRight.dispose();
  }
}

import { MeasuresController as MeasuresControllerBase, ToolEvents, ViewType } from "@ortho-next/nextray-core";
import { BoneTypeEnum } from "../../app/core/repositories/models/case";
import { AppModel, MeasuresRPM } from "../Models/AppModel";
import { bindedModel } from "../Models/BindedModel";
import { Main } from "./Main";
import { MeasuresCalculator } from "./MeasuresCalculator";

/**
 * Binds measures calculations.
 */
export class MeasuresController extends MeasuresControllerBase {
  public measuresCalculator: MeasuresCalculator;
	protected _main: Main;
	protected _model: AppModel;

	constructor(main: Main) {
		super(main, MeasuresCalculator);
	}

  protected updateMeasureInModel<K extends keyof MeasuresRPM>(...propertiesName: K[]): void {
    for (const propertyName of propertiesName) {
      this._model.rpmMeasures[propertyName] = this.measuresCalculator[propertyName];
    }
  }

  /**
   * Binds rpm measures calculations.
   */
  public bindRPM(viewType: ViewType): void {
    viewType == ViewType.AP ? this.bindRPM_AP() : this.bindRPM_LT();
  }

  private bindRPM_AP(): void {
    const eocPlane = this._main.toolsAP.EoCPlane;
    const boneType = this._main.caseModel.boneType;

    eocPlane.addEventListener(ToolEvents.updated, () => {
      this.updateMeasureInModel(
        "rpm_ap_secondaryTransl",
        "rpm_ap_axialLength",
        "rpm_ap_cortexLength",
        "rpm_ap_angle"
      );
    });

    if (boneType === BoneTypeEnum.LongLeg) {
      eocPlane.addEventListener(ToolEvents.updated, () => {
        this.updateMeasureInModel(
          "rpm_ap_MAD",
          "rpm_ap_JLCA",
          "rpm_ap_kneeTranslation",
          "rpm_ap_fullLength"
        );
      });
    }

    if (boneType === BoneTypeEnum.Femur || boneType === BoneTypeEnum.LongLeg) {
      eocPlane.addEventListener(ToolEvents.updated, () => {
        this.updateMeasureInModel(
          "rpm_ap_LPFA",
          "rpm_ap_mLDFA",
          "rpm_ap_MNSA",
          "rpm_ap_MPFA",
          "rpm_ap_aLDFA",
          "rpm_ap_femurLength"
        );
      });
    }

    if (boneType === BoneTypeEnum.Tibia || boneType === BoneTypeEnum.LongLeg) {
      eocPlane.addEventListener(ToolEvents.updated, () => {
        this.updateMeasureInModel(
          "rpm_ap_MPTA",
          "rpm_ap_LDTA",
          "rpm_ap_tibiaLength"
        );
      });
    }

  }

  private bindRPM_LT(): void {
    const eocPlane = this._main.toolsLT.EoCPlane;
    const boneType = bindedModel.boneTypeLT;

    eocPlane.addEventListener(ToolEvents.updated, () => {
      this.updateMeasureInModel(
        "rpm_lt_secondaryTransl",
        "rpm_lt_axialLength",
        "rpm_lt_cortexLength",
        "rpm_lt_angle"
      );
    });

    if (boneType === BoneTypeEnum.LongLeg) {
      eocPlane.addEventListener(ToolEvents.updated, () => {
        this.updateMeasureInModel(
          "rpm_lt_kneeTranslation"
        );
      });
    }

    if (boneType === BoneTypeEnum.Femur || boneType === BoneTypeEnum.LongLeg) {
      eocPlane.addEventListener(ToolEvents.updated, () => {
        this.updateMeasureInModel(
          "rpm_lt_PDFA"
        );
      });
    }

    if (boneType === BoneTypeEnum.Tibia || boneType === BoneTypeEnum.LongLeg) {
      eocPlane.addEventListener(ToolEvents.updated, () => {
        this.updateMeasureInModel(
          "rpm_lt_ADTA",
          "rpm_lt_PPTA",
        );
      });
    }

  }

}

import { DraggablePoint, SelectedApexMech, VectorUtils } from "@ortho-next/nextray-core";
import { CalculationPoint } from "@ortho-next/nextray-core/Tools/Primitive/CalculationPoint";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { bindedModel } from "../../Models/BindedModel";
import { Consts } from "../../Utils/Consts";
import { MechanicalAxisAP } from "../DeformityAnalyzer/FullAnalyzerAP";
import { ClonedMechanicalAxisManagerBase } from "./ClonedMechanicalAxisManagerBase";
import { CropVertices } from "./EoCPlaneBase";

export class ClonedMechanicalAxisManager extends ClonedMechanicalAxisManagerBase {
  public internalPoints: Vector3[] = [];
  public internalPointsName: string[] = [];

  /**
   * Sets the points that will be translated with eoc.
   */
  public updatePointsToUpdate(): void {
    this._pointsToUpdate = [];
    this._pointsNameToUpdate = [];
    const points = [];
    const selApex = bindedModel.selectedApex;

    if (this.obj instanceof MechanicalAxisAP) {
      const femur = this.obj.femur;
      const tibia = this.obj.tibia;

      if (selApex === SelectedApexMech.femurProximal || selApex === SelectedApexMech.femurDistal) {
        points.push(femur.mechanical.GT, femur.mechanical.FH);
        points.push(
          femur.anatomical.NS_GT_lower_A,
          femur.anatomical.NS_GT_lower_B,
          femur.anatomical.NS_GT_lower_C,
          femur.anatomical.NS_GT_upper_A,
          femur.anatomical.NS_GT_upper_B,
          femur.anatomical.NS_GT_upper_C,
          femur.anatomical.NS_FH_A,
          femur.anatomical.NS_FH_B,
          femur.anatomical.NS_FH_C,
          femur.anatomical.NS_FH_PointForLine,
          femur.anatomical.NS_GT_PointForLineUpper
        );
      }
      if (selApex === SelectedApexMech.tibiaProximal) {
        points.push(tibia.LA, tibia.MA);
      }

    } else {
      const femur = this.obj.femur;
      const tibia = this.obj.tibia;

      if (selApex === SelectedApexMech.femurProximal || selApex === SelectedApexMech.femurDistal) {
        points.push(femur.FH);
      } else if (selApex === SelectedApexMech.tibiaProximal) {
        points.push(tibia.AA, tibia.PA);
      }

    }

    for (const obj of points) {
      this._pointsToUpdate.push(obj);
      this._pointsNameToUpdate.push(obj.name);
    }
  }

  public updatedWeightBearingVertices(): void {
    if (this.obj instanceof MechanicalAxisAP) {
      this.obj.updatedWeightBearingVertices(bindedModel.selectedApex);
    }
  }

  public onResetAP(): void {
    const mech = this.obj as MechanicalAxisAP;
    const oldMech = this._originalObj as MechanicalAxisAP;

    mech.CH.position.copy(oldMech.CH.position);
    mech.CHLabel.position.copy(oldMech.CHLabel.position);
    mech.CHLabel.label.position.copy(oldMech.CHLabel.label.position);
    mech.weightBearing.v2 = oldMech.weightBearing.v2;
  }

  public onResetLT(): void {

  }

  /**
   * Returns an array with all points.
   */
  public get allPoints(): (DraggablePoint | CalculationPoint)[] {
    const points: (DraggablePoint | CalculationPoint)[] = [];
    if (this.obj instanceof MechanicalAxisAP) {
      const femur = this.obj.femur;
      const tibia = this.obj.tibia;
      femur && points.push(
        femur.mechanical.GT,
        femur.mechanical.FH,
        femur.mechanical.LE,
        femur.mechanical.ME,
        femur.anatomical.NS_GT_lower_A,
        femur.anatomical.NS_GT_lower_B,
        femur.anatomical.NS_GT_lower_C,
        femur.anatomical.NS_GT_upper_A,
        femur.anatomical.NS_GT_upper_B,
        femur.anatomical.NS_GT_upper_C,
        femur.anatomical.NS_FH_A,
        femur.anatomical.NS_FH_B,
        femur.anatomical.NS_FH_C,
        femur.anatomical.NS_FH_PointForLine,
        femur.anatomical.NS_GT_PointForLineUpper
      );
      tibia && points.push(
        tibia.LP,
        tibia.MP,
        tibia.LA,
        tibia.MA
      );
    } else {
      const femur = this.obj.femur;
      const tibia = this.obj.tibia;
      femur && points.push(
        femur.FH,
        femur.AE,
        femur.PE
      );
      tibia && points.push(
        tibia.AP,
        tibia.PP,
        tibia.AA,
        tibia.PA
      );
    }
    return points;
  }

  /**
   * Sets parameters to crop polygon.
   */
  public updateInternalPoints(cropVertices: CropVertices): void {
    this.internalPoints = [];
    this.internalPointsName = [];
    const points: (DraggablePoint | CalculationPoint)[] = this.allPoints;

    const vertices = [cropVertices.A, cropVertices.B, cropVertices.C];
    cropVertices.numOfVertices > 3 && vertices.push(cropVertices.D);
    cropVertices.numOfVertices > 4 && vertices.push(cropVertices.E);

    for (let i = 0; i < points.length; i++) {
      if (VectorUtils.isPointInsidePolygon(points[i].position, Consts.planeNormal, ...vertices)) {
        this.internalPointsName.push(points[i].name);
        this.internalPoints.push(new Vector3().copy(points[i].position));
      }
    }

  }

}

import { BindedModel } from '../Models/BindedModel';
import { BlockingScrewManager } from '../Tools/BlockingScrew/BlockingScrewManager';
import { MechanicalAxisAP } from '../Tools/DeformityAnalyzer/FullAnalyzerAP';
import { MechanicalAxisLT } from '../Tools/DeformityAnalyzer/FullAnalyzerLT';
import { Tools as ToolsBase } from "@ortho-next/nextray-core/Core/Tools";
import { CropPolygonTool } from '../Tools/EoCPlane/CropPolygonTool';
import { EoCPlane } from '../Tools/EoCPlane/EoCPlane';
import { FitboneManager } from '../Tools/FitBone/FitBoneManager';
import { Grid } from '../Tools/Grid';
import { InsertionPoint } from '../Tools/InsertionPoint/InsertionPoint';
import { InsertionPointManager } from '../Tools/InsertionPoint/InsertionPointManager';
import { Osteotomy } from '../Tools/Osteotomy/Osteotomy';
import { OsteotomyAP } from '../Tools/Osteotomy/OsteotomyAP';
import { OsteotomyLT } from '../Tools/Osteotomy/OsteotomyLT';
import { ReferencePoint } from '../Tools/ReferencePoint';
import { ViewType } from '@ortho-next/nextray-core';
import { DiaphysisAnatomicalAxis } from '../Tools/DiaphysisAnatomicalAxis';

/**
 * Group that contains all tools.
 */
export abstract class Tools extends ToolsBase {
  public abstract viewType: ViewType;
  public abstract osteotomy: Osteotomy;
  public fitBoneManager: FitboneManager;
  public referencePoint: ReferencePoint;
  public EoCPlane: EoCPlane;
  public cropPolygonTool: CropPolygonTool;
  public blockingScrews: BlockingScrewManager[] = [];
  public grid: Grid;
  public insertionPointManager: InsertionPointManager;
  public diaphysisAA: DiaphysisAnatomicalAxis;
  /**
   * Get the cloned mechanical axis moves togheter with EoCPlane.
   */
  public abstract clonedMechanicalAxis: MechanicalAxisAP | MechanicalAxisLT;
  /**
   * Get the cloned osteotomy moves togheter with EoCPlane.
   */
  public abstract clonedOsteotomy: OsteotomyAP | OsteotomyLT;
  /**
   * Get the insertion point.
   */
  public abstract get insertionPoint(): InsertionPoint;

  constructor() {
    super();
    this.name = 'Tools';

    this.bindProperty('isEnabled', (m: BindedModel) => {
      return !m.isViewerVisible;
    }, ['isViewerVisible']);

  }
}

/**
 * Group that contains all AP tools.
 */
export class ToolsAP extends Tools {
  public viewType = ViewType.AP;
  public mechanicalAxis: MechanicalAxisAP;
  public contralateralMechanicalAxis: MechanicalAxisAP;
  public osteotomy: OsteotomyAP;

  public get clonedMechanicalAxis(): MechanicalAxisAP {
    return this.EoCPlane && this.EoCPlane.clonedMechanicalAxis as MechanicalAxisAP;
  }

  public get clonedOsteotomy(): OsteotomyAP {
    return this.EoCPlane && this.EoCPlane.clonedOsteotomy as OsteotomyAP;
  }

  public get insertionPoint(): InsertionPoint {
    return this.insertionPointManager && this.insertionPointManager.insertionPoint;
  }
}

/**
 * Group that contains all lateral tools.
 */
export class ToolsLT extends Tools {
  public viewType = ViewType.LT;
  public mechanicalAxis: MechanicalAxisLT;
  public contralateralMechanicalAxis: undefined;
  public osteotomy: OsteotomyLT;

  public get clonedMechanicalAxis(): MechanicalAxisLT {
    return this.EoCPlane && this.EoCPlane.clonedMechanicalAxis as MechanicalAxisLT;
  }

  public get clonedOsteotomy(): OsteotomyLT {
    return this.EoCPlane && this.EoCPlane.clonedOsteotomy as OsteotomyLT;
  }

  public get insertionPoint(): InsertionPoint {
    return this.insertionPointManager && this.insertionPointManager.insertionPoint;
  }
}

import { MeasuresRPM } from "../Models/AppModel";
import { FormatUtils } from "../Utils/FormatUtils";
import { Main } from "./Main";
import { bindedModel } from "../Models/BindedModel";
import { SelectedApexMech, MeasuresCalculator as MeasuresCalculatorBase } from "@ortho-next/nextray-core";

/**
 * Calculates measures.
 */
export class MeasuresCalculator extends MeasuresCalculatorBase implements MeasuresRPM {
  protected _main: Main;

  public get rpm_ap_LPFA(): number {
    return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.femur.LPFA.value) : 0;
  }

  public get rpm_ap_mLDFA(): number {
    return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.femur.mLDFA.value) : 0;
  }

  public get rpm_ap_MPTA(): number {
    return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.tibia.MPTA.value) : 0;
  }

  public get rpm_ap_LDTA(): number {
    return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.tibia.LDTA.value) : 0;
  }

  public get rpm_ap_JLCA(): number {
    return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.JLCA.value) : 0;
  }

  public get rpm_ap_MAD(): number {
    return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.distance(this._main.toolsAP.clonedMechanicalAxis.MAD.value) : 0;
  }

  public get rpm_ap_MNSA(): number {
    return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.femur.MNSA.value) : 0;
  }

  public get rpm_ap_MPFA(): number {
    return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.femur.MPFA.value) : 0;
  }

  public get rpm_ap_aLDFA(): number {
    return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.femur.aLDFA.value) : 0;
  }

  public get rpm_ap_kneeTranslation(): number {
    return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.distance(this._main.toolsAP.clonedMechanicalAxis.kneeTranslation.value) : 0;
  }

  public get rpm_ap_fullLength(): number {
    return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.distance(this._main.toolsAP.clonedMechanicalAxis.length.value) : 0;
  }

  public get rpm_ap_femurLength(): number {
    return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.distance(this._main.toolsAP.clonedMechanicalAxis.femur.length.value) : 0;
  }

  public get rpm_ap_tibiaLength(): number {
    return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.distance(this._main.toolsAP.clonedMechanicalAxis.tibia.length.value) : 0;
  }

  public get rpm_lt_PDFA(): number {
    return this._main.toolsLT.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsLT.clonedMechanicalAxis.femur.PDFA.value) : 0;
  }

  public get rpm_lt_PPTA(): number {
    return this._main.toolsLT.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsLT.clonedMechanicalAxis.tibia.PPTA.value) : 0;
  }

  public get rpm_lt_ADTA(): number {
    return this._main.toolsLT.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsLT.clonedMechanicalAxis.tibia.ADTA.value) : 0;
  }

  public get rpm_lt_kneeTranslation(): number {
    return this._main.toolsLT.clonedMechanicalAxis ? FormatUtils.distance(this._main.toolsLT.clonedMechanicalAxis.kneeTranslation.value) : 0;
  }

  public get rpm_ap_axialLength(): number {
    const signToFix = (bindedModel.isFitboneInserted && bindedModel.isStrokeLocked && bindedModel.selectedApex !== SelectedApexMech.femurProximal) ? -1 : 1;
    return this._main.toolsAP.EoCPlane ? FormatUtils.distance(this._main.toolsAP.EoCPlane.getVerticalTranslation()) * signToFix: 0;
  }

  public get rpm_ap_cortexLength(): number {
    return this._main.toolsAP.EoCPlane ? FormatUtils.distance(this._main.toolsAP.EoCPlane.cortexLength) : 0;
  }

  public get rpm_ap_angle(): number {
    return this._main.toolsAP.EoCPlane ? FormatUtils.angleRadiants(this._main.toolsAP.EoCPlane.angle) : 0;
  }

  public get rpm_ap_secondaryTransl(): number {
    const signToFix = bindedModel.selectedApex !== SelectedApexMech.tibiaProximal ? -1 : 1;
    return this._main.toolsAP.EoCPlane ? FormatUtils.distance(this._main.toolsAP.EoCPlane.horizontalTranslation) * signToFix : 0;
  }

  public get rpm_lt_axialLength(): number {
    const signToFix = (bindedModel.isFitboneInserted && bindedModel.isStrokeLocked && bindedModel.selectedApex !== SelectedApexMech.femurProximal) ? -1 : 1;
    return this._main.toolsLT.EoCPlane ? FormatUtils.distance(this._main.toolsLT.EoCPlane.getVerticalTranslation()) * signToFix : 0;
  }

  public get rpm_lt_cortexLength(): number {
    return this._main.toolsLT.EoCPlane ? FormatUtils.distance(this._main.toolsLT.EoCPlane.cortexLength) : 0;
  }

  public get rpm_lt_angle(): number {
    return this._main.toolsLT.EoCPlane ? FormatUtils.angleRadiants(this._main.toolsLT.EoCPlane.angle) : 0
  }

  public get rpm_lt_secondaryTransl(): number {
    const signToFix = bindedModel.selectedApex !== SelectedApexMech.tibiaProximal ? -1 : 1;
    const signToFix2 = (bindedModel.isFitboneInserted && bindedModel.isStrokeLocked && bindedModel.selectedApex !== SelectedApexMech.femurProximal) ? -1 : 1;
    return this._main.toolsLT.EoCPlane ? FormatUtils.distance(this._main.toolsLT.EoCPlane.horizontalTranslation) * signToFix * signToFix2 : 0;
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '../../core';
import { BaseComponent } from '../base-component';


/**
* Comfirmation component configurations
*/
export interface ComfirmationConfig {
  title?: string,
  message?: string,
  isCancelBtn?: boolean
  cancelBtn?: string,
  confirmBtn?: string
}


/**
* Modal component to confirm an action
*/
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styles: []
})
export class ConfirmationComponent extends BaseComponent implements OnInit {

  @Input() config: ComfirmationConfig;

  constructor(private langSrv: LanguageService, public activeModal: NgbActiveModal) {
    super(langSrv);
  }

  ngOnInit() {
    this.config = Object.assign({ isCancelBtn: true }, this.config);
  }

}

import { BufferGeometry, DirectionalLight, Euler, Group, Matrix4, Mesh, MeshPhongMaterial, Object3D, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { AMFLoader } from "@ortho-next/three-base/three.js/examples/jsm/loaders/AMFLoader";
import { Screw } from "../../../app/core/repositories/models/screw";
import { environment } from "../../../environments/environment";
import { FitboneConfig as Config } from '../../Config/FitboneConfig';
import { BindedModel, bindedModel } from "../../Models/BindedModel";
import { PrintStateTypes } from "../../States/State";
import { Consts } from "../../Utils/Consts";
import { FormatUtils } from "../../Utils/FormatUtils";
import { MechanicalAxisAP } from "../DeformityAnalyzer/FullAnalyzerAP";
import { MechanicalAxisLT } from "../DeformityAnalyzer/FullAnalyzerLT";
import { EoCPlane } from "../EoCPlane/EoCPlane";
import { InsertionPoint } from "../InsertionPoint/InsertionPoint";
import { FitBoneExternal } from "./FitBoneExternal";
import { FitBoneInternal } from "./FitBoneInternal";
import { ScrewEvents, ScrewManager } from "./ScrewManager";
import { CalculationPoint } from "@ortho-next/nextray-core/Tools/Primitive/CalculationPoint";
import { DraggablePoint, MechanicalAxisFemurAP, Save, SaveChild, SelectedApexMech, ToolEvents, ToolToSave, ToolType, VectorUtils, ViewType } from "@ortho-next/nextray-core";
import { LinePointToPoint2 } from "@ortho-next/nextray-core/Tools/Primitive/LinePointToPoint2";
import { MechanicalAxisTibiaAP } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/TibiaAnalyzerAP";
import { MechanicalAxisTibiaLT } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/TibiaAnalyzerLT";
import { DiaphysisAnatomicalAxis } from "../DiaphysisAnatomicalAxis";
import { MechanicalAxisFemurLT } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/FemurAnalyzerLT";
import { FitboneMesh } from "src/nextray/Models/AppModel";

export interface FitboneState {
  geometryExt?: BufferGeometry;
  geometryInt?: BufferGeometry;
  screwsExt?: ScrewManager[];
  screwsInt?: ScrewManager[];
  rotationExt?: Euler;
  positionExt?: Vector3;
  positionInt?: Vector3;
  minLInt?: number;
  maxLInt?: number;
  fullLInt?: number;
  fullLExt?: number;
  direction?: Vector3;
  distanceDragPoint?: number;
  diameter?: number;
  lengthening?: number;
  labelPosition?: Vector3;
}

export class FitboneManager extends Group implements ToolToSave {
  public toolType = ToolType.fitBone;
  @SaveChild("position", "rotation") public external: FitBoneExternal;
  @Save("position") public rotationPointExternal: DraggablePoint;
  @Save("position") public rotationPointInternal: DraggablePoint;
  @Save("position") public boxPointA: CalculationPoint;
  @Save("position") public boxPointB: CalculationPoint;
  @Save("position") public boxPointC: CalculationPoint;
  @Save("position") public boxPointD: CalculationPoint;
  public boxLine1: LinePointToPoint2;
  public boxLine2: LinePointToPoint2;
  @Save() public direction = new Vector3();
  public isSyncEnabled: boolean;
  private _viewType: ViewType;
  private _startPoint = new Vector3();
  private _lastAngleApplied = 0;
  private _emptyVector = new Vector3();
  private _distanceDragPoint = 20;
  private _diameter = 11;
  private _stateToRestore: FitboneState = {};
  private _isRestoring: boolean;
  private directionalLight: any;
  @Save() private _axisPoint1: Vector3;
  @Save() private _axisPoint2: Vector3;
  @Save() private _axisPerp: Vector3;
  @Save() private _axisDir: Vector3;
  private _insertionPoint: InsertionPoint;
  private _perpTranslation: Vector3;

  private _eocPlaneSyncFunc = (args: any) => {
    if (bindedModel.isFitboneInserted && bindedModel.isStrokeLocked) {
      const prevCenter = args.rotationCenter.clone() as Vector3; //todo remove cast after insert type
      this.external.rotateOnWorldAxis(Consts.planeNormal, args.angle);
      this.direction.applyAxisAngle(Consts.planeNormal, args.angle);
      const newCenter = prevCenter.clone().sub(this.external.position).applyAxisAngle(Consts.planeNormal, args.angle).add(this.external.position);
      this.external.position.add(prevCenter.sub(newCenter));
      this.update();
    }
  }

  public get rotationCenterExternal(): Vector3 {
    this.updateMatrixWorld(true);
    return VectorUtils.getWorldCenter(this.external).add(this.direction.clone().setLength(this.external.fullLength / -2)).setZ(0);
  }

  public get rotationCenterInternal(): Vector3 {
    this.updateMatrixWorld(true);
    return VectorUtils.getWorldCenter(this.external).add(this.direction.clone().setLength(this.external.fullLength / 2 + this.internal.lengthening + this.internal.minLength)).setZ(0);
  }

  public get internal(): FitBoneInternal {
    return this.external.internal;
  }

  public onAfterRestore = () => {
    this.dispatchEvent({ type: ToolEvents.onAfterRestore });
    this.updatePerpTranslation();
  }

  public onBeforeRestore = () => {
    this._isRestoring = true;
  }

  /**
   * Gets the distance to reference points used to sync both views.
   */
  public get distanceToReferencePoint(): number {
    const intersectionOsteotomyOnAxis = VectorUtils.lines2DIntersection(this._axisPoint1, this._axisPoint2, this.external.position.clone().setZ(0), this.external.position.clone().add(this._axisPerp).setZ(0)); //todo project
    const sign = VectorUtils.computeSign(this.external.position, this._insertionPoint.position, this._axisDir);
    return this._insertionPoint.position.clone().setZ(0).distanceTo(intersectionOsteotomyOnAxis) * sign;
  }
  /**
  * Sets the distance to reference points used to sync both views.
  */
  public set distanceToReferencePoint(value: number) {
    this.external.position.copy(this._insertionPoint.position.clone().setZ(0).add(this._axisDir.clone().setLength(value)).add(this._perpTranslation));
    this.update();
  }

  constructor(viewType: ViewType, EoCPlane: EoCPlane, insertionPoint: InsertionPoint, sightMatrix?: Matrix4, sideMatrix?: Matrix4) {
    super();
    this.name = "FitBoneManager";
    this._viewType = viewType;
    this.position.z = 350;
    this._insertionPoint = insertionPoint;

    this.add(
      this.external = new FitBoneExternal(viewType, sightMatrix, sideMatrix),
      this.rotationPointExternal = new DraggablePoint("rotationPointExternal", viewType, 0xff0000),
      this.rotationPointInternal = new DraggablePoint("rotationPointInternal", viewType, 0xff0000),
      this.boxPointA = new CalculationPoint("boxA"),
      this.boxPointB = new CalculationPoint("boxB"),
      this.boxPointC = new CalculationPoint("boxC"),
      this.boxPointD = new CalculationPoint("boxD"),
      this.boxLine1 = new LinePointToPoint2("boxLine1", viewType, Config.boxLine_color, this.boxPointA, this.boxPointB, Config.boxLine_gapSize, Config.boxLine_dashSize),
      this.boxLine2 = new LinePointToPoint2("boxLine1", viewType, Config.boxLine_color, this.boxPointC, this.boxPointD, Config.boxLine_gapSize, Config.boxLine_dashSize),
      this.directionalLight = new DirectionalLight(0xffffff, 2)
    );
    this.bindEvents(EoCPlane);

    this.directionalLight.position.set(1000, -1000, 500);

    this.bindProperty('visible', (m: BindedModel) => {
      return m.isFitboneInserted && m.layer_fitbone
        && (m.printState === PrintStateTypes.none || m.printState === PrintStateTypes.closeFitboneLock || m.printState === PrintStateTypes.openFitboneLock);
    }, ['isFitboneInserted', 'layer_fitbone', 'printState']);

    this.rotationPointInternal.bindProperty("isEnabled", (m: BindedModel) => !m.isStrokeLocked, ["isStrokeLocked"]);
  }

  private bindEvents(EoCPlane: EoCPlane): void {
    this.bindEvent("onAfterDragMove", () => { this.update(); this.updatePerpTranslation(); });
    this.rotationPointExternal.bindEvent("onDragStart", (obj) => { !bindedModel.isStrokeLocked && this.initRotationData(obj) });
    this.rotationPointInternal.bindEvent("onDragStart", (obj) => { !bindedModel.isStrokeLocked && this.initRotationData(obj) });
    this.rotationPointExternal.bindEvent("onDragMove", (args) => { !bindedModel.isStrokeLocked && this.handleRotationPoint(args, "rotationCenterInternal") });
    this.rotationPointInternal.bindEvent("onDragMove", (args) => { !bindedModel.isStrokeLocked && this.handleRotationPoint(args, "rotationCenterExternal") });
    this.rotationPointExternal.bindEvent("onPositionComponentChange", () => this.updateBox());
    this._viewType === ViewType.AP && this.bindEvent("onRotationComponentChange", () => this.calculateDefaultLength(EoCPlane));
    this.internal.bindEvent("onPositionComponentChange", () => !this._isRestoring && this.update());
  }

  /**
   * Download and load Fitbone STL from server, creating mesh and screws.
   */
  public async loadSTL(fitboneMesh: FitboneMesh, eocPlane: EoCPlane, diaphysisAnatomicalAxis: DiaphysisAnatomicalAxis, siteMatrix: Matrix4): Promise<void> {
    await this.loadSTLAsync(fitboneMesh, siteMatrix);
    if (!this._isRestoring) {
      this.external.lengthsToRestore = []
      this.external.anchorsToRestore = []
      this.internal.lengthsToRestore = []
      this.internal.anchorsToRestore = []
    }

    if (this._viewType === ViewType.AP) {
      this.bindAndRestoreScrews(this.external);
      this.bindAndRestoreScrews(this.internal);
    }
    this._distanceDragPoint = fitboneMesh.gripPointDistance;
    this._diameter = fitboneMesh.diameter;
    eocPlane.addEventListener("rotated", this._eocPlaneSyncFunc);

    if (!this._isRestoring) {
      this.internal.lengthening = 0;
      this.direction.copy(VectorUtils.getWorldCenter(this.external).setZ(0).normalize().multiplyScalar(bindedModel.selectedApex === SelectedApexMech.tibiaProximal ? -1 : 1));
      const worldLabelPosition = this.rotationCenterInternal.sub(this.direction.clone().setLength(this.internal.minLength)).add(Consts.horDir.clone().setLength(15)).setZ(50);
      this.external.stretchLabel.anchor = VectorUtils.getWorldToLocalOrientation(this.external, worldLabelPosition);
      this.calculateDefaultLength(eocPlane);
      this.applyDefaultTranslation(eocPlane, diaphysisAnatomicalAxis);
      this.applyDefaultRotation(eocPlane, diaphysisAnatomicalAxis);
      this.update();
      this.triggerEvent("onRotationComponentChange"); //to update eocPlane.fitboneDirecton TODO FIX IN RESTORE
    } else {
      this.external.stretchLabel.setParam("text", FormatUtils.roundedNumber(this.internal.lengthening).toString()); //fare funzone e unirla così non va bene
    }

    this._isRestoring = false;
  }

  private async loadSTLAsync(fitboneMesh: FitboneMesh, siteMatrix: Matrix4): Promise<void> {
    return new Promise<void>((resolve, reject) => { //todo refactor with promise.all
      this.deleteGeometry();
      !this._isRestoring && this.external.updateRotation(siteMatrix);
      let endedCount = 0;

      new AMFLoader().load(`${environment.cdnUrl}/templates/fitbone/${fitboneMesh.fileNameExt}`, (group: Group) => { // make AMFLoader static e evitare di farlo caricare due volte
        this.external.setGeometryAndScrews(this.getFitboneGeometry(group), fitboneMesh);
        this.removeWrongIds(group, fitboneMesh.extScrewMeshIds);
        this.external.addScrews(this.createScrews(group, this._viewType == ViewType.AP, fitboneMesh.proximalScrewList));
        ++endedCount == 2 && resolve();
      });

      new AMFLoader().load(`${environment.cdnUrl}/templates/fitbone/${fitboneMesh.fileNameInt}`, (group: Group) => { // make AMFLoader static e evitare di farlo caricare due volte
        this.internal.setGeometry(this.getFitboneGeometry(group), fitboneMesh);
        this.removeWrongIds(group, fitboneMesh.intScrewMeshIds);
        this.internal.addScrews(this.createScrews(group, this._viewType == ViewType.AP, fitboneMesh.distalScrewList));
        ++endedCount == 2 && resolve();
      });
    });
  }

  private bindAndRestoreScrews(screwsParent: FitBoneExternal | FitBoneInternal) {
    for (let i = 0; i < screwsParent.screws.length; i++) {
      const screwManager = screwsParent.screws[i];

      if (this._isRestoring) {
        if (screwsParent.anchorsToRestore[i]) {
          screwManager.labelRight.anchor = screwsParent.anchorsToRestore[i];
        }

        if (screwsParent.lengthsToRestore[i]) {
          screwManager.screw.leftLength = screwsParent.lengthsToRestore[i * 2];
          screwManager.screw.rightLength = screwsParent.lengthsToRestore[i * 2 + 1];
          (screwManager as any)._oldLeftValue = screwManager.screw.leftLength;
          (screwManager as any)._oldRightValue = screwManager.screw.rightLength;
          screwManager.update();
        }
      }

      screwManager.addEventListener(ScrewEvents.updated, () => {
        //if (screwType === MeshType.nonlocking || screwType === MeshType.screw) {
        //  this.BOM[screwType == MeshType.nonlocking ? "screwNoLockingList" : "screwLockingList"][screwManager.indexBOM] = screwManager.screw.length;
        //}
        screwsParent.lengthsToRestore[i * 2] = screwManager.screw.leftLength;
        screwsParent.lengthsToRestore[i * 2 + 1] = screwManager.screw.rightLength;
      });

      screwManager.labelRight.bindEvent("onPositionComponentChange", () => {
        screwsParent.anchorsToRestore[i] = screwManager.labelRight.anchor;
      });

    }
  }

  private applyDefaultTranslation(eocPlane: EoCPlane, dAnatomicalAxis: DiaphysisAnatomicalAxis): void {
    if (this._viewType == ViewType.AP) {
      const translation = VectorUtils.getCenterWorldOrientation(this.external).add(this.direction.clone().setLength(this.external.fullLength / -2));
      if (bindedModel.selectedApex === SelectedApexMech.tibiaProximal) {
        const tibia = eocPlane.clonedMechanicalAxis.tibia as MechanicalAxisTibiaAP;
        translation.sub(tibia.CA.position.clone().sub(tibia.CP.position).setLength(5));
        this.external.position.copy(tibia.CP.position.clone().sub(translation));
      } else {
        const femur = eocPlane.clonedMechanicalAxis.femur as MechanicalAxisFemurAP;
        if (bindedModel.selectedApex === SelectedApexMech.femurProximal) {
          translation.add(femur.anatomical.NS_GT_upper_C.position.clone().sub(femur.anatomical.NS_GT_lower_C.position).setLength(5));
          this.external.position.copy(femur.mechanical.GT.position.clone().sub(translation));
        } else {
          translation.add(femur.anatomical.NS_GT_lower_C.position.clone().sub(femur.anatomical.NS_GT_upper_C.position).setLength(10));
          const point = VectorUtils.lines2DIntersection(femur.mechanical.ME.position, femur.mechanical.LE.position, femur.anatomical.NS_GT_upper_C.position, femur.anatomical.NS_GT_lower_C.position);
          this.external.position.copy(point.sub(translation));
        }
      }
    } else {
      const translation = VectorUtils.getCenterWorldOrientation(this.external).add(this.direction.clone().setLength(this.external.fullLength / -2));
      if (bindedModel.selectedApex === SelectedApexMech.tibiaProximal) {
        const tibia = eocPlane.clonedMechanicalAxis.tibia as MechanicalAxisTibiaLT;
        this.external.position.copy(tibia.FP.position.clone().sub(translation));
      } else {
        const femur = eocPlane.clonedMechanicalAxis.femur as MechanicalAxisFemurLT;
        const translation = VectorUtils.getCenterWorldOrientation(this.external).add(this.direction.clone().setLength(this.external.fullLength / -2));
        if (bindedModel.selectedApex === SelectedApexMech.femurProximal) {
          translation.add(dAnatomicalAxis.B.position.clone().sub(dAnatomicalAxis.A.position).setLength(-20));
          this.external.position.copy(femur.FH.position.clone().sub(translation));
        } else {
          translation.add(dAnatomicalAxis.A.position.clone().sub(dAnatomicalAxis.B.position).setLength(-20));
          const point = VectorUtils.lines2DIntersection(femur.AE.position, femur.PE.position, dAnatomicalAxis.A.position, dAnatomicalAxis.B.position);
          this.external.position.copy(point.sub(translation));
        }
      }
    }
    this.update();
  }

  private applyDefaultRotation(eocPlane: EoCPlane, dAnatomicalAxis: DiaphysisAnatomicalAxis): void { //rifattorizza
    let dir: Vector3;
    if (this._viewType == ViewType.AP) {
      if (bindedModel.selectedApex === SelectedApexMech.tibiaProximal) {
        const tibia = eocPlane.clonedMechanicalAxis.tibia as MechanicalAxisTibiaAP;
        dir = tibia.CP.position.clone().sub(tibia.CA.position);
      } else {
        const femur = eocPlane.clonedMechanicalAxis.femur as MechanicalAxisFemurAP;
        if (bindedModel.selectedApex === SelectedApexMech.femurProximal) {
          dir = femur.anatomical.NS_GT_upper_C.position.clone().sub(femur.anatomical.NS_GT_lower_C.position);
        } else {
          dir = femur.anatomical.NS_GT_lower_C.position.clone().sub(femur.anatomical.NS_GT_upper_C.position);
        }
      }
    } else {
      if (bindedModel.selectedApex === SelectedApexMech.tibiaProximal) {
        const tibia = eocPlane.clonedMechanicalAxis.tibia as MechanicalAxisTibiaLT;
        dir = tibia.FP.position.clone().sub(tibia.MA.position);
      } else {
        if (bindedModel.selectedApex === SelectedApexMech.femurProximal) {
          dir = dAnatomicalAxis.A.position.clone().sub(dAnatomicalAxis.B.position);
        } else {
          dir = dAnatomicalAxis.B.position.clone().sub(dAnatomicalAxis.A.position);
        }
      }
    }

    this.initRotationData(this.rotationPointInternal);
    this.handleRotationPoint({
      position: this.rotationCenterExternal.sub(dir.setLength(1000)),
    }, "rotationCenterExternal"); //simulate rotation
  }

  /**
   * Calculates the default lengthening.
   */
  public calculateDefaultLength(eocPlane: EoCPlane): void {
    if (this._viewType === ViewType.AP) {
      let lengthening = 0;
      const ost = eocPlane.osteotomy;
      const clOst = eocPlane.clonedOsteotomy;
      let isValid = false;

      let projA = VectorUtils.lines2DIntersection(ost.A.position, ost.A.position.clone().add(eocPlane.fitboneDirection), clOst.A.position, clOst.B.position);
      let projB = VectorUtils.lines2DIntersection(ost.B.position, ost.B.position.clone().add(eocPlane.fitboneDirection), clOst.A.position, clOst.B.position);
      if (projA && projB) {
        const distA = projA.distanceTo(ost.A.position);
        const distB = projB.distanceTo(ost.B.position);
        if (distA < distB) {
          if (VectorUtils.isPointOnSegment(clOst.A.position, clOst.B.position, projA)) {
            lengthening = distA;
            isValid = true;
          }
        } else {
          if (VectorUtils.isPointOnSegment(clOst.A.position, clOst.B.position, projB)) {
            lengthening = distB;
            isValid = true;
          }
        }
      }

      if (!isValid) {
        projA = VectorUtils.lines2DIntersection(clOst.A.position, clOst.A.position.clone().add(eocPlane.fitboneDirection), ost.A.position, ost.B.position);
        projB = VectorUtils.lines2DIntersection(clOst.B.position, clOst.B.position.clone().add(eocPlane.fitboneDirection), ost.A.position, ost.B.position);
        if (projA && projB) {
          const distA = projA.distanceTo(clOst.A.position);
          const distB = projB.distanceTo(clOst.B.position);
          lengthening = Math.min(distA, distB);
        } else {
          lengthening = Number.MAX_SAFE_INTEGER;
        }
      }

      this.internal.lengthening = lengthening;
      this.update();
    }
  }

  /**
   * Updates internal matrix and rotation points position.
   */
  public update(): void {
    this.updateMatrixWorld(true);
    this.updateRotationPoints();
  }

  /**
   * Updates rotation points position.
   */
  public updateRotationPoints(): void {
    const translationDir = this.direction.clone().setLength(this._distanceDragPoint);
    this.rotationPointExternal.position.copy(this.rotationCenterExternal.sub(this.position)).sub(translationDir).setZ(50);
    this.rotationPointInternal.position.copy(this.rotationCenterInternal.sub(this.position)).add(translationDir).setZ(50);
  }

  private updateBox(): void {
    const dir_int = this.direction.clone().setLength(this._distanceDragPoint);
    const dir_ext = this.direction.clone().setLength(this._distanceDragPoint * 2);
    const dirPerp = VectorUtils.getPerpendicular(this.direction).clone().setLength(this._diameter / 2);
    this.boxPointA.position.copy(this.rotationPointExternal.position.clone().sub(dirPerp).add(dir_int));
    this.boxPointB.position.copy(this.rotationPointExternal.position.clone().sub(dirPerp).sub(dir_ext));
    this.boxPointC.position.copy(this.rotationPointExternal.position.clone().add(dirPerp).add(dir_int));
    this.boxPointD.position.copy(this.rotationPointExternal.position.clone().add(dirPerp).sub(dir_ext));
  }

  private initRotationData(obj: Object3D): void {
    this._startPoint.copy(obj.getWorldPosition(this._emptyVector));
    this._lastAngleApplied = 0;
  }

  private handleRotationPoint(args: { position: Vector3, preventDefault?: boolean }, rotationPointKey: string): void {
    args.preventDefault = true;
    const argsWorld = this.localToWorld(args.position);
    const angle = VectorUtils.linesAngle(argsWorld, this._startPoint, this[rotationPointKey]);
    const prevCenter = this[rotationPointKey] as Vector3;
    this.external.rotateOnWorldAxis(Consts.planeNormal, this._lastAngleApplied - angle);
    this.direction.applyAxisAngle(Consts.planeNormal, this._lastAngleApplied - angle);
    const newCenter = this[rotationPointKey] as Vector3;
    this.external.position.add(prevCenter.sub(newCenter));
    this._lastAngleApplied = angle;
  }

  public reset(): void {
    this.external.deleteGeometry();
  }

  private removeWrongIds(group: Group, ids: string): void {
    const id = ids.split(",");
    const children = group.children;
    for (let i = children.length - 1; i >= 0; i--) {
      if (id.indexOf(children[i].name) === -1) {
        group.remove(children[i]); //todo dispose material?
      }
    }
  }

  private getFitboneGeometry(group: Group): BufferGeometry {
    const countArrays = group.children.map(x => ((x.children[0] as Mesh).geometry as BufferGeometry).attributes.position.count);
    const fitboneGroup = group.children[countArrays.indexOf(Math.max(...countArrays))];
    const geometry = (fitboneGroup.children[0] as Mesh).geometry as BufferGeometry;
    ((fitboneGroup.children[0] as Mesh).material as MeshPhongMaterial).dispose();
    group.remove(fitboneGroup);
    return geometry;
  }

  private createScrews(group: Group, visible: boolean, screwsData: Screw[]): ScrewManager[] {
    const lengths: number[] = [... new Set(screwsData.map(x => x.length))].sort((a, b) => a - b); //ascendent sorted
    const screws: ScrewManager[] = [];
    for (const child of group.children) {
      const screwManager = new ScrewManager(this._viewType, child, lengths);
      screws.push(screwManager);
      screwManager.visible = visible;
    }
    return screws;
  }

  /**
   * Creates a state to restore fitbone if cancel during workflow.
   */
  public createStateToRestore(): void {
    this._stateToRestore = {
      geometryExt: this.external.geometry,
      geometryInt: this.internal.geometry,
      screwsExt: this.external.screws,
      screwsInt: this.internal.screws,
      rotationExt: this.external.rotation.clone(),
      positionExt: this.external.position.clone(),
      positionInt: this.internal.position.clone(),
      minLInt: this.internal.minLength,
      maxLInt: this.internal.maxLength,
      fullLInt: this.internal.fullLength,
      fullLExt: this.external.fullLength,
      direction: this.direction.clone(),
      distanceDragPoint: this._distanceDragPoint,
      diameter: this._diameter,
      lengthening: this.internal.lengthening,
      labelPosition: this.external.stretchLabel.position.clone()
    };
  }

  /**
   * Restores a saved fitbone state.
   */
  public confirmStateRestore(): void {
    this.deleteGeometry();
    if (this._stateToRestore.geometryExt) {
      this.external.geometry = this._stateToRestore.geometryExt;
      this.internal.geometry = this._stateToRestore.geometryInt;
      this.external.addScrews(this._stateToRestore.screwsExt);
      this.internal.addScrews(this._stateToRestore.screwsInt);
      this.external.setRotationFromEuler(this._stateToRestore.rotationExt);
      this.external.position.copy(this._stateToRestore.positionExt);

      this.internal.minLength = this._stateToRestore.minLInt;
      this.internal.maxLength = this._stateToRestore.maxLInt;
      this.internal.fullLength = this._stateToRestore.fullLInt;
      this.internal.position.copy(this._stateToRestore.positionInt);

      this.external.fullLength = this._stateToRestore.fullLExt;
      this.direction.copy(this._stateToRestore.direction);
      this._distanceDragPoint = this._stateToRestore.distanceDragPoint;
      this._diameter = this._stateToRestore.diameter;
      this.internal.lengthening = this._stateToRestore.lengthening;
      this.external.stretchLabel.setParam("text", FormatUtils.roundedNumber(this.internal.lengthening));
      this.external.stretchLabel.position.copy(this._stateToRestore.labelPosition);
      this.update();
    }
    this._stateToRestore = {};
  }

  /**
   * Deletes a state created to restore fitbone.
   */
  public cancelStateRestore(): void {
    if (this._stateToRestore.geometryExt && this._stateToRestore.geometryExt !== Consts.emptyGeometry && this._stateToRestore.geometryExt !== this.external.geometry) {
      this._stateToRestore.geometryExt.dispose();
      this._stateToRestore.geometryExt = Consts.emptyGeometry;
      this._stateToRestore.geometryInt.dispose();
      this._stateToRestore.geometryInt = Consts.emptyGeometry;
      const screws = [...this._stateToRestore.screwsExt, ...this._stateToRestore.screwsInt];
      for (const screw of screws) {
        screw.dispose();
      }
    }
    this._stateToRestore = {};
  }

  public deleteGeometry(): void {
    if (this.external.geometry !== this._stateToRestore.geometryExt) {
      this.external.deleteGeometry();
      this.internal.deleteGeometry();
      const screws = [...this.external.screws, ...this.internal.screws];
      for (const screw of screws) {
        screw.dispose();
      }
    } else {
      this.external.remove(...this.external.screws);
      this.internal.children = [];
    }
  }

  /**
  * Check if a point is inside insertion point box.
  */
  public isPointInsideBox(point: Vector3): boolean {
    const boxVertices = [this.boxPointA.position, this.boxPointB.position, this.boxPointD.position, this.boxPointC.position];
    return VectorUtils.isPointInsidePolygon(point, Consts.planeNormal, ...boxVertices);
  }

  protected setPointsForSync(p1: Vector3, p2: Vector3): void {
    this._axisPoint1 = p1.clone();
    this._axisPoint2 = p2.clone();
    this._axisDir = p1.clone().sub(p2).normalize();
    this._axisPerp = VectorUtils.getPerpendicular(this._axisDir);
  }

  private updatePerpTranslation(): void {
    if (this._axisPoint1) {
      const intersectionOsteotomyOnAxis = VectorUtils.projectOnVector(this.external.position, this._insertionPoint.position, this._insertionPoint.position.clone().add(this._axisDir));
      this._perpTranslation = this.external.position.clone().sub(intersectionOsteotomyOnAxis);
    }
  }

  /**
   * Sets default position by insertionPoint and sync translation.
   */
  public setPositionByInsertionPoint(mechanicalAxis: MechanicalAxisAP | MechanicalAxisLT, diaphysisAA?: DiaphysisAnatomicalAxis, APtransl?: number): void {
    if (mechanicalAxis instanceof MechanicalAxisAP) {
      this.setPositionByInsertionPointAP(mechanicalAxis);
    } else {
      this.setPositionByInsertionPointLT(mechanicalAxis, diaphysisAA, APtransl);
    }
  }

  private setPositionByInsertionPointAP(mechanicalAxis: MechanicalAxisAP): void {
    switch (bindedModel.selectedApex) {
      case SelectedApexMech.femurProximal:
      case SelectedApexMech.femurDistal: {
        const femur = mechanicalAxis.femur;
        const dir = femur.anatomical.NS_GT_lower_C.position.clone().sub(femur.anatomical.NS_GT_upper_C.position);
        const perpDir = VectorUtils.getPerpendicular(dir);
        const refPoint = this._insertionPoint.position.clone().setZ(0);

        const upperPoint = bindedModel.selectedApex === SelectedApexMech.femurProximal ?
          refPoint :
          VectorUtils.lines2DIntersection(refPoint, refPoint.clone().add(dir), femur.mechanical.GT.position, femur.mechanical.GT.position.clone().add(perpDir))

        const lowerPoint = bindedModel.selectedApex === SelectedApexMech.femurProximal ?
          VectorUtils.lines2DIntersection(refPoint, refPoint.clone().add(dir), femur.mechanical.CE.position, femur.mechanical.CE.position.clone().add(perpDir)) :
          refPoint;

        this.setPointsForSync(lowerPoint, upperPoint);
        break;
      }
      case SelectedApexMech.tibiaProximal: {
        const tibia = mechanicalAxis.tibia;
        this.setPointsForSync(tibia.CA.position, tibia.CP.position);
      }
    }
    this.update();
    this.updatePerpTranslation();
  }

  private setPositionByInsertionPointLT(mechanicalAxis: MechanicalAxisLT, diaphysisAA: DiaphysisAnatomicalAxis, APtransl: number): void {
    switch (bindedModel.selectedApex) {
      case SelectedApexMech.femurProximal:
      case SelectedApexMech.femurDistal: {
        const femur = mechanicalAxis.femur;
        const dir = diaphysisAA.B.position.clone().sub(diaphysisAA.A.position);
        const perpDir = VectorUtils.getPerpendicular(dir);
        const refPoint = this._insertionPoint.position.clone().setZ(0);

        const upperPoint = bindedModel.selectedApex === SelectedApexMech.femurProximal ?
          refPoint :
          VectorUtils.lines2DIntersection(refPoint, refPoint.clone().add(dir), femur.FH.position, femur.FH.position.clone().add(perpDir));

        const lowerPoint = bindedModel.selectedApex === SelectedApexMech.femurProximal ?
          VectorUtils.lines2DIntersection(refPoint, refPoint.clone().add(dir), femur.TE.position, femur.TE.position.clone().add(perpDir)) :
          refPoint;

        this.setPointsForSync(lowerPoint, upperPoint);
        break;
      }
      case SelectedApexMech.tibiaProximal: {
        const tibia = mechanicalAxis.tibia;
        this.setPointsForSync(tibia.MA.position, tibia.FP.position);
      }
    }
    this.update();
    this.updatePerpTranslation();

    this.distanceToReferencePoint = APtransl;
  }
}

<!-- Box table -->
<div class="box-table">
	<span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
		{{ labels.LLD_TABLE_TITLE }}
		<div class="fake-material-icon icon-expand expand"></div>
	</span>
	<table class="table" [ngbCollapse]="isCollapsed">
		<thead>
			<tr>
				<th></th>
				<th>{{ labels.LLD_TABLE_LENGTH_HEADER }} <br />
					({{ (isLeft ? sideEnum.Left : sideEnum.Right) | anatomicalSideLabel }})
				</th>
				<th>{{ labels.LLD_TABLE_CL_LENGTH_HEADER }} <br />
					({{ (isLeft ? sideEnum.Right : sideEnum.Left) | anatomicalSideLabel }})
				</th>
				<th>{{ labels.LLD_TABLE_LLD_HEADER }}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngIf="isLongLeg">
				<td>{{ labels.LLD_TABLE_LONGLEG }}</td>
				<td>{{ defAPMeasures?.def_ap_fullLength }}</td>
				<td>{{ defCLMeasures?.def_ap_fullLength_ctrlateral }}</td>
				<td>{{ longlegLLD }}</td>
			</tr>
			<tr *ngIf="isLongLeg || isFemur">
				<td>{{ labels.LLD_TABLE_FEMUR }}</td>
				<td>{{ defAPMeasures?.def_ap_femurLength }}</td>
				<td>{{ defCLMeasures?.def_ap_femurLength_ctrlateral }}</td>
				<td>{{ femurLLD }}</td>
			</tr>
			<tr *ngIf="isLongLeg || isTibia">
				<td>{{ labels.LLD_TABLE_TIBIA }}</td>
				<td>{{ defAPMeasures?.def_ap_tibiaLength }}</td>
				<td>{{ defCLMeasures?.def_ap_tibiaLength_ctrlateral }}</td>
				<td>{{ tibiaLLD }}</td>
			</tr>
			<tr *ngIf="isLongLeg">
				<td>{{ labels.LLD_TABLE_FEMUR_TIBIA }}</td>
				<td>{{ trueLength }}</td>
				<td>{{ trueLengthCL }}</td>
				<td>{{ trueLengthLLD }}</td>
			</tr>
		</tbody>
	</table>
</div>
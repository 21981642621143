import { CdkDrag } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Layers component draggable on canvas view
*/
@Component({
  selector: 'layers',
  templateUrl: './layers.component.html',
  styles: []
})
export class LayersComponent extends BaseComponent implements OnInit {

  isMinimized: boolean;

  // Height of layers content
  private readonly OFFSET_HEIGHT = 255;
  dragPosition: any;

  @ViewChild(CdkDrag, { static: false }) dragElement: CdkDrag;


  constructor(
    private langSrv: LanguageService,
    private canvasSrv: CanvasService
  ) {
    super(langSrv, canvasSrv);
  }

  ngOnInit() { }

  /**
  * Toggle layers content
  */
  toggle() {
    if (this.isMinimized) {
      const windowHeight = window.innerHeight;
      const bottom = this.dragElement.getRootElement().getBoundingClientRect().bottom;
      if (windowHeight - bottom < this.OFFSET_HEIGHT) {
        this.dragPosition = { x: this.dragElement.getFreeDragPosition().x, y: this.dragElement.getFreeDragPosition().y - (this.OFFSET_HEIGHT - (windowHeight - bottom)) };
      }
    }
    this.isMinimized = !this.isMinimized;
  }

  /**
  * Reset labels position.
  */
  resetLB(): void {
    this.canvasSrv.dispatch("resetLabelsPosition");
  }

  /**
  * Set labels visibility.
  */
  setVisibilityLB(val: boolean): void {
    this.canvasSrv.dispatch("setVisibility_labels", val);
  }

  /**
  * Set grid editing mode.
  */
  setEditingGR(val: boolean): void {
    this.canvasSrv.dispatch("setVisibility_grid_center", val);
  }

  /**
  * Set grid visibility.
  */
  setVisibilityGR(val: boolean): void {
    this.canvasSrv.dispatch("setVisibility_grid", val);
  }

  /**
  * Set mechanical axis visibility.
  */
  setVisibilityMA(val: boolean): void {
    this.canvasSrv.dispatch("setVisibility_mechanicalAxis_mechanical", val);
  }

  /**
   * Set anatomical axis visibility.
   */
  setVisibilityAX(val: boolean): void {
    this.canvasSrv.dispatch("setVisibility_anatomicalAxis", val);
  }

  /**
  * Set anatomical axis visibility.
  */
  setVisibilityMNSA(val: boolean): void {
    this.canvasSrv.dispatch("setVisibility_mechanicalAxis_anatomical", val);
  }

  /**
  * Set weightbearing visibility.
  */
  setVisibilityWB(val: boolean): void {
    this.canvasSrv.dispatch("setVisibility_mechanicalAxis_weightBearing", val);
  }

  /**
  * Set contralateral visibility
  */
  setVisibilityCL(val: boolean): void {
    this.canvasSrv.dispatch("setVisibility_contralateral_all", val);
  }

  /**
  * Set lines visibility
  */
  setVisibilityLN(val: boolean): void {
    this.canvasSrv.dispatch("setVisibility_lines", val);
  }

  /**
  * Set goniometers visibility
  */
  setVisibilityGM(val: boolean): void {
    this.canvasSrv.dispatch("setVisibility_goniometers", val);
  }

  /**
  * Set fitbone visibility
  */
  setVisibilityFB(val: boolean): void {
    this.canvasSrv.dispatch("setVisibility_fitbone", val);
  }

  /**
  * Set plane visibility
  */
  setVisibilityPL(val: boolean): void {
    this.canvasSrv.dispatch("setVisibility_plane", val);
  }

}

import { LayerElementsBase, AppModel as AppModelBase, SelectedApexMech, NextRayMeasures } from "@ortho-next/nextray-core";
import { Context } from "../States/Context";
import { StateDescription, StateTypes } from "../States/State";
import { PrintMeasures, PrintResult } from "../Utils/PrintUtils";
import { Fitbone } from "../../app";

export interface FitboneMesh extends Fitbone { };

export interface MeasuresRPM extends NextRayMeasures  {
  rpm_ap_LPFA?: number;
  rpm_ap_mLDFA?: number;
  rpm_ap_MPTA?: number;
  rpm_ap_LDTA?: number;
  rpm_ap_JLCA?: number;
  rpm_ap_MAD?: number;
  rpm_ap_MNSA?: number;
  rpm_ap_MPFA?: number;
  rpm_ap_aLDFA?: number;
  rpm_ap_kneeTranslation?: number;
  rpm_ap_femurLength?: number;
  rpm_ap_tibiaLength?: number;
  rpm_ap_fullLength?: number;

  rpm_lt_PDFA?: number;
  rpm_lt_PPTA?: number;
  rpm_lt_ADTA?: number;
  rpm_lt_kneeTranslation?: number;

  rpm_ap_angle?: number;
  rpm_lt_angle?: number;
  rpm_ap_cortexLength?: number;
  rpm_lt_cortexLength?: number;
  rpm_ap_axialLength?: number;
  rpm_lt_axialLength?: number;
  rpm_ap_secondaryTransl?: number;
  rpm_lt_secondaryTransl?: number;
}

export interface LayerElements extends LayerElementsBase {
  mechanicalAxis_anatomical?: boolean;
  mechanicalAxis_mechanical?: boolean;
  mechanicalAxis_weightBearing?: boolean;
  contralateral_all?: boolean;
  grid?: boolean;
  grid_center?: boolean;
  fitbone?: boolean;
  plane?: boolean;
}

export interface Messages {
  isInsPointOutBoxAP?: boolean;
  isInsPointOutBoxLT?: boolean;
  isScrewNearOstAP?: boolean;
}

/**
 * Model used by angular to create bindings. Only three.js part can set this values.
 */
export class AppModel extends AppModelBase {
  protected _context: Context;
  // public measures: NextRayMeasures; //override TODO ???
  public layers: LayerElements; //override
  public layersValue: LayerElements; //override
  public selectedApex: SelectedApexMech = SelectedApexMech.none; //override
  
  /**
   * Gets images for print report.
   */
  public printResult: PrintResult
  /**
   * Gets correction measures for print report.
   */
  public printMeasures: PrintMeasures;
  /**
   * Gets messages visibility.
   */
  public messages: Messages  = {};
  /**
   * Gets fitbone measures.
   */
  public rpmMeasures: MeasuresRPM = {};
  /**
   * Indicates if EoC cut switch is on/off.
   */
  public eocCut: boolean;
  /**
   * Indicates if the fitbone is inserted.
   */
  public isFitboneInserted: boolean;
  /**
   * Gets selected fitbone model data.
   */
  public fitbone: FitboneMesh;
  /**
   * Indicates if stroke is locked.
   */
  public isStrokeLocked: boolean;
  /**
   * Indicates if the insertion point is inserted.
   */
  public isInsPointInserted: boolean;
  /**
   * Indicates if viewer is visible.
   */
  public isViewerVisible: boolean;
  /**
   * Osteotomy level.
   */
  public osteotomyLevel: number;
  /**
   * Target leg length.
   */
  public targetLegLength: number;
  /**
   * Mechanical Axis bone(femur/tibia) lengthening by insertion approach.
   */
  public maBoneLengthening: string
  /**
   * Indicates if blocking screw is selected in AP view.
   */
  public isBlockingScrewSelectedAP: boolean
  /**
   * Indicates if blocking screw is selected in LT view.
   */
  public isBlockingScrewSelectedLT: boolean;
  /**
   * Indicates if number of blocking screws is maximum in AP view.
   */
  public isBlockingScrewFullAP: boolean;
  /**
   * Indicates if number of blocking screws is maximum in LT view.
   */
  public isBlockingScrewFullLT: boolean;
  /**
   * Indicates if app is downloading a fitbone in AP view.
   */
  public isFitboneLoadingAP: boolean;
  /**
   * Indicates if app is downloading a fitbone in lateral view.
   */
  public isFitboneLoadingLT: boolean;

  //override
  public get stateType(): StateTypes {
    return super.stateType;
  }
  public get stateDescription(): StateDescription {
    return super.stateDescription;
  }
}

import { Patient } from "./patient-base";


/**
* Case model
*/
export interface Case {
  id: string;
  patientId: string;
  boneType: BoneTypeEnum;
  version: string;
  status: string;
  number: string;
  name?: string;
  userGuid: string;
  type: PlanTypeEnum;
  referenceType: ReferenceTypeEnum;
  side: AnatomicalSideEnum;
  isPostOperative: boolean;
  surgeryDate?: Date;
  notes?: string;
  apImageGuid?: string;
  ltImageGuid?: string;
  isPublished: boolean;
  isReceived?: boolean;
  patient: Patient;
  frameID?: string;
}

export enum AnatomicalSideEnum {
  Left = "Left",
  Right = "Right"
}

export enum BoneTypeEnum {
  LongLeg = "Long Leg",
  Femur = "Femur",
  Tibia = "Tibia"
}

export enum BoneTypeMinEnum {
  Femur = "Femur",
  Tibia = "Tibia"
}

export enum ReferenceTypeEnum {
  Proximal = "Proximal",
  Distal = "Distal"
}

export enum PlanTypeEnum {
  Deformity = "Deformity",
  Fracture = "Fracture"
}

export enum BoneViewEnum {
  AP = "AP",
  LAT = "LAT"
}

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { ImageCompression, jsPDF } from 'jspdf';
import { take, takeWhile, timer } from 'rxjs';
import { TitilliumWeb } from '../../assets/fonts';
import { LanguageService, LoaderService } from '../core';
import { CanvasService } from './canvas.service';


interface PrintSizes {
	canvasWidth: number;
	canvasHeight: number;
	imgWidth: number;
	imgHeight: number;
}


/**
* This service handles the PDF print of current ray analysis.
*/
@Injectable()
export class PrinterService {
	/**
	 * PDF permissions accessible by any user.
	 */
	private readonly pdfUserPermissions = ['print' as const];
	private readonly labels: any;
	private readonly pdfCompression: ImageCompression = 'MEDIUM';

	constructor(
		private router: Router,
		private canvasSrv: CanvasService,
		private location: Location,
		private loadSrv: LoaderService,
		private langSrv: LanguageService
	) {
		this.labels = langSrv.labels;
	}

	/**
	 * Print OR Plan PDF.
	 */
	printORPlan(): void {
		this.loadSrv.show();
		this.canvasSrv.saveState();
		const sizes = this.getSizesByFormat("a3"); //todo refactor?
		this.canvasSrv.dispatch('generateImagesToPrint', { w: sizes.canvasHeight, h: sizes.canvasWidth });
		this.canvasSrv.dispatch('updateMeausuresOnlyPrint');
		this.router.navigate([
			'/',
			{ outlets: { 'print': ['or-plan'] } }
		]);
	}

	/**
	 * Generate PDF when OR Plan data is ready.
	 */
	async onORPlanDataReady(pages: NodeListOf<HTMLElement>) {
		const format = 'a3';
		const sizes = this.getSizesByFormat(format);
		const pdfOwnerPassword = Buffer.from(crypto.getRandomValues(new Uint16Array(16))).toString('base64');

		const pdf = new jsPDF({
			compress: true,
			format,
			orientation: 'p',
			unit: 'mm',
			encryption: { userPermissions: this.pdfUserPermissions, ownerPassword: pdfOwnerPassword }
		});

		const imgWidth = sizes.imgWidth;
		const imgHeight = sizes.imgHeight;
		const printPagePromises: Promise<HTMLCanvasElement>[] = [];

		pages.forEach(page => {
			page.hidden = false;
			const printPagePromise = html2canvas(page, {
				allowTaint: true,
				scale: 2,
				width: sizes.canvasHeight,
				height: sizes.canvasWidth,
				useCORS: true,
				imageTimeout: 100000,
			});
			printPagePromises.push(printPagePromise);
		});

		pdf.addFileToVFS('TitilliumWeb-Regular-normal.ttf', TitilliumWeb)
			.addFont('TitilliumWeb-Regular-normal.ttf', 'TitilliumWeb-Regular', 'normal');
		pdf.setFont('TitilliumWeb-Regular', 'normal');

		await Promise.all(printPagePromises)
			.then((canvasPages) => canvasPages.forEach((canvas, index) => {
				const pageNumber = index + 1;

				index > 0 && pdf.addPage();

				pdf.setPage(pageNumber)
					.addImage(canvas, 'PNG', 0, 0, imgHeight, imgWidth, undefined, this.pdfCompression)
					.setFontSize(15)
					.text(`${pageNumber}/${pages.length}`, imgHeight - 10, imgWidth - 8)
					.setFontSize(10)
					.text(this.labels.PRT_OR_PLAN_BOTTOM_RIGHT_PAGE_TEXT, imgHeight - 45, imgWidth - 1);

				pages[index].hidden = true
			}));

		this.loadSrv.hide();
		const fileName = `${this.canvasSrv.currentCase.number} - Preoperative Planning Report.pdf`;
		pdf.save(fileName); // Generated PDF
		this.location.replaceState('?caseGuid=' + this.canvasSrv.currentCase.id);
		timer(0).pipe(
			takeWhile(() => !this.location.getState()),
			take(10)
		).subscribe(() => this.location.back());
	}

	private getSizesByFormat(format: 'a4' | 'a3'): PrintSizes {
		let canvasWidth: number;
		let canvasHeight: number;
		let imgWidth: number;
		let imgHeight: number;

		switch (format) {
			case 'a4':
				canvasWidth = 1348;
				canvasHeight = 952;
				imgWidth = 297;
				imgHeight = 210;

				break;
			case 'a3':
				canvasWidth = 1902;
				canvasHeight = 1348;
				imgWidth = 420;
				imgHeight = 297;
		}

		return {
			canvasWidth,
			canvasHeight,
			imgWidth,
			imgHeight
		};
	}

}

<!-- Box table -->
<div class="box-table">
  <span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
    {{ labels.WEDGE_TABLE_COMPONENT_TITLE }}
    <div class="fake-material-icon icon-expand expand"></div>
  </span>
  <table class="table" [ngbCollapse]="isCollapsed">
    <thead>
      <tr>
        <th></th>
        <th *ngIf="hasAP">{{ labels.WEDGE_TABLE_COMPONENT_AP_HEADER }}</th>
        <th *ngIf="hasLT">{{ labels.WEDGE_TABLE_COMPONENT_LT_HEADER }}</th>
      </tr>
    </thead>
    <tbody>
      <tr id="wedgeAngle">
        <td>{{ labels.WEDGE_TABLE_COMPONENT_WEDGE_ANGLE }}</td>
        <td *ngIf="hasAP">{{ ap_wedgeAngle }}</td>
        <td *ngIf="hasLT">{{ lt_wedgeAngle }}</td>
      </tr>
      <tr id="cortexLength">
        <td>{{ labels.WEDGE_TABLE_COMPONENT_CORTEX_LENGTH }}</td>
        <td *ngIf="hasAP">{{ ap_cortexLength }}</td>
        <td *ngIf="hasLT">{{ lt_cortexLength }}</td>
      </tr>
      <tr id="axialLength">
        <td>{{ labels.WEDGE_TABLE_COMPONENT_AXIAL_LENGTH }}</td>
        <td *ngIf="hasAP">{{ ap_axialLength }}</td>
        <td *ngIf="hasLT">{{ lt_axialLength }}</td>
      </tr>
      <tr id="secondaryTranslation">
        <td>{{ labels.WEDGE_TABLE_COMPONENT_SECONDARY_TRANSLATION }}</td>
        <td *ngIf="hasAP">{{ ap_secondaryTransl }}</td>
        <td *ngIf="hasLT">{{ lt_secondaryTransl }}</td>
      </tr>
    </tbody>
  </table>
</div>

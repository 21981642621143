import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthEvent } from '@ortho-next/auth-core';
import { environment } from '../../environments/environment';
import { MonitoringService } from './monitoring.service';

/**
 * This service handles generic errors
 */
@Injectable()
export class MonitoringErrorHandler implements ErrorHandler {

	constructor(private injector: Injector, private monitorSrv: MonitoringService) { }

	handleError(error: any): void {
		if (error instanceof OAuthEvent === false && error instanceof OAuthErrorEvent === false) {
			this.monitorSrv.logError(error);
			if (environment.name != 'default') {
				const router = this.injector.get(Router);
				router.navigate(['/error'], { queryParams: { error: error } });
			}
		}
	}

}

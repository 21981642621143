<div class="spacing-top">
	<a class="wrapper-back" href="#" (click)="back()" onclick="return false;">
		<span class="material-icons md-18 back">arrow_back</span>
		<span class="action">{{ labels.OSTEOTOMY_WORKFLOW_COMPONENT_CANCEL_BTN }}</span>
	</a>
</div>

<div class="main-step">
	<div class="box-menu-settings active">
		<div class="row-box">
			<h4>{{ labels.OSTEOTOMY_WORKFLOW_COMPONENT_TITLE }}</h4>
		</div>
		<div class="row-box">
			<div class="box-suggestion">
				<p>
					{{ labels.OSTEOTOMY_WORKFLOW_COMPONENT_MESSAGE }}
				</p>
			</div>
		</div>
		<div class="row-box bones-area">


			<div class="bones-wrapper right" *ngIf="isLeft" [ngClass]="{'bones-wrapper-mobile': isMobile }">

				<div class="right-side">
					<div class="custom-control bones-control custom-radio" *ngIf="isLongLegVisible || isFemurVisible">
						<input type="radio" id="proximal-femur" name="anatomicalSiteLeft" class="custom-control-input"
							[value]="apexSiteEnum.ProximalFemur" [(ngModel)]="site" />
						<label class="custom-control-label" for="proximal-femur"></label>
					</div>
					<div [ngStyle]="{'min-height': isMobile ? '2rem' : '6rem' }" *ngIf="isLongLegVisible || isFemurVisible"></div>
					<div class="custom-control bones-control custom-radio" *ngIf="isLongLegVisible || isFemurVisible">
						<input type="radio" id="distal-femur" name="anatomicalSiteLeft" class="custom-control-input"
							[value]="apexSiteEnum.DistalFemur" [(ngModel)]="site" />
						<label class="custom-control-label" for="distal-femur"></label>
					</div>

					<div [ngStyle]="{'min-height': isMobile ? '11.5rem' : '15.8rem' }" *ngIf="isTibiaVisible"></div>
					<div class="custom-control bones-control custom-radio" *ngIf="isLongLegVisible || isTibiaVisible">
						<input type="radio" id="proximal-tibia" name="anatomicalSiteLeft" class="custom-control-input"
							[value]="apexSiteEnum.ProximalTibia" [(ngModel)]="site" />
						<label class="custom-control-label" for="proximal-tibia"></label>
					</div>
					<!--<div [ngStyle]="{'min-height': isMobile ? '2rem' : '6rem' }" *ngIf="isLongLegVisible || isTibiaVisible"></div>-->
					<!--<div class="custom-control bones-control custom-radio" *ngIf="isLongLegVisible || isTibiaVisible">
            <input type="radio" id="distal-tibia" name="anatomicalSiteLeft" class="custom-control-input"
                   [value]="apexSiteEnum.DistalTibia" [(ngModel)]="site" />
            <label class="custom-control-label" for="distal-tibia"></label>
          </div>-->
				</div>
				<label class="info-leg-bottom">{{ apexSiteLabel(site) }}</label>
			</div>


			<div class="bones-wrapper left" *ngIf="isRight" [ngClass]="{'bones-wrapper-mobile': isMobile }">
				<div class="left-side">
					<div class="custom-control bones-control custom-radio" *ngIf="isLongLegVisible || isFemurVisible">
						<input type="radio" id="proximal-femur" name="anatomicalSiteRight" class="custom-control-input"
							[value]="apexSiteEnum.ProximalFemur" [(ngModel)]="site" />
						<label class="custom-control-label" for="proximal-femur"></label>
					</div>
					<div [ngStyle]="{'min-height': isMobile ? '2rem' : '6rem' }" *ngIf="isLongLegVisible || isFemurVisible"></div>
					<div class="custom-control bones-control custom-radio" *ngIf="isLongLegVisible || isFemurVisible">
						<input type="radio" id="distal-femur" name="anatomicalSiteRight" class="custom-control-input"
							[value]="apexSiteEnum.DistalFemur" [(ngModel)]="site" />
						<label class="custom-control-label" for="distal-femur"></label>
					</div>

					<div [ngStyle]="{'min-height': isMobile ? '11.5rem' : '15.8rem' }" *ngIf="isTibiaVisible"></div>
					<div class="custom-control bones-control custom-radio" *ngIf="isLongLegVisible || isTibiaVisible">
						<input type="radio" id="proximal-tibia" name="anatomicalSiteRight" class="custom-control-input"
							[value]="apexSiteEnum.ProximalTibia" [(ngModel)]="site" />
						<label class="custom-control-label" for="proximal-tibia"></label>
					</div>
					<!--<div [ngStyle]="{'min-height': isMobile ? '2rem' : '6rem' }" *ngIf="isLongLegVisible || isTibiaVisible"></div>-->
					<!--<div class="custom-control bones-control custom-radio" *ngIf="isLongLegVisible || isTibiaVisible">
            <input type="radio" id="distal-tibia" name="anatomicalSiteRight" class="custom-control-input"
                   [value]="apexSiteEnum.DistalTibia" [(ngModel)]="site" />
            <label class="custom-control-label" for="distal-tibia"></label>
          </div>-->
				</div>
				<label class="info-leg-bottom">{{ apexSiteLabel(site) }}</label>
			</div>


		</div>
		<div class="mt-auto">
			<div class="divider-box"></div>
			<div class="wrapper-btn-confirm">
				<button type="button" class="btn btn-primary pl-2 pr-2" [disabled]="!site" (click)="confirm()">
					{{ labels.OSTEOTOMY_WORKFLOW_COMPONENT_CONFIRM_BTN }}
				</button>
			</div>
		</div>
	</div>
</div>
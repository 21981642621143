import { Component, Input } from '@angular/core';

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styles: []
})
export class MessageComponent {

  @Input() text: string;

}

import { CaseModel, MainBase, MultiViewsElementCustom, RotateCameraType, SelectedApexMech, ViewType } from "@ortho-next/nextray-core";
import { Globals } from "@ortho-next/three-base/Utils/Globals";
import { BindedModel } from "../Models/BindedModel";
import { DeformityAppModel } from "../Models/DeformityAppModel";
import { Context } from "../States/Context";
import { PrintUtils } from "../Utils/PrintUtils";
import { Bridge } from "./Bridge";
import { ContextInit } from "./ContextInit";
import { Layers } from "./Layers";
import { MeasuresController } from "./MeasuresController";
import { SaveHandler } from "./Save";
import { Scene } from "./Scene";
import { Tools, ToolsAP, ToolsLT } from "./Tools";
import { ToolsInit } from "./ToolsInit";
import { Viewer } from "./Viewer";
import { AppModel } from "../Models/AppModel";
import { CustomCameraControls } from "../Controls/CustomCameraControls";

/**
 * Manages rendering and all logics.
 */
export class Main extends MainBase {
  public scenes: Scene[]; // override
  public model: AppModel;
  public deformityModel: DeformityAppModel;
  public bridge: Bridge;
  public toolsInit: ToolsInit;
  public printUtils: PrintUtils;
  public layers: Layers;
  public measuresController: MeasuresController;
  public viewer: Viewer;
  protected _context: Context;
  protected _saveHandler: SaveHandler;

  //overridden getter
  public get activeScene(): Scene { return super.activeScene as Scene }
  public get toolsAP(): ToolsAP { return super.toolsAP as ToolsAP }
  public get toolsLT(): ToolsLT { return super.toolsLT as ToolsLT }
  public get activeTools(): Tools { return super.activeTools as Tools }

  constructor(canvasContainer: HTMLDivElement) {
    super(canvasContainer, {
      appModel: AppModel,
      bindedModel: new BindedModel(),
      bridge: Bridge,
      contextProto: Context,
      measuresController: MeasuresController,
      printUtilsProto: PrintUtils,
      saveHandlerProto: SaveHandler,
      sceneProto: Scene,
      toolsAPProto: ToolsAP,
      toolsLTProto: ToolsLT,
      cameraControlsProto: CustomCameraControls
    });
    ContextInit.create(this, this._context);
    this.showStats = false; //todo remove it from package
    this.deformityModel = new DeformityAppModel();
    this.viewer = new Viewer(this);
  }

  /**
   * Rotates camera based on app state.
   */
  public rotateCamera(type: RotateCameraType): void {
    if (this.hasAP) {
      const value = type === RotateCameraType.none ? 0 : -this.toolsAP.mechanicalAxis.getAngleForApexConfirmed(this.model.selectedApex);
      this.scenes[ViewType.AP].cameraControls.rotateCamera(value);
      (this.multiviewRenderer.viewsports[ViewType.AP] as MultiViewsElementCustom).cameraControls.rotateCamera(value);
    }
    if (this.hasLT) {
      const value = type === RotateCameraType.none ? 0 : (this.model.selectedApex === SelectedApexMech.tibiaProximal ?
        -this.toolsLT.mechanicalAxis.getAngleForApexConfirmed(this.model.selectedApex) : -this.toolsLT.diaphysisAA.getAngleForApexConfirmed(this.model.selectedApex));
      this.scenes[ViewType.LT].cameraControls.rotateCamera(value);
      (this.multiviewRenderer.viewsports[ViewType.LT] as MultiViewsElementCustom).cameraControls.rotateCamera(value);
    }
  }

  /**
   * Init scenes and restore old data.
   */
  public loadScenes(caseModel: CaseModel): void {
    if (!this.caseModel) {
      super.loadScenes(caseModel, ToolsInit, Layers);
      this._context.handle("rotateCamera");
      this.setView(this.hasAP ? ViewType.AP : ViewType.LT);
    }
  }

  /**
   * Set the zoom to simulate real scale.
   */
  public setRealScale(monitorInchDiagonal: number): void {
    const monitorPixelWidth = window.screen.width;
    const monitorPixelHeigth = window.screen.height;
    const monitorPixelDiagonal = Math.sqrt(monitorPixelWidth ** 2 + monitorPixelHeigth ** 2);
    const ppi = monitorPixelDiagonal / monitorInchDiagonal;
    const inchToMM = 25.4;
    const cameraProp = Globals.canvas.clientWidth / 200; //200 is camera width
    const ppmm = ppi / inchToMM / cameraProp;
    this.scenes[ViewType.AP].cameraControls.setZoom(ppmm);

    if (this.hasLT) {
      this.scenes[ViewType.LT].cameraControls.setZoom(ppmm);
      (this.multiviewRenderer.viewsports[ViewType.AP] as MultiViewsElementCustom).cameraControls.setZoom(ppmm);
      (this.multiviewRenderer.viewsports[ViewType.LT] as MultiViewsElementCustom).cameraControls.setZoom(ppmm);
    }
  }

  //override
  public getTools(viewType: ViewType): Tools {
    return super.getTools(viewType) as Tools;
  }
}

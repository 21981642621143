import { ToolsInitBase, ViewType, SelectedApexMech, LabelTextParams, VectorUtils, ToolEvents, SceneEvents } from "@ortho-next/nextray-core";
import { MechanicalAxisTibiaAP } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/TibiaAnalyzerAP";
import { Matrix4, Vector3, Object3D } from "@ortho-next/three-base/three.js/build/three.module";
import { Binding } from "@ortho-next/three-base/Binding/Binding";
import { AnatomicalSideEnum } from '../../app/core/repositories/models/case';
import { Messages } from "../Models/AppModel";
import { bindedModel, BindedModel } from '../Models/BindedModel';
import { BlockingScrewManager } from '../Tools/BlockingScrew/BlockingScrewManager';
import { FullAnalyzerAPEvent, MechanicalAxisAP } from '../Tools/DeformityAnalyzer/FullAnalyzerAP';
import { CropPolygonTool } from '../Tools/EoCPlane/CropPolygonTool';
import { EoCPlane } from '../Tools/EoCPlane/EoCPlane';
import { FitboneManager } from '../Tools/FitBone/FitBoneManager';
import { Grid } from '../Tools/Grid';
import { InsertionPoint } from '../Tools/InsertionPoint/InsertionPoint';
import { InsertionPointManager } from '../Tools/InsertionPoint/InsertionPointManager';
import { OsteotomyAP } from '../Tools/Osteotomy/OsteotomyAP';
import { OsteotomyLT } from '../Tools/Osteotomy/OsteotomyLT';
import { ReferencePoint } from '../Tools/ReferencePoint';
import { Consts } from '../Utils/Consts';
import { FormatUtils } from '../Utils/FormatUtils';
import { MeasuresUtils } from '../Utils/MeasuresUtils';
import { BridgeResultMessages } from './Bridge';
import { Main } from './Main';
import { Tools } from './Tools';
import { LimitUtils } from "../Utils/LimitUtils";
import { DiaphysisAnatomicalAxis } from "../Tools/DiaphysisAnatomicalAxis";
import { MechanicalAxisLT } from "../Tools/DeformityAnalyzer/FullAnalyzerLT";

/**
 * Manages the initialization of the tools.
 */
export class ToolsInit extends ToolsInitBase {
  protected _main: Main;
  private _osteotomySync: boolean;
  private _EoCSync: boolean;
  private _fitboneLengthSync: boolean;
  private _fitboneSync: boolean;

  private getSightMatrix(viewType: ViewType): Matrix4 {
    return viewType === ViewType.AP ? undefined : new Matrix4().makeRotationY(Math.PI / -2);
  }

  private get siteMatrix(): Matrix4 {
    return this._main.model.selectedApex === SelectedApexMech.femurProximal ? undefined : new Matrix4().makeRotationX(Math.PI);
  }

  private getSideMatrix(viewType: ViewType) {
    if (viewType == ViewType.AP) {
      return this._main.caseModel.side === AnatomicalSideEnum.Left ? new Matrix4().makeRotationAxis(new Vector3(0, 1, 0), Math.PI) : undefined;
    }
    return this._main.caseModel.side === AnatomicalSideEnum.Left ? undefined : new Matrix4().makeRotationAxis(new Vector3(0, 1, 0), Math.PI);
  }

  constructor(main: Main) {
    super(main, undefined, MechanicalAxisAP, MechanicalAxisLT)
  }

  /**
   * Inits plane and the tool used to register points (used by calibration for example) for both scenes. Called once during app starting.
   */
  public initPlaneAndRegisterPlane(onAllImageLoad: () => void): void {
    super.initPlaneAndRegisterPlane(onAllImageLoad);
    this.initGrids();
  }

  /**
   * Inits grids.
   */
  public initGrids(): void {
    this._main.hasAP && this._main.toolsAP.add(this._main.toolsAP.grid = new Grid(ViewType.AP));
    this._main.hasLT && this._main.toolsLT.add(this._main.toolsLT.grid = new Grid(ViewType.LT));
    bindedModel.isGridInserted = true;
  }

  /**
   * Inits EoCPlane if necessary and returns it.
   */
  public initEoCPlaneTool(viewType: ViewType): EoCPlane { //todo ask
    const tools = this._main.getTools(viewType);
    if (!tools.EoCPlane) {
      tools.add(tools.EoCPlane = new EoCPlane(viewType, this._main.caseModel.side === AnatomicalSideEnum.Left, tools.plane, tools.osteotomy, tools.mechanicalAxis as MechanicalAxisAP, tools.insertionPoint));
      this._main.measuresController.bindRPM(viewType);
      this.bindEoCSync();
      this.bindEOCDragAndFitboneLenght(viewType);

      tools.EoCPlane.osteotomy.A.bindEvent("onPositionComponentChange", (args) => {
        this.resetCroppedPolygon(tools.EoCPlane);
      });
      tools.EoCPlane.osteotomy.B.bindEvent("onPositionComponentChange", (args) => {
        this.resetCroppedPolygon(tools.EoCPlane);
      });

    }
    return tools.EoCPlane;
  }

  private resetCroppedPolygon(eocPlane: EoCPlane): void {
    bindedModel.isPolygonCropped = false;
    eocPlane.resetPoints();
  }

  /**
   * Inits the crop plane tool if necessary and returns it.
   */
  public initCropPolygonTool(viewType: ViewType): CropPolygonTool {
    const tools = this._main.getTools(viewType);
    if (!tools.cropPolygonTool) {
      tools.add(tools.cropPolygonTool = new CropPolygonTool(viewType, tools.plane, tools.EoCPlane));
    }
    return tools.cropPolygonTool;
  }

  /**
   * Adds a blocking screw on the scene if the limit is not reached.
   */
  public initBlockingScrew(tools = this._main.activeTools): BlockingScrewManager {
    if (tools.blockingScrews.length < 4) {
      const blockingScrew = new BlockingScrewManager(tools.EoCPlane, tools.mechanicalAxis as MechanicalAxisAP, tools.insertionPoint, tools.diaphysisAA, tools.osteotomy);
      tools.add(blockingScrew);
      tools.blockingScrews.push(blockingScrew);

      blockingScrew.addEventListener(SceneEvents.select, () => { 
        this._main.model[this._main.activeTools.viewType === ViewType.AP ? "isBlockingScrewSelectedAP" : "isBlockingScrewSelectedLT"] = true;
       });

       blockingScrew.addEventListener(SceneEvents.deselect, () => { 
        this._main.model[this._main.activeTools.viewType === ViewType.AP ? "isBlockingScrewSelectedAP" : "isBlockingScrewSelectedLT"] = null;
      });

      blockingScrew.addEventListener(SceneEvents.delete, () => { 
        const viewType = this._main.activeTools.viewType;
        this._main.model[viewType === ViewType.AP ? "isBlockingScrewSelectedAP" : "isBlockingScrewSelectedLT"] = null;
        const blkScrewFull = viewType === ViewType.AP ? "isBlockingScrewFullAP" : "isBlockingScrewFullLT";
        this._main.activeTools.blockingScrews = this._main.activeTools.blockingScrews.filter(screw => screw !== this._main.activeScene.activeTool as Object3D);
        this._main.model[blkScrewFull] = this._main.getTools(viewType).blockingScrews.length > 3;
      });

      this._main.model[tools.viewType === ViewType.AP ? "isBlockingScrewFullAP" : "isBlockingScrewFullLT"] = tools.blockingScrews.length > 3;

      return blockingScrew;
    }
  }

  /**
   * Inits the AP mechanical axis if necessary and returns it.
   */
  public initMechanicalAxisAP(): MechanicalAxisAP {
    const tools = this._main.toolsAP;
    let mechAxis = tools.mechanicalAxis;
    if (!mechAxis) {
      mechAxis = super.initMechanicalAxisAP() as MechanicalAxisAP;

      mechAxis.addEventListener(FullAnalyzerAPEvent.updateCHPosition, () => {
        tools.mechanicalAxis.CH.position.copy(MeasuresUtils.getCHposition(tools.mechanicalAxis, tools.contralateralMechanicalAxis));
        tools.mechanicalAxis.CHLimitPoint = MeasuresUtils.getCHposition(tools.mechanicalAxis, null, false);
        this.updateCHLabelMeasures(tools);
      });
      
      mechAxis.addEventListener(FullAnalyzerAPEvent.onDragMoveCHPoint, (event) => this.updateCHLabelMeasures(tools, event.position));
      mechAxis.addEventListener(ToolEvents.updated, (event) => this.updateCHLabelMeasures(tools));
    }
    
    return mechAxis;
  }

  /**
  * Inits both insertion points if necessary.
  */
  public initInsertionPoints(): void {
    this._main.hasAP && this.initInsertionPoint(ViewType.AP);
    this._main.hasLT && this.initInsertionPoint(ViewType.LT);
  }

  /**
  * Inits the insertion point of a specific view if necessary and returns it.
  */
  public initInsertionPoint(viewType: ViewType): InsertionPointManager {
    const tools = this._main.getTools(viewType);
    if (!tools.insertionPointManager) {
      tools.add(tools.insertionPointManager = new InsertionPointManager(viewType));
    }
    tools.insertionPoint.calculate(bindedModel.selectedApex, tools.mechanicalAxis as MechanicalAxisAP | MechanicalAxisLT);

    return tools.insertionPointManager;
  }

  /**
  * Update CH label text and measures.
  */
  private updateCHLabelMeasures(tools: Tools, chPosition?: Vector3) {
    const mechanicalAxis = tools.mechanicalAxis as MechanicalAxisAP;
    chPosition = chPosition || mechanicalAxis.CH.position.clone();
    const approach = bindedModel.selectedApex;
    const hasCtrl = bindedModel.isControlateralMechanicalAxisValid;

    // single bone measures
    const boneLength = MeasuresUtils.getTargetBoneLength(mechanicalAxis, chPosition);
    const bone = approach === SelectedApexMech.tibiaProximal ? 'Tibia' : 'Femur';
    const initialBoneLength = approach === SelectedApexMech.tibiaProximal ? this._main.deformityModel.measures.def_ap_tibiaLength : this._main.deformityModel.measures.def_ap_femurLength;
    const boneMALength = boneLength - initialBoneLength;
    let initialCtrlBoneLength, boneDiscrepancy;
    if (hasCtrl) {
      initialCtrlBoneLength = approach === SelectedApexMech.tibiaProximal ? this._main.deformityModel.measures.def_ap_tibiaLength_ctrlateral : this._main.deformityModel.measures.def_ap_femurLength_ctrlateral;
      boneDiscrepancy = boneLength - initialCtrlBoneLength;
    }

    // long leg measures
    const oppositeLegPoint = approach === SelectedApexMech.tibiaProximal ? mechanicalAxis.femur.FH : mechanicalAxis.tibia.CA;
    const legLength = chPosition.distanceTo(oppositeLegPoint.position);
    const initialLegLength = this._main.deformityModel.measures.def_ap_fullLength;
    const legMALength = legLength - initialLegLength;
    let initialCtrlLegLength, legDiscrepancy;
    if (hasCtrl) {
      initialCtrlLegLength = this._main.deformityModel.measures.def_ap_fullLength_ctrlateral;
      legDiscrepancy = legLength - initialCtrlLegLength;
    }

    // update label text
    const chLabelTextParams: LabelTextParams[] = [];
    chLabelTextParams.push({ text: `Target ${bone} Length: ${FormatUtils.distanceWithSymbol(boneLength)}`, paddingTop: 20, paddingBottom: 20 });
    chLabelTextParams.push({ text: `Mechanical Axis Lengthening: ${FormatUtils.distanceWithSign(boneMALength)}`, fontSize: 50, paddingLeft: 60 });
    if (hasCtrl) {
      chLabelTextParams.push({ text: `Discrepancy:                              ${FormatUtils.distanceWithSign(boneDiscrepancy)} - [Contralateral: ${initialCtrlBoneLength} mm]`, fontSize: 50, paddingLeft: 60 });
    }
    chLabelTextParams.push({ text: `Target Leg Length: ${FormatUtils.distanceWithSymbol(legLength)}`, paddingTop: 50, paddingBottom: 20 });
    chLabelTextParams.push({ text: `Mechanical Axis Lengthening: ${FormatUtils.distanceWithSign(legMALength)}`, fontSize: 50, paddingBottom: hasCtrl ? 0 : 30, paddingLeft: 60 });
    if (hasCtrl) {
      chLabelTextParams.push({ text: `Discrepancy:                              ${FormatUtils.distanceWithSign(legDiscrepancy)} - [Contralateral: ${initialCtrlLegLength} mm]`, fontSize: 50, paddingBottom: 30, paddingLeft: 60 });
    }

    mechanicalAxis.CHLabel.setParam('text', chLabelTextParams);
  }

  /**
  * Inits the diaphysis anatomical axis in LAT view if necessary and returns it.
  */
  public initDiaphysisAA(): DiaphysisAnatomicalAxis {
    const tools = this._main.toolsLT;
    if (!tools.diaphysisAA) {
      tools.add(tools.diaphysisAA = new DiaphysisAnatomicalAxis(tools.mechanicalAxis.femur));
    }
    tools.diaphysisAA.initPosition();
    return tools.diaphysisAA;
  }

  /**
   * Inits the contralateral if necessary and returns it.
   */
  public initControlaterMechanicalAxis(): MechanicalAxisAP {
    const tools = this._main.toolsAP;
    let ctrlAxis = tools.contralateralMechanicalAxis;
    if (!ctrlAxis) {
      ctrlAxis = super.initControlaterMechanicalAxis() as MechanicalAxisAP;
      tools.contralateralMechanicalAxis.addEventListener(ToolEvents.updated, () => this.updateCHLabelMeasures(tools));
    }
    return ctrlAxis;
  }

  /**
   * Inits both osteotomies if necessary.
   */
  public initOsteotomies(): void {
    this._main.hasAP && this.initOsteotomyAP();
    this._main.hasLT && this.initOsteotomyLT();
  }

  /**
   * Inits AP osteotomy if necessary and returns it.
   */
  public initOsteotomyAP(): OsteotomyAP {
    const tools = this._main.toolsAP;
    if (!tools.osteotomy) {
      tools.add(tools.osteotomy = new OsteotomyAP(tools.mechanicalAxis, tools.insertionPoint as any)); //todo fix interface
      this.bindOsteotomySync();
      tools.osteotomy.addEventListener(ToolEvents.onError, (event) => {
        this._main.bridge.dispatchEvent({ type: 'onResult', args: BridgeResultMessages.osteotomyLengthError });
      });
    }
    return tools.osteotomy;
  }

  /**
   * Inits LT osteotomy if necessary and returns it.
   */
  public initOsteotomyLT(): OsteotomyLT {
    const tools = this._main.toolsLT;
    if (!tools.osteotomy) {
      tools.add(tools.osteotomy = new OsteotomyLT(tools.mechanicalAxis, tools.insertionPoint as any, tools.diaphysisAA)); //todo fix
      this.bindOsteotomySync();
      tools.osteotomy.addEventListener(ToolEvents.onError, (event) => {
        this._main.bridge.dispatchEvent({ type: 'onResult', args: BridgeResultMessages.osteotomyLengthError });
      });
    }
    tools.osteotomy.diaphysisAA = tools.diaphysisAA;
    return tools.osteotomy;
  }

  /**
   * Inits both reference points if necessary.
   */
  public initReferencePoints(): void {
    this._main.hasAP && this.initReferencePointAP();
    this._main.hasLT && this.initReferencePointLT();
  }

  /**
   * Inits AP reference point if necessary and returns it.
   */
  public initReferencePointAP(): ReferencePoint {
    const tools = this._main.toolsAP;
    if (!tools.referencePoint) {
      tools.referencePoint = new ReferencePoint();
    }
    return tools.referencePoint;
  }

  /**
   * Inits LT reference point if necessary and returns it.
   */
  public initReferencePointLT(): ReferencePoint {
    const tools = this._main.toolsLT;
    if (!tools.referencePoint) {
      tools.referencePoint = new ReferencePoint();
    }
    return tools.referencePoint;
  }

  /**
   * Inits both fitbones if necessary.
   */
  public initFitBones(): void {
    this._main.hasAP && this.initFitBone(ViewType.AP);
    this._main.hasLT && this.initFitBone(ViewType.LT);
    Promise.all([this.initFitboneSTL(ViewType.AP), this.initFitboneSTL(ViewType.LT)]).then(() => {
      if (this._main.hasAP && this._main.hasLT) {
        this._main.toolsLT.fitBoneManager.internal.lengthening = this._main.toolsAP.fitBoneManager.internal.lengthening;
      }
      if (this._main.hasAP) {
        this._main.toolsAP.fitBoneManager.setPositionByInsertionPoint(this._main.toolsAP.clonedMechanicalAxis);
        this._main.toolsAP.EoCPlane.updateCropDirection();
      }
      if (this._main.hasLT) {
        const translAP = this._main.hasAP ? this._main.toolsAP.fitBoneManager.distanceToReferencePoint : undefined;
        this._main.toolsLT.fitBoneManager.setPositionByInsertionPoint(this._main.toolsLT.clonedMechanicalAxis, this._main.toolsLT.diaphysisAA, translAP);
        this._main.toolsLT.EoCPlane.updateCropDirection();
      }
    });
  }

  /**
   * Inits fitbone if necessary and returns it.
   */
  public initFitBone(viewType: ViewType): FitboneManager {
    const tools = this._main.getTools(viewType);
    if (!tools.fitBoneManager) {
      tools.add(tools.fitBoneManager = new FitboneManager(viewType, tools.EoCPlane, tools.insertionPoint, this.getSightMatrix(viewType), this.getSideMatrix(viewType)));

      tools.fitBoneManager.bindEvent('onRotationComponentChange', () => {
        tools.EoCPlane.fitboneDirection = tools.fitBoneManager.direction;
      });

      tools.fitBoneManager.rotationPointExternal.bindEvent('onPositionComponentChange', () => {
        this.updateInsPointOutBoxMessages(viewType, tools.fitBoneManager, tools.insertionPoint);
      });

      viewType === ViewType.AP && tools.fitBoneManager.external.bindEvent('onPositionComponentChange', () => {
        this.computeOsteotomyNearScrewsWarning();
      });

      tools.fitBoneManager.rotationPointExternal.bindEvent('onDragStart', () => {
        this._main.hasAP && this.computeScrewsInMovingEoCPartFlag(this._main.toolsAP);
        this._main.hasLT && this.computeScrewsInMovingEoCPartFlag(this._main.toolsLT);
      });

      tools.fitBoneManager.rotationPointExternal.bindEvent('onDragMove', (args) => {
        if (bindedModel.isStrokeLocked) {
          args.preventDefault = true;
          tools.EoCPlane.rotationByPoint(args.position, tools.fitBoneManager.rotationPointExternal);
          tools.EoCPlane.dispatchEvent({ type: ToolEvents.updated });
        }
      });

      this.bindFitboneLengtheningSync();
      this.bindFitboneSync();
      tools.fitBoneManager.addEventListener(ToolEvents.onAfterRestore, () => {
        this.initFitboneSTL(viewType).then(() => {
          tools.fitBoneManager.update()
          tools.EoCPlane.updateCropDirection();
          tools.EoCPlane.dispatchEvent({ type: ToolEvents.updated });
        });
      });

      Binding.createCustom(`stroke_lock_${viewType}`, (m: BindedModel) => m.isStrokeLocked, (value: boolean) => {
        this.updateInsPointOutBoxMessages(viewType, tools.fitBoneManager, tools.insertionPoint);
      }, ['isStrokeLocked']);
    }
    tools.fitBoneManager.isSyncEnabled = false;
    return tools.fitBoneManager;
  }

  private async initFitboneSTL(viewType: ViewType): Promise<void> {
    const fitboneModel = this._main.model.fitbone;
    const tools = this._main.getTools(viewType);
    if (fitboneModel && tools.fitBoneManager) {
      const key = viewType === ViewType.AP ? "isFitboneLoadingAP" : "isFitboneLoadingLT";
      this._main.model[key] = true;
      await tools.fitBoneManager.loadSTL(fitboneModel, tools.EoCPlane, tools.diaphysisAA, this.siteMatrix);
      tools.fitBoneManager.isSyncEnabled = true;
      this._main.model[key] = false;
    }
  }

  private updateInsPointOutBoxMessages(viewType: ViewType, fitbone: FitboneManager, insPoint: InsertionPoint): void {
    const isInsPointOutBox: keyof Messages = viewType === ViewType.AP ? 'isInsPointOutBoxAP' : 'isInsPointOutBoxLT';
    if (bindedModel.isStrokeLocked) {
      this._main.model.messages[isInsPointOutBox] = !fitbone.isPointInsideBox(insPoint.position);
    } else {
      this._main.model.messages[isInsPointOutBox] = false;
    }
  }

  private bindOsteotomySync(): void {
    const toolsAP = this._main.toolsAP;
    const toolsLT = this._main.toolsLT;
    const ostAP = toolsAP.osteotomy;
    const ostLT = toolsLT.osteotomy;

    if (ostAP && ostLT && !this._osteotomySync) {

      ostAP.bindEvent('onAfterDragMove', () => { // TODO: history will not work
        ostLT.distanceToReferencePoint = ostAP.distanceToReferencePoint;
      });

      ostLT.bindEvent('onAfterDragMove', () => {
        ostAP.distanceToReferencePoint = ostLT.distanceToReferencePoint;
      });

      this._osteotomySync = true;
    }
  }

  /**
   * Binds EoC vertical translation sync in both views.
   */
  public bindEoCSync(): void {
    const croppedPlaneAP = this._main.toolsAP.EoCPlane;
    const croppedPlaneLT = this._main.toolsLT.EoCPlane;

    if (croppedPlaneAP && croppedPlaneLT && !this._EoCSync) {

      croppedPlaneAP.bindEvent('onAfterDragMove', () => { // TODO: history will not work
        const oldPos = croppedPlaneLT.croppedPlane.position.clone();
        const pan = croppedPlaneLT.setVerticalTranslation(croppedPlaneAP.getVerticalTranslation());
        if (bindedModel.isFitboneInserted) {
          if (bindedModel.isStrokeLocked) {
            if (bindedModel.selectedApex === SelectedApexMech.femurProximal) {
              this._main.toolsLT.fitBoneManager.external.position.sub(oldPos.sub(croppedPlaneLT.croppedPlane.position));
              this._main.toolsLT.fitBoneManager.updateRotationPoints();
            }
            this.moveLockingScrewsSync(pan, this._main.toolsLT);
          } else {
            this._main.toolsAP.fitBoneManager.calculateDefaultLength(croppedPlaneAP);
          }
        }
      });

      croppedPlaneLT.bindEvent('onAfterDragMove', () => {
        const oldPos = croppedPlaneAP.croppedPlane.position.clone();
        const pan = croppedPlaneAP.setVerticalTranslation(croppedPlaneLT.getVerticalTranslation());
        if (bindedModel.isFitboneInserted && bindedModel.isStrokeLocked) {
          if (bindedModel.selectedApex === SelectedApexMech.femurProximal) {
            this._main.toolsAP.fitBoneManager.external.position.sub(oldPos.sub(croppedPlaneAP.croppedPlane.position));
            this._main.toolsAP.fitBoneManager.updateRotationPoints();
          }
          this.moveLockingScrewsSync(pan, this._main.toolsAP);
        }
      });

      this._EoCSync = true;
    }
  }

  private bindFitboneLengtheningSync(): void {
    const fitboneAP = this._main.toolsAP.fitBoneManager;
    const fitboneLT = this._main.toolsLT.fitBoneManager;
    if (fitboneAP && fitboneLT && !this._fitboneLengthSync) {
      fitboneAP.internal.bindEvent('onPositionComponentChange', () => { // TODO: history will not work
        if (fitboneLT.isSyncEnabled) {
          fitboneLT.internal.lengthening = fitboneAP.internal.lengthening;
        }
      });
      fitboneLT.internal.bindEvent('onPositionComponentChange', () => { // TODO: history will not work
        if (fitboneAP.isSyncEnabled) {
          fitboneAP.internal.lengthening = fitboneLT.internal.lengthening;
        }
      });
      this._fitboneLengthSync = true;
    }
  }

  private bindFitboneSync(): void {
    const toolsAP = this._main.toolsAP;
    const toolsLT = this._main.toolsLT;
    const fitboneAP = toolsAP.fitBoneManager;
    const fitboneLT = toolsLT.fitBoneManager;

    if (fitboneAP && fitboneLT && !this._fitboneSync) {

      fitboneAP.bindEvent('onAfterDragMove', () => { // TODO: history will not work
        !bindedModel.isStrokeLocked && (fitboneLT.distanceToReferencePoint = fitboneAP.distanceToReferencePoint);
      });

      fitboneLT.bindEvent('onAfterDragMove', () => {
        !bindedModel.isStrokeLocked && (fitboneAP.distanceToReferencePoint = fitboneLT.distanceToReferencePoint);
      });

      this._fitboneSync = true;
    }
  }

  private bindEOCDragAndFitboneLenght(viewType: ViewType): void { // TODO: refactoring
    const tools = this._main.getTools(viewType);

    tools.EoCPlane.bindEvent('onDragStart', () => {
      this._main.hasAP && this.computeScrewsInMovingEoCPartFlag(this._main.toolsAP);
      this._main.hasLT && this.computeScrewsInMovingEoCPartFlag(this._main.toolsLT);
    });

    tools.EoCPlane.croppedPlane.bindEvent('onDragMove', (args) => {
      this.moveEOCAndFitboneLenght(args.position, tools, viewType);
    });
  }

  /**
   * Set fitbone lengthening to 0.
   */
  public closeFitbone(tools: Tools, viewType: ViewType): void {
    const direction =
      bindedModel.selectedApex === SelectedApexMech.femurDistal || bindedModel.selectedApex === SelectedApexMech.femurProximal ?
        -10000 : 10000;
    const position = new Vector3(0, direction, 1);
    this.moveEOCAndFitboneLenght(position, tools, viewType, viewType === ViewType.LT)
  }

  /**
   * Moves Fitbone and eoc togheter.
   */
  public moveEOCAndFitboneLenght(position: Vector3, tools: Tools, viewType: ViewType, moveOnlyOnAxis = false): void {
    moveOnlyOnAxis = viewType === ViewType.AP || moveOnlyOnAxis;

    moveOnlyOnAxis && position
      .copy(VectorUtils.projectOnVector(position, tools.EoCPlane.croppedPlane.position, tools.EoCPlane.croppedPlane.position.clone().add(tools.EoCPlane.cropDirection)));

    const pan = position.clone().sub(tools.EoCPlane.croppedPlane.position);
    const newClOstA = tools.clonedOsteotomy.A.position.clone().add(pan);
    const newClOstB = tools.clonedOsteotomy.B.position.clone().add(pan);

    if (bindedModel.isStrokeLocked || !LimitUtils.applyOsteotomySegmentsTouchingLimit(tools.osteotomy.A.position, tools.osteotomy.B.position, newClOstA, newClOstB, tools.osteotomy.axisDir)) {
      if (bindedModel.isFitboneInserted && bindedModel.isStrokeLocked) {
        this.moveEOCFitboneLenght(position, tools, moveOnlyOnAxis);
        this.moveLockingScrews(position, tools);
      }
      tools.EoCPlane.translationByPoint(position);
    }
  }

  private moveEOCFitboneLenght(position: Vector3, tools: Tools, moveOnAxis: boolean): void {
    const signApex = bindedModel.selectedApex === SelectedApexMech.femurProximal ? -1 : 1;
    const sign = VectorUtils.computeSign(position, tools.EoCPlane.croppedPlane.position, tools.fitBoneManager.direction) * signApex;
    const projectedPosition = position.clone()
      .copy(VectorUtils.projectOnVector(position, tools.EoCPlane.croppedPlane.position, tools.EoCPlane.croppedPlane.position.clone().add(tools.EoCPlane.cropDirection)));
    const horTransl = position.clone().sub(projectedPosition);

    if (moveOnAxis) {
      const transl = position.distanceTo(tools.EoCPlane.croppedPlane.position) * sign;
      const oldLength = tools.fitBoneManager.internal.lengthening;
      tools.fitBoneManager.internal.lengthening += transl;
      position.copy(tools.EoCPlane.croppedPlane.position.clone().add(tools.EoCPlane.cropDirection.clone().setLength(((tools.fitBoneManager.internal.lengthening - oldLength) * signApex))));
    } else {
      const transl = projectedPosition.distanceTo(tools.EoCPlane.croppedPlane.position) * sign;
      const oldLength = tools.fitBoneManager.internal.lengthening;
      tools.fitBoneManager.internal.lengthening += transl;
      position.copy(tools.EoCPlane.croppedPlane.position.clone().add(tools.EoCPlane.cropDirection.clone().setLength(((tools.fitBoneManager.internal.lengthening - oldLength) * signApex)))).add(horTransl);
    }
    if (bindedModel.selectedApex === SelectedApexMech.femurProximal) {
      tools.fitBoneManager.external.position.add(position.clone().sub(tools.EoCPlane.croppedPlane.position));
      tools.fitBoneManager.update();
    } else {
      tools.fitBoneManager.external.position.add(horTransl);
      tools.fitBoneManager.update();
    }
  }

  private moveLockingScrews(position: Vector3, tools: Tools): void {
    const pan = position.clone().sub(tools.EoCPlane.croppedPlane.position);
    for (const screw of tools.blockingScrews.map(x => x.obj)) {
      screw.moveWithEOC && screw.position.add(pan);
    }
  }

  private moveLockingScrewsSync(pan: Vector3, tools: Tools): void {
    for (const screw of tools.blockingScrews.map(x => x.obj)) {
      screw.moveWithEOC && screw.position.add(pan);
    }
  }

  private computeScrewsInMovingEoCPartFlag(tools: Tools) {
    const refPoint = bindedModel.selectedApex !== SelectedApexMech.tibiaProximal ?
      tools.mechanicalAxis.femur.FH.position :
      (tools.mechanicalAxis.tibia instanceof MechanicalAxisTibiaAP ? tools.mechanicalAxis.tibia.CA.position : tools.mechanicalAxis.tibia.MA.position)
    const ost = tools.osteotomy
    for (const screw of tools.blockingScrews.map(x => x.obj)) {
      screw.moveWithEOC = VectorUtils.arePointsOnSameSide(ost.A.position, ost.B.position, Consts.planeNormal, refPoint, screw.position);
    }
  }

  private computeOsteotomyNearScrewsWarning(): void {
    const tools = this._main.toolsAP;
    const fitExt = tools.fitBoneManager.external;
    const ost = tools.osteotomy;
    const blackPlaneCenter = VectorUtils.getVerticesCenter(...tools.EoCPlane.blackPlane.getVertices()).setZ(0);

    for (const screw of fitExt.screws.map(x => x.screw)) {
      if (screw.parent) {
        const center = fitExt.localToWorld(screw.origin.clone()).setZ(0);
        const direction = VectorUtils.getLocalToWorldOrientation(screw.parent, screw.direction).setZ(0);
        const p1 = center.clone().sub(direction.setLength(screw.leftLength));
        const p2 = center.clone().add(direction.setLength(screw.rightLength));
        const p1proj = VectorUtils.projectOnVector(p1, ost.A.position, ost.B.position);
        const p2proj = VectorUtils.projectOnVector(p2, ost.A.position, ost.B.position);
        const dist1sign = (VectorUtils.arePointsOnSameSide(ost.A.position, ost.B.position, Consts.planeNormal, p1, blackPlaneCenter) ? 1 : -1) * (bindedModel.selectedApex !== SelectedApexMech.femurProximal ? -1 : 1);
        const dist2sign = (VectorUtils.arePointsOnSameSide(ost.A.position, ost.B.position, Consts.planeNormal, p2, blackPlaneCenter) ? 1 : -1) * (bindedModel.selectedApex !== SelectedApexMech.femurProximal ? -1 : 1);

        const dist1 = p1proj.distanceTo(p1) * dist1sign;
        const dist2 = p2proj.distanceTo(p2) * dist2sign;

        if (dist1 < 5 || dist2 < 5) {
          this._main.model.messages.isScrewNearOstAP = true;
          return;
        }
      }
    }
    this._main.model.messages.isScrewNearOstAP = false;
  }
}

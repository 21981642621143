import { ActionsBase, BridgeResultMessagesBase, CaseModel, SelectedApexMech, State as StateBase, StateDescription as StateDescriptionBase, StateTransitionData } from "@ortho-next/nextray-core";
import { BridgeResultMessages } from "../Core/Bridge";
import { FitboneMesh } from "../Models/AppModel";
import { Context } from "./Context";

/**
 * List of context events.
 */
export interface Actions extends ActionsBase {
	/**
	 * Sets model to get case data (only when app starts).
	 */
	setModel: CaseModel;
	/**
	 * Set insert selected apex during ostoetomy workflow.
	 */
	selectApex: SelectedApexMech;
	/**
	 * Ends insert osteotomy workflow.
	 */
	confirmApex: never;
	/**
	 * Adds or removes the plane cut along the osteotomy.
	 */
	cutEoC: boolean;
	/**
	 * Sends a simple code message to the Bridge object.
	 */
	onBridgeResult: BridgeResultMessages | BridgeResultMessagesBase;
	/**
	 * Starts select osteotomy workflow.
	 */
	startSelectingApex: never;
	/**
	 * Ends select apex workflow and restore old selected apex value.
	 */
	cancelSelectingApex: never;
	/**
	 * Starts insertion point workflow.
	 */
	startInsertionPoint: never;
	/**
	 * Cancel insertion point workflow.
	 */
	cancelInsertionPoint: never;
	/**
	 * Confirm insertion point workflow.
	 */
	confirmInsertionPoint: never;
	/**
	 * Rotate camera based on current state.
	 */
	rotateCamera: never;
	/**
	 * Starts the inserting fitbone workflow.
	 */
	startInsertingFitbone: never;
	/**
	 * Ends inserting fitbone workflow.
	 */
	confirmFitbone: never;
	/**
	 * Ends inserting fitbone workflow and restore old selected fitbone.
	 */
	cancelFitbone: never;
	/**
	 * Inserts fitbone to the scenes.
	 */
	insertFitbone: FitboneMesh;
	/**
	 * Lock/Unlock stroke movement.
	 */
	lockStroke: boolean;
	/**
	 * Updates measures available for print report only.
	 */
	updateMeausuresOnlyPrint: never;
	/**
	 * Generates images for print report.
	 */
	generateImagesToPrint: { w: number, h: number };
	/**
	* Start the crop polygon tool
	*/
	startCropPolygon: never;
	/**
	* Confirm and close the crop polygon tool
	*/
	confirmCropPolygon: never;
	/**
	* Open viewer.
	*/
	openViewer: never;
	/**
	* Close viewer.
	*/
	closeViewer: never;
	/**
	* Set viewer.
	*/
	setViewer: ViewerStateTypes;
	/**
	* Add a Blocking Screw in the active view.
	*/
	addBlockingScrew: never;
	/**
	* Delete the selected Blocking Screw in the active view.
	*/
	deleteBlockingScrew: never;
	/**
	* Delete the selected Measurement Tool in the active view.
	*/
	deleteMeasurementTool: never;
	/**
	 * Set realistic zoom in the active view.
	 */
	setRealisticZoom: number;
}

export enum OsteotomyCut {
	none,
	opening,
	closing
}

export enum PrintStateTypes {
	none,
	deformityAnalysis,
	openFitboneLock,
	closeFitboneLock,
}

export enum ViewerStateTypes {
	none,
	fitToView,
	proximal,
	distal,
	osteotomy
}

export enum StateTypes { // important: sorted by flow
	loading,
	new,  //todo remove
	calibrated, //todo remove
	deformityAnalysis,
	EOC,
	templating,
	RPM,
	tlhex
}

/**
 * Workflow list.
 */
export enum WorkflowRunning {
	none,
	insertOsteotomy,
	insertInsertionPoint,
	insertFitbone
}

/**
 * Data of current state.
 */
export interface StateDescription extends StateDescriptionBase {
	isWorkflowRunning: WorkflowRunning; //override
}

/**
 * Abstract class to create a state.
 */
export abstract class State extends StateBase {
	public abstract stateDescription: StateDescription;
	/**
	 * Abstract function called after the state is initialized and assigned to the context.
	 */
	public abstract initState(): void;
	/**
	 * Abstract function to manage the event logic of a specific state.
	 */
	protected abstract handleChangeStateEvent<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData;
	protected _context: Context;
	// protected _caseModel: CaseModel;
	protected _restore: object;
	public type: StateTypes;

	/**
	* Function called after the state is initialized and assigned to the context.
	*/
	public onAfterInit(): void {
		super.onAfterInit();
		this.handle("rotateCamera");
	}

	constructor(context: Context, caseModel: CaseModel, type: StateTypes, restore?: object) {
		super(context, caseModel as any, type, restore); //todo fix
	}

	protected handleNotChangeStateEvent<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData {
		switch (event) {
			case "rotateCamera":
				this.dispatchToMain(event, args);
				return { type: this.type };
		}
		return super.handleNotChangeStateEvent(event, args);
	}

	//override

	public handle<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData {
		return super.handle(event, args);
	}
	protected dispatchToMain<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData {
		return super.dispatchToMain(event, args);
	}
}

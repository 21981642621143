import { BridgeActionsBase, BridgeBase, CaseModel, SelectedApexMech } from "@ortho-next/nextray-core";
import { FitboneMesh } from "../Models/AppModel";
import { ViewerStateTypes } from "../States/State";

/**
 * List of events that can be triggered by the angular part to interact with the three.js part.
 */
export interface BridgeActions extends BridgeActionsBase {
	/**
	 * Sets model to get case data (only when app starts).
	 */
	setModel: CaseModel;
	/**
	 * Sets femur proximal as osteotomy site during insert osteotomy worflow.
	 */
	apexFemurProximal: never;
	/**
	 * Sets femur distal as osteotomy site during insert osteotomy worflow.
	 */
	apexFemurDistal: never;
	/**
	 * Sets tibia proximal as osteotomy site during insert osteotomy worflow.
	 */
	apexTibiaProximal: never;
	/**
	 * Ends selecting apex workflow.
	 */
	confirmApex: never;
	/**
	 * Adds or removes the plane cut along the osteotomy.
	 */
	cutEoC: boolean;
	/**
	 * Starts selecting apex workflow.
	 */
	startSelectingApex: never;
	/**
	 * Ends selecting apex workflow and restore old selected apex value.
	 */
	cancelSelectingApex: never;
	/**
	 * Cancel insertion point workflow.
	 */
	cancelInsertionPoint: never;
	/**
	 * Confirm insertion point workflow.
	 */
	confirmInsertionPoint: never;
	/**
	 * Switches the current state to RPM.
	 */
	switchToRPM: never;
	/**
	 * Starts the inserting fitbone workflow.
	 */
	startInsertingFitbone: never;
	/**
	 * Ends inserting fitbone workflow.
	 */
	confirmFitbone: never;
	/**
	 * Ends inserting fitbone workflow and restore old selected fitbone.
	 */
	cancelFitbone: never;
	/**
	 * Inserts fitbone to the scenes.
	 */
	insertFitbone: FitboneMesh;
	/**
	 * Lock/Unlock stroke movement.
	 */
	lockStroke: boolean;
	/**
	 * Generates images for print report.
	 */
	generateImagesToPrint: { w: number, h: number };
	/**
	 * Updates measures available for print report only.
	 */
	updateMeausuresOnlyPrint: never;
	/**
	* Start the crop polygon tool
	*/
	startCropPolygon: never;
	/**
	* Confirm and close the crop polygon tool
	*/
	confirmCropPolygon: never;
	/**
	* Open viewer.
	*/
	openViewer: never;
	/**
	* Close viewer.
	*/
	closeViewer: never;
	/**
	* Set viewer.
	*/
	setViewer: ViewerStateTypes;
	/**
	* Add a Blocking Screw in the active view.
	*/
	addBlockingScrew: never;
	/**
	* Delete the selected Blocking Screw in the active view.
	*/
	deleteBlockingScrew: never;
	/**
	 * Set realistic zoom in the active view.
	 */
	setRealisticZoom: number;

	/** LAYERS EVENTS **/

	/**
	 * Toggles the visibility of fitbone layer
	 */
	setVisibility_fitbone: boolean;
	/**
	 * Toggles the visibility of grid layer
	 */
	setVisibility_grid: boolean;
	/**
	 * Toggles the visibility of grid center
	 */
	setVisibility_grid_center: boolean;
	/**
	 * Toggles the visibility of plane layer
	 */
	setVisibility_plane: boolean;
}

/**
 * Messages sent from three.js part to angular part.
 */
export enum BridgeResultMessages {
	/**
	 * Error: osteotomy line is too long.
	 */
	osteotomyLengthError = "osteotomyLengthError",
	/**
	 * Error: one of the two osteotomy doens't intersect the plane. Cannot cut the plane along osteotomy.
	 */
	cannotCut = "cannotCut"
}

/**
 * Manages the communications between three.js and angular part.
 */
export class Bridge extends BridgeBase {

	//override
	public mapEvent<K extends keyof BridgeActions>(type: K, args?: BridgeActions[K]): void {
		switch (type) {
			case "setModel":
				return this._context.handle("setModel", args as CaseModel);
			case "apexFemurProximal":
				return this._context.handle("selectApex", SelectedApexMech.femurProximal);
			case "apexFemurDistal":
				return this._context.handle("selectApex", SelectedApexMech.femurDistal);
			case "apexTibiaProximal":
				return this._context.handle("selectApex", SelectedApexMech.tibiaProximal);
			case "confirmApex":
				return this._context.handle("confirmApex");
			case "startCropPolygon":
				return this._context.handle("startCropPolygon");
			case "confirmCropPolygon":
				return this._context.handle("confirmCropPolygon");
			case "cutEoC":
				return this._context.handle("cutEoC", args as boolean);
			case "startSelectingApex":
				return this._context.handle("startSelectingApex");
			case "cancelSelectingApex":
				return this._context.handle("cancelSelectingApex");
			case "cancelInsertionPoint":
				return this._context.handle("cancelInsertionPoint");
			case "confirmInsertionPoint":
				return this._context.handle("confirmInsertionPoint");
			case "startInsertingFitbone":
				return this._context.handle("startInsertingFitbone");
			case "cancelFitbone":
				return this._context.handle("cancelFitbone");
			case "confirmFitbone":
				return this._context.handle("confirmFitbone");
			case "insertFitbone":
				return this._context.handle("insertFitbone", args as FitboneMesh);
			case "lockStroke":
				return this._context.handle("lockStroke", args as boolean);
			case "updateMeausuresOnlyPrint":
				return this._context.handle("updateMeausuresOnlyPrint");
			case "generateImagesToPrint":
				return this._context.handle("generateImagesToPrint", args as { w: number, h: number });
			case "openViewer":
				return this._context.handle("openViewer");
			case "closeViewer":
				return this._context.handle("closeViewer");
			case "setViewer":
				return this._context.handle("setViewer", args as ViewerStateTypes);
			case "addBlockingScrew":
				return this._context.handle("addBlockingScrew");
			case "deleteBlockingScrew":
				return this._context.handle("deleteBlockingScrew");
			case "setRealisticZoom":
				return this._context.handle("setRealisticZoom", args as number);
			case "setVisibility_fitbone":
				return this._context.handle("setVisibility", { key: "fitbone", value: args as boolean });
			case "setVisibility_grid":
				return this._context.handle("setVisibility", { key: "grid", value: args as boolean });
			case "setVisibility_grid_center":
				return this._context.handle("setVisibility", { key: "grid_center", value: args as boolean });
			case "setVisibility_plane":
				return this._context.handle("setVisibility", { key: "plane", value: args as boolean });
		}

		return super.mapEvent(type, args);
	}
}

import { Component, OnInit } from '@angular/core';


/**
 * Main container component of application
 */
@Component({
  selector: 'container-template',
  templateUrl: './container-template.component.html'
})
export class ContainerTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}

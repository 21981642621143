import { MechanicalAxisFemurAP, MultiViewsElementCustom, SelectedApexMech, ViewType } from "@ortho-next/nextray-core";
import { MechanicalAxisFemurLT } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/FemurAnalyzerLT";
import { MechanicalAxisTibiaAP } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/TibiaAnalyzerAP";
import { MechanicalAxisTibiaLT } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/TibiaAnalyzerLT";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { CustomCameraControls } from '../Controls/CustomCameraControls';
import { bindedModel } from '../Models/BindedModel';
import { ViewerStateTypes } from '../States/State';
import { Main } from './Main';
import { Scene } from './Scene';
import { Tools } from './Tools';


/**
 * Manages the Viewer tool.
 */
export class Viewer {
  private _main: Main;

  constructor(main: Main) {
    this._main = main;
  }

  /**
  * Set initial configuration in both views.
  */
  start(): void {
    this._main.toolsAP.fitBoneManager.isSyncEnabled = false;
    this._main.hasLT && (this._main.toolsLT.fitBoneManager.isSyncEnabled = false);

    this._main.toolsInit.closeFitbone(this._main.toolsAP, ViewType.AP);
    this._main.hasLT && this._main.toolsInit.closeFitbone(this._main.toolsLT, ViewType.LT);

    this._main.toolsAP.fitBoneManager.isSyncEnabled = true;
    this._main.hasLT && (this._main.toolsLT.fitBoneManager.isSyncEnabled = true);
  }

  /**
  * Set final configuration in both views.
  */
  stop(): void {
    this._main.scenes[0].cameraControls.fitToPlane();
    this._main.scenes[1].cameraControls.fitToPlane();
    (this._main.multiviewRenderer.viewsports[0] as MultiViewsElementCustom).cameraControls.fitToPlane();
    (this._main.multiviewRenderer.viewsports[1] as MultiViewsElementCustom).cameraControls.fitToPlane();
  }

  /**
  * Set configuration in both views according to control type.
  */
  set(ctrlType: ViewerStateTypes): void {
    const otherView = this._main.activeTools.viewType === ViewType.AP ? ViewType.LT : ViewType.AP;
    if (this._main.multiViewsActive) {
      this.setView((this._main.multiviewRenderer.viewsports[0] as MultiViewsElementCustom).cameraControls as CustomCameraControls, ctrlType, this._main.toolsAP);
      this.setView((this._main.multiviewRenderer.viewsports[1] as MultiViewsElementCustom).cameraControls as CustomCameraControls, ctrlType, this._main.toolsLT);
      this.setView((this._main.scenes[otherView] as Scene).cameraControls, ctrlType, this._main.getTools(otherView));
    } else {
      this._main.hasAP && this.setView((this._main.scenes[0] as Scene).cameraControls, ctrlType, this._main.toolsAP);
      this._main.hasLT && this.setView((this._main.scenes[1] as Scene).cameraControls, ctrlType, this._main.toolsLT);
      if (this._main.hasAP && this._main.hasLT) {
        this.setView((this._main.multiviewRenderer.viewsports[otherView] as MultiViewsElementCustom).cameraControls as CustomCameraControls, ctrlType, this._main.getTools(otherView));
      }
    }
  }

  private setView(cameraCtrl: CustomCameraControls, setType: ViewerStateTypes, tools: Tools): void {
    cameraCtrl.fitToPlane();
    if (setType && setType !== ViewerStateTypes.fitToView) {
      // zoom
      const factor = 1.0 - (500 * 0.00025) * 1.5 * 4.5;
      cameraCtrl.zoomByFactor(factor);
      cameraCtrl.dispatchEvent({ type: "zoom", args: factor });
      // pan
      const pos = this.getCenterPosition(tools, setType);
      const dir = pos.sub(cameraCtrl.camera.position.clone().setZ(0));
      pos && cameraCtrl.panByVector(dir);
      pos && cameraCtrl.dispatchEvent({ type: "pan", args: pos });
    }
  }

  private getCenterPosition(tools: Tools, vType: ViewerStateTypes): Vector3 {
    let pos: Vector3;
    if (vType === ViewerStateTypes.osteotomy) {
      return tools.osteotomy.C.position.clone().setZ(0);
    }
    const approach = bindedModel.selectedApex;
    if (tools.viewType === ViewType.AP) {
      if (approach === SelectedApexMech.femurDistal || approach === SelectedApexMech.femurProximal) {
        const mechAxis = tools.clonedMechanicalAxis.femur as MechanicalAxisFemurAP;
        vType === ViewerStateTypes.proximal && (pos = mechAxis.mechanical.GT.position.clone());
        vType === ViewerStateTypes.distal && (pos = mechAxis.CE.position.clone());
      } else {
        const mechAxis = tools.clonedMechanicalAxis.tibia as MechanicalAxisTibiaAP;
        vType === ViewerStateTypes.proximal && (pos = mechAxis.CP.position.clone());
        vType === ViewerStateTypes.distal && (pos = mechAxis.CA.position.clone());
      }
    } else {
      if (approach === SelectedApexMech.femurDistal || approach === SelectedApexMech.femurProximal) {
        const mechAxis = tools.clonedMechanicalAxis.femur as MechanicalAxisFemurLT;
        vType === ViewerStateTypes.proximal && (pos = mechAxis.FH.position.clone());
        vType === ViewerStateTypes.distal && (pos = mechAxis.TE.position.clone());
      } else {
        const mechAxis = tools.clonedMechanicalAxis.tibia as MechanicalAxisTibiaLT;
        vType === ViewerStateTypes.proximal && (pos = mechAxis.FP.position.clone());
        vType === ViewerStateTypes.distal && (pos = mechAxis.MA.position.clone());
      }
    }
    pos && pos.setZ(0);
    return pos;
  }

}

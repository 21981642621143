import { Component } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component to handle feedback messages on canvas view
*/
@Component({
  selector: 'messages',
  templateUrl: './messages.component.html',
  styles: []
})
export class MessagesComponent extends BaseComponent {

  constructor(
    private langSrv: LanguageService,
    private canvaSrv: CanvasService
  ) {
    super(langSrv, canvaSrv);
  }

}

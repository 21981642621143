<div class="ngb-toasts" style="position: absolute; z-index: 0;">
  <message *ngIf="messages?.isInsPointOutBoxAP"
           text="{{labels.MESSAGES_COMPONENT_INS_POINT_OUT_BOX_AP}}">
  </message>
  <message *ngIf="messages?.isInsPointOutBoxLT"
           text="{{labels.MESSAGES_COMPONENT_INS_POINT_OUT_BOX_LT}}">
  </message>
  <message *ngIf="messages?.isScrewNearOstAP"
           text="{{labels.MESSAGES_COMPONENT_SCREW_NEAR_OST_AP}}">
  </message>
</div>

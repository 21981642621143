import { DraggablePoint, MechanicalAxisFemurAP, SelectedApexMech, ViewType } from "@ortho-next/nextray-core";
import { MechanicalAxisFemurLT } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/FemurAnalyzerLT";
import { MechanicalAxisTibiaAP } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/TibiaAnalyzerAP";
import { MechanicalAxisTibiaLT } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/TibiaAnalyzerLT";
import { BindedModel } from "../../Models/BindedModel";
import { MechanicalAxisAP } from "../DeformityAnalyzer/FullAnalyzerAP";
import { MechanicalAxisLT } from "../DeformityAnalyzer/FullAnalyzerLT";

export class InsertionPoint extends DraggablePoint {

  constructor(viewType: ViewType) {
    super("point", viewType, 0xED7D31, undefined, false);
    this.name = "Insertion point";

    this.bindProperty("visible", (m: BindedModel) => {
      return m.isInsertionPointInserted;
    }, ["isInsertionPointInserted"]);

    this.bindProperty("isEnabled", (m: BindedModel) => {
      return m.isInsertionPointEnabled;
    }, ["isInsertionPointEnabled"]);

  }

  /**
   * Gets position according to selected apex and mechanical axis points.
   */
  public calculate(selectedApex: SelectedApexMech, mechanicalAxis: MechanicalAxisAP | MechanicalAxisLT): void {
    switch (selectedApex) {
      case SelectedApexMech.femurProximal:
        return this.handleProximalFemurApex(mechanicalAxis.femur);
      case SelectedApexMech.femurDistal:
        return this.handleDistalFemurApex(mechanicalAxis.femur);
      case SelectedApexMech.tibiaProximal:
        return this.handleTibiaApex(mechanicalAxis.tibia);
      case SelectedApexMech.none:
        return this.handleNoSelectedApex();
    }
  }

  private handleNoSelectedApex(): void { //todo
    this.position.set(0, 0, 0);
  }

  private handleProximalFemurApex(femur: MechanicalAxisFemurAP | MechanicalAxisFemurLT): void {
    if (femur instanceof MechanicalAxisFemurAP) {
      this.position.copy(femur.mechanical.GT.position);
    } else {
      this.position.copy(femur.FH.position);
    }
  }

  private handleDistalFemurApex(femur: MechanicalAxisFemurAP | MechanicalAxisFemurLT): void {
    if (femur instanceof MechanicalAxisFemurAP) {
      this.position.copy(femur.CE.position);
    } else {
      this.position.copy(femur.TE.position);
    }
  }


  private handleTibiaApex(tibia: MechanicalAxisTibiaAP | MechanicalAxisTibiaLT): void {
    if (tibia instanceof MechanicalAxisTibiaAP) {
      this.position.copy(tibia.CP.position);
    } else {
      this.position.copy(tibia.FP.position);
    }
  }

}

import { DraggablePoint, LimitUtils, SelectableTool, VectorUtils, ViewType } from "@ortho-next/nextray-core";
import { Plane } from "@ortho-next/nextray-core/Tools/Plane";
import { CalculationPoint } from "@ortho-next/nextray-core/Tools/Primitive/CalculationPoint";
import { LinePointToPoint } from "@ortho-next/nextray-core/Tools/Primitive/LinePointToPoint";
import { Group, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { EoCPlaneConfig as Config } from "../../Config/EoCPlaneConfig";
import { BindedModel, bindedModel } from "../../Models/BindedModel";
import { Consts } from "../../Utils/Consts";
import { EoCPlane } from "./EoCPlane";
import { CropVertices, EOCPlaneVertices } from "./EoCPlaneBase";
import { Default } from "@ortho-next/nextray-core/Utils/Default";

/**
 * Tool that allow to crop the cut polygon.
 */
export class CropPolygonTool extends Group implements SelectableTool {
  public isSelectable = true;
  public isDeletable = false;
  public isSelected: boolean;
  private readonly _startA: CalculationPoint;
  private readonly _startB: CalculationPoint;
  private readonly _startC: CalculationPoint;
  private readonly _startD: CalculationPoint;
  private readonly _startE: CalculationPoint;
  public line1: LinePointToPoint;
  public line2: LinePointToPoint;
  public line3: LinePointToPoint;
  public line4: LinePointToPoint;
  public line5: LinePointToPoint;

  public croppedA: CalculationPoint;
  public croppedB: CalculationPoint;
  public croppedC: CalculationPoint;
  public croppedD: CalculationPoint;
  public croppedE: CalculationPoint;

  public cropAB: DraggablePoint;
  public cropBC: DraggablePoint;
  public cropCD: DraggablePoint;
  public cropDE: DraggablePoint;
  public cropEA: DraggablePoint;
  public cropCA: DraggablePoint;
  public cropDA: DraggablePoint;

  private _viewType: ViewType;
  private _plane: Plane;
  private _eocPlane: EoCPlane;
  private _startingPoints: CropVertices;
  private _numOfVertices: number;
  private _internalLimits: Map<string, Vector3> = new Map();

  public onSelect(): void {
    Default.colorAllDraggablePoints(this);
  }

  public onDeselect(): void {
    Default.restoreColorAllDraggablePoints(this);
  }

  constructor(viewType: ViewType, plane: Plane, eocPlane: EoCPlane) {
    super();
    this.name = "CropPolygonTool";
    this._viewType = viewType;
    this._plane = plane;
    this._eocPlane = eocPlane;

    this.add(
      this._startA = new CalculationPoint("A"),
      this._startB = new CalculationPoint("B"),
      this._startC = new CalculationPoint("C"),

      this.croppedA = new CalculationPoint("CroppedA"),
      this.croppedB = new CalculationPoint("CroppedB"),
      this.croppedC = new CalculationPoint("CroppedC"),

      this.line1 = new LinePointToPoint("Line1", Config.line1_color, this.croppedA, this.croppedB),
      this.line2 = new LinePointToPoint("Line2", Config.line2_color, this.croppedB, this.croppedC),
      this.line3 = new LinePointToPoint("Line3", Config.line3_color),
    );

    this._startD = new CalculationPoint("D");
    this._startE = new CalculationPoint("E");
    this.croppedD = new CalculationPoint("CroppedD");
    this.croppedE = new CalculationPoint("CroppedE");

    this.line4 = new LinePointToPoint("Line4", Config.line4_color);
    this.line5 = new LinePointToPoint("Line5", Config.line5_color);

    this.cropAB = new DraggablePoint("CroppedAB", viewType, 0xFF0000);
    this.cropBC = new DraggablePoint("CroppedBC", viewType, 0xFF0000);
    this.cropCD = new DraggablePoint("CroppedCD", viewType, 0xFF0000);
    this.cropDE = new DraggablePoint("CroppedDE", viewType, 0xFF0000);
    this.cropEA = new DraggablePoint("CroppedEA", viewType, 0xFF0000);
    this.cropCA = new DraggablePoint("CroppedCA", viewType, 0xFF0000);
    this.cropDA = new DraggablePoint("CroppedDA", viewType, 0xFF0000);

    this.bindEvents();

    this.bindProperty('visible', (m: BindedModel) => {
      return m.isCropPolygonToolVisible;
    }, ['isCropPolygonToolVisible']);

  }


  protected bindEvents(): void {

    this.croppedA.bindEvent("onPositionComponentChange", (args) => {
      this.cropAB.position.copy(this.croppedA.position.clone().lerp(this.croppedB.position, 0.5));
      this.cropCA.parent && this.cropCA.position.copy(this.croppedC.position.clone().lerp(this.croppedA.position, 0.5));
      this.cropDA.parent && this.cropDA.position.copy(this.croppedD.position.clone().lerp(this.croppedA.position, 0.5));
      this.cropEA.parent && this.cropEA.position.copy(this.croppedE.position.clone().lerp(this.croppedA.position, 0.5));
    });

    this.croppedB.bindEvent("onPositionComponentChange", (args) => {
      this.cropAB.position.copy(this.croppedA.position.clone().lerp(this.croppedB.position, 0.5));
      this.cropBC.position.copy(this.croppedB.position.clone().lerp(this.croppedC.position, 0.5));
    });

    this.croppedC.bindEvent("onPositionComponentChange", (args) => {
      this.cropBC.position.copy(this.croppedB.position.clone().lerp(this.croppedC.position, 0.5));
      this.cropCA.parent && this.cropCA.position.copy(this.croppedC.position.clone().lerp(this.croppedA.position, 0.5));
      this.cropCD.parent && this.cropCD.position.copy(this.croppedC.position.clone().lerp(this.croppedD.position, 0.5));
    });

    this.croppedD.bindEvent("onPositionComponentChange", (args) => {
      this.cropCD.parent && this.cropCD.position.copy(this.croppedC.position.clone().lerp(this.croppedD.position, 0.5));
      this.cropDA.parent && this.cropDA.position.copy(this.croppedD.position.clone().lerp(this.croppedA.position, 0.5));
      this.cropDE.parent && this.cropDE.position.copy(this.croppedD.position.clone().lerp(this.croppedE.position, 0.5));
    });

    this.croppedE.bindEvent("onPositionComponentChange", (args) => {
      this.cropDE.parent && this.cropDE.position.copy(this.croppedD.position.clone().lerp(this.croppedE.position, 0.5));
      this.cropEA.parent && this.cropEA.position.copy(this.croppedE.position.clone().lerp(this.croppedA.position, 0.5));
    });

    this.cropAB.bindEvent("onDragMove", (args) => {
      args.preventDefault = true;
      const dir = this.croppedA.position.clone().sub(this.croppedB.position);

      LimitUtils.applyLimitAndSetVector(this._startA.position, this._startB.position, args.position, this._startA.position.clone().lerp(this._startB.position, 0.5), this._startC.position);
      const nearestPoint = this._internalLimits.get(this.cropAB.name);
      LimitUtils.applyLimitAndSetVector(nearestPoint, nearestPoint.clone().add(dir), args.position, nearestPoint, this._startA.position);

      const intersectionBC = VectorUtils.lines2DIntersection(args.position, args.position.clone().add(dir), this.croppedB.position, this.croppedC.position);
      const prevVertex = this._numOfVertices === 3 ? this.croppedC : (this._numOfVertices === 4 ? this.croppedD : this.croppedE);
      const intersection2 = VectorUtils.lines2DIntersection(args.position, args.position.clone().add(dir), this.croppedA.position, prevVertex.position);
      this.croppedB.position.copy(intersectionBC);
      this.croppedA.position.copy(intersection2);
    });

    this.cropBC.bindEvent("onDragMove", (args) => {
      args.preventDefault = true;
      const dir = this.croppedB.position.clone().sub(this.croppedC.position);

      LimitUtils.applyLimitAndSetVector(this._startB.position, this._startC.position, args.position, this._startC.position.clone().lerp(this._startB.position, 0.5), this._startA.position);
      const nearestPoint = this._internalLimits.get(this.cropBC.name);
      LimitUtils.applyLimitAndSetVector(nearestPoint, nearestPoint.clone().add(dir), args.position, nearestPoint, this._startB.position);

      const intersectionAB = VectorUtils.lines2DIntersection(args.position, args.position.clone().add(dir), this.croppedB.position, this.croppedA.position);
      const nextVertex = this._numOfVertices === 3 ? this.croppedA : this.croppedD;
      const intersection2 = VectorUtils.lines2DIntersection(args.position, args.position.clone().add(dir), this.croppedC.position, nextVertex.position);
      this.croppedB.position.copy(intersectionAB);
      this.croppedC.position.copy(intersection2);
    });

    this.cropCA.bindEvent("onDragMove", (args) => {
      args.preventDefault = true;
      const dir = this.croppedA.position.clone().sub(this.croppedC.position);

      LimitUtils.applyLimitAndSetVector(this._startC.position, this._startA.position, args.position, this._startA.position.clone().lerp(this._startC.position, 0.5), this._startB.position);
      const nearestPoint = this._internalLimits.get(this.cropCA.name);
      LimitUtils.applyLimitAndSetVector(nearestPoint, nearestPoint.clone().add(dir), args.position, nearestPoint, this._startA.position);

      const intersectionAB = VectorUtils.lines2DIntersection(args.position, args.position.clone().add(dir), this.croppedB.position, this.croppedA.position);
      const intersectionBC = VectorUtils.lines2DIntersection(args.position, args.position.clone().add(dir), this.croppedB.position, this.croppedC.position);
      this.croppedA.position.copy(intersectionAB);
      this.croppedC.position.copy(intersectionBC);
    });

    this.cropCD.bindEvent("onDragMove", (args) => {
      args.preventDefault = true;
      const dir = this.croppedC.position.clone().sub(this.croppedD.position);

      LimitUtils.applyLimitAndSetVector(this._startC.position, this._startD.position, args.position, this._startC.position.clone().lerp(this._startD.position, 0.5), this._startA.position);
      const nearestPoint = this._internalLimits.get(this.cropCD.name);
      LimitUtils.applyLimitAndSetVector(nearestPoint, nearestPoint.clone().add(dir), args.position, nearestPoint, this._startC.position);

      const intersectionBC = VectorUtils.lines2DIntersection(args.position, args.position.clone().add(dir), this.croppedB.position, this.croppedC.position);
      const nextVertex = this._numOfVertices === 4 ? this.croppedA : this.croppedE;
      const intersection2 = VectorUtils.lines2DIntersection(args.position, args.position.clone().add(dir), this.croppedD.position, nextVertex.position);
      this.croppedC.position.copy(intersectionBC);
      this.croppedD.position.copy(intersection2);
    });

    this.cropDA.bindEvent("onDragMove", (args) => {
      args.preventDefault = true;
      const dir = this.croppedD.position.clone().sub(this.croppedA.position);

      LimitUtils.applyLimitAndSetVector(this._startD.position, this._startA.position, args.position, this._startA.position.clone().lerp(this._startD.position, 0.5), this._startB.position);
      const nearestPoint = this._internalLimits.get(this.cropDA.name);
      LimitUtils.applyLimitAndSetVector(nearestPoint, nearestPoint.clone().add(dir), args.position, nearestPoint, this._startD.position);

      const intersectionAB = VectorUtils.lines2DIntersection(args.position, args.position.clone().add(dir), this.croppedA.position, this.croppedB.position);
      const intersectionCD = VectorUtils.lines2DIntersection(args.position, args.position.clone().add(dir), this.croppedC.position, this.croppedD.position);
      this.croppedA.position.copy(intersectionAB);
      this.croppedD.position.copy(intersectionCD);
    });

    this.cropDE.bindEvent("onDragMove", (args) => {
      args.preventDefault = true;
      const dir = this.croppedD.position.clone().sub(this.croppedE.position);

      LimitUtils.applyLimitAndSetVector(this._startD.position, this._startE.position, args.position, this._startD.position.clone().lerp(this._startE.position, 0.5), this._startB.position);
      const nearestPoint = this._internalLimits.get(this.cropDE.name);
      LimitUtils.applyLimitAndSetVector(nearestPoint, nearestPoint.clone().add(dir), args.position, nearestPoint, this._startD.position);

      const intersectionDC = VectorUtils.lines2DIntersection(args.position, args.position.clone().add(dir), this.croppedD.position, this.croppedC.position);
      const intersectionEA = VectorUtils.lines2DIntersection(args.position, args.position.clone().add(dir), this.croppedE.position, this.croppedA.position);
      this.croppedD.position.copy(intersectionDC);
      this.croppedE.position.copy(intersectionEA);
    });

    this.cropEA.bindEvent("onDragMove", (args) => {
      args.preventDefault = true;
      const dir = this.croppedE.position.clone().sub(this.croppedA.position);

      LimitUtils.applyLimitAndSetVector(this._startE.position, this._startA.position, args.position, this._startE.position.clone().lerp(this._startA.position, 0.5), this._startB.position);
      const nearestPoint = this._internalLimits.get(this.cropEA.name);
      LimitUtils.applyLimitAndSetVector(nearestPoint, nearestPoint.clone().add(dir), args.position, nearestPoint, this._startA.position);

      const intersectionAB = VectorUtils.lines2DIntersection(args.position, args.position.clone().add(dir), this.croppedA.position, this.croppedB.position);
      const intersectionDE = VectorUtils.lines2DIntersection(args.position, args.position.clone().add(dir), this.croppedD.position, this.croppedE.position);
      this.croppedA.position.copy(intersectionAB);
      this.croppedE.position.copy(intersectionDE);
    });

  }

  /**
  * Get the nearest point from segment created by sidePoint1 and sidePoint2 .
  */
  private getNearestInternalPoint(sidePoint1: Vector3, sidePoint2: Vector3, ...points: Vector3[]): Vector3 {
    const nearestPoint = VectorUtils.getNearestPointFromSegment(sidePoint1, sidePoint2, ...points);
    nearestPoint.setZ(sidePoint1.z);
    return nearestPoint;
  }

  /**
  * Calculate nearest internal point for each polygon side to limit side movement 
  */
  private updateInternalLimits(): void {
    this._internalLimits.clear();
    const mechAxisMng = this._eocPlane.clonedMechanicalAxisManager;
    const osteotomy = this._eocPlane.clonedOsteotomy;

    // Calculate mechanical axis points inside polygon 
    mechAxisMng.updateInternalPoints(this.croppedVertices);

    // Check if osteotomy points are inside polygon
    const planeVertices = this._plane.getVertices(0) as EOCPlaneVertices;
    const vertices = [planeVertices.A, planeVertices.B, planeVertices.C, planeVertices.D];
    const ostInternalPoints: Vector3[] = [];
    VectorUtils.isPointInsidePolygon(osteotomy.A.position, Consts.planeNormal, ...vertices) && ostInternalPoints.push(osteotomy.A.position);
    VectorUtils.isPointInsidePolygon(osteotomy.B.position, Consts.planeNormal, ...vertices) && ostInternalPoints.push(osteotomy.B.position);

    // Calculate nearest internal point for each polygon side 
    const points = [...ostInternalPoints, ...mechAxisMng.internalPoints];
    if (this._numOfVertices === 3) {
      this.cropAB.parent && this._internalLimits.set(this.cropAB.name, this.getNearestInternalPoint(this._startA.position, this._startB.position, this._startC.position, ...points));
      this.cropBC.parent && this._internalLimits.set(this.cropBC.name, this.getNearestInternalPoint(this._startB.position, this._startC.position, this._startA.position, ...points));
      this.cropCA.parent && this._internalLimits.set(this.cropCA.name, this.getNearestInternalPoint(this._startC.position, this._startA.position, this._startB.position, ...points));
    }
    if (this._numOfVertices === 4) {
      this.cropAB.parent && this._internalLimits.set(this.cropAB.name, this.getNearestInternalPoint(this._startA.position, this._startB.position, this._startC.position, this._startD.position, ...points));
      this.cropBC.parent && this._internalLimits.set(this.cropBC.name, this.getNearestInternalPoint(this._startB.position, this._startC.position, this._startA.position, this._startD.position, ...points));
      this.cropCD.parent && this._internalLimits.set(this.cropCD.name, this.getNearestInternalPoint(this._startC.position, this._startD.position, this._startA.position, this._startB.position, ...points));
      this.cropDA.parent && this._internalLimits.set(this.cropDA.name, this.getNearestInternalPoint(this._startD.position, this._startA.position, this._startB.position, this._startC.position, ...points));
    }
    if (this._numOfVertices === 5) {
      this.cropAB.parent && this._internalLimits.set(this.cropAB.name, this.getNearestInternalPoint(this._startA.position, this._startB.position, this._startC.position, this._startD.position, this._startE.position, ...points));
      this.cropBC.parent && this._internalLimits.set(this.cropBC.name, this.getNearestInternalPoint(this._startB.position, this._startC.position, this._startA.position, this._startD.position, this._startE.position, ...points));
      this.cropCD.parent && this._internalLimits.set(this.cropCD.name, this.getNearestInternalPoint(this._startC.position, this._startD.position, this._startA.position, this._startB.position, this._startE.position, ...points));
      this.cropDE.parent && this._internalLimits.set(this.cropDE.name, this.getNearestInternalPoint(this._startD.position, this._startE.position, this._startA.position, this._startB.position, this._startC.position, ...points));
      this.cropEA.parent && this._internalLimits.set(this.cropEA.name, this.getNearestInternalPoint(this._startE.position, this._startA.position, this._startB.position, this._startC.position, this._startD.position, ...points));
    }
  }

  /**
  * Check if osteotomy intersect plane.
  */
  public check(): boolean {
    this._startingPoints = this._eocPlane.getStartVertices();
    return !!this._startingPoints;
  }

  /**
  * Init crop polygon tool.
  */
  public start(): void {
    this.reset();
    this.initCropPlane();
  }

  /**
  * Confirm cropped vertices and update eoc polygon.
  */
  public confirm(): void {
    const vertices = this.croppedVertices;
    this._eocPlane.updateCroppedVertices(vertices);
  }

  /**
  * Get current cropped vertices.
  */
  private get croppedVertices(): CropVertices {
    const vertices = new CropVertices();
    vertices.numOfVertices = this._numOfVertices;
    vertices.A = this.croppedA.position;
    vertices.B = this.croppedB.position;
    vertices.C = this.croppedC.position;
    vertices.D = this._numOfVertices >= 4 ? this.croppedD.position : null;
    vertices.E = this._numOfVertices === 5 ? this.croppedE.position : null;
    return vertices;
  }

  /**
  * Reset crop polygon tool.
  */
  private reset(): void {
    this._startA.position.set(0, 0, 0);
    this._startB.position.set(0, 0, 0);
    this._startC.position.set(0, 0, 0);
    this._startD.position.set(0, 0, 0);
    this._startE.position.set(0, 0, 0);
    this.remove(this._startD, this._startE, this.line4, this.line5);

    this.croppedA.position.set(0, 0, 0);
    this.croppedB.position.set(0, 0, 0);
    this.croppedC.position.set(0, 0, 0);
    this.croppedD.position.set(0, 0, 0);
    this.croppedE.position.set(0, 0, 0);
    this.remove(this.croppedD, this.croppedE);

    this.cropAB.position.set(0, 0, 0);
    this.cropBC.position.set(0, 0, 0);
    this.cropCD.position.set(0, 0, 0);
    this.cropDE.position.set(0, 0, 0);
    this.cropEA.position.set(0, 0, 0);
    this.cropCA.position.set(0, 0, 0);
    this.cropDA.position.set(0, 0, 0);
    this.remove(this.cropAB, this.cropBC, this.cropCA, this.cropCD, this.cropDA, this.cropDE, this.cropEA);
  }

  /**
  * Init vertices with initial points or previous cropped vertices.
  */
  private initCropPlane(): boolean {
    const startingPoints = this._startingPoints;
    if (!startingPoints) { return false; }

    const currentVertices = bindedModel.isPolygonCropped ? this._eocPlane.getCurrentVertices() : startingPoints;
    this._numOfVertices = startingPoints.numOfVertices;

    if (this._numOfVertices === 4) {
      //CROP 4 VERTICES

      this._startA.position.copy(startingPoints.A).setZ(0).add(Consts.planeNormal);
      this._startB.position.copy(startingPoints.B).setZ(0).add(Consts.planeNormal);
      this._startC.position.copy(startingPoints.C).setZ(0).add(Consts.planeNormal);
      this._startD.position.copy(startingPoints.D).setZ(0).add(Consts.planeNormal);

      if (startingPoints.blockedVertices === "AB") { this.add(this.cropBC, this.cropCD, this.cropDA); }
      if (startingPoints.blockedVertices === "BC") { this.add(this.cropAB, this.cropCD, this.cropDA); }
      if (startingPoints.blockedVertices === "CD") { this.add(this.cropAB, this.cropBC, this.cropDA); }
      if (startingPoints.blockedVertices === "DA") { this.add(this.cropAB, this.cropBC, this.cropCD); }

      this.add(this._startD, this.croppedD, this.line4);

      this.croppedA.position.copy(currentVertices.A).setZ(0).add(Consts.planeNormal);
      this.croppedB.position.copy(currentVertices.B).setZ(0).add(Consts.planeNormal);
      this.croppedC.position.copy(currentVertices.C).setZ(0).add(Consts.planeNormal);
      this.croppedD.position.copy(currentVertices.D).setZ(0).add(Consts.planeNormal);

      this.line3.v1 = this.croppedC;
      this.line3.v2 = this.croppedD;
      this.line4.v1 = this.croppedD;
      this.line4.v2 = this.croppedA;
    }
    else if (this._numOfVertices === 3) {
      //CROP 3 VERTICES

      this._startA.position.copy(startingPoints.A).setZ(0).add(Consts.planeNormal);
      this._startB.position.copy(startingPoints.B).setZ(0).add(Consts.planeNormal);
      this._startC.position.copy(startingPoints.C).setZ(0).add(Consts.planeNormal);

      if (startingPoints.blockedVertices == "A") { this.add(this.cropAB, this.cropCA); }
      if (startingPoints.blockedVertices == "B") { this.add(this.cropAB, this.cropBC); }
      if (startingPoints.blockedVertices == "C") { this.add(this.cropBC, this.cropCA); }
      if (startingPoints.blockedVertices == "D") { this.add(this.cropBC, this.cropCA); }

      this.croppedA.position.copy(currentVertices.A).setZ(0).add(Consts.planeNormal);
      this.croppedB.position.copy(currentVertices.B).setZ(0).add(Consts.planeNormal);
      this.croppedC.position.copy(currentVertices.C).setZ(0).add(Consts.planeNormal);

      this.line3.v1 = this.croppedC;
      this.line3.v2 = this.croppedA;
    }
    else if (this._numOfVertices === 5) {
      //CROP 5 VERTICES

      this._startA.position.copy(startingPoints.A).setZ(0).add(Consts.planeNormal);
      this._startB.position.copy(startingPoints.B).setZ(0).add(Consts.planeNormal);
      this._startC.position.copy(startingPoints.C).setZ(0).add(Consts.planeNormal);
      this._startD.position.copy(startingPoints.D).setZ(0).add(Consts.planeNormal);
      this._startE.position.copy(startingPoints.E).setZ(0).add(Consts.planeNormal);

      if (startingPoints.blockedVertices == "ABC") { this.add(this.cropAB, this.cropBC, this.cropCD, this.cropEA); }
      if (startingPoints.blockedVertices == "BCD") { this.add(this.cropAB, this.cropBC, this.cropCD, this.cropDE); }
      if (startingPoints.blockedVertices == "ACD") { this.add(this.cropAB, this.cropCD, this.cropDE, this.cropEA); }
      if (startingPoints.blockedVertices == "ABD") { this.add(this.cropAB, this.cropBC, this.cropDE, this.cropEA); }

      this.add(this._startD, this._startE, this.croppedD, this.croppedE, this.line4, this.line5);

      this.croppedA.position.copy(currentVertices.A).setZ(0).add(Consts.planeNormal);
      this.croppedB.position.copy(currentVertices.B).setZ(0).add(Consts.planeNormal);
      this.croppedC.position.copy(currentVertices.C).setZ(0).add(Consts.planeNormal);
      this.croppedD.position.copy(currentVertices.D).setZ(0).add(Consts.planeNormal);
      this.croppedE.position.copy(currentVertices.E).setZ(0).add(Consts.planeNormal);

      this.line3.v1 = this.croppedC;
      this.line3.v2 = this.croppedD;
      this.line4.v1 = this.croppedD;
      this.line4.v2 = this.croppedE;
      this.line5.v1 = this.croppedE;
      this.line5.v2 = this.croppedA;
    }

    this._eocPlane.clonedOsteotomyManager.reset();
    this._eocPlane.clonedMechanicalAxisManager.reset();
    this._eocPlane.clonedMechanicalAxisManager.updatePointsToUpdate();
    this.updateInternalLimits();

    return true;
  }

}

<!-- Box table -->
<div class="box-table">

	<span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
		{{ labels.LLD_RECAP_TITLE }}
		<div class="fake-material-icon icon-expand expand"></div>
	</span>

	<table class="table" [ngbCollapse]="isCollapsed">
		<thead>
			<tr>
				<th></th>
				<th>{{ labels.LLD_RECAP_LENGTH_HEADER }} <br />
					({{ (isLeft ? sideEnum.Left : sideEnum.Right) | anatomicalSideLabel }})
				</th>
				<th>{{ labels.LLD_RECAP_CL_LENGTH_HEADER }} <br />
					({{ (isLeft ? sideEnum.Right : sideEnum.Left) | anatomicalSideLabel }})
				</th>
				<th>{{ labels.LLD_RECAP_LLD_HEADER }}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngIf="isLongLeg">
				<td>{{ labels.LLD_RECAP_LONGLEG }}</td>
				<td>{{ rpmMeasures?.rpm_ap_fullLength }}</td>
				<td>{{ defCLMeasures?.def_ap_fullLength_ctrlateral }}</td>
				<td innerHtml="{{ longlegLLD }}" style="min-width: 5rem"></td>
			</tr>
			<tr *ngIf="isLongLeg || isFemur">
				<td>{{ labels.LLD_RECAP_FEMUR }}</td>
				<td>{{ rpmMeasures?.rpm_ap_femurLength }}</td>
				<td>{{ defCLMeasures?.def_ap_femurLength_ctrlateral }}</td>
				<td innerHtml="{{ femurLLD }}" style="min-width: 5rem"></td>
			</tr>
			<tr *ngIf="isLongLeg || isTibia">
				<td>{{ labels.LLD_RECAP_TIBIA }}</td>
				<td>{{ rpmMeasures?.rpm_ap_tibiaLength }}</td>
				<td>{{ defCLMeasures?.def_ap_tibiaLength_ctrlateral }}</td>
				<td innerHtml="{{ tibiaLLD }}" style="min-width: 5rem"></td>
			</tr>
			<tr *ngIf="isLongLeg">
				<td>{{ labels.LLD_RECAP_FEMUR_TIBIA }}</td>
				<td>{{ trueLength }}</td>
				<td>{{ trueLengthCL }}</td>
				<td innerHtml="{{ trueLengthLLD }}" style="min-width: 5rem"></td>
			</tr>
		</tbody>
	</table>

</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BridgeResultMessagesBase, ViewType } from '@ortho-next/nextray-core';
import { environment } from '../../../environments/environment';
import { BridgeResultMessages } from '../../../nextray/Core/Bridge';
import { LanguageService, ToastService } from '../../core';
import { CanvasService, PrinterService } from '../../services';
import { BaseComponent, ConfirmationComponent } from '../../shared';


/**
* Steps enum to handle custom tooltip
*/
enum StepEnum {
  None,
  InsApproach,
  Cut,
  FitBone,
  Stroke,
  View
}


/**
* Component of first vertical sidebar to handle the tools.
*/
@Component({
  selector: 'tools-menu',
  templateUrl: './tools-menu.component.html',
  styles: []
})
export class ToolsMenuComponent extends BaseComponent implements OnInit, OnDestroy {

  cutForm: FormGroup;
  fitboneForm: FormGroup;
  isResizeCrop: boolean;
  step: StepEnum = StepEnum.None;
  stepEnum = StepEnum;

  constructor(
    private langSrv: LanguageService,
    private canvasSrv: CanvasService,
    private printSrv: PrinterService,
    private toastSrv: ToastService,
    private modalSrv: NgbModal
  ) {
    super(langSrv, canvasSrv);
  }

  ngOnInit() {
    this.canvasSrv.addEventListener('onResult', this.updateForms);
    this.canvasSrv.addEventListener('onResult', this.cutError);
    this.isResizeCrop = false;
    this.initCutForm();
    this.initFitboneForm();
    this.updateActiveStep();
  }

  private openWarningModal(message: string, confirmFunction: Function, cancelFunction?: Function): void {
    if (!this.modalSrv.hasOpenModals()) {
      const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
        centered: true, backdrop: 'static'
      });
      (modalRef.componentInstance as ConfirmationComponent).config = {
        title: this.labels['TOOLS_MENU_COMPONENT_WARNING_MODAL_TITLE'],
        message: message
      };
      modalRef.result.then(() => confirmFunction.call(this), () => cancelFunction ? cancelFunction.call(this) : null).catch(error => false);
    }
  }


  /* DEFORMITY MENU  */

  /**
  * Open modal of confirmation before back to deformity analysis
  */
  openBackToDefModal(): void {
    this.openWarningModal(this.labels['TOOLS_MENU_COMPONENT_BACK_DEFORMITY_MODAL_MESSAGE'], this.backToDeformityAnalysis);
  }

  private backToDeformityAnalysis(): void {
    window.location.href = `${environment.nextraySite}?caseGuid=${this.canvasSrv.currentCase.id}`;
  }


  /* FITBONE MENU  */


  /**
  * Start insertion approach workflow.
  */
  openOsteotomyWorkflow(): void {
    if (!this.isEocCut) {
      if (this.isInsPointInserted) {
        const message: string = this.labels['TOOLS_MENU_COMPONENT_INS_APPROACH_MODAL_MESSAGE'];
        this.openWarningModal(message, this.startInsertionApproach);
      } else {
        this.startInsertionApproach();
      }
    }
  }

  private startInsertionApproach(): void {
    this.canvasSrv.dispatch('startSelectingApex');
  }

  /**
  * Event listener for update after restore.
  */
  updateForms = (event) => {
    if (event.args == BridgeResultMessagesBase.restore) {
      this.cutForm.controls.cut.setValue(this.isEocCut);
      this.selectedApex ? this.cutForm.controls.cut.enable() : this.cutForm.controls.cut.disable();
      this.fitboneForm.controls.lock.setValue(this.isStrokeLocked);
      this.isFitboneInserted ? this.fitboneForm.controls.lock.enable() : this.fitboneForm.controls.lock.disable();
      this.updateActiveStep();
    }
  };

  /**
  * Event listener for cut error.
  */
  cutError = (event) => {
    if (event.args == BridgeResultMessages.cannotCut) {
      this.toastSrv.showWarning(this.labels['TOOLS_MENU_COMPONENT_CUT_ERROR']);
      this.isResizeCrop = false;
      this.cutForm.controls.cut.setValue(this.isEocCut);
    }
  };

  private initCutForm(): void {
    this.cutForm = new FormGroup({
      'cut': new FormControl({ value: this.isEocCut, disabled: !this.selectedApex })
    });
  }

  /**
  * Set cut.
  */
  setCut(): void {
    if (this.selectedApex) {
      if (this.isResizeCrop) {
        this.setResize();
      }
      const val = !this.isEocCut;
      this.canvasSrv.dispatch('cutEoC', val);
      this.cutForm.controls.cut.setValue(this.isEocCut);
      this.canvasSrv.saveState();

      this.fitboneForm.reset();
      this.fitboneForm.disable();
      this.updateActiveStep();
    }
  }

  /**
  * Reset cut.
  */
  resetCut(): void {
    if (this.isEocCut) {
      this.setCut();
      this.setCut();
    }
  }

  /**
  * Show/hide crop polygon tool.
  */
  setResize(): void {
    if (this.selectedApex) {
      if (this.isEocCut) {
        this.setCut();
      }
      if (!this.isResizeCrop) {
        this.isResizeCrop = true;
        this.canvasSrv.dispatch('startCropPolygon');
      } else {
        this.isResizeCrop = false;
        this.canvasSrv.dispatch('confirmCropPolygon');
      }
    }
  }

  /**
  * Start fitbone selection workflow.
  */
  openFitboneWorkflow(): void {
    if (this.isEocCut) {
      this.canvasSrv.dispatch('startInsertingFitbone');
    }
  }

  private initFitboneForm(): void {
    this.fitboneForm = new FormGroup({
      'lock': new FormControl({ value: this.isStrokeLocked, disabled: !this.isFitboneInserted })
    });
  }

  /**
  * Set lock.
  */
  setLock(): void {
    if (this.isFitboneInserted) {
      const val = !this.isStrokeLocked;
      this.canvasSrv.dispatch('lockStroke', val);
      this.canvasSrv.saveState();
      this.fitboneForm.controls.lock.setValue(this.isStrokeLocked);
      this.updateActiveStep();
    }
  }

  /**
  * Indicate if blocking screw add button is disabled in active view.
  */
  get isAddDisabled(): boolean {
    return !this.isStrokeLocked || (this.activeView === ViewType.AP ? this.isBlockingScrewFullAP : this.isBlockingScrewFullLT);
  }

  /**
  * Indicate if blocking screw delete button is disabled in active view.
  */
  get isDeleteDisabled(): boolean {
    return !this.isStrokeLocked || (this.activeView === ViewType.AP ? !this.isBlockingScrewSelectedAP : !this.isBlockingScrewSelectedLT);
  }

  /**
  * Add blocking screw in active view.
  */
  addBlockingScrew(): void {
    if (!this.isAddDisabled) {
      this.canvasSrv.dispatch("addBlockingScrew");
    }
  }

  /**
  * Delete blocking screw in active view.
  */
  deleteBlockingScrew(): void {
    if (!this.isDeleteDisabled) {
      this.canvasSrv.dispatch("deleteBlockingScrew");
    }
  }


  /* FOOTER MENU  */

  /**
   * Generate PDF OR plan.
   */
  printOR(): void {
    if (this.isStrokeLocked) {
      this.printSrv.printORPlan();
    }
  }

  /**
   * Show viewer.
   */
  showViewer(): void {
    if (this.isStrokeLocked) {
      this.canvasSrv.dispatch('openViewer');
      this.canvasSrv.saveState();
    }
  }

  /**
  * Update active step to handle custom tooltip.
  */
  private updateActiveStep(): void {
    if (this.isStrokeLocked) this.step = StepEnum.View;
    else if (this.isFitboneInserted) this.step = StepEnum.Stroke;
    else if (this.isEocCut) this.step = StepEnum.FitBone;
    else if (this.isInsPointInserted) this.step = StepEnum.Cut;
    else if (this.layersVisible) this.step = StepEnum.InsApproach;
    else this.step = StepEnum.None;
  }


  ngOnDestroy() {
    this.canvasSrv.removeEventListener('onResult', this.updateForms);
    this.canvasSrv.removeEventListener('onResult', this.cutError);
  }

}

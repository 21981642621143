import { Component, OnInit } from '@angular/core';
import { Utils } from '@ortho-next/nextray-core';
import { Consts } from '../../../nextray/Utils/Consts';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Table of correction measures for AP image.
*/
@Component({
  selector: 'ma-ap-recap',
  templateUrl: './ma-ap-recap.component.html',
  styles: []
})
export class MaApRecapComponent extends BaseComponent implements OnInit {

  isCollapsed: boolean = true;

  apAngles = { ...Consts.paleyAngles.AP };

  constructor(
    private langSrv: LanguageService,
    private canvasSrv: CanvasService
  ) {
    super(langSrv, canvasSrv);
  }

  ngOnInit() { }


  // VISIBILITY

  /**
  * Check if MAD is visible.
  */
  get isMAD(): boolean {
    return this.isLongLeg;
  }

  /**
  * Check if MNSA is visible.
  */
  get isMNSA(): boolean {
    return this.isLongLeg || this.isFemur;
  }

  /**
  * Check if LPFA is visible.
  */
  get isLPFA(): boolean {
    return this.isLongLeg || this.isFemur;
  }

  /**
  * Check if MPFA is visible.
  */
  get isMPFA(): boolean {
    return this.isLongLeg || this.isFemur;
  }

  /**
  * Check if MLDFA is visible.
  */
  get isMLDFA(): boolean {
    return this.isLongLeg || this.isFemur;
  }

  /**
  * Check if JLCA is visible.
  */
  get isJLCA(): boolean {
    return this.isLongLeg;
  }

  /**
  * Check if Knee Translation is visible.
  */
  get isKneeTransl(): boolean {
    return this.isLongLeg;
  }

  /**
  * Check if MPTA is visible.
  */
  get isMPTA(): boolean {
    return this.isLongLeg || this.isTibia;
  }

  /**
  * Check if LDTA is visible.
  */
  get isLDTA(): boolean {
    return this.isLongLeg || this.isTibia;
  }




  // VALIDATION

  /**
  * Check if MAD value is valid.
  */
  get isValidMAD(): boolean {
    return !this.rpmMeasures.rpm_ap_MAD || Utils.isPaleyAngleValid(this.rpmMeasures.rpm_ap_MAD, this.apAngles.MAD);
  }


  /**
  * Check if MNSA value is valid.
  */
  get isValidMNSA(): boolean {
    return !this.rpmMeasures.rpm_ap_MNSA || Utils.isPaleyAngleValid(this.rpmMeasures.rpm_ap_MNSA, this.apAngles.MNSA);
  }


  /**
  * Check if LPFA value is valid.
  */
  get isValidLPFA(): boolean {
    return !this.rpmMeasures.rpm_ap_LPFA || Utils.isPaleyAngleValid(this.rpmMeasures.rpm_ap_LPFA, this.apAngles.mLPFA);
  }


  /**
  * Check if MPFA value is valid.
  */
  get isValidMPFA(): boolean {
    return !this.rpmMeasures.rpm_ap_MPFA || Utils.isPaleyAngleValid(this.rpmMeasures.rpm_ap_MPFA, this.apAngles.MPFA);
  }

  /**
  * Check if MLDFA value is valid.
  */
  get isValidMLDFA(): boolean {
    return !this.rpmMeasures.rpm_ap_mLDFA || Utils.isPaleyAngleValid(this.rpmMeasures.rpm_ap_mLDFA, this.apAngles.mLDFA);
  }

  /**
  * Check if JLCA value is valid.
  */
  get isValidJLCA(): boolean {
    return !this.rpmMeasures.rpm_ap_JLCA || Utils.isPaleyAngleValid(this.rpmMeasures.rpm_ap_JLCA, this.apAngles.JLCA);
  }

  /**
  * Check if Knee Translation value is valid.
  */
  get isValidKneeTransl(): boolean {
    return !this.rpmMeasures.rpm_ap_kneeTranslation || Utils.isPaleyAngleValid(this.rpmMeasures.rpm_ap_kneeTranslation, this.apAngles.KneeTransl);
  }

  /**
  * Check if MPTA value is valid.
  */
  get isValidMPTA(): boolean {
    return !this.rpmMeasures.rpm_ap_MPTA || Utils.isPaleyAngleValid(this.rpmMeasures.rpm_ap_MPTA, this.apAngles.MPTA);
  }

  /**
  * Check if LDTA value is valid.
  */
  get isValidLDTA(): boolean {
    return !this.rpmMeasures.rpm_ap_LDTA || Utils.isPaleyAngleValid(this.rpmMeasures.rpm_ap_LDTA, this.apAngles.LDTA);
  }

  /**
  * Check if a measure is out of range.
  */
  get isMeasureOutOfRange(): boolean {
    return !this.isValidMAD || !this.isValidMNSA || !this.isValidLPFA || !this.isValidMPFA || !this.isValidMLDFA || !this.isValidJLCA || !this.isValidKneeTransl || !this.isValidMPTA || !this.isValidLDTA;
  }



  // ANGLES RANGE

  /**
  * Get MAD range.
  */
  get rangeMAD(): string {
    return `(${this.apAngles.MAD.min}mm - ${this.apAngles.MAD.max}mm)`;
  }

  /**
  * Get MNSA range.
  */
  get rangeMNSA(): string {
    return `(${this.apAngles.MNSA.min}° - ${this.apAngles.MNSA.max}°)`;
  }

  /**
  * Get LPFA range.
  */
  get rangeLPFA(): string {
    return `(${this.apAngles.mLPFA.min}° - ${this.apAngles.mLPFA.max}°)`;
  }

  /**
  * Get MPFA range.
  */
  get rangeMPFA(): string {
    return `(${this.apAngles.MPFA.min}° - ${this.apAngles.MPFA.max}°)`;
  }

  /**
  * Get MLDFA range.
  */
  get rangeMLDFA(): string {
    return `(${this.apAngles.mLDFA.min}° - ${this.apAngles.mLDFA.max}°)`;
  }

  /**
  * Get JLCA range.
  */
  get rangeJLCA(): string {
    return `(${this.apAngles.JLCA.min}° - ${this.apAngles.JLCA.max}°)`;
  }

  /**
  * Get Knee Translation range.
  */
  get rangeKneeTransl(): string {
    return `(${this.apAngles.KneeTransl.min}mm - ${this.apAngles.KneeTransl.max}mm)`;
  }

  /**
  * Get MPTA range.
  */
  get rangeMPTA(): string {
    return `(${this.apAngles.MPTA.min}° - ${this.apAngles.MPTA.max}°)`;
  }

  /**
  * Get LDTA range.
  */
  get rangeLDTA(): string {
    return `(${this.apAngles.LDTA.min}° - ${this.apAngles.LDTA.max}°)`;
  }

}

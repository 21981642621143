import { Config, DraggablePoint, LabelText, LabelTextConfig, LimitUtils, Save, SaveChild, SelectableTool, SelectedApexMech, ToolEvents, ToolToSave, ToolType, VectorUtils, ViewType } from "@ortho-next/nextray-core";
import { LimitLine } from "@ortho-next/nextray-core/Tools/Primitive/LimitLine";
import { LinePointToPoint2 } from "@ortho-next/nextray-core/Tools/Primitive/LinePointToPoint2";
import { Group, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { MeasuresUtils } from '../../Utils/MeasuresUtils';
import { MechanicalAxisAP } from '../DeformityAnalyzer/FullAnalyzerAP';
import { MechanicalAxisLT } from '../DeformityAnalyzer/FullAnalyzerLT';
import { ReferencePoint } from '../ReferencePoint';
import { Default } from "@ortho-next/nextray-core/Utils/Default";

/**
 * TODO remove reference point from here
 */
export abstract class OsteotomyBase extends Group implements ToolToSave, SelectableTool {
  public isSelectable = true;
  public isDeletable = false;
  public isSelected: boolean;
  public toolType = ToolType.osteotomy;
  @Save('position') public A: DraggablePoint;
  @Save('position') public B: DraggablePoint;
  @Save('position') public C: DraggablePoint;
  public ABLine: LinePointToPoint2;
  @SaveChild() public label: LabelText;
  @Save() public startingA: Vector3;
  @Save() public startingB: Vector3;
  protected abstract _mechanicalAxis: MechanicalAxisAP | MechanicalAxisLT;
  protected _referencePoint: ReferencePoint;
  protected _initialLength = 30;
  @Save() protected _axisPoint1: Vector3;
  @Save() protected _axisPoint2: Vector3;
  @Save() protected _axisPerp: Vector3;
  @Save() protected _axisDir: Vector3;
  @Save() protected _axisValidPoint: Vector3;
  protected _perpTranslation: Vector3;
  protected _limitLines: LimitLine[] = [];
  protected _enableLimits = false; // refresh page to make it works (to improve performance when don't use it)
  protected _isEOC: boolean
  /**
   * Error when initial translation + 1mm > lenght
   */
  private _errorLength: boolean;

  public abstract setPositionByApex(selectedApex: SelectedApexMech, distanceToReferenceePoint?: number): void;
  public abstract bindProperties(): void;

  public onAfterRestore = () => {
    this.update();
    this._isEOC && this.dispatchEvent({ type: ToolEvents.updated }); //todo fix con callback generale
  }

  public onSelect(): void {
    Default.colorAllDraggablePoints(this);
  }

  public onDeselect(): void {
    Default.restoreColorAllDraggablePoints(this);
  }

  constructor(viewType: ViewType, referencePoint: ReferencePoint, isEOC?: boolean) {
    super();
    this.name = 'Osteotomy';
    this._referencePoint = referencePoint;
    this._isEOC = isEOC;

    if (this._enableLimits) {
      this._limitLines[0] = new LimitLine(viewType, 0xffff00);
      this._limitLines[1] = new LimitLine(viewType, 0xffff00);
      this._limitLines[2] = new LimitLine(viewType, 0x00ffff);
      this._limitLines[3] = new LimitLine(viewType, 0x00ffff);
    }

    this.add(
      this.A = new DraggablePoint('A', viewType, Config.osteotomy_colorHex, new Vector3(this._initialLength / 2, 0, 0)),
      this.B = new DraggablePoint('B', viewType, Config.osteotomy_colorHex, new Vector3(this._initialLength / -2, 0, 0)),
      this.C = new DraggablePoint('C', viewType, Config.osteotomy_colorHex, this.A.position.clone().lerp(this.B.position, 0.5)),
      this.ABLine = new LinePointToPoint2('ABLine', viewType, Config.osteotomy_colorHex, this.A, this.B),
      this.label = new LabelText({
        name: 'label',
        color: LabelTextConfig.color,
        viewType,
        objToAnchor: this.C,
        anchor: new Vector3(0, 5, 0),
        labelParams: { text: 'Osteotomy', backgroundColor: Config.osteotomy_colorCssAlpha, fontColor: Config.osteotomy_fontColor }
      }),
      ...this._limitLines
    );

    this.bindEvents();

    this.bindProperties();
  }

  /**
   * Set osteotomy length error when initialtranslation + 1mm > lenght
   */
  private set errorLength(value: boolean) {
    if (this._errorLength !== value) {
      this._errorLength = value;
      if (value) {
        this.dispatchEvent({ type: ToolEvents.onError });
      }
    }
  }

  public get length(): number {
    return this.A.position.distanceTo(this.B.position);
  }

  public get distanceToReferencePoint(): number { //remove from here?
    const intersectionOsteotomyOnAxis = VectorUtils.projectOnVector(this.C.position, this._referencePoint.position, this._referencePoint.position.clone().add(this._axisDir));
    const sign = VectorUtils.computeSign(this.C.position, this._referencePoint.position, this._axisDir);
    return this._referencePoint.position.clone().setZ(0).distanceTo(intersectionOsteotomyOnAxis) * sign;
  }

  public set distanceToReferencePoint(value: number) {
    const newCenter = this._referencePoint.position.clone().setZ(0).add(this._axisDir.clone().setLength(value)).add(this._perpTranslation);
    this.updateCenter(newCenter);
  }

  protected bindEvents(): void {
    this.bindEvent("onEnabledChange", (value) => {
      this.interceptedByRaycaster = value;
    });

    this.bindEvent('onAfterDragMove', () => {
      this.update();
      const distance = MeasuresUtils.getHorizontalTranslation(this, this._mechanicalAxis, this.C.position, this._mechanicalAxis.isLeft);
      this.errorLength = Math.abs(distance) + 1 > this.length;
    });

    this.bindEvent('onDragEnd', () => {
      this.clearLimits();
    });

    this.A.bindEvent('onDragMove', (args) => {
      LimitUtils.applyLimitAndProjectVector(this._axisPoint1, this._axisPoint1.clone().add(this._axisPerp), args.position, this._axisValidPoint, 0, this._limitLines[0]);
      LimitUtils.applyLimitAndProjectVector(this._axisPoint2, this._axisPoint2.clone().add(this._axisPerp), args.position, this._axisValidPoint, 0, this._limitLines[1]);
      this.C.position.copy(args.position.clone().lerp(this.B.position, 0.5));
    });

    this.B.bindEvent('onDragMove', (args) => {
      LimitUtils.applyLimitAndProjectVector(this._axisPoint1, this._axisPoint1.clone().add(this._axisPerp), args.position, this._axisValidPoint, 0, this._limitLines[0]);
      LimitUtils.applyLimitAndProjectVector(this._axisPoint2, this._axisPoint2.clone().add(this._axisPerp), args.position, this._axisValidPoint, 0, this._limitLines[1]);
      this.C.position.copy(args.position.clone().lerp(this.A.position, 0.5));
    });

    this.C.bindEvent('onDragMove', (args) => {
      LimitUtils.applyLimitAndProjectVectorCenterLinePoint(this._axisPoint1, this._axisPoint1.clone().add(this._axisPerp), this.C.position, args.position, this.A.position, this.B.position, this._axisValidPoint, this._limitLines[0], this._limitLines[1]);
      LimitUtils.applyLimitAndProjectVectorCenterLinePoint(this._axisPoint2, this._axisPoint2.clone().add(this._axisPerp), this.C.position, args.position, this.A.position, this.B.position, this._axisValidPoint, this._limitLines[2], this._limitLines[3]);
      this.translateAB(args.position);
    });
  }

  protected update(): void {
    if (this._axisPoint1 && this._axisPoint2) { // cloned osteotomy doesn't use them
      const intersectionOsteotomyOnAxis = VectorUtils.projectOnVector(this.C.position, this._referencePoint.position, this._referencePoint.position.clone().add(this._axisDir));
      this._perpTranslation = this.C.position.clone().sub(intersectionOsteotomyOnAxis);
    }
  }

  protected updateCenter(newCenter: Vector3): void {
    this.translateAB(newCenter);
    this.C.position.copy(newCenter);
  }

  protected updateCenterAndOsteotomyStartPoints(newCenter: Vector3): void {
    this.updateCenter(newCenter);
    this.startingA = this.A.position.clone();
    this.startingB = this.B.position.clone();
  }

  protected translateAB(newCenter: Vector3): void {
    const pan = this.C.position.clone().sub(newCenter);
    this.A.position.sub(pan);
    this.B.position.sub(pan);
  }

  protected placeOsteotomyByApex(origin: Vector3, direction: Vector3): void {
    this.C.position.copy(origin);
    this.A.position.copy(origin).add(direction);
    this.B.position.copy(origin).sub(direction);
    this.startingA = this.A.position.clone();
    this.startingB = this.B.position.clone();
  }

  protected setPointsForSync(p1: Vector3, p2: Vector3): void {
    this._axisPoint1 = p1.clone();
    this._axisPoint2 = p2.clone();
    this._axisDir = p1.clone().sub(p2).normalize();
    this._axisPerp = VectorUtils.getPerpendicular(this._axisDir);
    this._axisValidPoint = p1.clone().lerp(p2, 0.5);
  }

  protected clearLimits(): void {
    for (const limitLine of this._limitLines) {
      limitLine.visible = false;
    }
  }
}
